import { useEffect, useCallback, useState } from 'react';

// MUI
import Icon from '@mui/material/Icon';

// WRM
import { requestApi } from 'api/request-api';
import ListResources from 'components/shared/ListResources';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const title = 'Web pages';
const apiEndpoint = 'web-pages';
const resourceName = 'web page';

const filters = [
  {
    field: '_webPageSearch',
    label: 'Search',
    type: 'text',
    apiAttribute: 'webPageSearch',
  },
  {
    field: 'parentPage',
    label: 'Parent page',
    type: 'select',
  },
  {
    field: '_topLevelOnly',
    label: 'Top level only',
    type: 'checkbox',
    apiAttribute: 'exists[parentPage]',
    apiValue: 'false'
  },
  {
    field: '_excludeArchived',
    label: 'Exclude archived',
    type: 'checkbox',
    apiAttribute: 'isArchived',
    apiValue: 'false',
  },
  {
    field: 'orderAsTree',
    label: 'Tree view',
    type: 'checkbox',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const WebPages = () => {
  const [initialised, setInitialised] = useState(false);
  // A local state for filterValues that will be percolated up from ListResources so that columns
  // can turn indentation on/off when the orderAsTree filter is true
  const [filterValues, setFilterValues] = useState({});

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const columns = [
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      formatter: useCallback((resource) => {
        const { name, namePath } = resource;
        // If orderAsTree is not true, render only the WebPage name
        if (!filterValues.orderAsTree) {
          return name;
        }
        // If orderAsTree is true, render the required indentation
        const namePathPartsLength = (namePath ?? '').split(' | ').filter((namePathPart) => namePathPart.trim() !== '').length;
        return (
          <>
            {[...Array(namePathPartsLength)].map(() => (
              <Icon fontSize="small" sx={{padding: '5px 0px'}}>chevron_right</Icon>
            ))}
            {name}
          </>
        );
      }),
    },
    {
      field: 'namePath',
      label: 'Parent page',
    },
    {
      field: 'uri',
      label: 'Link',
      sortable: true,
    },
  ];

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const webPageChoices = await requestApi.getResponse({ url: 'web-pages/choices' });
      const noChoice = { value: '', label: '--- Remove filter ---' }
      const parentPageFilter = filters.find((filter) => filter.field === 'parentPage');
      parentPageFilter.choices = [noChoice, ...webPageChoices];
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultSortValues={defaultSortValues}
      filters={filters}
      resourceName={resourceName}
      setFilterValues={setFilterValues}
      title={title}
    />
  );
};

export default WebPages;
