import { useMemo } from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { resourceApi } from 'api/resource-api';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';
import AddEditIplicitOrderExportForm from './AddEditIplicitOrderExportForm';

const apiEndpoint = 'iplicit-order-exports';
const resourceName = 'Iplicit order export';

const iplicitCustomerSearch = (search, setChoices) => {
  if (search.length) {
    resourceApi.getResources({
      apiEndpoint: 'iplicit-customers',
      filterValues: { search },
      sortValues: [{ field: 'name', direction: 'asc' }],
    }).then((response) => {
        const iplicitCustomers = response.resources;
        if (!Array.isArray(iplicitCustomers)) {
          setChoices([]);
        }
        const choices = iplicitCustomers.map((iplicitCustomer) => {
          const iplicitCustomerParts = [];
          if (iplicitCustomer?.code) iplicitCustomerParts.push(iplicitCustomer.code);
          if (iplicitCustomer?.name) iplicitCustomerParts.push(iplicitCustomer.name);
          if (iplicitCustomer?.fullAddress) iplicitCustomerParts.push(iplicitCustomer.fullAddress);
          return {
            id: iplicitCustomer['@id'],
            label: iplicitCustomerParts.join(' / '),
          };
        });
        setChoices(choices);
      });
  } else {
    setChoices([]);
  }
};

const fields = [
  {
    name: 'manuallyConfirmedIplicitCustomer',
    label: 'Or manually search for a Iplicit Customer',
    type: 'typeahead',
    formValue: () => null,
  },
  {
    name: 'countryCode',
    label: 'Country code',
    type: 'text',
  }
];

const validationSchema = Yup.object().shape({
});

const toApiValuesCustom = (formValues, fieldsToMap) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fieldsToMap);

  if (formValues.manuallyConfirmedIplicitCustomer?.id) {
    apiValues.confirmedIplicitCustomer = formValues.manuallyConfirmedIplicitCustomer.id;
  }

  return apiValues;
}

const AddEditIplicitOrderExport = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const debounceDelayInMilliseconds = 280;
  const debouncedIplicitCustomerSearch = useMemo(
    () => debounce((search, setChoices) => iplicitCustomerSearch(search, setChoices), debounceDelayInMilliseconds),
    []
  );
  const manuallyConfirmedIplicitCustomerField = fields.find((field) => field.name === 'manuallyConfirmedIplicitCustomer');
  manuallyConfirmedIplicitCustomerField.search = debouncedIplicitCustomerSearch;

  return (
    <AddEditResource
      addEditForm={AddEditIplicitOrderExportForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditIplicitOrderExport;
