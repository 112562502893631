import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from "@mui/icons-material/Delete";

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'components/shared/Table';
import useMounted from 'hooks/use-mounted';
import AddEditProductGroupPart from "./AddEditProductGroupPart";

const ProductGroupParts = props => {
  const {
    formik,
    product,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productGroupParts, setProductGroupParts] = useState([]);
  const [productGroupPartToEdit, setProductGroupPartToEdit] = useState(null);
  const [addEditProductGroupPartModalOpen, setAddEditProductGroupPartModalOpen] = useState(false);

  const isMounted = useMounted();

  const loadProductGroupParts = async () => {
    const data = await resourceApi.getResources({
      apiEndpoint: 'product-group-parts',
      filterValues: {
        product: product['@id'],
      },
      sortValues: [
        {
          field: 'displayOrder',
          direction: 'asc',
        },
      ],
      pagination: {
        itemsPerPage: 999999,
      },
    });
    if (isMounted()) {
      setProductGroupParts(data.resources);
    }
    return data.resources;
  }

  const initialise = useCallback(async () => {
    await loadProductGroupParts();
    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedProductGroupParts = [];
    const movedProductGroupPart = productGroupParts[result.source.index];
    productGroupParts.forEach((productGroupPart, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedProductGroupParts.push(movedProductGroupPart);
      }
      if (index !== result.source.index) {
        reorderedProductGroupParts.push(productGroupPart);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedProductGroupParts.push(movedProductGroupPart);
      }
    });
    setProductGroupParts(reorderedProductGroupParts);

    // Reorder productGroupParts in the calling form
    const productGroupPartsValue = formik.values.productGroupParts;
    const reorderedProductGroupPartValues = [];
    const movedProductGroupPartValue = productGroupPartsValue[result.source.index];
    productGroupPartsValue.forEach((productGroupPartValue, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedProductGroupPartValues.push(movedProductGroupPartValue);
      }
      if (index !== result.source.index) {
        reorderedProductGroupPartValues.push(productGroupPartValue);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedProductGroupPartValues.push(movedProductGroupPartValue);
      }
    });
    formik.setFieldValue('productGroupParts', reorderedProductGroupPartValues);
  };

  const deleteProductGroupPart = (deleteIndex) => {
    setProductGroupParts(productGroupParts.filter((part, index) => index !== deleteIndex));

    const formProductGroupParts = formik.values.productGroupParts;
    formik.setFieldValue(
        'productGroupParts',
        formProductGroupParts.filter((part, index) => index !== deleteIndex)
    );
  };

  const addProductGroupPart = () => {
    setProductGroupPartToEdit(null);
    setAddEditProductGroupPartModalOpen(true);
  };

  const editProductGroupPart = (productGroupPart) => {
    setProductGroupPartToEdit(productGroupPart);
    setAddEditProductGroupPartModalOpen(true);
  };

  const closeAddEditProductGroupPartModal = async () => {
    setAddEditProductGroupPartModalOpen(false);
    const updatedProductGroupParts = await loadProductGroupParts();
    // update parent page form otherwise it will overwrite on save
    formik.setFieldValue(
        'productGroupParts',
        updatedProductGroupParts.map(part => part['@id'])
    );
  }

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Products in group</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
          >
            <Grid
              item
              xs={12}
            >
              <Box display="flex" justifyContent="flex-end">
                <MDButton
                  color="info"
                  onClick={addProductGroupPart}
                  variant="gradient"
                >
                  Add product to group
                </MDButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={20} />
                    <TableCell heading>
                      SKU
                    </TableCell>
                    <TableCell heading>
                      Product
                    </TableCell>
                    <TableCell heading>
                      Group with (on product page)
                    </TableCell>
                    <TableCell heading>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="product-group-parts-droppable" direction="vertical">
                    {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            productGroupParts.length === 0 &&
                            <TableRow>
                              <TableCell align="center" colSpan={4}>
                                No products in group
                              </TableCell>
                            </TableRow>
                          }
                          {
                            productGroupParts.map((productGroupPart, index) => (
                              <Draggable
                                draggableId={`product-group-part-${productGroupPart.id}`}
                                index={index}
                                key={`product-group-part-${productGroupPart.id}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      dragHandleProps={draggableProvided.dragHandleProps}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none"
                                      }}
                                      onDoubleClick={() => editProductGroupPart(productGroupPart)}
                                    >
                                      <TableCell>
                                        {productGroupPart.partProduct?.sku}
                                      </TableCell>
                                      <TableCell>
                                        {productGroupPart.partProduct?.name}
                                      </TableCell>
                                      <TableCell>
                                        {
                                          productGroupPart.groupWithProductGroupPart &&
                                          `${productGroupPart.groupWithProductGroupPart.partProduct.sku} (${productGroupPart.groupWithProductGroupPart?.partProduct.name})`
                                        }
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                            onClick={() => deleteProductGroupPart(index)}
                                        >
                                          <DeleteIcon
                                              color="error"
                                              fontSize="small"
                                          />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            ))
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </Table>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={addEditProductGroupPartModalOpen}
        onClose={closeAddEditProductGroupPartModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-product-group-part"
        aria-describedby="add-edit-product-group-part"
      >
        <DialogContent>
          <AddEditProductGroupPart
            productGroupPart={productGroupPartToEdit}
            product={product}
            onClose={closeAddEditProductGroupPartModal}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

ProductGroupParts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

ProductGroupParts.defaultProps = {
}

export default ProductGroupParts;
