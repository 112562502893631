import PropTypes from "prop-types";

// MUI
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// WRM
import getUserName from "utils/get-user-name";
import convertPriceToPounds from "utils/convert-price-to-pounds";

// MDPR
import MDButton from "mdpr2/components/MDButton";
import MDBadge from "mdpr2/components/MDBadge";
import {useNavigate} from "react-router-dom";

const addressProperties = [
    'email',
    'company',
    'line1',
    'line2',
    'line3',
    'townCity',
    'county',
    'postcode',
    'country',
    'phone',
];

const schoolAddressProperties = [
    'name',
    'addressStreet',
    'addressLocality',
    'addressTownCity',
    'addressPostcode',
    'addressCountry'
];

const buildAddressParts = (address) => {
    const addressParts = [];
    addressParts.push(`${address.firstName} ${address.lastName}`);
    addressProperties.forEach(addressProperty => {
        if (address[addressProperty] !== undefined && address[addressProperty]) {
            addressParts.push(address[addressProperty]);
        }
    });
    return addressParts;
}

const buildSchoolAddressParts = (school) => {
    const addressParts = [];
    schoolAddressProperties.forEach(addressProperty => {
        if (school[addressProperty] !== undefined && school[addressProperty]) {
            addressParts.push(school[addressProperty]);
        }
    });
    return addressParts;
}

const OrderPreviewDialog = ({open, previewOrder, close, context}) => {

    const navigate = useNavigate();

    const billingAddressParts = previewOrder?.billingAddress ? buildAddressParts(previewOrder?.billingAddress) : [];
    const deliveryAddressParts = previewOrder?.deliveryAddress ? buildAddressParts(previewOrder?.deliveryAddress) : [];
    const schoolAddressParts = previewOrder?.school ? buildSchoolAddressParts(previewOrder?.school) : [];
    const memberships = previewOrder?.memberships ?? [];
    const orderLines = previewOrder?.orderLines ?? [];

    const orderLinesTable = () => (
            <TableContainer>
                <Table>
                    <TableBody>
                        {/* floating TableHeader not working so header row here */}
                        <TableRow>
                            <TableCell variant="head">Product</TableCell>
                            <TableCell variant="head" align="right">Quantity</TableCell>
                            <TableCell variant="head" align="right">Line total</TableCell>
                        </TableRow>
                        {
                            orderLines.map(orderLine => (
                                <TableRow>
                                    <TableCell>
                                        <p>{orderLine.orderItem.name}</p>
                                        <small>
                                            {orderLine.orderItem.sku}
                                            {
                                                orderLine.orderItem.attributes.map(attribute => (
                                                        <>
                                                            <br />
                                                            {attribute.name}: {attribute.value}
                                                        </>
                                                    ))
                                            }
                                            {
                                                // New orderData format
                                                Array.isArray(orderLine.orderData) &&
                                                orderLine.orderData.map(orderDataElement => (
                                                        <>
                                                            <br />
                                                            {orderDataElement.label}: {orderDataElement.value}
                                                        </>
                                                    ))
                                            }
                                            {
                                                // Old orderData format - remove when Order data is clean
                                                !Array.isArray(orderLine.orderData) &&
                                                Object.keys(orderLine.orderData).map(orderDataAttribute => (
                                                        <>
                                                            <br />
                                                            {orderDataAttribute}: {orderLine.orderData[orderDataAttribute]}
                                                        </>
                                                    ))
                                            }
                                        </small>
                                    </TableCell>
                                    <TableCell align="right">
                                        {orderLine.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                        £{convertPriceToPounds(orderLine.linePrice.excTax)}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );

    const membershipsTable = () => (
            <TableContainer sx={{mt: 3}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Memberships created</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                        {
                            memberships.map(membership => {
                                // If the Membership is associated with a TeamMembership, provide a link to that
                                let url = null;
                                let badgeContent = null;
                                let teamMembershipName = null;
                                if (membership.teamMembership) {
                                    url = `/admin/team-memberships/edit/${membership.teamMembership.id}`
                                    badgeContent = 'Team';
                                    teamMembershipName = `${membership.teamMembership.name}`;
                                } else {
                                    url = `/admin/memberships/edit/${membership.id}`
                                    badgeContent = 'Individual';
                                }
                                let badgeColour = 'error'
                                if (membership.status === 'current') {
                                    if (membership.isActive) {
                                        badgeColour = 'success'
                                    } else {
                                        badgeColour = 'warning'
                                    }
                                }
                                return(
                                    <TableRow>
                                        <TableCell>
                                            {membership.membershipPlan.name}<br/>
                                            {teamMembershipName}
                                        </TableCell>
                                        <TableCell>
                                            {badgeContent && <MDBadge color={badgeColour} badgeContent={badgeContent}/>}
                                        </TableCell>
                                        <TableCell>
                                            <MDButton
                                                variant="outlined"
                                                color="dark"
                                                iconOnly
                                                onClick={() => navigate(url)}
                                                sx={{mr: 1}}
                                            >
                                                <EditIcon/>
                                            </MDButton>
                                            <MDButton
                                                variant="outlined"
                                                color="dark"
                                                iconOnly
                                                onClick={() => window.open(url, '_blank')}
                                            >
                                                <OpenInNewIcon/>
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle>
                {
                    previewOrder && <>Order #{previewOrder.reference} - {previewOrder.status.replace('_', ' ').toUpperCase()}</>
                }
            </DialogTitle>
            <DialogContent>
                {
                    previewOrder &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6}>
                            { orderLinesTable() }
                            { memberships.length > 0 && membershipsTable() }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {
                                previewOrder.paymentStatus === 'unpaid' &&
                                <Typography variant="h5" gutterBottom>
                                    Awaiting payment via {previewOrder.paymentMethod}
                                </Typography>
                            }
                            {
                                previewOrder.paymentStatus === 'expected' &&
                                <Typography variant="h5" gutterBottom>
                                    Payment expected via {previewOrder.paymentMethod}
                                </Typography>
                            }
                            {
                                previewOrder.paymentStatus === 'paid' &&
                                <Typography variant="h5" gutterBottom>
                                    Payment via {previewOrder.paymentMethod}
                                </Typography>
                            }

                            <Typography variant="h5" gutterBottom>Customer</Typography>
                            <Box marginBottom={1}>
                                { previewOrder.user && getUserName(previewOrder.user) }
                                &nbsp;
                                { previewOrder.email }
                            </Box>

                            <Typography variant="h5" gutterBottom>School</Typography>
                            <Box marginBottom={1}>
                                { previewOrder.school && schoolAddressParts.map(schoolAddressPart => <>{schoolAddressPart}<br /></>) }
                                { previewOrder.school === null && (
                                    <>
                                        No school set on this order
                                    </>
                                )}
                            </Box>

                            <Typography variant="h5" gutterBottom>Billing address</Typography>
                            <Box marginBottom={1}>
                                { billingAddressParts.map(billingAddressPart => <>{billingAddressPart}<br /></>) }
                            </Box>

                            <Typography variant="h5" gutterBottom>Delivery address</Typography>
                            <Box marginBottom={1}>
                                { deliveryAddressParts.map(deliveryAddressPart => <>{deliveryAddressPart}<br /></>) }
                            </Box>

                            <Typography variant="h5" gutterBottom>{previewOrder.shippingServiceName}</Typography>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <MDButton
                    color="info"
                    href={`${context === 'warehouse' ? `/admin/warehouse/orders/edit/${previewOrder?.id}` : `/admin/orders/edit/${previewOrder?.id}`}`}
                    sx={{mr: 1}}
                >
                    Edit
                </MDButton>
                <MDButton color="secondary" autoFocus onClick={close}>
                    Close
                </MDButton>
            </DialogActions>
        </Dialog>
    );
};

OrderPreviewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    previewOrder: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    context: PropTypes.string.isRequired
}

export default OrderPreviewDialog;