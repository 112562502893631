import { useState } from 'react';
import { Field, Formik } from 'formik';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// WRM
import { requestApi } from 'api/request-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import PropTypes from "prop-types";

const AddEditStockChangeForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: stockChangeLog,
    validationSchema,
  } = props;

  const [productChoices, setProductChoices] = useState([]);

  const searchProducts = async (event) => {
    const search = event.target.value;
    if (search.length) {
      // eslint-disable-next-line no-shadow
      const productChoices = await requestApi.getResponse({ url: `products/products-as-choices?search=${encodeURIComponent(search)}` });
      setProductChoices(productChoices);
    } else {
      setProductChoices([]);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                {
                  stockChangeLog.id &&
                  <FormField
                    type="static"
                    name="product"
                    label="Product"
                    value={`${stockChangeLog.product?.sku} (${stockChangeLog.product?.name})`}
                  />
                }
                {
                  !stockChangeLog.id &&
                  <Grid
                    item
                    key="grid-product"
                    xs={12}
                  >
                    <Field
                      name="product"
                      component={Autocomplete}
                      fullWidth
                      options={productChoices}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) => {
                        if (value) {
                          formik.setFieldValue('product', value.value);
                        }
                      }}
                      renderInput={(fieldParams) => (
                        <TextField
                          {...fieldParams}
                          fullWidth
                          label="Search for product"
                          onChange={searchProducts}
                          helperText={getApiError('product') || (formik.touched.product && formik.errors.product)}
                          error={(getApiError('product') || (formik.touched.product && formik.errors.product)) && true}
                        />
                      )}
                    />
                  </Grid>
                }

                <FormField
                  {...fields.find((field) => field.name === 'flow')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'location')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'quantity')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'tags')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'notes')}
                  formik={formik}
                  getApiError={getApiError}
                />
              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                cancelLocation={cancelLocation}
                formik={formik}
              />
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

AddEditStockChangeForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditStockChangeForm;
