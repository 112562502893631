import { useCallback, useEffect, useState } from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';
import useMounted from 'hooks/use-mounted';
import AddEditProductVariantForm from './AddEditProductVariantForm';

const apiEndpoint = 'product-variants';
const resourceName = 'product variant';

const productVariantOptionValuesGetType = (values, field) => {
  const parentField = field.replace('.value', '');
  const { productOption } = getIn(values, parentField);
  if (productOption.dataType === 'select') {
    return 'select';
  }
  if (productOption.dataType === 'text') {
    return 'text';
  }

  return 'hidden'
}

const productVariantOptionValuesGetChoices = (values, field) => {
  const parentField = field.replace('.value', '');
  const { productOption } = getIn(values, parentField);
  const { options } = productOption;
  const choices = [];
  choices.push({value: '', label: 'Unset option...'});
  options.forEach(option => {
    const choice = {
      'value': option.value,
      'label': option.label,
    }
    choices.push(choice);
  });

  return choices;
};

const fields = [
  {
    name: 'product',
    label: 'Product',
    type: 'hidden',
  },
  {
    name: 'sku',
    label: 'SKU',
  },
  {
    name: 'price',
    label: 'Variant-specific price',
    type: 'currency',
  },
  {
    name: 'warehouseValue',
    label: 'Variant-specific warehouse value',
    type: 'currency',
  },
  {
    name: 'packingUnits',
    label: 'Variant-specific packing units',
    type: 'decimal',
  },
  {
    name: 'productVariantOptionValues',
    label: 'Options',
    type: 'fieldCollection',
    childFields: [
      {
        name: 'value',
        label: 'Value',
        getType: productVariantOptionValuesGetType,
        getChoices: productVariantOptionValuesGetChoices,
      },
    ],
  },
  {
    name: 'membershipPlans',
    label: 'Gives access to membership plans',
    type: 'selectAsTable',
    choices: [],
  },
];

const validationSchema = Yup.object().shape({
  sku: Yup.string()
    .required('SKU is required'),
  price: Yup.number().nullable()
    .typeError('Invalid price'),
});

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (values, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(values, fields);

  // Additional mapping for productVariantOptionValues - see notes in toFormValuesCustom.
  apiValues.productVariantOptionValues = [];
  values.productVariantOptionValues.forEach(productVariantOptionValue => {
    const productVariantOptionValueApiValues = {};
    // Set @id, productOption and value API values for the ProductVariantOptionValue
    productVariantOptionValueApiValues['@id'] = productVariantOptionValue['@id'];
    // Reduce the full productOption data to a single IRI data (for the API)
    productVariantOptionValueApiValues.productOption = productVariantOptionValue.productOption['@id'];
    productVariantOptionValueApiValues.value = productVariantOptionValue.value;
    // Only add the ProductVariantOptionValue if its value is set
    if (productVariantOptionValueApiValues.value) {
      apiValues.productVariantOptionValues.push(productVariantOptionValueApiValues);
    }
  });

  return apiValues;
}

const AddEditProductVariant = (props) => {
  const {
    isModal,
    onModalClose,
    parent: product,
    productVariantId,
  } = props;

  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const params = useParams();
  let id;
  if (productVariantId) {
    id = productVariantId;
  } else if (product) {
    id = null;
  } else {
    id = params.id ? Number(params.id) : null;
  }

  const initialise = useCallback(async () => {
    const membershipPlanChoices = await requestApi.getResponse({ url: 'membership-plans/choices' });
    if (isMounted()) {
      const membershipPlansField = fields.find((field) => field.name === 'membershipPlans');
      membershipPlansField.choices = membershipPlanChoices;
      const productField = fields.find((field) => field.name === 'product');
      productField.defaultValue = product['@id'];
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    // Perform standard mapping
    const formValues = toFormValues(resource, fields);

    // Create form value for id (used by ProductBundleParts)
    formValues.id = resource.id;

    // Additional mapping for membershipPlans
    if (typeof resource.membershipPlans === 'undefined') {
      formValues.membershipPlans = [];
    }

    // Additional mapping for productVariantOptionValues.
    // Each ProductVariant has any number of ProductVariantOptionValues. The API returns only ProductVariantOptionValues
    // that currently exist (in any order), but the form renders a ProductVariantOptionValue field for each ProductOption.
    // This mapper iterates the ProductOptions and EITHER finds a matching ProductVariantOptionValues OR creates one.
    //
    // A Product's ProductOptions is a combination of the ProductOptions assigned to the Product's ProductCategories
    // and the ProductOptions assigned specifically to the Product.
    const productOptions = [];
    product.productCategories?.forEach(productCategory => {
      productCategory.productOptions?.forEach(productOption => {
        // Check that the ProductOption doesn't already exist in productOptions
        if (!productOptions.some(po => po['@id'] === productOption['@id'])) productOptions.push(productOption);
      });
    })
    product.productOptions.forEach(productOption => {
      // Check that the ProductOption doesn't already exist in productOptions
      if (!productOptions.some(po => po['@id'] === productOption['@id'])) productOptions.push(productOption);
    });
    formValues.productVariantOptionValues = [];
    productOptions.forEach(productOption => {
      const productVariantOptionValueIndexesForProductOption = resource.productVariantOptionValues?.map(
        (productVariantOptionValue, index) => productVariantOptionValue.productOption['@id'] === productOption['@id'] ? index : ''
      ).filter(String);

      if (productVariantOptionValueIndexesForProductOption && productVariantOptionValueIndexesForProductOption.length > 0) {
        // ProductVariantOptionValues exist for this ProductOption
        productVariantOptionValueIndexesForProductOption.forEach(productVariantOptionValueIndexForProductOption => {
          const productVariantOptionValue = resource.productVariantOptionValues[productVariantOptionValueIndexForProductOption];
          // Convert to form values
          const productVariantOptionValueFormValues = {};
          productVariantOptionValueFormValues['@id'] = productVariantOptionValue['@id'];
          // productVariantOptionValue.productOption does not contain the required productOptionValues, but productOption does
          productVariantOptionValueFormValues.productOption = productOption;
          productVariantOptionValueFormValues.value = productVariantOptionValue.value;
          formValues.productVariantOptionValues.push(productVariantOptionValueFormValues);
        });
      } else {
        // Create a new ProductVariantOptionValue for this ProductOption
        const productVariantOptionValueFormValues = {};
        productVariantOptionValueFormValues.productOption = productOption;
        formValues.productVariantOptionValues.push(productVariantOptionValueFormValues);
      }
    });

    return formValues;
  }

  return (
    <AddEditResource
      addEditForm={AddEditProductVariantForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal={isModal}
      onModalClose={onModalClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditProductVariant.propTypes = {
  isModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  parent: PropTypes.object.isRequired,
  productVariantId: PropTypes.number,
};

AddEditProductVariant.defaultProps = {
  isModal: false,
  onModalClose: null,
  productVariantId: null,
}

export default AddEditProductVariant;
