import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Box,
  Card,
  CardActions,
  CardContent, Dialog, DialogContent,
  Grid, TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';
import TimelineItem from 'mdpr2/examples/Timeline/TimelineItem';

// WRM
import { resourceApi } from 'api/resource-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import ListResourcesTable from 'components/shared/ListResources/ListResourcesTable';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';
import getDateLocaleString from 'utils/get-date-locale-string';
import getUserName from 'utils/get-user-name';
import CommentIcon from "@mui/icons-material/Comment";
import AddEditMembership from "../Memberships/AddEditMembership";
import {useAppContext} from "../../../contexts/app-context";
import TeamOwnerCard from "./TeamOwnerCard";

const membershipColumns = [
  {
    field: 'openInNewTab',
    label: ' ',
    flex: 0.1,
    formatter: (resource) => {
      const url = `/admin/users/edit/${resource.user.id}`;
      return (
        <MDButton color="info" iconOnly className="open-in-new-tab" onClick={() => window.open(url)}><OpenInNewIcon /></MDButton>
      );
    }},
  {
    field: 'userName',
    label: 'Name',
    flex: 1,
    formatter: (resource) => `${resource.user.firstName} ${resource.user.lastName}`,
  },
  {
    field: 'userEmail',
    label: 'Email',
    flex: 2,
    formatter: (resource) => resource.user.email,
  },
  {
    field: 'userTeamRole',
    label: 'Team role',
    flex: 1,
    formatter: (resource) => {
      const isActive = resource.isActive ? 'active' : 'inactive';
      return resource.isTeamMembershipManager ? `Manager (${isActive})` : `Member (${isActive})`; },
  },
  {
    field: 'createdAt',
    label: 'Added',
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.createdAt, 'date')
  },
];

const AddEditTeamMembershipForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: teamMembership,
    validationSchema,
  } = props;

  const [memberships, setMemberships] = useState([]);
  const [teamMembershipNoteStories, setTeamMembershipNoteStories] = useState([]);
  const [addMembershipModalOpen, setAddMembershipModalOpen] = useState(false);
  const [noteText, setNoteText] = useState('');

  const { userFeedbackSuccess } = useAppContext();
  const isMounted = useMounted();
  const navigate = useNavigate();

  const addMembership = () => {
    setAddMembershipModalOpen(true);
  }

  const loadMemberships = async () => {
    const membershipsResponse = await resourceApi.getResources({
      apiEndpoint: 'memberships',
      filterValues: {
        teamMembership: `/team-memberships/${teamMembership.id}`,
      },
      sortValues: [
        {field: 'user.firstName', direction: 'asc'},
      ],
      pagination: {
        itemsPerPage: 999,
      },
    });
    if (isMounted()) {
      setMemberships(membershipsResponse.resources);
    }
  }

  const loadTeamMembershipNotes = async () => {
    const teamMembershipNotesResponse = await resourceApi.getResources({
      apiEndpoint: 'team-membership-notes',
      filterValues: {
        teamMembership: `/team-memberships/${teamMembership.id}`,
      },
      sortValues: [
        {field: 'createdAt', direction: 'desc'},
        {field: 'id', direction: 'desc'},
      ],
      pagination: {
        itemsPerPage: 999,
      },
    });
    const teamMembershipNotes = teamMembershipNotesResponse.resources;
    // eslint-disable-next-line no-shadow
    const teamMembershipNoteStories = [];
    teamMembershipNotes.forEach((teamMembershipNote) => {
      teamMembershipNoteStories.push({
        description: teamMembershipNote.content,
        title:
            renderToStaticMarkup(<>
              {getDateLocaleString(teamMembershipNote.createdAt)}
              {
                  teamMembershipNote.createdByNameLegacy &&
                  <>
                    &nbsp;by {teamMembershipNote.createdByNameLegacy}
                  </>
              }
              {
                  teamMembershipNote.createdByUser &&
                  <>
                    &nbsp;by {getUserName(teamMembershipNote.createdByUser)}
                  </>
              }
            </>),
        dateTime: '',
        color: 'warning',
        icon: 'comment',
        key: `team-membership-note-${teamMembershipNote.id}`,
      });
    });
    setTeamMembershipNoteStories(teamMembershipNoteStories);
  };

  const onModalClose = () => {
    setAddMembershipModalOpen(false);
    loadMemberships();
    loadTeamMembershipNotes();
  }

  const initialise = useCallback(async () => {
    if (isMounted()) {
      await loadMemberships();
      await loadTeamMembershipNotes();
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  const createNewTeamMembershipNote = async () => {
    const data = {
      teamMembership: teamMembership['@id'],
      content: noteText
    };

    await resourceApi.saveResource({
      apiEndpoint: 'team-membership-notes',
      data,
      id: null,
    });

    userFeedbackSuccess('Note added successfully');

    setNoteText('');

    await loadTeamMembershipNotes();
  }

  return (
    <>
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={8}
            >
              <Grid
                container
                spacing={2}
              >
                <
                  Grid
                  item
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        mt={1}
                        spacing={2}
                      >
                        <FormField
                          {...fields.find((field) => field.name === 'name')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'membershipPlan')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'startsAt')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'expiresAt')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'maxMembers')}
                          formik={formik}
                          getApiError={getApiError}
                        />
                      </Grid>
                    </CardContent>
                    <CardActions sx={{flexWrap: 'wrap', m: 1}}>
                      <SaveCancelButtons
                        cancelLocation={cancelLocation}
                        formik={formik}
                      />
                    </CardActions>
                  </Card>
                </Grid>
                <
                  Grid
                  item
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <MDTypography variant="h6">Team members - {memberships.length}; Max: {teamMembership.maxMembers}</MDTypography>
                      { teamMembership.id && (
                        <Box
                            sx={{mt: -1, mb:2 }}
                            display="flex"
                            alignItems="right"
                            justifyContent="flex-end"
                        >
                          <MDButton color="info" onClick={addMembership}>Add team member</MDButton>
                        </Box>
                      )}
                      <ListResourcesTable
                        apiEndpoint="memberships"
                        columns={membershipColumns}
                        isAddEditModal
                        onAddEditModalClose={onModalClose}
                        resources={memberships}
                        resourceCount={memberships.length}
                        customItemsPerPage={999}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <
              Grid
              item
              xs={4}
            >
              <Grid
                container
                spacing={2}
              >
                <
                  Grid
                  item
                  pt={1}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <MDTypography variant="h6">Orders</MDTypography>
                      {
                        teamMembership.order &&
                        <Grid container alignItems="center">
                          <Grid item xs={12} sm={12} md={8}>
                            <MDTypography variant="subtitle2">Created from order #{teamMembership.order.reference}</MDTypography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} style={{textAlign: 'right'}}>
                            <MDButton
                              sx={{mr: 1}}
                              color="info"
                              iconOnly
                              onClick={() => navigate(`/admin/orders/edit/${teamMembership.order.id}`)}
                              variant="gradient"
                            >
                              <EditIcon/>
                            </MDButton>
                            <MDButton
                              color="info"
                              iconOnly
                              onClick={() => window.open(`/admin/orders/edit/${teamMembership.order.id}`, '_blank')}
                              variant="gradient"
                            >
                              <OpenInNewIcon/>
                            </MDButton>
                          </Grid>
                        </Grid>
                      }
                      {
                        teamMembership.membershipRenewals?.map((membershipRenewal) => (
                          <Grid container alignItems="center">
                            <Grid item xs={8}>
                              Renewed from order #{membershipRenewal.order.reference}
                              {
                                membershipRenewal.isRevoked && <>&nbsp;<MDBadge color="error" badgeContent="Cancelled" /></>
                              }
                            </Grid>
                            <Grid item xs={4} style={{textAlign: 'right'}}>
                              <MDButton
                                sx={{mr: 1}}
                                color="primary"
                                iconOnly
                                onClick={() => navigate(`/admin/orders/edit/${membershipRenewal.order.id}`)}
                                variant="gradient"
                              >
                                <EditIcon/>
                              </MDButton>
                              <MDButton
                                color="info"
                                iconOnly
                                onClick={() => window.open(`/admin/orders/edit/${membershipRenewal.order.id}`, '_blank')}
                                variant="gradient"
                              ><OpenInNewIcon/></MDButton>
                            </Grid>
                          </Grid>
                        ))
                      }
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item pt={1} xs={12}>
                  { teamMembership.id && (
                      <TeamOwnerCard teamMembership={teamMembership}/>
                  ) }
                </Grid>

                {
                    teamMembership.id && (
                        <Grid item marginTop={1} xs={12}>
                          <TextField
                              fullWidth
                              label="Add Note"
                              multiline
                              name="add-note"
                              rows={3}
                              placeholder="New Note Here"
                              onChange={(e) => setNoteText(e.target.value)}
                              value={noteText}
                          />
                          <MDButton
                              sx={{mt: 1}}
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={createNewTeamMembershipNote}
                          >
                            <CommentIcon sx={{mr: 1}}/> Add Note
                          </MDButton>
                        </Grid>
                    )
                }


                <
                  Grid
                  item
                  xs={12}
                >
                  <TimelineList title="Notes">
                    {
                      teamMembershipNoteStories.map((teamMembershipNoteStory) => <TimelineItem {...teamMembershipNoteStory} />)
                    }
                  </TimelineList>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
    <Dialog
        open={addMembershipModalOpen}
        onClose={onModalClose}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-membership"
        aria-describedby="add-edit-membership"
    >
      <DialogContent>
        <AddEditMembership
            isModal
            membershipId={null}
            onModalClose={onModalClose}
            teamMembership={teamMembership}
        />
      </DialogContent>
    </Dialog>
    </>
  );
};

AddEditTeamMembershipForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditTeamMembershipForm;