/* eslint-disable */
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDTypography from 'mdpr2/components/MDTypography';
import MDButton from 'mdpr2/components/MDButton';
import ListResourcesTable from 'components/shared/ListResources/ListResourcesTable';
import { resourceApi } from 'api/resource-api';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddEditLearningGroupAccess from './AddEditLearningGroupAccess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppContext } from 'contexts/app-context';
import { asTitleCase } from 'utils/helpers';
import loadAccesses from 'utils/load-accesses';

/* keep this in its own general component, so we can use for other types of LearningGroup */
const LearningGroupAccesses = ({ learningGroup }) => {
  const [accesses, setAccesses] = useState(null);
  const [addLearningGroupAccessModalOpen, setAddLearningGroupAccessModalOpen] = useState(false);
  const [deleteLearningGroupAccessModalOpen, setDeleteLearningGroupAccessModalOpen] = useState(false);
  const [learningGroupAccessIdToDelete, setLearningGroupAccessIdToDelete] = useState();
  const { userFeedbackSuccess } = useAppContext();

  const columns = [
    {
      field: 'openInNewTab',
      label: 'User',
      flex: 0.1,
      formatter: (resource) => {
        const url = `/admin/users/edit/${resource.user.id}`;
        return (
          <MDButton color="info" iconOnly className="open-in-new-tab" onClick={() => window.open(url)}>
            <OpenInNewIcon />
          </MDButton>
        );
      },
    },
    {
      field: 'userName',
      label: 'Name',
      flex: 1,
      formatter: (resource) => `${resource.user.firstName} ${resource.user.lastName}`,
    },
    {
      field: 'userEmail',
      label: 'Email',
      flex: 2,
      formatter: (resource) => resource.user.email,
    },
    {
      field: 'role',
      label: 'Role',
      flex: 1,
      formatter: ({ role }) => asTitleCase(role),
    },
    {
      field: 'isActive',
      label: 'Active?',
      sortable: true,
      flex: 1,
      formatter: (resource) => {
        const color = resource.isActive ? 'green' : 'red';

        return <b style={{ color }}>{resource.isActive ? 'Yes' : 'No'}</b>;
      },
    },
    {
      field: 'deleteLearningGroupAccess',
      label: 'Remove',
      flex: 0.1,
      formatter: (resource) => {
        return (
          <MDButton
            color="error"
            iconOnly
            onClick={() => {
              setLearningGroupAccessIdToDelete(resource.id);
              setDeleteLearningGroupAccessModalOpen(true);
            }}
          >
            <DeleteIcon />
          </MDButton>
        );
      },
    },
  ];

  const addLearningGroupAccess = () => {
    setAddLearningGroupAccessModalOpen(true);
  };

  useEffect(async () => {
    const licencesResponse = await loadAccesses(learningGroup.id);

    setAccesses(licencesResponse);
  }, [learningGroup]);

  const onAddLearningGroupAccessModalClose = async () => {
    setAddLearningGroupAccessModalOpen(false);
    const accessesResponse = await loadAccesses(learningGroup.id);

    setAccesses(accessesResponse);
    // loadNotes ();
  };

  const onDeleteLearningGroupAccessModalClose = () => {
    setDeleteLearningGroupAccessModalOpen(false);
  };

  const confirmDeleteLearningGroupAccess = async () => {
    await resourceApi.deleteResource({
      apiEndpoint: 'learning-group-accesses',
      id: learningGroupAccessIdToDelete,
    });
    setDeleteLearningGroupAccessModalOpen(false);
    userFeedbackSuccess('User removed from group successfully');
    await loadAccesses(learningGroup.id, setAccesses);
  };

  if (accesses === null) {
    return (
      <Box marginTop={5} justifyContent="center">
        <CircularProgress /> Loading...
      </Box>
    );
  }

  return (
    <>
      <MDTypography variant="h6">Group access - {accesses.length} user(s)</MDTypography>
      <Box display="flex" alignItems="right" justifyContent="flex-end">
        <MDButton color="info" onClick={addLearningGroupAccess}>
          Add group access
        </MDButton>
      </Box>
      <Box sx={{ mt: 2 }}>
        <ListResourcesTable
          apiEndpoint="learning-group-accesses"
          columns={columns}
          isAddEditModal
          onAddEditModalClose={onAddLearningGroupAccessModalClose}
          resources={accesses}
          resourceCount={accesses.length}
        />
      </Box>

      <Dialog
        open={addLearningGroupAccessModalOpen}
        onClose={onAddLearningGroupAccessModalClose}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-access"
        aria-describedby="add-access"
      >
        <DialogContent>
          <AddEditLearningGroupAccess
            isModal
            onModalClose={onAddLearningGroupAccessModalClose}
            learningGroup={learningGroup}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteLearningGroupAccessModalOpen}
        onClose={onDeleteLearningGroupAccessModalClose}
        aria-labelledby="delete-access"
        aria-describedby="delete-access"
      >
        <DialogTitle id="scroll-dialog-title">Remove group access?</DialogTitle>
        <DialogContent>Are you sure you want to remove this user from this group?</DialogContent>
        <DialogActions>
          <MDButton color="info" onClick={confirmDeleteLearningGroupAccess}>
            Yes
          </MDButton>
          <MDButton color="secondary" autoFocus onClick={onDeleteLearningGroupAccessModalClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

LearningGroupAccesses.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  learningGroup: PropTypes.object.isRequired,
};

export default LearningGroupAccesses;
