import {
  useRef,
  useState,
} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@mui/material';

// WRM
import FileBrowserDialog from '../FileBrowser/FileBrowserDialog';
import Form from '../Form';
import FormField from '../FormField';
import SaveCancelButtons from '../SaveCancelButtons';

const AddEditResourceForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    isModal,
    onFormSubmit,
    onModalClose,
    validationSchema,
  } = props;

  // File browser
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [fileBrowserField, setFileBrowserField] = useState(null);
  const fileBrowserCallback = useRef(null);

  // File browser
  const showFileBrowser = ((fieldOrCallback) => {
    if (typeof fieldOrCallback === 'string') {
      setFileBrowserField(fieldOrCallback);
    }
    if (typeof fieldOrCallback === 'function') {
      fileBrowserCallback.current = fieldOrCallback;
    }
    setFileBrowserOpen(true);
  });
  const closeFileBrowser = () => {
    setFileBrowserOpen(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <>
          <Form formik={formik}>
            <Card>
              <CardContent>
                <Grid
                  container
                  mt={1}
                  spacing={2}
                >
                  {
                    fields.map((field) => (
                      <FormField
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...field}
                        key={`form-field-${field.name}`}
                        formik={formik}
                        getApiError={getApiError}
                        showFileBrowser={(field.type === 'filePicker' || field.type === 'richtext') ? showFileBrowser : null}
                      />
                    ))
                  }
                </Grid>
              </CardContent>
              <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                <SaveCancelButtons
                  cancelLocation={cancelLocation}
                  formik={formik}
                  isModal={isModal}
                  onModalClose={onModalClose}
                />
              </CardActions>
            </Card>
          </Form>
          {
            fileBrowserOpen
            && (
              <FileBrowserDialog
                fieldToUpdate={fileBrowserField}
                setFieldValue={formik.setFieldValue}
                callbackOnSelection={fileBrowserCallback.current || null}
                closeFileBrowser={closeFileBrowser}
              />
            )
          }
        </>
      )}
    </Formik>
  );
};

AddEditResourceForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  isModal: PropTypes.bool,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
};

AddEditResourceForm.defaultProps = {
  isModal: false,
  onModalClose: null,
  validationSchema: {},
};

export default AddEditResourceForm;
