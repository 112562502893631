// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Digital tools';
const apiEndpoint = 'digital-tools';
const resourceName = 'digital tool';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    field: 'accessUrl',
    label: 'Access URL',
    flex: 2,
  },
  {
    field: 'displayOrder',
    label: 'Display order',
    sortable: true
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
];

const defaultFilterValues = {};

const defaultSortValues = [
  {
    field: 'displayOrder',
    direction: 'asc',
  },
];

const fixedFilterValues = {};

const DigitalTools = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default DigitalTools;
