// eslint-disable-next-line import/prefer-default-export
export const schoolFullAddress = (school) => {

    const fields = [
        'name',
        'addressStreet',
        'addressLocality',
        'addressTownCity',
        'addressPostcode',
        'addressCountry'
    ];

    const fullAddress = [];

    for (let i = 0; i < fields.length; i += 1)
    {
        const field = fields[i];
        const val = school[field] ?? null;

        if(val !== null && val.trim() !== '') {
            fullAddress.push(val);
        }
    }

    return fullAddress.join(', ');
}