import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/lab/Alert';

// MDPR
import MDButton from "mdpr2/components/MDButton";
import MDTypography from "mdpr2/components/MDTypography";

// WRM
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { useAppContext } from 'contexts/app-context';
import ConfirmModal from 'components/shared/ConfirmModal';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import getDateLocaleString from 'utils/get-date-locale-string';

const AddEditSageOrderExportForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    refreshResource,
    resource: sageOrderExport,
    validationSchema,
  } = props;

  const [deleteSageOrderExportModalOpen, setDeleteSageOrderExportModalOpen] = useState(false);
  const [isGoToNextOrderDisabled, setIsGoToNextOrderDisabled] = useState(false);

  const navigate = useNavigate();
  const { userFeedbackSuccess } = useAppContext();

  const selectCandidateSageCustomer = async (candidateSageCustomerIri) => {
    await resourceApi.saveResource({
      apiEndpoint: 'sage-order-exports',
      id: sageOrderExport.id,
      data: {
        'confirmedSageCustomer': candidateSageCustomerIri,
      },
    });
    refreshResource();
  };

  const editNextSageOrderExport = async () => {
    const sageOrderExportsResponse = await resourceApi.getResources({
      apiEndpoint: 'sage-order-exports',
      filterValues: {
        isExportableToSage: false,
        isExportedToSage: false,
        isDeleted: false,
      },
      sortValues: [
        {field: 'order.reference', direction: 'asc'},
      ],
      pagination: {
        itemsPerPage: 1,
      },
    });

    if ((sageOrderExportsResponse.resources?.length ?? 0) > 0) {
      const nextSageOrderExport = sageOrderExportsResponse.resources[0];
      navigate(`/admin/sage-order-exports/edit/${nextSageOrderExport.id}`);
    } else {
      setIsGoToNextOrderDisabled(true);
    }
  }

  const handleDeleteSageOrderExport = () => {
    setDeleteSageOrderExportModalOpen(true);
  }

  const handleDeleteSageOrderExportConfirm = async (note) => {
    const data = {};
    if (note) {
      data.reasonForDeletion = note;
    }
    const response = await requestApi.putResponse({
      url: `sage-order-exports/${sageOrderExport.id}/delete`,
      data,
    });
    if (response) {
      setDeleteSageOrderExportModalOpen(false);
      navigate('/admin/sage-order-exports');
      // Add user feedback in a 'one millisecond' timeout to outlive the navigation
      setTimeout(
        () => { userFeedbackSuccess('Sage order export deleted successfully'); },
        1
      );
    }
  }

  let statusColour = 'info';
  if (sageOrderExport.isExportedToSage) {
    statusColour = 'success';
  } else if (sageOrderExport.isExportableToSage) {
    statusColour = 'warning';
  } else {
    statusColour = 'error';
  }

  let orderStatusColour = 'info';
  if (sageOrderExport.order?.status === 'processing') {
    orderStatusColour = 'warning';
  } else if (sageOrderExport.order?.status === 'completed') {
    orderStatusColour = 'success';
  } else if (sageOrderExport.order?.status === 'cancelled') {
    orderStatusColour = 'error';
  }

  const { billingAddress } = sageOrderExport.order;

  // The same data as output on EditOrderForm
  const billingAddressParts = [];
  billingAddressParts.push(`${billingAddress.firstName} ${billingAddress.lastName}`);
  if (billingAddress.line1) billingAddressParts.push(billingAddress.line1);
  if (billingAddress.line2) billingAddressParts.push(billingAddress.line2);
  if (billingAddress.line3) billingAddressParts.push(billingAddress.line3);
  if (billingAddress.townCity) billingAddressParts.push(billingAddress.townCity);
  if (billingAddress.county) billingAddressParts.push(billingAddress.county);
  if (billingAddress.postcode) billingAddressParts.push(billingAddress.postcode);
  if (billingAddress.country) billingAddressParts.push(billingAddress.country);
  const billingAddressLine2Parts = [];
  if (billingAddress.email) billingAddressLine2Parts.push(billingAddress.email);
  if (billingAddress.phone) billingAddressLine2Parts.push(billingAddress.phone);
  if (billingAddress.company) billingAddressLine2Parts.push(billingAddress.company);

  const { confirmedSageCustomer } = sageOrderExport;
  const confirmedSageCustomerParts = [];
  if (confirmedSageCustomer?.code) confirmedSageCustomerParts.push(`Customer code ${confirmedSageCustomer.code}`);
  if (confirmedSageCustomer?.name) confirmedSageCustomerParts.push(confirmedSageCustomer.name);
  if (confirmedSageCustomer?.fullAddress) confirmedSageCustomerParts.push(confirmedSageCustomer.fullAddress);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <MDTypography variant="h3">
                  Order #{sageOrderExport.order?.reference}: <MDTypography variant="span" color={statusColour}>{sageOrderExport.status.toUpperCase()}</MDTypography>
                </MDTypography>
                <MDTypography variant="subtitle2">Order placed {getDateLocaleString(sageOrderExport.order?.createdAt) || 'N/A'}</MDTypography>
              </Grid>

              {/* If the SageOrderExport has been attempted, but failed, render the import errors from Sage */}
              {
                sageOrderExport.importErrorsFromSage?.length > 0 &&
                <Grid
                  item
                  xs={12}
                >
                  <Alert severity="error">
                    <h4>Import errors from Sage</h4>
                    <ul>
                      {sageOrderExport.importErrorsFromSage.map((importErrorFromSage) => <li>{importErrorFromSage}</li>)}
                    </ul>
                  </Alert>
                </Grid>
              }

              <Grid
                item
                xs={12}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2" color={orderStatusColour}>
                          <h4>Order status</h4>
                          {sageOrderExport.order?.status.replace('_', ' ').toUpperCase()}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Billing address</h4>
                          {billingAddressParts.join(', ')}<br />
                          {billingAddressLine2Parts.join(', ')}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Billing email</h4>
                          {billingAddress.email}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Customer note</h4>
                          {sageOrderExport.order?.customerNote || 'N/A'}
                        </MDTypography>
                      </Grid>

                      {/* If the SageOrderExport has a confirmedSageCustomer, render it and allow 'unselection' */}
                      {
                        confirmedSageCustomer &&
                        <Grid
                          item
                          xs={12}
                        >
                          <MDTypography variant="subtitle2">
                            <h4>Confirmed Sage customer</h4>
                            {confirmedSageCustomerParts.join(', ')}
                            {
                              !sageOrderExport.isExportedToSage &&
                              <>
                                <br />
                                <MDButton
                                  color="warning"
                                  onClick={() => selectCandidateSageCustomer(null)}
                                  size="small"
                                  variant="gradient"
                                >
                                  Unselect this Customer
                                </MDButton>
                              </>
                            }
                          </MDTypography>
                        </Grid>
                      }

                      {/* If the SageOrderExport has been exported to Sage, render the export details */}
                      {
                        sageOrderExport.isExportedToSage &&
                        <Grid
                          item
                          xs={12}
                        >
                          <MDTypography variant="subtitle2">
                            <h4>Exported</h4>
                            {getDateLocaleString(sageOrderExport.exportedToSageAt)} as Sage Order number
                            &nbsp;
                            {
                              Object.keys(sageOrderExport.sageSalesOrderNumbers).map((orderReference, index, arr) => (
                                <>
                                  {sageOrderExport.sageSalesOrderNumbers[orderReference]} ({orderReference})
                                  {index === arr.length - 1 ? '' : ' / '}
                                </>
                              ))
                            }
                          </MDTypography>
                        </Grid>
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      mt={1}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Table>
                          <TableBody>
                            {
                              sageOrderExport.sageSalesOrdersData.map((sageSalesOrderData) => (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={9}>
                                      <strong>Order #{sageSalesOrderData.customer_document_no}</strong>
                                      { sageSalesOrderData.customer_document_no.slice(-1) === 'S' && '  - Subscriptions' }
                                      { sageSalesOrderData.customer_document_no.slice(-1) === 'M' && '  - Main shipment' }
                                      { sageSalesOrderData.customer_document_no.slice(-1) === 'D' && '  - Deferred shipment' }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Sku</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Unit price</TableCell>
                                    <TableCell>Nominal code</TableCell>
                                    <TableCell>Department code</TableCell>
                                    <TableCell>Tax code ID</TableCell>
                                    <TableCell>Tax code</TableCell>
                                  </TableRow>
                                  {
                                    sageSalesOrderData.lines.map((line) => (
                                      <TableRow>
                                        <TableCell>{line.line_type}</TableCell>
                                        <TableCell>{line.sku}</TableCell>
                                        <TableCell>{line.description}</TableCell>
                                        <TableCell>{line.line_quantity}</TableCell>
                                        <TableCell>{line.selling_unit_price}</TableCell>
                                        <TableCell>{line.nominal_reference || <MDTypography variant="inherit" fontWeight="bold" color="error">N/A</MDTypography>}</TableCell>
                                        <TableCell>{line.nominal_department || <MDTypography variant="inherit" fontWeight="bold" color="error">N/A</MDTypography>}</TableCell>
                                        <TableCell>{line.tax_code_id}</TableCell>
                                        <TableCell>{line.taxCodeName}</TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* If the SageOrderExport has not been exported to Sage, render candidateSageCustomers and a manual 'search' */}
              {
                !sageOrderExport.isExportedToSage &&
                <Grid
                  item
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        mt={1}
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mt: 1 }}
                            variant="h6"
                          >
                            Matching Sage Customers
                          </Typography>
                          <Table>
                            <TableBody>
                              {
                                sageOrderExport.candidateSageCustomers.map((candidateSageCustomer) => (
                                  <TableRow>
                                    <TableCell>{candidateSageCustomer.code}</TableCell>
                                    <TableCell>{candidateSageCustomer.name}</TableCell>
                                    <TableCell>{candidateSageCustomer.fullAddress}</TableCell>
                                    <TableCell>{candidateSageCustomer.email}</TableCell>
                                    <TableCell>
                                      <MDButton
                                        color="info"
                                        onClick={() => selectCandidateSageCustomer(candidateSageCustomer['@id'])}
                                        variant="gradient"
                                      >
                                        Select this Customer
                                      </MDButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </Grid>

                        <FormField
                          {...fields.find((field) => field.name === 'manuallyConfirmedSageCustomer')}
                          formik={formik}
                          getApiError={getApiError}
                        />
                      </Grid>
                    </CardContent>
                    <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                      <SaveCancelButtons
                        cancelLocation={cancelLocation}
                        formik={formik}
                      />
                      {
                        !sageOrderExport.isDeleted &&
                        <MDButton
                          color="error"
                          type="button"
                          variant="gradient"
                          onClick={handleDeleteSageOrderExport}
                        >
                          Delete this Sage order export
                        </MDButton>
                      }
                      {
                        sageOrderExport.isExportableToSage &&
                        <MDButton
                          color="info"
                          disabled={isGoToNextOrderDisabled}
                          type="button"
                          variant="gradient"
                          onClick={editNextSageOrderExport}
                        >
                          Go to next order
                        </MDButton>
                      }
                    </CardActions>
                  </Card>
                </Grid>
              }
            </Grid>
          </Form>
        )}
      </Formik>
      <ConfirmModal
        body='This Sage order export will not be exported to Sage if you delete it'
        confirmCallback={handleDeleteSageOrderExportConfirm}
        hasNote
        isDelete
        open={deleteSageOrderExportModalOpen}
        setOpen={(open) => setDeleteSageOrderExportModalOpen(open)}
        title='Are you sure?'
      />
    </>
  );
};

AddEditSageOrderExportForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  refreshResource: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditSageOrderExportForm;
