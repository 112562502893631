import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import useMounted from 'hooks/use-mounted';

const apiEndpoint = 'product-attribute-groups';
const resourceName = 'attribute group';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'productAttributes',
    label: 'Product attributes',
    type: 'selectAsTable',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
});

const AddEditProductAttributeGroup = () => {
  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      const productAttributeChoices = await requestApi.getResponse({ url: 'product-attributes/choices' });
      const productAttributesField = fields.find((field) => field.name === 'productAttributes');
      productAttributesField.choices = productAttributeChoices;

      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditProductAttributeGroup;
