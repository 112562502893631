/* eslint-disable */

import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import FormField from 'components/shared/FormField';
import { Alert, Box, Typography } from '@mui/material';
import { resourceApi } from 'api/resource-api';
import { debounce } from 'lodash';

const MergeLearningGroupForm = ({ formRef, mergeRequestErrored }) => {
  const fetchLearningGrops = (query, setOptions) => {
    resourceApi
      .getResources({
        apiEndpoint: 'learning-groups',
        filterValues: {
          name: query,
          excludeIndividuals: true,
        },
        sortValues: [
          { field: 'createdAt', direction: 'desc' },
          { field: 'id', direction: 'desc' },
        ],
        pagination: {
          itemsPerPage: 999,
        },
      })
      .then((response) => {
        setOptions(
          response?.resources.map((item) => ({
            id: item.id,
            label: `${item.name}  (#${item.id})`,
          }))
        );
      });
  };

  const debouncedLearningGroupsFilterSearch = useMemo(
    () => debounce((search, setOptions) => fetchLearningGrops(search, setOptions), 200),
    []
  );

  const learningGroupFields = {
    name: 'learningGroup',
    label: 'Group into which this group will be merged',
    type: 'typeahead',
    search: debouncedLearningGroupsFilterSearch,
    apiAttribute: 'learningGroup',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        learningGroup: '',
      }}
      validateOnChange={false}
      innerRef={formRef}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form formik={formik}>
          <Typography variant="h6" gutterBottom>
            Search/select a group into which this group will be merged. All users and licences will be transferred and
            the original group deleted
          </Typography>
          <Box paddingTop="5px">
            <FormField {...learningGroupFields} getApiError={() => {}} formik={formik} />
          </Box>
          {mergeRequestErrored && (
            <Alert severity="error" sx={{ mt: 2 }}>
              There has been a problem, please try again.
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MergeLearningGroupForm;
