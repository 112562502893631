import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@mui/material';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import ProductBundleParts from "./ProductBundleParts";

const AddEditProductVariantForm = (props) => {
  const {
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    onModalClose,
    resource: productVariant,
    validationSchema,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                {
                  fields.map((field) => (
                    <FormField
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...field}
                      formik={formik}
                      getApiError={getApiError}
                      key={`form-field-${field.name}`}
                    />
                  ))
                }

                {/* Products in bundle */}
                {
                  productVariant.isBundle && (
                    <Grid
                      item
                      xs={12}
                    >
                      {
                        productVariant.id && (
                          <ProductBundleParts
                            formik={formik}
                            productVariant={productVariant}
                          />
                        )
                      }
                      {
                        !productVariant.id && (
                          <h4>Please save this variant before defining its bundle products</h4>
                        )
                      }
                    </Grid>
                  )
                }
              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                isModal
                onModalClose={onModalClose}
                formik={formik}
              />
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

AddEditProductVariantForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

AddEditProductVariantForm.defaultProps = {
  resource: {},
};

export default AddEditProductVariantForm;