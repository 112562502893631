// WRM
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Orders from '../../Orders/Orders';

const getOrderStatusFromLocation = ({ pathname }) => pathname.substring(pathname.lastIndexOf('/') + 1)

const OrdersWarehouse = () => {

  const location = useLocation();
  const [orderStatus, setOrderStatus] = useState(getOrderStatusFromLocation(location));

  useEffect(() => {
    setOrderStatus(getOrderStatusFromLocation(location));
  }, [location])

  return (
      <Orders
          context="warehouse"
          orderStatus={orderStatus}
      />
  );
}

export default OrdersWarehouse;
