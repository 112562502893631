import { useState } from "react";

// WRM
import ListResources from 'components/shared/ListResources';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MDButton from 'mdpr2/components/MDButton';
import ConfirmModal from "components/shared/ConfirmModal";
import { resourceApi } from 'api/resource-api';

const title = 'Order reports';
const apiEndpoint = 'order-reports';
const resourceName = 'order report';

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'isActive',
    label: 'Active?',
    type: 'select',
    choices: [
        {value: '', label: 'Yes or no'},
        {value: 'true', label: 'Yes'},
        {value: 'false', label: 'No'},
    ],
  },
];

const defaultFilterValues = {};

const defaultSortValues = [];

const fixedFilterValues = {};

const OrderReports = () => {

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [context, setContext] = useState();

  const columns = [
    {
      field: 'name',
      label: 'Name',
      sortable: true
    },
    {
      field: 'isActive',
      label: 'Active?',
      formatter: (resource) => resource.isActive ? 'Yes' : 'No',
    },
    {
      field: 'lastRunAt',
      label: 'Last Run',
      formatter: (resource) => resource.lastRunAt ? new Date(resource.lastRunAt).toLocaleString() : 'Never',
    },
    {
      field: 'preview',
      label: ' ',
      flex: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (resource) => {
        const { id, name, lastRunAt } = resource;
        return (
          <MDButton
            color="dark"
            onClick={() => {
              setContext({ id, name, lastRunAt });
              setPreviewModalOpen(true);
            }}
            variant="outlined"
            iconOnly
          >
            <PublishedWithChangesIcon />
          </MDButton>
        );
      },
    },
  ];
  
  const runReport = async () => {
    try {
      await resourceApi.saveResource({
        apiEndpoint: 'order-reports',
        id: context.id,
        extension: 'run',
        data: {},
      });
    } catch (error) {
      alert(error);
      return;
    } finally {
      setPreviewModalOpen(false);
      window.location.reload();
    }
  }

  return (
    <>
      <ListResources
        apiEndpoint={apiEndpoint}
        columns={columns}
        defaultFilterValues={defaultFilterValues}
        defaultSortValues={defaultSortValues}
        filters={filters}
        fixedFilterValues={fixedFilterValues}
        resourceName={resourceName}
        title={title}
      />
      <ConfirmModal
        open={previewModalOpen}
        body={
          `By pressing run, you'll induce a full run of the report, updating the last run at time
          and fetching you all data between now and the last instance the report was run. If you have a 
          scheduled runtime, that will then collect data in between now and it's normal scheduled time.`
        }
        title="Run Report"
        setOpen={(open) => setPreviewModalOpen(open)}
        confirmCallback={runReport}
      />
    </>
)};

export default OrderReports;