import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const apiEndpoint = 'product-categories';
const resourceName = 'product category';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'slug',
    label: 'Slug',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea'
  },
  {
    name: 'parentProductCategory',
    label: 'Parent category',
    type: 'select',
  },
  {
    name: 'productOptions',
    label: 'Options for products in this category',
    type: 'selectAsTable',
  },
  {
    name: 'containsPhysicalProducts',
    label: 'Contains physical products',
    type: 'checkbox',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

const AddEditProductCategory = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const productCategoryChoices = await requestApi.getResponse({ url: 'product-categories/choices' });
      const noParentChoice = {
        value: 'none',
        label: 'No parent',
      }
      const parentProductCategoryField = fields.find((field) => field.name === 'parentProductCategory');
      parentProductCategoryField.choices = [noParentChoice, ...productCategoryChoices];
      const productOptionChoices = await requestApi.getResponse({ url: 'product-options/choices' });
      const productOptionsField = fields.find((field) => field.name === 'productOptions');
      productOptionsField.choices = productOptionChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditProductCategory;
