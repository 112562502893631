import axios from './axios';

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  if (localStorage.getItem('accessToken')) {
    headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return headers;
};

class RequestApi {
  // eslint-disable-next-line class-methods-use-this
  async getResponse({ url, rawResponse }) {
    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
          headers: getHeaders(),
        };

        if (rawResponse) {
          axiosConfig.responseType = 'blob';
        }

        axios(axiosConfig)
          .then((response) => {
            resolve(rawResponse ? response : response.data);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async postResponse({ url, data, rawResponse }) {
    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: 'POST',
          url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
          headers: getHeaders(),
          data: JSON.stringify(data),
        };
        if (rawResponse) {
          axiosConfig.responseType = 'blob';
        }

        axios(axiosConfig)
          .then((response) => {
            resolve(rawResponse ? response : response.data);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async putResponse({ url, data, rawResponse }) {
    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: 'PUT',
          url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
          headers: getHeaders(),
          data: JSON.stringify(data),
        };
        if (rawResponse) {
          axiosConfig.responseType = 'blob';
        }

        axios(axiosConfig)
          .then((response) => {
            resolve(rawResponse ? response : response.data);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const requestApi = new RequestApi();
