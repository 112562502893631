import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import { writeOffReasons } from "./constants";
import AddStockWriteOffRequestForm from "./AddStockWriteOffRequestForm";

const apiEndpoint = 'stock-changes/stock-write-off-request';
const adminEndpoint = 'stock-change'
const resourceName = 'Stock Write Off';

const AddStockWriteOffRequest = () => {
    const [initialised, setInitialised] = useState(false);
    const [locationChoices, setLocationChoices] = useState([]);

    const { setShowLoadingSpinner } = useAppContext();
    const isMounted = useMounted();
    const params = useParams();
    const id = params.id ? Number(params.id) : null;

    const initialise = useCallback(async () => {
        if (isMounted) {
            setShowLoadingSpinner(true);
            const locationChoicesResponse = await requestApi.getResponse({ url: 'locations/choices' });
            setLocationChoices(locationChoicesResponse);
            setInitialised(true);
            setShowLoadingSpinner(false);
        }
    }, [isMounted]);

    useEffect(() => {
        initialise();
    }, [initialise]);

    if (!initialised) return '';

    const fields = [
        { name: 'location', type: 'select', choices: locationChoices, defaultValue: '/locations/1', label: "Location" },
        { name: 'reason', type: 'select', choices: writeOffReasons, label: "Reason" },
        { name: 'notes', type: 'textarea', label: "Main Notes" },
        {
            name: 'items',
            label: 'Write Off Products',
            type: 'fieldCollection',
            defaultValue: [
                {
                    product: '',
                    quantity: '',
                    notes: ''
                }
            ],
            childFields: [
                { name: 'product', type: 'hidden', label: "Product" },
                { name: 'quantity', type: 'number', defaultValue: 1, label: "Quantity" },
                { name: 'notes', type: 'text', label: "Item Notes" } // item specific note
            ],
        }
    ];

    const validationSchema = Yup.object().shape({
        location: Yup.string().required('Location required'),
        notes: Yup.string(),
        items: Yup.array().of(
            Yup.object().shape({
                product: Yup.string()
                    .required('Product is required')
                    .notOneOf(['none'], 'Product is required'),
                quantity: Yup.number()
                    .typeError('Invalid quantity')
                    .min(1, 'Quantity cannot be negative or zero')
                    .required('Quantity is required'),
                notes: Yup.string(),
            }),
        ),
        reason: Yup.string().test(
            'empty-check',
            'Reason must not be empty',
            (reason) => {
                console.log(reason);
                return reason !== null && reason !== 'none';
            }
        )
    });

    // eslint-disable-next-line no-shadow
    const toApiValuesCustom = (formValues, fields) => {
        const apiValues = toApiValues(formValues, fields);

        apiValues.notes = formValues.notes?.split("\n");
        apiValues.items.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.notes = item.notes?.split("\n");
        });

        return apiValues;
    };

    return (
        <AddEditResource
            addEditForm={AddStockWriteOffRequestForm}
            apiEndpoint={apiEndpoint}
            adminEndpoint={adminEndpoint}
            fields={fields}
            id={id}
            resourceName={resourceName}
            toApiValuesCustom={toApiValuesCustom}
            validationSchema={validationSchema}
            showTitleText={false}
        />
    );
};

AddStockWriteOffRequest.propTypes = {
};

AddStockWriteOffRequest.defaultProps = {
}

export default AddStockWriteOffRequest;
