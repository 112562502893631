import { toApiValues } from 'components/shared/resource/api-form-mapper';

const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  apiValues.billingAddress = formValues.billingAddress;
  apiValues.deliveryAddress = formValues.deliveryAddress;
  // Push firstName, lastName and phone into the billing address
  apiValues.billingAddress.firstName = formValues.deliveryAddress?.firstName;
  apiValues.billingAddress.lastName = formValues.deliveryAddress?.lastName;
  apiValues.billingAddress.phone = formValues.deliveryAddress?.phone;

  return apiValues;
}

export default toApiValuesCustom;
