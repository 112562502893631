import { Formik } from 'formik';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@mui/material';

import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import PropTypes from "prop-types";

const AddEditSingleUseDiscountForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    validationSchema,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <FormField
                  {...fields.find((field) => field.name === 'promotionCodeCode')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'type')}
                  formik={formik}
                  getApiError={getApiError}
                />

                {
                  formik.values.type === 'fixed' && (
                    <FormField
                      {...fields.find((field) => field.name === 'fixedValue')}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  )
                }

                {
                  formik.values.type === 'percentage' && (
                    <FormField
                      {...fields.find((field) => field.name === 'percentageValue')}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  )
                }

                <FormField
                  {...fields.find((field) => field.name === 'description')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'status')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                  <SaveCancelButtons
                    cancelLocation={cancelLocation}
                    formik={formik}
                  />
                </CardActions>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

AddEditSingleUseDiscountForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditSingleUseDiscountForm;
