import MDBox from 'mdpr2/components/MDBox';
import {Box, Card, CardContent, Grid, Icon} from "@mui/material";
import CardHeader from "../CardHeader";

// eslint-disable-next-line react/prop-types
const StatCard = ({ statName, icon, headline, secondary, fontSize = 'large' }) => (
        <Card>
            <CardHeader color="warning">
                <Grid container alignItems="center" fontSize="x-large" spacing={0.5}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Icon>{icon}</Icon>
                        </Grid>
                    </Grid>
                    <Grid item fontSize={fontSize}>
                        <Box>{statName}</Box>
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                <MDBox fontSize="x-large" mt={-2} fontWeight="bold">
                    {headline}
                </MDBox>
                <MDBox color="grey" fontSize="medium" font>
                    {secondary}
                </MDBox>
            </CardContent>
        </Card>
    );

export default StatCard;
