// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Users';
const apiEndpoint = 'users';
const resourceName = 'user';

const columns = [
  {
    field: 'firstName',
    label: 'First name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'lastName',
    label: 'Last name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
    flex: 1,
  },
  {
    field: '_membershipPlans',
    label: 'Accessible Membership Plans',
    flex: 3,
    formatter: (resource) => <div>{resource.accessibleMembershipPlans.map(accessibleMembershipPlan => <a
        href={`/admin/membership-plans/edit/${accessibleMembershipPlan.id}`}
        key={accessibleMembershipPlan.id}>
          {accessibleMembershipPlan.name}<br />
      </a>)}</div>
  },
];

const filters = [
  {
    field: 'firstName',
    label: 'First name',
    type: 'text',
  },
  {
    field: 'lastName',
    label: 'Last name',
    type: 'text',
  },
  {
    field: 'email',
    label: 'Email',
    type: 'text',
  },

];

const defaultSortValues = [
  {
    field: 'firstName',
    direction: 'asc',
  },
];

const Users = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
    shouldLoadResources={false}
  />
);

export default Users;
