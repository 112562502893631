import { resourceApi } from 'api/resource-api';

const webComponentSearch = (search, setOptions) => {
  if (search.length) {
    resourceApi
      .getResources({
        apiEndpoint: 'web-components',
        filterValues: { name: search },
      })
      .then((response) => {
        console.log(response);

        if (!Array.isArray(response.resources)) {
          setOptions([]);
        }

        const choices = response.resources.map((row) => ({
          id: row['@id'],
          label: row.name,
        }));

        setOptions(choices);
      });
  } else {
    setOptions([]);
  }
};

export default webComponentSearch;
