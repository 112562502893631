// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Redirects';
const apiEndpoint = 'redirects';
const resourceName = 'redirect';

const columns = [
  {
    field: 'fromUrl',
    label: 'From URL',
    sortable: true,
  },
  {
    field: 'toUrl',
    label: 'To URL',
    sortable: true,
  },
  {
    field: 'isActive',
    label: 'Active?',
    sortable: true,
    formatter: (resource) => resource.isActive ? 'Yes' : 'No',
  },
];

const filters = [
  {
    field: 'fromUrl',
    label: 'From URL',
    type: 'text',
  },
  {
    field: 'toUrl',
    label: 'To URL',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'fromUrl',
    direction: 'asc',
  },
];

const Redirects = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default Redirects;
