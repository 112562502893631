/* eslint-disable */
import React from 'react';

// Material UI
import { Grid } from '@mui/material';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from'mdpr2/components/MDTypography';

// WRM
import FileBrowser from 'components/shared/FileBrowser';

const Media = () => (
  <MDBox my={1}>
    <MDBox mb={2}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MDTypography variant="h4" fontWeight="medium">
            Media
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <FileBrowser />
      </Grid>
    </Grid>
  </MDBox>
);

export default Media;
