// WRM
import ListResources from 'components/shared/ListResources';
import getDateUtcString from 'utils/get-date-utc-string';

const title = 'Memberships';
const apiEndpoint = 'memberships';
const resourceName = 'membership';

const singleOrTeamChoices = [
  {
    value: '',
    label: 'Single or team memberships'
  },
  {
    value: 'false',
    label: 'Single memberships only',
  },
  {
    value: 'true',
    label: 'Team memberships only',
  },
];

const columns = [
  {
    field: 'userEmail',
    label: 'User',
    flex: 2,
    formatter: (resource) => resource.user.email,
  },
  {
    field: 'teamMembershipName',
    label: 'Team',
    flex: 2,
    formatter: (resource) => resource.teamMembership ? resource.teamMembership.name : '',
  },
  {
    field: 'membershipPlanName',
    label: 'Membership plan',
    flex: 2,
    formatter: (resource) => resource.membershipPlan.name,
  },
  {
    field: 'startsAt',
    label: 'Starts',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.teamMembership ? 'N/A' : getDateUtcString(resource.startsAt, 'date'),
  },
  {
    field: 'expiresAt',
    label: 'Expires',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.teamMembership ? 'N/A' : (getDateUtcString(resource.expiresAt, 'date') || 'Never'),
  },
  {
    field: 'isActive',
    label: 'Active?',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.isActive ? 'Yes' : 'No',
  },
];

const filters = [
  {
    field: '_search',
    label: 'Search',
    type: 'text',
    apiAttribute: 'search',
  },
  {
    field: '_singleOrTeam',
    label: 'Single/team membership',
    type: 'select',
    choices: singleOrTeamChoices,
    apiAttribute: 'exists[teamMembership]',
  },
];

const defaultSortValues = [
  {
    field: 'startsAt',
    direction: 'desc',
  },
];

const Memberships = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default Memberships;
