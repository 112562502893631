import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// MUI
import {
  Card,
  Icon,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { resourceApi } from 'api/resource-api';
import { requestApi } from 'api/request-api';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import { getResourcesState, setResourcesState } from '../resource/resources-state';
import ListResourcesFilters from './ListResourcesFilters';
import ListResourcesTable from './ListResourcesTable';

const ListResources = (props) => {
  const {
    adminEndpoint,
    apiEndpoint,
    columns,
    customItemsPerPage,
    defaultFilterValues,
    defaultSortValues,
    disableAddResource,
    disableEditResource,
    disableOpenInNewTab,
    filters,
    fixedFilterValues,
    fixedSortValues,
    isDownloadable,
    onRowDoubleClick,
    resourceName,
    setFilterValues: setFilterValuesParent,
    shouldLoadResources,
    title,
  } = props;

  const isMounted = useMounted();
  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [resourceCount, setResourceCount] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const minimumSearchLength = 3;

  const resourcesState = getResourcesState(adminEndpoint || apiEndpoint, customItemsPerPage);
  const [resourcesConfig, setResourcesConfig] = useState({
    adminEndpoint,
    apiEndpoint,
    filterValues: Object.keys(resourcesState.filterValues).length > 0 ? resourcesState.filterValues : defaultFilterValues,
    sortValues: resourcesState.sortValues.length > 0 ? resourcesState.sortValues : defaultSortValues,
    pagination: resourcesState.pagination,
  });
  const [loadResources, setLoadResources] = useState(shouldLoadResources);

  const { setShowLoadingSpinner } = useAppContext();

  const generateDownloadFilterURI = (filterObj) => {
    const queryString = Object.entries(filterObj)
      .map(([key, value]) =>
        Array.isArray(value)
          ? value.map(val => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&')
          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');

    return queryString;
  };

  const handleDownloadOnClick = () => {
    setIsDownloading(true);
    requestApi.getResponse({
      url: `download-${apiEndpoint}?${generateDownloadFilterURI({ ...resourcesConfig.filterValues, ...fixedFilterValues })}`,
      rawResponse: true
  }).then((response) => {
      const [, filename] = response.headers['content-disposition'].split('filename=');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
  }).catch((error) => {
    toast.error('Error downloading file. If this problem persists, please reduce the number of records selected.');
    console.error('Error downloading file:', error);
    setIsDownloading(false);
  });
}

  // trigger a reload when our fixed filter vals change
  useEffect(() => {
    setLoadResources(shouldLoadResources);
  }, [fixedFilterValues, shouldLoadResources]);

  const setFilterValues = (filterValues) => {
    const newResourcesConfig = { ...resourcesConfig };
    newResourcesConfig.filterValues = filterValues;
    newResourcesConfig.pagination.page = 1;
    setResourcesConfig(newResourcesConfig);
    setLoadResources(true);
    if (setFilterValuesParent) {
      setFilterValuesParent(filterValues);
    }
  };

  const setSortValues = (sortValues) => {
    const newResourcesConfig = { ...resourcesConfig };
    newResourcesConfig.sortValues = sortValues;
    setResourcesConfig(newResourcesConfig);
    setLoadResources(true);
  };

  const setPagination = (pagination) => {
    const newResourcesConfig = { ...resourcesConfig };
    newResourcesConfig.pagination = pagination;
    setResourcesConfig(newResourcesConfig);
    setLoadResources(true);
  };

  const getResources = useCallback(async (params) => {
    toast.dismiss();

    if (params.apiEndpoint === 'users') {
      const keys = Object.keys(params.filterValues);

      const hasValidFilter = keys.find((key) => (
        params.filterValues[key] && params.filterValues[key].length >= minimumSearchLength
      ));

      if (!hasValidFilter) {
        toast.error(`One or more filters must contain at least ${minimumSearchLength} characters.`);

        return;
      }
    }

    setShowLoadingSpinner(true);

    try {
      const data = await resourceApi.getResources(params);
      if (isMounted()) {
        setResources(data.resources);
        setResourceCount(data.resourceCount);
        setLoadResources(false);
      }
    } catch (error) {
      // TODO
    }
    setShowLoadingSpinner(false);
  }, [isMounted]);

  const persistableFilterValues = (filterValues) => {
    const persistable = {...filterValues};

    filters
        .filter(filter => filter?.type === 'typeahead')
        .forEach(filter => delete persistable[filter.field]);

    return persistable;
  }

  useEffect(() => {
    if (loadResources) {
      getResources({
        apiEndpoint: resourcesConfig.apiEndpoint,
        filterValues: { ...resourcesConfig.filterValues, ...fixedFilterValues },
        sortValues: [...resourcesConfig.sortValues, ...fixedSortValues],
        pagination: resourcesConfig.pagination,
      });
      setResourcesState(resourcesConfig.adminEndpoint || resourcesConfig.apiEndpoint, {
        filterValues: persistableFilterValues(resourcesConfig.filterValues),
        sortValues: resourcesConfig.sortValues,
        pagination: resourcesConfig.pagination,
      });
      if (setFilterValuesParent) {
        setFilterValuesParent({ ...resourcesConfig.filterValues, ...fixedFilterValues });
      }
    }
  }, [getResources, loadResources, resourcesConfig]);

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDTypography variant="h5">{title}</MDTypography>
        <MDBox display="flex">
          <MDBox ml={1}>
            {
              !disableAddResource
              && (
                <MDButton variant="gradient" display="flex" color="info" onClick={() => navigate(`/admin/${adminEndpoint || apiEndpoint}/add`)}>
                  <AddIcon />&nbsp; add {resourceName}
                </MDButton>
              )
            }
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>


        <ListResourcesFilters
          filters={filters}
          filterValues={resourcesConfig.filterValues}
          setFilterValues={setFilterValues}
          defaultFilterValues={defaultFilterValues}
        >
        {
          isDownloadable &&
          <MDBox display="flex">
          <MDButton
            onClick={() => handleDownloadOnClick()}
            variant="gradient"
            color="info"
            disabled={isDownloading}
            sx={{width:140}}
          >
            <Icon>download</Icon>
            {isDownloading ? 'Downloading...' : 'Download'}
          </MDButton>
          </MDBox>
        }
        </ListResourcesFilters>
        <ListResourcesTable
          adminEndpoint={adminEndpoint}
          apiEndpoint={apiEndpoint}
          columns={columns}
          pagination={resourcesConfig.pagination}
          resources={resources}
          resourceCount={resourceCount}
          sortValues={resourcesConfig.sortValues}
          setSortValues={setSortValues}
          setPagination={setPagination}
          disableEditResource={disableEditResource}
          disableOpenInNewTab={disableOpenInNewTab}
          customItemsPerPage={customItemsPerPage}
          onRowDoubleClick={onRowDoubleClick}
        />
      </Card>
    </MDBox>
  );
};

ListResources.propTypes = {
  adminEndpoint: PropTypes.string,
  apiEndpoint: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  customItemsPerPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  defaultFilterValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  defaultSortValues: PropTypes.array,
  disableAddResource: PropTypes.bool,
  disableEditResource: PropTypes.bool,
  disableOpenInNewTab: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fixedFilterValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  fixedSortValues: PropTypes.array,
  isDownloadable: PropTypes.bool,
  onRowDoubleClick: PropTypes.func,
  resourceName: PropTypes.string.isRequired,
  setFilterValues: PropTypes.func,
  shouldLoadResources: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ListResources.defaultProps = {
  adminEndpoint: null,
  customItemsPerPage: null,
  defaultFilterValues: {},
  defaultSortValues: [],
  disableAddResource: false,
  disableEditResource: false,
  disableOpenInNewTab: false,
  fixedFilterValues: {},
  fixedSortValues: [],
  isDownloadable: false,
  onRowDoubleClick: null,
  setFilterValues: null,
  shouldLoadResources: true,
};

export default ListResources;
