import React from 'react';
import PropTypes from 'prop-types';

import Styles from './MembershipCountWidget.module.scss';

const MembershipCountWidget = ({ label, dayTotal = 'N/A', lastYearTotal = 'N/A', change = '' }) => {
  const numberFormatter = new Intl.NumberFormat('en-GB');

  const formattedDayTotal = numberFormatter.format(dayTotal);
  const formattedLastYearTotal = numberFormatter.format(lastYearTotal);

  const percentageChange = change && lastYearTotal ? Math.round((change / lastYearTotal) * 100) : null;  
  let percentageChangeLabel = 'neutral';

  if(percentageChange < 0) {
    percentageChangeLabel = 'negative'
  } 
  
  else if (percentageChange > 0) {
    percentageChangeLabel = 'positive'
  }

  const getFormattedNumberString = (number) => {
    if(Number.isNaN(number) || number === 'NaN') {
      return 'N/A';
    }

    return number;
  }

  const getWidgetLabelString = (str) => str.replace('(Team)', '').replace('Course – ', '').replace(' School', '')

  return (
    <div className={Styles.widget}>
      <h2 className={Styles.widgetLabel}>{ getWidgetLabelString(label) }</h2>

      <ul className={Styles.widgetCounts}>
        <li className={Styles.widgetDayTotal}>
          <span>Today</span>
          <span>
            { percentageChange ? 
              <span className={`${ Styles.percentageChange } ${ Styles[percentageChangeLabel] }`}>{ percentageChange }%</span>
            : null } 
            { getFormattedNumberString(formattedDayTotal) }
          </span>
        </li>
        <li className={Styles.widgetYearTotal}>
          <span>Last Year</span>
          <span>{ getFormattedNumberString(formattedLastYearTotal) }</span>
        </li>
      </ul>
    </div>
  );
}

MembershipCountWidget.propTypes = {
  label: PropTypes.string.isRequired,
  dayTotal: PropTypes.string.isRequired,
  lastYearTotal: PropTypes.string.isRequired,
  change: PropTypes.string.isRequired,
}

MembershipCountWidget.defaultProps = {}
  
export default MembershipCountWidget;
  