import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SortableTree from '@nosferatu500/react-sortable-tree';

// MUI
import {
  IconButton,
  Tooltip
} from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import { requestApi } from 'api/request-api';
import useMounted from 'hooks/use-mounted';
import LoadingSpinner from 'components/LoadingSpinner';

const ProductSummary = (props) => {
  const { product } = props;

  const [treeData, setTreeData] = useState();
  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();

  // eslint-disable-next-line no-shadow
  const mapProductToTreeNode = (product) => {
    const treeNode = {};
    treeNode.title = product.name;
    treeNode.subtitle = product.sku;
    treeNode.shortName = product.shortName;
    treeNode.slug = product.slug;
    treeNode.type = product.type;
    const typeColor = {
      simple: 'light',
      variable: 'dark',
      group: 'error'
    }
    treeNode.typeColor = typeColor[product.type];
    treeNode.excludeFromSearch = product.excludeFromSearch;
    treeNode.isBundle = product.isBundle;
    if (product.quantity) {
      treeNode.quantity = product.quantity;
    }
    if (product.orderShipment || product.shipment) {
      treeNode.shipment = product.orderShipment ?? `${product.shipment} - (unset order shipment)`;
    }

    treeNode.children = [];
    product.productsInGroup?.forEach(childProduct => {
      treeNode.children.push(mapProductToTreeNode(childProduct));
    });
    product.productsInBundle?.forEach(childProduct => {
      treeNode.children.push(mapProductToTreeNode(childProduct));
    });

    return treeNode;
  };

  const mapProductSummaryToTree = (productSummary) => {
    const tree = [];
    tree.push(mapProductToTreeNode(productSummary));

    return tree;
  };

  const initialise = useCallback(async () => {
    // eslint-disable-next-line no-shadow
    const productSummary = await requestApi.getResponse({
      url: `products/${product.id}/summary`
    });
    if (isMounted()) {
      setTreeData(mapProductSummaryToTree(productSummary));
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return <LoadingSpinner/>;

  // eslint-disable-next-line no-shadow
  const handleTreeOnChange = (treeData) => {
    setTreeData(treeData);
  }

  return (
    <div style={{ height: 400 }}>
      <SortableTree
        treeData={treeData}
        canDrag={false}
        onChange={handleTreeOnChange}
        // eslint-disable-next-line react/no-unstable-nested-components
        generateNodeProps={({node}) => ({
          buttons: [
            <IconButton><MDBadge color={node.typeColor} size="lg" badgeContent={node.type} /></IconButton>,
            node.isBundle ? <Tooltip title="Product is a bundle containing other products"><IconButton><GroupWorkIcon color="disabled" fontSize="medium" /></IconButton></Tooltip> : '',
            node.excludeFromSearch ? <Tooltip title="Exclude from search"><IconButton><VisibilityOffIcon color="disabled" fontSize="medium" /></IconButton></Tooltip> : '',
            node.quantity || node.shipment ? <IconButton><MDBadge color="warning" size="lg" badgeContent={node.quantity && `x${node.quantity} ${node.shipment}`} /></IconButton>: '',
          ],
        })}
      />
    </div>
  );
}

ProductSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

export default ProductSummary;
