const stockChangeVerbPhrase = function(stockChangeType, warehouseDelta) {

    switch (stockChangeType) {
        case "App\\Entity\\StockChangeCorrection":
            return 'correction for';

        case "App\\Entity\\StockChangeCount":
            return 'count for';

        case "App\\Entity\\StockChangeCustomerOrder":
            return 'customer-ordered from';

        case "App\\Entity\\StockChangeDespatch":
            return 'despatched from';

        case "App\\Entity\\StockChangeOrder":
            return 'ordered for';

        case "App\\Entity\\StockChangeReceive":
            return 'received into';

        case "App\\Entity\\StockChangeReserve":
            return 'reserved from';

        case "App\\Entity\\StockChangeReturn":
            return 'returned to';

        case "App\\Entity\\StockChangeSupplierOrder":
            return 'ordered for';

        case "App\\Entity\\StockChangeWriteoff":
            return 'written off from';

        case "App\\Entity\\StockChangeTransfer":
            return warehouseDelta < 0 ? 'transferred out from' : 'transferred into';

        default:
            return 'change to';
    }
};

export {
    // eslint-disable-next-line import/prefer-default-export
    stockChangeVerbPhrase
};