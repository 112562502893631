import { useCallback, useEffect, useState } from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import PropTypes from 'prop-types';

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'components/shared/Table';
import useMounted from 'hooks/use-mounted';
import AddEditProductRelationship from "./AddEditProductRelationship";

const ProductRelationships = (props) => {
  const {
    formik,
    product,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productRelationships, setProductRelationships] = useState([]);
  const [productRelationshipToEdit, setProductRelationshipToEdit] = useState(null);
  const [addEditProductRelationshipModalOpen, setAddEditProductRelationshipModalOpen] = useState(false);
  // Delete state
  const [productRelationshipIdToDelete, setProductRelationshipIdToDelete] = useState(false);
  const [deleteProductRelationshipModalOpen, setDeleteProductRelationshipModalOpen] = useState(false);

  const isMounted = useMounted();

  const loadProductRelationships = async () => {
    const data = await resourceApi.getResources({
      apiEndpoint: 'product-relationships',
      filterValues: {
        product: product['@id'],
      },
      sortValues: [
        {
          field: 'displayOrder',
          direction: 'asc',
        },
      ],
      pagination: {
        itemsPerPage: 999999,
      },
    });
    if (isMounted()) {
      setProductRelationships(data.resources);
    }
  }

  const initialise = useCallback(async () => {
    await loadProductRelationships();
    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const addProductRelationship = () => {
    setProductRelationshipToEdit(null);
    setAddEditProductRelationshipModalOpen(true);
  };

  const closeAddEditProductRelationshipModal = async () => {
    setAddEditProductRelationshipModalOpen(false);
    loadProductRelationships();
  }

  const handleDeleteOnClick = (productRelationshipId) => {
    setProductRelationshipIdToDelete(productRelationshipId);
    setDeleteProductRelationshipModalOpen(true);
  };
  const confirmDeleteProductRelationship = async () => {
    await resourceApi.deleteResource({
      apiEndpoint: 'product-relationships',
      id: productRelationshipIdToDelete,
    });
    setDeleteProductRelationshipModalOpen(false);
    loadProductRelationships();
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || destination?.index === source?.index) {
      return;
    }

    // change local state
    const productRelationshipsTemp = [...productRelationships];
    const element = productRelationshipsTemp.splice(source.index, 1)[0];
    productRelationshipsTemp.splice(destination.index, 0, element);
    setProductRelationships(productRelationshipsTemp);

    // update form
    formik.setFieldValue(
        'productRelationships',
        productRelationshipsTemp.map(productRelationship => productRelationship['@id'])
    );
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Related products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
          >
            <Grid
              item
              xs={12}
            >
              <Box display="flex" justifyContent="flex-end">
                <MDButton
                  color="info"
                  onClick={addProductRelationship}
                  variant="gradient"
                >
                  Add related product
                </MDButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell heading>
                        SKU
                    </TableCell>
                    <TableCell heading>
                      Related product
                    </TableCell>
                    <TableCell heading />
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                      droppableId="product-relationships-droppable"
                      direction="vertical"
                  >
                    {
                      (droppableProvided) => (
                          <TableBody
                              ref={droppableProvided.innerRef}
                              {...droppableProvided.droppableProps}
                          >
                            {
                                productRelationships.length === 0 &&
                                <TableRow>
                                  <TableCell align="center" colSpan={2}>
                                    No related products
                                  </TableCell>
                                </TableRow>
                            }
                            {
                              productRelationships.map((productRelationship, index) => (
                                  <Draggable
                                      draggableId={`product-relationship-${productRelationship.id}`}
                                      index={index}
                                      key={`product-relationship-${productRelationship.id}`}
                                  >
                                    {
                                      (draggableProvided, snapshot) => (
                                          <TableRow
                                              key={`product-relationship-${productRelationship.id}`}
                                              dragHandleProps={draggableProvided.dragHandleProps}
                                              ref={draggableProvided.innerRef}
                                              {...draggableProvided.draggableProps}
                                              style={{
                                                ...draggableProvided.draggableProps.style,
                                                background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none"
                                              }}
                                          >
                                            <TableCell>
                                              {productRelationship.relatedProduct?.sku}
                                            </TableCell>
                                            <TableCell>
                                              {productRelationship.relatedProduct?.name}
                                            </TableCell>
                                            <TableCell align="right">
                                              <MDButton
                                                  color="error"
                                                  iconOnly
                                                  onClick={() => handleDeleteOnClick(productRelationship.id)}
                                                  size="small"
                                                  variant="gradient"
                                              >
                                                <DeleteIcon/>
                                              </MDButton>
                                            </TableCell>
                                          </TableRow>
                                      )
                                    }

                                  </Draggable>
                              ))
                            }
                            {droppableProvided.placeholder}
                          </TableBody>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </Table>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={addEditProductRelationshipModalOpen}
        onClose={closeAddEditProductRelationshipModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-product-relationship"
        aria-describedby="add-edit-product-relationship"
      >
        <DialogContent>
          <AddEditProductRelationship
            productRelationship={productRelationshipToEdit}
            product={product}
            onClose={closeAddEditProductRelationshipModal}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteProductRelationshipModalOpen}
        aria-labelledby="delete-product-relationship"
        aria-describedby="delete-product-relationship"
      >
        <DialogTitle id="scroll-dialog-title">Delete related product</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this related product?
        </DialogContent>
        <DialogActions>
          <MDButton
            color="info"
            onClick={confirmDeleteProductRelationship}
            variant="gradient"
          >
            Yes
          </MDButton>
          <MDButton
            color="secondary"
            onClick={() => setDeleteProductRelationshipModalOpen(false)}
            variant="gradient"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

ProductRelationships.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

ProductRelationships.defaultProps = {
}

export default ProductRelationships;
