import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'locations';
const resourceName = 'location';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
});

const AddEditLocation = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditLocation;
