/* eslint-disable */
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

const CardHeader = ({ color, children }) => {
  return (
    <MDBox display="flex">
      <MDBox
        bgColor={color}
        borderRadius="md"
        m={2}
        mt={-2}
        shadow="lg"
        variant="gradient"
      >
        <MDTypography
          color="white"
          sx={{ m: 1.5 }}
          variant="subtitle2"
        >
          {children}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
};

export default CardHeader;
