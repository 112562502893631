const getDateUtcString = (dateAsString, format) => {
  if (!dateAsString) return '';
  const date = new Date(dateAsString);
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return '';
  }

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hour = String(date.getUTCHours()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');
  const second = String(date.getUTCSeconds()).padStart(2, '0');

  if (format === 'date') {
    return `${day}/${month}/${year}`;
  }
  if (format === 'time') {
    return `${hour}:${minute}:${second}`;
  }
  return `${day}/${month}/${year}, ${hour}:${minute}:${second}`;
};

export default getDateUtcString;
