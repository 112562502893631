import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MDButton from 'mdpr2/components/MDButton';
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import PropTypes from 'prop-types';
import axios from 'axios';
import { putRequestQuery } from 'api/apiRequest';
import capitalise from 'utils/capitalise';
import { useAppContext } from 'contexts/app-context';


const BasketLines = (props) => {
  const { basket, setBasket, disableRemoveItems, entityName } = props;

  const { userFeedbackSuccess } = useAppContext();

  const removeProductFromBasket = async (productIri) => {
    let data;
    if (productIri.match(/^\/product-variants/)) {
        data = {
            basketLines: [
                { productVariant: productIri, quantity: 0 }
            ]
        }
    } else {
        data = {
            basketLines: [
                { product: productIri, quantity: 0 }
            ]
        }
    }
    const response = await axios(putRequestQuery(`baskets/${basket.id}`, data));
    if (response && response.data) {
        setBasket(response.data);
        userFeedbackSuccess(`${capitalise(entityName)} has been updated`);
    }
};

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead style={{ display: 'table-row-group' }}>
          <TableCell>Product</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Line Total</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableHead>
        <TableBody>
          {basket.basketLines?.map((basketLine, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`basket-line-${index}`}>
              <TableCell>
                <h4>{basketLine.basketItem.name}</h4>
                {basketLine.basketItem.sku}
                {basketLine.basketItem.attributes.map((attribute) => (
                  <>
                    <br />
                    {attribute.name}: {attribute.value}
                  </>
                ))}
                {Array.isArray(basketLine.orderData) &&
                  basketLine.orderData.map((orderDataElement) => (
                    <>
                      <br />
                      {orderDataElement.label}: {orderDataElement.value}
                    </>
                  ))}
              </TableCell>
              <TableCell>{basketLine.quantity}</TableCell>
              <TableCell>£{convertPriceToPounds(basketLine.linePriceBeforeDiscount.excTax)}</TableCell>
              {!disableRemoveItems && (
                <TableCell>
                  <MDButton
                    color="error"
                    iconOnly
                    onClick={() => removeProductFromBasket(basketLine.product || basketLine.productVariant)}
                    variant="gradient"
                  >
                    <DeleteIcon />
                  </MDButton>
                </TableCell>
              )}
            </TableRow>
          ))}

          <TableRow key="discounts_applied">
            <TableCell>
              <h4>Discounts applied</h4>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>

          {basket.promotions?.length
            ? basket.promotions?.map((basketUnitDiscount) => (
                <TableRow>
                  <TableCell>
                    {basketUnitDiscount.promotion.name === 'Retrospective Subscription Credit'
                      ? 'Credit from unused subscriptions '
                      : `${basketUnitDiscount.promotion.name} `}
                  </TableCell>
                  <TableCell />
                  <TableCell>-£{convertPriceToPounds(basketUnitDiscount.discountPrice.excTax)}</TableCell>
                  <TableCell />
                </TableRow>
              ))
            : null}

          <TableRow key="summary">
            <TableCell>
              <h4 style={{ visibility: 'hidden' }}>Summary</h4>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>

          <TableRow>
            <TableCell />
            <TableCell>Subtotal</TableCell>
            <TableCell>£{convertPriceToPounds(basket.subtotalPrice?.excTax || 0)}</TableCell>
            <TableCell />
          </TableRow>
          {basket.manualDiscount === null && basket.discountPrice?.incTax > 0 && (
            <TableRow>
              <TableCell />
              <TableCell>Discount</TableCell>
              <TableCell>-£{convertPriceToPounds(basket.discountPrice.excTax)}</TableCell>
              <TableCell />
            </TableRow>
          )}
          {basket.manualDiscount !== null && (
            <TableRow>
              <TableCell />
              <TableCell>Discount</TableCell>
              <TableCell>-£{convertPriceToPounds(basket.discountPrice.excTax)}</TableCell>
              <TableCell />
            </TableRow>
          )}
          {basket.manualShippingServiceAmount === null && (
            <TableRow>
              <TableCell />
              <TableCell>
                Shipping
                <br />
                {basket.basketShipping?.shippingService?.name || (
                  <strong>No shipping option is available for this order</strong>
                )}
              </TableCell>
              <TableCell>£{convertPriceToPounds(basket.basketShipping?.price?.incTax || 0)}</TableCell>
              <TableCell />
            </TableRow>
          )}
          {basket.manualShippingServiceAmount !== null && (
            <TableRow>
              <TableCell>
                Manual shipping:&nbsp;
                {basket.manualShippingServiceName || <strong>No manual shipping service is set for this order</strong>}
              </TableCell>
              <TableCell />
              <TableCell>£{convertPriceToPounds(basket.manualShippingServiceAmount || 0)}</TableCell>
              <TableCell />
            </TableRow>
          )}
          <TableRow>
            <TableCell />
            <TableCell>
              <Tooltip title="Subtotal - Discount + Shipping" disableInteractive>
                <strong>Total Excl. Tax</strong>
              </Tooltip>
            </TableCell>
            <TableCell>£{convertPriceToPounds(basket.totalPrice?.excTax || 0)}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>VAT</TableCell>
            <TableCell>
              £{convertPriceToPounds((basket.totalPrice?.incTax || 0) - (basket.totalPrice?.excTax || 0))}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>
              <Tooltip title="(Subtotal - Discount + Shipping) + Tax" disableInteractive>
                <strong>Total Incl. Tax</strong>
              </Tooltip>
            </TableCell>
            <TableCell>
              <h4>
                <strong>£{convertPriceToPounds(basket.totalPrice?.incTax || 0)}</strong>
              </h4>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BasketLines.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  basket: PropTypes.object.isRequired,
  setBasket: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  disableRemoveItems: PropTypes.bool.isRequired,
};

export default BasketLines;
