import {useEffect, useState} from "react";
import PropTypes from "prop-types";

/* MDPR */
import TimelineList from "mdpr2/examples/Timeline/TimelineList";

/* WRM */
import { resourceApi } from 'api/resource-api';
import TimelineItemRich from "components/TimelineItemRich";
import { requestApi } from "api/request-api";
import {buildShipmentStories} from "./helpers";
import {useAppContext} from "../../../contexts/app-context";

const OrderShipments = ({order, handleMarkAsCompleted, handleMarkDespatchAsCompleted}) => {

    const { userFeedbackError } = useAppContext();
    const [stockChangesData, setStockChangesData] = useState(null);
    const [returnsContext, setReturnsContext] = useState(null);
    const [returnsContextLoading, setReturnsContextLoading] = useState(false);

    const loadStockChanges = async () => {
        try {
            const stockChangesResponse = await resourceApi.getResources({
                apiEndpoint: 'stock-changes',
                filterValues: {
                    order: order['@id'],
                    'type[]': 'StockChangeDespatch'
                },
                pagination: {
                    itemsPerPage: 9999,
                },
            });
            const stockChanges = stockChangesResponse.resources || [];

            const stockChangesDataIndexedOnSku = [];
            stockChanges.forEach((stockChange) => {
                const sku = stockChange.actualProduct.sku || null;
                const shipment = stockChange.shipment || null;
                if (!stockChangesDataIndexedOnSku[sku]) {
                    stockChangesDataIndexedOnSku[sku] = {};
                }
                if (!(shipment in stockChangesDataIndexedOnSku[sku])) {
                    stockChangesDataIndexedOnSku[sku][shipment] = {
                        sku: stockChange.sku,
                        quantity: stockChange.quantity,
                        createdAt: stockChange.createdAt,
                    };
                } else {
                    stockChangesDataIndexedOnSku[sku][shipment].quantity += stockChange.quantity;
                }
            });
            setStockChangesData(stockChangesDataIndexedOnSku);
        } catch (error) {
            console.error(`loadStockChanges: ${error.message}`);
            setStockChangesData([]);
        }
    };

    const refreshReturnsContext = () => {
        setReturnsContextLoading(true);
        requestApi
            .getResponse({url: `orders/${order.id}/returns-context`})
            // eslint-disable-next-line no-shadow
            .then(returnsContext => {
                setReturnsContext(returnsContext);
                setReturnsContextLoading(false);
            })
            .catch(() => {
                setReturnsContext(false);
                userFeedbackError('Failed to load returns data for order shipments')
            });
    }

    useEffect(() => {
        loadStockChanges();
        refreshReturnsContext();
    }, [order]);

    if (stockChangesData === null || returnsContext === null || returnsContextLoading) {
        return <>Loading Shipments...</>
    }

    const shipmentStories = buildShipmentStories(
        order,
        stockChangesData,
        returnsContext,
        handleMarkAsCompleted,
        handleMarkDespatchAsCompleted
    );

    return (
        // this generates each shipment 'block'
        <TimelineList title="">
            { shipmentStories.map((shipmentStory) => <TimelineItemRich key={shipmentStory.title} {...shipmentStory} />) } 
        </TimelineList>
    );
}
OrderShipments.propTypes = {
    /* eslint-disable */
    order: PropTypes.object.isRequired,
    /* eslint-disable */
    handleMarkAsCompleted: PropTypes.func.isRequired,
    /* eslint-enable */
    handleMarkDespatchAsCompleted: PropTypes.func.isRequired,
};

export default OrderShipments;
