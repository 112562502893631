// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Membership plans';
const apiEndpoint = 'membership-plans';
const resourceName = 'membership plan';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 2,
  },
  {
    field: '_length',
    label: 'Length',
    flex: 1,
    formatter: (resource) => `${resource.lengthAmount} ${resource.lengthPeriod}${resource.lengthAmount === 1 ? '' : 's'}`,
  },
  {
    field: 'products',
    label: 'Products',
    flex: 3,
    formatter: (resource) => {
      const productsData = [];
      resource.products?.forEach(product => { productsData.push(`${product.name} (${product.sku})`); });
      return productsData.join(', ');
    },
  }
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const MembershipPlans = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default MembershipPlans;
