import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import {toApiValues, toFormValues} from 'components/shared/resource/api-form-mapper';
import {useMemo} from "react";
import {debounce} from "lodash";
import userSearch from "utils/userSearch";

const apiEndpoint = 'learning-group-accesses';
const resourceName = 'Learning Group Access';

const buildFields = ({debouncedUserSearch, readOnlyUser}) => {

  const userField = () => {
    if(readOnlyUser) {
      return {
        name: 'userDescription',
        label: 'User',
        type: 'text',
        disabled: true
      };
    }
    return     {
      name: 'user',
      label: 'User',
      type: 'typeahead',
      search: debouncedUserSearch,
      formValue: (resource) => resource?.user !== null ?
          { id: resource.user['@id'], label: resource.user.email } :
          null
      ,
    };
  }

  return [
    {
      name: 'learningGroupName',
      label: 'Learning Group',
      type: 'text',
      disabled: true
    },
    userField(),
    {
      name: 'role',
      label: 'Role',
      type: 'select',
      choices: [
        { value: 'user', label: 'User'},
        { value: 'manager', label: 'Manager'}
      ],
      defaultValue: 'user'
    },
    {
      name: 'isActive',
      label: 'Active?',
      type: 'checkbox',
      defaultValue: true,
    },
  ];
}

const AddEditLearningGroupAccess = ({ isModal, learningGroupAccessId, onModalClose, learningGroup }) => {
  const params = useParams();
  let id;
  if (learningGroupAccessId) {
    id = learningGroupAccessId;
  } else if(learningGroup) {
    id = null;
  } else {
    id = params.id ? Number(params.id) : null;
  }

  // once a resource has been created, never allow the user to be amended
  const readOnlyUser = (id !== null);

  const validationSchema = Yup.object().shape({
    ...(readOnlyUser ? {} : {
      user: Yup.object()
          .required('User is required')
          .typeError('User is required'), // Capture 'no selection'
    }),
    role: Yup.string()
        .required('Role is required')
  });

  // eslint-disable-next-line no-shadow
  const toApiValuesCustom = (formValues, fields) => {
    // Perform standard mapping
    const apiValues = toApiValues(formValues, fields);

    // we are adding a new access to a learning group
    if (id === null && learningGroup) {
      apiValues.learningGroup = learningGroup['@id'];
    }

    return apiValues;
  }

  const toFormValuesCustom = (resource, fields) => {
    const formValues = toFormValues(resource, fields);

    formValues.learningGroupName = learningGroup ? learningGroup.name : resource.learningGroup.name;

    if(readOnlyUser) {
      const {user} = resource;
      formValues.userDescription = `${user.firstName} ${user.lastName} (${user.email})`;
    }

    return formValues;
  }

  const debouncedUserSearch = useMemo(
      () => debounce((search, setOptions) => userSearch(search, setOptions), 280),
      []
  );

  const fields = buildFields({debouncedUserSearch, readOnlyUser});

  return (
      <AddEditResource
          apiEndpoint={apiEndpoint}
          fields={fields}
          id={id}
          isModal={isModal}
          onModalClose={onModalClose}
          resourceName={resourceName}
          toApiValuesCustom={toApiValuesCustom}
          toFormValuesCustom={toFormValuesCustom}
          validationSchema={validationSchema}
      />
  );
};

AddEditLearningGroupAccess.propTypes = {
  isModal: PropTypes.bool,
  learningGroupAccessId: PropTypes.number,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  learningGroup: PropTypes.object,
};

AddEditLearningGroupAccess.defaultProps = {
  isModal: false,
  learningGroupAccessId: null,
  onModalClose: null,
  learningGroup: null,
}

export default AddEditLearningGroupAccess;
