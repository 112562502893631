/* eslint-disable */
import { Field, FieldArray, FormikProvider, getIn } from 'formik';
import moment from 'moment';
import React, { useState } from "react";

// MUI
import Typography from '@mui/material/Typography';
import {
    Autocomplete,
    Box,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// WRM
import FormField from "components/shared/FormField";
import { requestApi } from "api/request-api";

const getField = (childFields, itemIndex, parentFieldName, childFieldName) => {
    const childField = childFields.filter((field) => field.name === childFieldName)[0] ?? {};
    const field = { ...childField };
    field.name = `${parentFieldName}[${itemIndex}].${childFieldName}`;
    return field;
}

const StockItemsCollectionFormField = (props) => {
    const {
        childFields,
        formik,
        getApiError,
        label,
        name,
        showFileBrowser,
        setValidatedStockCount,
        validatedStockCount = [],
    } = props;

    const [productChoices, setProductChoices] = useState([]);

    const addItemToFieldArray = (fieldArrayHelpers) => {
        const uuid = `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}-${moment().valueOf()}`;
        const prototype = {
            product: null,
            quantity: null,
            notes: '',
            uuid
        };
        fieldArrayHelpers.push(prototype);
        setValidatedStockCount([]);
    }

    const searchProducts = (event) => {
        const search = event.target.value;
        if (search.length) {
            requestApi.getResponse(
                { 'url': `products/products-as-choices?ignore_bundle_products=1&search=${encodeURIComponent(search)}` }
            ).then(response => {
                setProductChoices(response);
            });
        } else {
            setProductChoices([]);
        }
    };

    const renderProductField = (itemIndex) => {

        const field = getField(childFields, itemIndex, name, 'product');
        return (
            <Field
                name={name}
                component={Autocomplete}
                options={productChoices}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                    if (value) {
                        formik.setFieldValue(field.name, value.value);
                    }
                }}
                renderInput={(fieldParams) => (
                    <TextField
                        {...fieldParams}
                        label="Search for product"
                        variant="outlined"
                        onChange={searchProducts}
                        error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
                    />
                )}
            />
        );
    }

    const renderDifferential = (itemValue) => {
        const matchedStock = validatedStockCount.filter((el) => el.product === itemValue.product)[0];

        if (!matchedStock) {
            return <Typography sx={{color: 'red', fontSize: '12px'}}>Could not calculate difference.</Typography>
        }

        return matchedStock.quantity !== 0 && (
          <Typography sx={{color: 'red', fontSize: '12px'}}>{
              matchedStock.quantity > 0 ? `${matchedStock.quantity} more stock in the warehouse`
                : `${matchedStock.quantity} less stock in the warehouse`
          }</Typography>
        );
    };

    let values = getIn(formik.values, name);
    if (!Array.isArray(values)) {
        values = [];
    }

    // https://formik.org/docs/api/useFormik
    // Be aware that <Field>, <FastField>, <ErrorMessage>, connect(), and <FieldArray> will NOT work with useFormik() as they all require React Context.
    // Solution: Wrap <FieldArray> in <FormikProvider>
    return (
        <FormikProvider value={formik}>
            <FieldArray
                name={name}
                render={(fieldArrayHelpers) => (
                    <>
                        <Box my={1}>
                            <Typography variant="h6">{label}</Typography>
                        </Box>
                        <Box>
                            {
                                values.map((itemValue, itemIndex) => (
                                    <Grid
                                        key={`stock_items_${itemIndex}`}
                                        container
                                        mt={0}
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                        >
                                            {renderProductField(itemIndex)}
                                        </Grid>
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                        >
                                            <FormField
                                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                                {...getField(childFields, itemIndex, name, 'quantity')}
                                                formik={formik}
                                                getApiError={getApiError}
                                                showFileBrowser={showFileBrowser}
                                            />
                                            {validatedStockCount.length > 0 && renderDifferential(itemValue)}
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <FormField
                                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                                {...getField(childFields, itemIndex, name, 'notes')}
                                                formik={formik}
                                                getApiError={getApiError}
                                                showFileBrowser={showFileBrowser}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={1}
                                            xs={12}
                                            alignContent="right"
                                        >
                                            {
                                                values.length > 1 && (
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => fieldArrayHelpers.remove(itemIndex)}
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }

                                            {
                                                values.length === (itemIndex + 1) && (
                                                    <IconButton
                                                        size="small"
                                                        color="info"
                                                        onClick={() => addItemToFieldArray(fieldArrayHelpers)}
                                                    >
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Box>
                    </>
                )}
            />
        </FormikProvider>
    );
};

export default StockItemsCollectionFormField;
