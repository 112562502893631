import PropTypes from 'prop-types';
// import DashboardLayout from 'mdpr2/examples/LayoutContainers/DashboardLayout';
import MDBox from "mdpr2/components/MDBox";

const BlankLayout = ({ children }) => (
  <MDBox p={2}>
  {children}
  </MDBox>
);

BlankLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
};

export default BlankLayout;
