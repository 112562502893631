import { resourceApi } from 'api/resource-api';

const sortLicences = (licences) => licences.sort((a, b) => new Date(b.expiresAt) - new Date(a.expiresAt));

export const loadLicences = async (id) => {
  const response = await resourceApi.getResources({
    apiEndpoint: 'licences',
    filterValues: {
      ownedBy: `/learning-groups/${id}`,
    },
    sortValues: [{ field: 'expiresAt', direction: 'asc' }],
    pagination: {
      itemsPerPage: 999,
    },
  });

  return sortLicences(response.resources);
};

export default loadLicences;
