import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import countriesArr from 'utils/countriesList';

const apiEndpoint = 'orders';
const resourceName = 'delivery address';

const fields = [
  {
    name: 'deliveryAddress.firstName',
    label: 'First Name',
  },
  {
    name: 'deliveryAddress.lastName',
    label: 'Last Name',
  },
  {
    name: 'deliveryAddress.line1',
    label: 'Address Line 1',
  },
  {
    name: 'deliveryAddress.line2',
    label: 'Address line 2',
  },
  {
    name: 'deliveryAddress.line3',
    label: 'Address line 3'
  },
  {
    name: 'deliveryAddress.townCity',
    label: 'Town/city'
  },
  {
    name: 'deliveryAddress.county',
    label: 'County',
  },
  {
    name: 'deliveryAddress.country',
    label: 'Country',
    type: 'select',
    choices: countriesArr.map((choice) => ({ value: choice[0], label: choice[1] })),
  },
  {
    name: 'deliveryAddress.postcode',
    label: 'Postcode',
  },
  {
    name: 'deliveryAddress.email',
    label: 'Email address',
  },
  {
    name: 'deliveryAddress.phone',
    label: 'Phone Number',
  },
  {
    name: 'deliveryAddress.company',
    label: 'Company',
  },
];

const validationSchema = Yup.object().shape({
  deliveryAddress: Yup.object().shape({
    firstName: Yup.string().nullable().default(''),
    lastName: Yup.string().nullable().default(''),
    line1: Yup.string().required('You must provide an address line 1'),
    line2: Yup.string().nullable().default(''),
    line3: Yup.string().nullable().default(''),
    townCity: Yup.string().required('You must provide a town or city'),
    county: Yup.string().nullable().default(''),
    country: Yup.string().required('You must provide a country'),
    postcode: Yup.string()
      .when('country', {
        is: 'GBR',
        then: Yup.string().required('You must provide a postcode')
      }),
    email: Yup.string().nullable().default(''),
    phone: Yup.string().nullable().default(''),
    company: Yup.string().nullable().default(''),
  }),
});

const EditDeliveryAddress = (props) => {
  const {
    onClose,
    resourceId
  } = props;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={resourceId}
      isModal
      onModalClose={onClose}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

EditDeliveryAddress.propTypes = {
  onClose: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired,
}

export default EditDeliveryAddress;
