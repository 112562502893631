import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './ConditionsHelper.module.scss';
import { conditionAttributeLabels } from './constants';

const ConditionsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const attributeChoices = [
    {value: 'basket.subtotal', label: conditionAttributeLabels['basket.subtotal'] ?? 'basket.subtotal'},
    {value: 'basket.packingUnits', label: conditionAttributeLabels['basket.packingUnits'] ?? 'basket.packingUnits'},
    {value: 'product.packingUnits', label: conditionAttributeLabels['product.packingUnits'] ?? 'product.packingUnits'},
    {value: 'basket.buyerType', label: conditionAttributeLabels['basket.buyerType'] ?? 'basket.buyerType'},
  ];
  const comparatorChoices = [
    {value: '>', label: '>'},
    {value: '>=', label: '>='},
    {value: '<', label: '<'},
    {value: '<=', label: '<='},
    {value: '=', label: '='},
  ];

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedConditions = [];
    const movedCondition = formik.values.conditionsHelper[result.source.index];
    formik.values.conditionsHelper.forEach((condition, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedConditions.push(movedCondition);
      }
      if (index !== result.source.index) {
        reorderedConditions.push(condition);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedConditions.push(movedCondition);
      }
    });
    formik.setFieldValue('conditionsHelper', reorderedConditions);
  };

  return (
    <Grid
      item
      xs={12}
    >
      <FieldArray name="conditionsHelper" render={fieldArrayHelpers => (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <h4>Conditions</h4>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MDButton
              className={componentStyles['add-condition-button']}
              color="info"
              onClick={() => {
                const prototype = {};
                prototype.attribute = '';
                prototype.comparator = '';
                prototype.value = '';
                prototype.uuid = moment().valueOf();
                fieldArrayHelpers.push(prototype);
              }}
              size="sm"
              startIcon={<AddIcon/>}
            >
              Add condition
            </MDButton>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Table>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  direction="vertical"
                  droppableId="conditions-droppable"
                >
                  {
                    (droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {
                          formik.values.conditionsHelper?.map((condition, index) => {
                            const attributeFieldName = `conditionsHelper[${index}].attribute`;
                            const comparatorFieldName = `conditionsHelper[${index}].comparator`;
                            const valueFieldName = `conditionsHelper[${index}].value`;
                            return (
                              <Draggable
                                draggableId={`condition-field-${condition.uuid}`}
                                index={index}
                                key={`condition-field-${condition.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                      }}
                                    >
                                      <TableCell style={{ width: '1px' }}>
                                        <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          type="select"
                                          name={attributeFieldName}
                                          label="Attribute"
                                          choices={attributeChoices}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          fieldType="select"
                                          name={comparatorFieldName}
                                          label="Comparator"
                                          choices={comparatorChoices}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={valueFieldName}
                                          label="Value"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: '1px' }}>
                                        <MDButton
                                          color="danger"
                                          justIcon
                                          onClick={() => {
                                            fieldArrayHelpers.remove(index);
                                          }}
                                        >
                                          <DeleteIcon/>
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </TableBody>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
      )} />
    </Grid>
  );
};

ConditionsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default ConditionsHelper;
