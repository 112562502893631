import { useCallback, useEffect, useState } from 'react';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import { requestApi } from 'api/request-api';
import ListResources from 'components/shared/ListResources';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import { conditionAttributeLabels } from './constants';

const title = 'Shipping Services';
const apiEndpoint = 'shipping-services';
const resourceName = 'shipping service';

const columns = [
  {
    field: 'name',
    label: 'Name',
    flex: 1,
    formatter: (resource) => (<>
      <b>{resource.name}</b>
      <br/>
      <i>{resource.code}</i>
    </>)
  },
  {
    field: 'shippingServiceZone',
    label: 'Shipping zone',
    flex: 1,
    formatter: (resource) => resource.shippingServiceZone ? resource.shippingServiceZone.name : '',
  },
  {
    field: '_conditions',
    label: 'Conditions',
    flex: 2,
    formatter: (resource) => resource.conditions?.map((condition) => <>{conditionAttributeLabels[condition.attribute] ?? condition.attribute} {condition.comparator} {condition.value}<br /></>),
  },
  {
    field: 'price',
    label: 'Price',
    flex: 1,
    formatter: (resource) => !Number.isNaN(resource.price) ? `£${convertPriceToPounds(resource.price)}` : '',
  },
  {
    field: '_status',
    label: 'Status',
    flex: 0.5,
    formatter: (resource) => {
      const { status } = resource;
      const color = (status === 'active') ? 'success' : 'error';
      return <MDBadge color={color} badgeContent={status === 'active' ? 'Active' : 'Inactive'} />;
    },
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'shippingServiceZone',
    label: 'Shipping zone',
    type: 'select',
  },
];

const defaultFilterValues = {
  shippingServiceZone: [] // Force an array for multiselect
};

const defaultSortValues = [
  {
    field: 'displayOrder',
    direction: 'asc',
  },
];

const ShippingServices = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const shippingServiceZoneChoices = await requestApi.getResponse({url: 'shipping-service-zones/choices'});
      const shippingServiceZoneFilter = filters.find((filter) => filter.field === 'shippingServiceZone');
      shippingServiceZoneFilter.choices = shippingServiceZoneChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultFilterValues={defaultFilterValues}
      defaultSortValues={defaultSortValues}
      filters={filters}
      resourceName={resourceName}
      title={title}
    />
  );
};

export default ShippingServices;
