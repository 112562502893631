import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import EmailRecipientsHelper from './EmailRecipientsHelper';
import RunDeepDiveReport from './RunDeepDiveReport';

const AddEditDeepDiveReportForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: deepDiveReport,
    validationSchema,
  } = props;

  const [runDeepDiveReportModalOpen, setRunDeepDiveReportModalOpen] = useState(false);

  const handleRunReportOnClick = () => {
    setRunDeepDiveReportModalOpen(true);
  }

  const closeRunDeepDiveReportModal = () => {
    setRunDeepDiveReportModalOpen(false);
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
            <Card>
              <CardContent>
                <Grid
                  container
                  mt={1}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <MDButton
                      color="info"
                      onClick={handleRunReportOnClick}
                      variant="gradient"
                    >
                      Run report
                    </MDButton>
                  </Grid>

                  <FormField
                    {...fields.find((field) => field.name === 'type')}
                    formik={formik}
                    getApiError={getApiError}
                    disabled={!!deepDiveReport.id}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'name')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'description')}
                    formik={formik}
                    getApiError={getApiError}
                  />

									<FormField
                    {...fields.find((field) => field.name === 'deepDiveSegments')}
                    formik={formik}
                    getApiError={getApiError}
                  />

									<FormField
                    {...fields.find((field) => field.name === 'deepDiveOutputSteps')}
                    formik={formik}
                    getApiError={getApiError}
                  />

									<FormField
                    {...fields.find((field) => field.name === 'filenameFormat')}
                    formik={formik}
                    getApiError={getApiError}
                  />

									<FormField
                    {...fields.find((field) => field.name === 'isActive')}
                    formik={formik}
                    getApiError={getApiError}
                  />

									<FormField
                    {...fields.find((field) => field.name === 'schedule')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <EmailRecipientsHelper formik={formik} getApiError={getApiError} />

                  <FormField
                    {...fields.find((field) => field.name === 'emailSubject')}
                    formik={formik}
                    getApiError={getApiError}
                  />
                </Grid>
              </CardContent>
              <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                <SaveCancelButtons
                  cancelLocation={cancelLocation}
                  formik={formik}
                />
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>

      <Dialog
        open={runDeepDiveReportModalOpen}
        onClose={closeRunDeepDiveReportModal}
        maxWidth="md"
        scroll="paper"
      >
        <DialogContent>
          <RunDeepDiveReport
            deepDiveReport={deepDiveReport}
            getApiError={getApiError}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            color="info"
            onClick={closeRunDeepDiveReportModal}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddEditDeepDiveReportForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditDeepDiveReportForm;
