import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'redirects';
const resourceName = 'redirect';

const fields = [
  {
    name: 'fromUrl',
    label: 'From URL',
  },
  {
    name: 'toUrl',
    label: 'To URL',
  },
  {
    name: 'isActive',
    label: 'Active?',
    type: 'checkbox',
    defaultValue: false,
  },
];

const validationSchema = Yup.object().shape({
  fromUrl: Yup.string()
    .required('From URL is required'),
  toUrl: Yup.string()
    .required('To URL required'),
});

const AddEditRedirect = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditRedirect;
