import jwtDecode from 'jwt-decode';

export const checkAdmin = (token) => {
  const decodedToken = jwtDecode(token);
  if (decodedToken) {
    return decodedToken.roles.includes('ROLE_ADMIN');
  }

  return false;
};

export const getUserSession = (name) => JSON.parse(localStorage.getItem(name) || null);

export const checkTokenUsernameAndGuest = (token) => {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);

  if (decodedToken) {
    // eslint-disable-next-line camelcase
    const { username, is_guest_user } = decodedToken;

    // eslint-disable-next-line camelcase
    return username !== '' && is_guest_user === false;
  }

  return false;
};

export const ordinalSuffixOf = (number) => {
  const r10 = number % 10;
  const r100 = number % 100;

  if (r10 === 1 && r100 !== 11) {
    return `${number}st`;
  }

  if(r10 === 2 && r100 !== 12) {
    return `${number}nd`;
  }

  if(r10 === 3 && r100 !== 13) {
    return `${number}rd`;
  }

  return `${number}th`;
}

export const getShallowArrayDiff = (first, second) => [...first.filter(item => !second.includes(item)), ...second.filter(item=> !first.includes(item))]

export const isEmptyObject = (obj) => obj
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype

export const mediaFileS3IdToUrl = (id) => {
  if (id.startsWith('secure/')) {
    return `${process.env.REACT_APP_S3_ASSETS_SECURE_PUBLIC_HOSTNAME}/${id}`;
  }

  return`${process.env.REACT_APP_S3_ASSETS_PUBLIC_HOSTNAME}/${id}`;
}

export const isDevOrStaging = () => {
  const { origin = '' } = window.location;

  if(origin.indexOf('://staging.') >= 0 || origin.indexOf('://localhost') >= 0) {
    return true;
  }

  return false;
}

const learningGroupLabels = {
  "SchoolLearningGroup": "School",
  "IndividualLearningGroup": "Individual User",
  "CustomLearningGroup": "Custom"
};

export const learningGroupPrettyType = (learningGroup) => {
  const learningGroupType = learningGroup['@type'] ?? null;
  return learningGroupLabels[learningGroupType] ?? 'Unknown';
}

export const asTitleCase = (str) => {
  if(!str || typeof str !== 'string') {
    return str;
  }

  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
}