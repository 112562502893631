const onboardingApplicationStatusChoices = [
  {
    value: 'sale-enquiry',
    label: 'Sale Enquiry',
  },
  {
    value: 'order-created',
    label: 'Order created',
  },
  {
    value: 'access-request-sent',
    label: 'Access request sent',
  },
  {
    value: 'complete',
    label: 'Complete',
  },
];

export default onboardingApplicationStatusChoices;
