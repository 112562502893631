/* eslint-disable */
import { getIn } from 'formik';

// MUI
import { TextField } from '@mui/material';

const Static = (props) => {
  const {
    formik,
    label,
    name,
    value,
  } = props;

  return (
    <TextField
      disabled
      fullWidth
      label={label}
      name={name}
      value={formik ? getIn(formik.values, name) : value}
    />
  );
};

export default Static;
