import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Grid, TextField,
} from '@mui/material';

// WRM
import { requestApi } from 'api/request-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';

const AddEditProductBundlePartForm = (props) => {
  const {
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    onModalClose,
    validationSchema,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productChoices, setProductChoices] = useState([]);

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    // Do not allow a 'bundle Product' to be added as a ProductBundlePart's 'part Product', by using ignore_bundle_products=1
    // eslint-disable-next-line no-shadow
    const productChoices = await requestApi.getResponse({ url: `products/products-as-choices?ignore_bundle_products=1` });
    if (isMounted()) {
      setProductChoices(productChoices);
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    onChange={(event, choice) => {
                      formik.setFieldValue('partProductOrPartProductVariant', choice.value);
                    }}
                    options={productChoices}
                    renderInput={(params) => {
                      const error = formik.errors.partProductOrPartProductVariant && true;
                      const helperText = formik.errors.partProductOrPartProductVariant;
                      return (
                        <TextField
                          error={error}
                          helperText={helperText}
                          label="Product"
                          {...params}
                        />
                      );
                    }}
                    defaultValue={productChoices.find((choice) => choice.value === formik.values.partProductOrPartProductVariant)?.label}
                  />
                </Grid>

                {fields.map((mappedField) => (
                    <FormField
                      {...mappedField}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  ),
                )}
              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                isModal
                onModalClose={onModalClose}
                formik={formik}
              />
            </CardActions>
          </Card>

        </Form>
      )}
    </Formik>
  );
};

AddEditProductBundlePartForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditProductBundlePartForm;