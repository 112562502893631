// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Single use discounts';
const apiEndpoint = 'promotions';
const adminEndpoint = 'single-use-discounts'
const resourceName = 'single use discount';

const columns = [
  {
    // This is a bit of a fix and will need revisiting. The filter uses the Promotion code, which is more an identifier,
    // but just so happens to contain the PromotionCode code, so the filter works for now
    field: 'code',
    label: 'Discount code',
    flex: 1,
    formatter: (resource) => resource.promotionCodes?.length > 0 ? resource.promotionCodes[0].code : '',
  },
  {
    field: 'discounts',
    label: 'Discount',
    flex: 1,
    formatter: (resource) => {
      const { discounts } = resource;
      if (discounts.length > 0) {
        if (discounts[0].type === 'fixed' && discounts[0].value) {
          return `£${discounts[0].value / 100}`;
        }
        if (discounts[0].type === 'percentage' && discounts[0].value) {
          return `${discounts[0].value}%`;
        }
      }
      return '';
    }
  },
  {
    field: 'description',
    label: 'Description',
    sortable: false,
    flex: 2,
  },
  {
    field: 'createdAt',
    label: 'Created',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.createdAt, 'datetime'),
  },
  {
    field: '_usesTotal',
    label: 'Times used',
    flex: 1,
    formatter: (resource) => {
      let usesTotal = 0;
      resource.promotionCodes?.forEach(promotionCode => {
        usesTotal += promotionCode.uses;
      });
      return usesTotal;
    },
  },
  {
    field: '_status',
    label: 'Status',
    flex: 0.5,
    formatter: (resource) => {
      const { status } = resource;
      const color = status === 'active' ? 'success' : 'error';
      return <MDBadge color={color} badgeContent={status} />;
    },
  },
];

const filters = [
  {
    field: 'code',
    label: 'Discount code',
    type: 'text',
  },
  {
    field: 'description',
    label: 'Description',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'createdAt',
    direction: 'desc',
  },
];

const fixedFilterValues = {
  singleUse: true,
};

const SingleUseDiscounts = () => (
  <ListResources
    adminEndpoint={adminEndpoint}
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default SingleUseDiscounts;
