import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import moment from "moment";
import { toast } from 'react-hot-toast';

// WRM
import { requestApi } from 'api/request-api';
import { resourceApi } from "api/resource-api";
import CardHeader from 'components/shared/CardHeader';
import FormField from "components/shared/FormField";
import convertPriceToPounds from 'utils/convert-price-to-pounds';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Grid,
    Icon,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

// MDPR
import MDButton from "mdpr2/components/MDButton";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ConfirmModal from 'components/shared/ConfirmModal';
import LoadingSpinner from "../../../components/LoadingSpinner";

const useComponentStyles = makeStyles((theme) => ({
    totalTypeSelect: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: '-50px',
    },
    refreshBtn: {
        height: 'fit-content',
        alignSelf: 'center',
        marginRight: '1rem',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
}));

const StockAuditReport = () => {

    // eslint-disable-next-line no-unused-vars
    const [totalType, setTotalType] = useState('totalInWarehouse');
    const [filterBySku, setFilterBySku] = useState('');
    const [confirmRefresh, setConfirmRefresh] = useState(false);

    const [stockAuditTotalsReport, setStockAuditTotalsReport] = useState(null);
    const [stockAuditTotalsColumns, setStockAuditTotalsColumns] = useState([]);
    const [locations, setLocations] = useState([]);
    const [reportGenerating, setReportGenerating] = useState(false);

    const componentClasses = useComponentStyles();

    const prettyTotalType = () => {
        switch (totalType) {
            case 'totalInWarehouse':
                return "Total Stock";

            case 'totalReserved':
                return "Total reserved stock";

            case 'totalOnOrder':
                return "Total stock on order";

            default:
                return "Unknown";
        }
    };

    useEffect(() => {

        requestApi
            .getResponse({
                url: 'product-reports/stock-audit-totals-report'
            })
            .then(response => {
                if (response) setStockAuditTotalsReport(response);
            });

        resourceApi
            .getResources({
                apiEndpoint: 'locations'
            })
            .then(response => {
                if (response) {
                    setLocations(response.resources);
                }
            });
    }, []);

    useEffect(() => {
        const columns = [
            { field: 'productSku', headerName: 'SKU', flex: 1, filterable: true },
            { field: 'productName', headerName: 'Product name', flex: 2, filterable: true },
        ];

        const calculateTotalFromInventories = (inventories) => {
            let total = 0;
            inventories?.forEach((inventory) => { total += inventory[totalType]; });
            return total;
        };

        // eslint-disable-next-line no-shadow
        locations.forEach((location) => columns.push(
            {
                type: 'number',
                field: location.id.toString(), headerName: location.name, flex: 1,
                valueGetter: (params) => {
                    const inventoryAtLocation = params.row.inventories?.find((inventory) => inventory.locationId === location.id);
                    return inventoryAtLocation ? inventoryAtLocation[totalType] : 0;
                }
            }
        ));
        columns.push(
            {
                type: 'number',
                field: 'inventoryTotal', headerName: 'Total stock', flex: 1,
                valueGetter: (params) => calculateTotalFromInventories(params.row.inventories)
            }
        );
        columns.push(
            {
                type: 'number',
                field: 'totalValue', headerName: 'Total value (£)', flex: 1,
                valueGetter: (params) => {
                    if (params.row.productWarehouseValue === null) {
                        return null;
                    }
                    const totalWarehouseValue = calculateTotalFromInventories(params.row.inventories) * params.row.productWarehouseValue;
                    return convertPriceToPounds(totalWarehouseValue);
                }
            }
        );
        setStockAuditTotalsColumns(columns);

    }, [locations, totalType]);

    const handleRunReportOnClick = (formValues) => {
        const startDate = formValues.startDate ? formValues.startDate : '';
        const endDate = formValues.endDate ? formValues.endDate : '';
        const extraDetail = formValues.extraDetail ? formValues.extraDetail : false;

        let downloadEndpoint = `product-reports/download-stock-audit-totals-report?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`;
        downloadEndpoint += `&extraDetail=${extraDetail ? '1' : '0'}`;

        setReportGenerating(true);

        requestApi.getResponse({
            url: downloadEndpoint,
            rawResponse: true
        }).then((response) => {
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setReportGenerating(false);
        });
    }

    return (
        <>
            <Grid
                container
                mt={-2}
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <CardHeader color="info">
                            <Icon>view_list</Icon> Run Stock Report
                        </CardHeader>
                        <CardContent>
                            <Box mb={2} fontSize="medium">
                                Note: <b>Extra Detail</b> option will further breakdown daily stock adjustments by transfer, write-off,
                                dispatch etc.
                            </Box>
                            <Formik
                                initialValues={{
                                    startDate: moment().startOf('day'),
                                    endDate: null,
                                    extraDetail: false
                                }}
                                validationSchema={Yup.object().shape({
                                    startDate: Yup.date()
                                        .nullable(),
                                    endDate: Yup.date()
                                        .nullable()
                                        .min(Yup.ref('startDate'), 'End date must be later than start date'),
                                })}
                            >
                                {(formik) => (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <FormField
                                                type="date"
                                                name="startDate"
                                                label="Start date"
                                                formik={formik}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <FormField
                                                type="date"
                                                name="endDate"
                                                label="End date"
                                                formik={formik}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormField
                                                type="checkbox"
                                                name="extraDetail"
                                                label="Extra Detail"
                                                formik={formik}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <MDButton
                                                color="primary"
                                                disabled={reportGenerating || (Object.keys(formik.errors).length > 0)}
                                                onClick={() => handleRunReportOnClick(formik.values)}>
                                                Run report
                                            </MDButton>
                                        </Grid>
                                    </Grid>

                                )}
                            </Formik>
                            {reportGenerating && <LoadingSpinner />}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container mt={3} spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="info">
                            <Icon>local_shipping</Icon> Current Stock Levels - {prettyTotalType()}
                        </CardHeader>
                        <CardContent>
                            <Box>
                                <div className={componentClasses.totalTypeSelect}>
                                    <MDButton
                                        className={componentClasses.refreshBtn}
                                        variant="gradient"
                                        display="flex"
                                        color="info"
                                        onClick={() => setConfirmRefresh(true)}
                                    >Refresh Table</MDButton>
                                    <FormControl className={componentClasses.formControl}>
                                        <TextField
                                            label="Filter By SKU"
                                            id="filter-by-sku"
                                            value={filterBySku}
                                            onChange={(event) => setFilterBySku(event.target.value)}
                                            placeholder="Filter by SKU"
                                        />
                                    </FormControl>
                                    <FormControl className={componentClasses.formControl}>
                                        <InputLabel id="total-type-select-label">Total Type</InputLabel>
                                        <Select
                                            labelId="total-type-select-label"
                                            id="total-type-select"
                                            value={totalType}
                                            onChange={(event) => setTotalType(event.target.value)}
                                        >
                                            <MenuItem value="totalInWarehouse">Stock</MenuItem>
                                            <MenuItem value="totalReserved">Stock Reserved</MenuItem>
                                            <MenuItem value="totalOnOrder">Stock On Order</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Box>
                            <Box mt={2}>
                                {
                                    stockAuditTotalsReport && stockAuditTotalsColumns.length > 0 &&
                                    <DataGrid
                                        autoHeight
                                        columns={stockAuditTotalsColumns}
                                        disableSelectionOnClick
                                        rows={stockAuditTotalsReport.filter(
                                            (row) => filterBySku.trim() === '' || row.productSku.toLowerCase().indexOf(filterBySku.toLowerCase().trim()) !== -1)
                                        }
                                        getRowId={(row) => row.productSku}
                                        rowsPerPageOptions={[25, 50, 100]}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ConfirmModal
                open={confirmRefresh}
                body='Refreshing the stock audit table may take up to 10 minutes. Are you sure you wish to do this?'
                title='Refresh Stock Report'
                setOpen={(open) => setConfirmRefresh(open)}
                confirmCallback={() =>{
                    requestApi.putResponse({
                        url: `stock-audit/rebuild`, data: {}
                    });
                    setConfirmRefresh(false);
                    toast.success('The table is now refreshing and should be updated soon.');
                }}
            />
        </>
    );
};

export default StockAuditReport;
