// WRM
import EditOrder from '../../Orders/EditOrder';

const EditOrderWarehouse = () => (
  <EditOrder
    context="warehouse"
  />
);

export default EditOrderWarehouse;
