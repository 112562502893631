import { CircularProgress } from '@mui/material';

const LoadingSpinner = () => (
  <div
    style={{
      position: 'fixed',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: 9999,
    }}
  >
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
    </div>
  </div>
);

export default LoadingSpinner;
