/* eslint-disable */
import axios from './axios';

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  headers.Authorization = `Bearer ${process.env.REACT_APP_WONDE_API_TOKEN}`;
  return headers;
};

class WondeApi {
  async getResources({
    apiEndpoint,
    id,
    filterValues = {},
    sortValues = [],
    pagination = {}
  }) {

    return new Promise((resolve, reject) => {
      try {
        const parameters = [];
        Object.keys(filterValues).forEach((filterKey) => {
          // Handle multiple values (eg from a multi select)
          if (Array.isArray(filterValues[filterKey])) {
            const parameterArray = [];
            filterValues[filterKey].forEach((filterValue) => parameterArray.push(`${filterKey}[]=${encodeURIComponent(filterValue)}`));
            parameters.push(parameterArray.join('&'));
          } else {
            parameters.push(`${filterKey}=${encodeURIComponent(filterValues[filterKey])}`);
          }
        });
        sortValues.forEach((sortValue) => {
          parameters.push(`_order[${sortValue.field}]=${sortValue.direction}`);
        });
        if (pagination.page) {
          parameters.push(`_page=${pagination.page}`);
        }
        if (pagination.itemsPerPage) {
          parameters.push(`_itemsPerPage=${pagination.itemsPerPage}`);
        }

        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_WONDE_API_BASE_URL}/schools/${id}/${apiEndpoint}?${parameters.join('&')}`,
          headers: getHeaders(),
        };

        axios(axiosConfig)
          .then((response) => {
            const resources = response.data;
            resolve({ resources });
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}


// eslint-disable-next-line import/prefer-default-export
export const wondeApi = new WondeApi();
