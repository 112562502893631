// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Deep Dive reports';
const apiEndpoint = 'deep-dive-reports';
const resourceName = 'Deep Dive report';

const columns = [
  {
    field: 'name',
    label: 'Report name',
    sortable: true,
  },
];

const filters = [
  {
    field: 'name',
    label: 'Report name',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const DeepDiveReports = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default DeepDiveReports;
