import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Line, LineChart, XAxis, YAxis, Tooltip,CartesianGrid, ResponsiveContainer } from 'recharts';
import { ordinalSuffixOf } from 'utils/helpers';
import Styles from './MembershipRevenueWidget.module.scss';

const getFormattedGBPString = (number) => {
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    return formatter.format(number);
}

const xAxisFormatter = (value) => `${ordinalSuffixOf(moment(value).format('D'))  } ${  moment(value).format('MMM')}`

const yAxisFormatter = (value) => {
    if(value >= 1000) {
        return `£${(value / 1000).toFixed(1)}k`.replace('.0', '');
    }
    return `£${value}`;
}

const tooltipLabelFormatter = (key) => {
    const day = moment(key);

    return `${ ordinalSuffixOf(day.format('D')) } ${ day.format('MMMM') }`;
}

const tooltipValueFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    return formatter.format(value);
}

const getAxisTick = (extend = {}) => ({ fontSize: 12, fontWeight: 500, ...extend })

const MembershipRevenueWidget = (props) => {
    const {
        label,
        data: { selectedPeriod = [], lastYearPeriod = [] },
        startDate: startDateProp,
        endDate: endDateProp
    } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setStartDate(startDateProp);
        setEndDate(endDateProp);
    }, []);

    if(!startDate || !endDate) {
        return null;
    }

    if(!Object.keys(selectedPeriod).length || !Object.keys(lastYearPeriod).length) {
        return null;
    }

    const selectedYear = moment(selectedPeriod[0].date).format('YYYY');
    const lastYear = moment(lastYearPeriod[0].date).format('YYYY');

    const chartData = selectedPeriod.map((selectedPeriodDay, index) => {
        const lastYearPeriodDay = lastYearPeriod[index];

        if(!lastYearPeriodDay) {
            return {
                name: selectedPeriodDay.date,

                [selectedYear]: Number.parseFloat(selectedPeriodDay.dateTotal),
                [lastYear]: '0'
            }
        }

        return {
            name: selectedPeriodDay.date,

            [selectedYear]: Number.parseFloat(selectedPeriodDay.dateTotal),
            [lastYear]: Number.parseFloat(lastYearPeriodDay.dateTotal)
        }
    });

    const sumSelectedPeriod = selectedPeriod.reduce((sum, day) => Number.parseFloat(sum) + Number.parseFloat(day.dateTotal), 0);
    const sumLastYearPeriod = lastYearPeriod.reduce((sum, day) => Number.parseFloat(sum) + Number.parseFloat(day.dateTotal), 0);

    const getSelectedPeriodTitleString = () => {
        if(startDate && endDate) {
            const start = moment(startDate);
            const end = moment(endDate);

            const isSameMonth = start.format('MMMM') === end.format('MMMM');
            const isSameYear = start.format('YYYY') === end.format('YYYY');

            if(isSameMonth && isSameYear) {
                return `${ ordinalSuffixOf(start.format('D')) } - ${ ordinalSuffixOf(end.format('D')) } ${ start.format('MMMM') } ${ start.format('YYYY') }`;
            } 
            
            return `${ ordinalSuffixOf(start.format('D')) } ${ start.format('MMMM') }${ isSameYear ? '' : ` ${ start.format('YYYY') }` } - ${ ordinalSuffixOf(end.format('D')) } ${ end.format('MMMM') } ${ end.format('YYYY') }`;
        }

        return '';
    }

    const getLabelString = () => label
            .replace('School (Team)', '')
            .replace('(Team)', '')
            .replace('Membership Resources – ', '')
            .replace('Course –', '')
        .trim()

    const percentageChange = Math.round((((sumSelectedPeriod - sumLastYearPeriod) / sumLastYearPeriod) * 100));
    const selectedPeriodColor = '#0976b4';
    const lastYearPeriodColor = '#fb8c00';

    return (
        <div className={Styles.widget}>
            <h1 className={Styles.widgetTitle}>{ getLabelString() } <br/><span>{ getSelectedPeriodTitleString() }</span></h1>

            <div className={Styles.widgetChart}>
                <ResponsiveContainer width='100%' height={200}>

                    <LineChart data={chartData}>
                        <CartesianGrid stroke='#eeeeee' strokeDasharray='5 5' />
                        <XAxis
                            tick={getAxisTick()}
                            tickFormatter={xAxisFormatter}
                            dataKey='name'
                            minTickGap={45}
                        />
                        <YAxis tick={getAxisTick()} tickFormatter={yAxisFormatter} />
                        <Tooltip labelFormatter={tooltipLabelFormatter} formatter={tooltipValueFormatter} />

                        <Line dataKey={selectedYear} stroke={selectedPeriodColor} dot={false}/>
                        <Line dataKey={lastYear} stroke={lastYearPeriodColor} dot={false}/>
                    </LineChart>
                
                </ResponsiveContainer>
            </div>

            <div className={Styles.widgetTotals}>
                <p>
                    <span style={{ color: selectedPeriodColor }}>{ selectedYear }</span>
                    <span>
                        { percentageChange ?
                            <em className={`${ Styles.percentageChange } ${ percentageChange < 0 ? Styles.negative : Styles.positive }`}>
                                { `${Number.isFinite(Number.parseInt(percentageChange, 10)) ? Number.parseInt(percentageChange, 10) : 100}%` }
                            </em>
                        : null }
                        { getFormattedGBPString(sumSelectedPeriod) }
                    </span>
                </p>
                <p>
                    <span style={{ color: lastYearPeriodColor }}>{ lastYear }</span>
                    <span>{ getFormattedGBPString(sumLastYearPeriod) }</span>
                </p>
            </div>
        </div>
    );
}

MembershipRevenueWidget.propTypes = {
    label: PropTypes.string,
    startDate: PropTypes.instanceOf(moment).isRequired,
    endDate: PropTypes.instanceOf(moment).isRequired,
    data: PropTypes.shape({
        /* eslint-disable */
        selectedPeriod: PropTypes.array.isRequired,
        lastYearPeriod: PropTypes.array.isRequired
        /* eslint-enable */
    }).isRequired
}

MembershipRevenueWidget.defaultProps = {
    label: ''
}

export default MembershipRevenueWidget;