import { getIn, setIn } from 'formik';
import moment from 'moment';

export const toFormValues = (resource, fields) => {
  let formValues = {};

  fields.forEach((field) => {
    const fieldValue = getIn(resource, field.name);
    let isFieldMapped = false;

    // Map checkbox fields (default false)
    if (field.type === 'checkbox') {
      formValues = setIn(formValues, field.name, (typeof fieldValue !== 'undefined' && fieldValue !== null) ? fieldValue : false);
      isFieldMapped = true;
    }

    // Map currency fields from pence (number) to pounds (string)
    if (field.type === 'currency') {
      formValues = setIn(formValues, field.name, (typeof fieldValue !== 'undefined' && fieldValue !== null) ? String((fieldValue / 100).toFixed(2)) : '');
      isFieldMapped = true;
    }

    // Map percentage into null if unset
    if (field.type === 'percentage') {
      formValues = setIn(formValues, field.name, (typeof fieldValue !== 'undefined' && fieldValue !== null && fieldValue !== '') ? Math.round(fieldValue) : null);
      isFieldMapped = true;
    }

    // Map date fields from string to a moment
    if (field.type === 'date') {
      if (fieldValue) {
        const fieldMoment = moment(fieldValue);
        formValues = setIn(formValues, field.name, (fieldMoment || null));
      } else {
        formValues = setIn(formValues, field.name, null);
      }
      isFieldMapped = true;
    }

    // Map datetime fields from string to a moment
    if (field.type === 'datetime') {
      if (fieldValue) {
        const fieldMoment = moment(fieldValue);
        formValues = setIn(formValues, field.name, (fieldMoment || null));
      } else {
        formValues = setIn(formValues, field.name, null);
      }
      isFieldMapped = true;
    }

    // Map decimal fields from numbers to strings
    if (field.type === 'decimal') {
      formValues = setIn(formValues, field.name, (typeof fieldValue !== 'undefined' && fieldValue !== null) ? String(fieldValue) : '');
      isFieldMapped = true;
    }

    // Map fieldCollection fields
    if (field.type === 'fieldCollection' && fieldValue) {
      const fieldFormValues = [];
      fieldValue.forEach((value) => {
        const collectionFormValues = toFormValues(value, field.childFields);
        collectionFormValues['@id'] = value['@id'];
        fieldFormValues.push(collectionFormValues);
      });
      formValues = setIn(formValues, field.name, fieldFormValues);
      isFieldMapped = true;
    }

    // Map fieldGroup fields
    if (field.type === 'fieldGroup') {
      if (field.childFields) {
        const fieldGroupFormValues = toFormValues(resource, field.childFields);
        const fieldFormValues = {};
        Object.keys(fieldGroupFormValues).forEach((formAttribute) => {
          fieldFormValues[formAttribute] = fieldGroupFormValues[formAttribute];
        });
        formValues = setIn(formValues, field.name, fieldFormValues);
      }
      isFieldMapped = true;
    }

    // Map imagePicker fields
    if (field.type === 'imagePicker') {
      // Map the base field
      formValues = setIn(formValues, field.name, fieldValue || '');
      // Map the altText field
      formValues = setIn(formValues, `${field.name}AltText`,  getIn(resource, `${field.name}AltText`) || '');
      isFieldMapped = true;
    }

    // Map number fields from numbers to strings
    if (field.type === 'number') {
      formValues = setIn(formValues, field.name, (typeof fieldValue !== 'undefined' && fieldValue !== null) ? String(fieldValue) : '');
      isFieldMapped = true;
    }

    // Map select fields, using the @id attribute where the value is an entity
    if (field.type === 'select' || field.type === 'selectAsTable') {
      // Process a multiple
      if (Array.isArray(fieldValue)) {
        const fieldFormValues = [];
        fieldValue.forEach((value) => {
          if (typeof value === 'object') {
            fieldFormValues.push(value['@id'] ? value['@id'] : '');
          } else {
            fieldFormValues.push(value);
          }
        });
        formValues = setIn(formValues, field.name, fieldFormValues);
      } else if (typeof fieldValue === 'object') {
        formValues = setIn(formValues, field.name, (fieldValue && fieldValue['@id']) ? fieldValue['@id'] : 'none');
      } else {
        formValues = setIn(formValues, field.name, (fieldValue || 'none'));
      }
      isFieldMapped = true;
    }

    // Map typeahead field
    if (field.type === 'typeahead') {
      formValues = setIn(formValues, field.name, field.formValue(resource));
      isFieldMapped = true;
    }

    // Map tabs fields
    if (field.type === 'tabs') {
      field.childFields?.forEach((childField) => {
        const tabFormValues = toFormValues(resource, childField.childFields);
        Object.keys(tabFormValues).forEach((tabFormValueKey) => {
          formValues = setIn(formValues, tabFormValueKey, tabFormValues[tabFormValueKey]);
        });
      });
      isFieldMapped = true;
    }

    // Map JSON fields from an object to a string
    if (field.json) {
      try {
        formValues = setIn(formValues, field.name, JSON.stringify(fieldValue, null, 2));
      } catch (e) {
        formValues = setIn(formValues, field.name, '');
      }
      isFieldMapped = true;
    }

    // Default mapping
    if (!isFieldMapped) {
      formValues = setIn(formValues, field.name, fieldValue || '');
    }
  });

  return formValues;
};

export const toApiValues = (formValues, fields) => {
  let apiValues = {};

  fields.forEach((field) => {
    const formValue = getIn(formValues, field.name);
    let isFieldMapped = false;

    // Map checkbox fields - no mapping required for boolean
    if (field.type === 'checkbox') {
      apiValues = setIn(apiValues, field.name, formValue);
      isFieldMapped = true;
    }

    // Map currency fields from pounds (string) to pence (number)
    if (field.type === 'currency') {
      apiValues = setIn(apiValues, field.name, formValue !== '' ? Math.round(formValue * 100) : null);
      isFieldMapped = true;
    }

    // Map percentage into null if unset
    if (field.type === 'percentage') {
      apiValues = setIn(apiValues, field.name, (typeof formValue !== 'undefined' && formValue !== null && formValue !== '') ? Math.round(formValue) : null);
      isFieldMapped = true;
    }

    // Map date fields from a moment to UTC string (axios doesn't handle other timezones well)
    if (field.type === 'date') {
      if (formValue) {
        apiValues = setIn(apiValues, field.name, formValue.utc().format());
      } else {
        apiValues = setIn(apiValues, field.name, null);
      }
      isFieldMapped = true;
    }

    // Map datetime fields from a moment to UTC string (axios doesn't handle other timezones well)
    if (field.type === 'datetime') {
      if (formValue) {
        apiValues = setIn(apiValues, field.name, formValue.utc().format());
      } else {
        apiValues = setIn(apiValues, field.name, null);
      }
      isFieldMapped = true;
    }

    // Map decimal fields from string to string, but empty string is null
    if (field.type === 'decimal') {
      apiValues = setIn(apiValues, field.name, formValue !== '' ? formValue : null);
      isFieldMapped = true;
    }

    // Map fieldCollection fields
    if (field.type === 'fieldCollection') {
      const fieldApiValues = [];
      let fieldCollectionValues = formValues[field.name];
      if (!Array.isArray(fieldCollectionValues)) {
        fieldCollectionValues = [];
      }
      fieldCollectionValues.forEach((fieldCollectionValue) => {
        const collectionApiValues = toApiValues(fieldCollectionValue, field.childFields);
        collectionApiValues['@id'] = fieldCollectionValue['@id'];
        fieldApiValues.push(collectionApiValues);
      });
      apiValues = setIn(apiValues, field.name, fieldApiValues);
      isFieldMapped = true;
    }

    // Map fieldGroup fields
    if (field.type === 'fieldGroup') {
      if (field.childFields) {
        const fieldGroupApiValues = toApiValues(formValue, field.childFields);
        Object.keys(fieldGroupApiValues).forEach((apiAttribute) => {
          apiValues = setIn(apiValues, apiAttribute, fieldGroupApiValues[apiAttribute]);
        });
      }
      isFieldMapped = true;
    }

    // Map imagePicker fields
    if (field.type === 'imagePicker') {
      // Map the base field
      apiValues = setIn(apiValues, field.name, (typeof formValue === 'string' || formValue instanceof String) ? formValue.trim() : formValue);
      // Map the altText field
      const formValueAltText = getIn(formValues, `${field.name}AltText`);
      apiValues = setIn(apiValues, `${field.name}AltText`, (typeof formValueAltText === 'string' || formValueAltText instanceof String) ? formValueAltText.trim() : formValueAltText);
      isFieldMapped = true;
    }

    // Map number fields from string to number
    if (field.type === 'number') {
      apiValues = setIn(apiValues, field.name, formValue !== '' ? Number(formValue) : null);
      isFieldMapped = true;
    }

    // Map select fields
    if (field.type === 'select' || field.type === 'selectAsTable') {
      apiValues = setIn(apiValues, field.name, (formValue && formValue !== 'none') ? formValue : null);
      isFieldMapped = true;
    }

    // Map typeahead
    if (field.type === 'typeahead' ) {
      apiValues = setIn(apiValues, field.name, formValue?.id ?? null);
      isFieldMapped = true;
    }

    // Map tabs fields
    if (field.type === 'tabs') {
      field.childFields?.forEach((childField) => {
        const tabApiValues = toApiValues(formValues, childField.childFields);
        Object.keys(tabApiValues).forEach((apiAttribute) => {
          apiValues = setIn(apiValues, apiAttribute, tabApiValues[apiAttribute]);
        });
      });
      isFieldMapped = true;
    }

    // Map JSON fields from an object to a string
    if (field.json) {
      try {
        const object = JSON.parse(formValue);
        apiValues = setIn(apiValues, field.name, object);
      } catch (e) {
        apiValues = setIn(apiValues, field.name, null);
      }
      isFieldMapped = true;
    }

    // Default mapping
    if (!isFieldMapped) {
      apiValues = setIn(apiValues, field.name, (typeof formValue === 'string' || formValue instanceof String) ? formValue.trim() : formValue);
    }
  });

  return apiValues;
};
