// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Page block types';
const apiEndpoint = 'page-block-types';
const resourceName = 'page block type';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    field: 'code',
    label: 'Code',
    sortable: true,
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'code',
    label: 'Code',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const PageBlockTypes = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default PageBlockTypes;
