// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

// MDPR
import MDTypography from 'mdpr2/components/MDTypography';

const title = 'Sage order exports';
const apiEndpoint = 'sage-order-exports';
const resourceName = 'Sage order export';

const columns = [
  {
    field: 'order.reference',
    label: 'Order #',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.order.reference,
  },
  {
    field: 'order.createdAt',
    label: 'Order date',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.order.createdAt),
  },
  {
    field: '_billingAddress',
    label: 'Billing address',
    flex: 1,
    formatter: (resource) => {
        const { billingAddress } = resource.order;
        const addressParts = [];
        if (billingAddress.company) addressParts.push(billingAddress.company);
        if (billingAddress.townCity) addressParts.push(billingAddress.townCity);
        if (billingAddress.postcode) addressParts.push(billingAddress.postcode);
        return addressParts.join(', ');
    },
  },
  {
    field: '_billingEmail',
    label: 'Billing email',
    flex: 1,
    formatter: (resource) => resource.order.billingAddress.email,
  },
  {
    field: 'status',
    label: 'Status',
    flex: 1,
    formatter: (resource) => {
      const { hasMissingNominalOrDepartmentCodes, status } = resource;
      return (
        <>
          {status}
          {hasMissingNominalOrDepartmentCodes && (
            <MDTypography variant="inherit" fontWeight="bold" color="error">Missing product nominal/department codes</MDTypography>
          )}
        </>
      );
    },
  },
  {
    field: 'customerNote',
    label: 'Customer note',
    flex: 1,
    formatter: (resource) => resource.order.customerNote?.substring(0, 50),
  },
  {
    field: 'createdAt',
    label: 'Export created',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.createdAt),
  },
];

const filters = [
  {
    field: 'order.reference',
    label: 'Order #',
    type: 'text',
  },
  {
    field: 'isExportableToSage',
    label: 'Exportable?',
    type: 'select',
    choices: [
        {value: '', label: 'Yes or no'},
        {value: 'true', label: 'Yes'},
        {value: 'false', label: 'No'},
    ],
  },
  {
    field: 'isExportedToSage',
    label: 'Exported?',
    type: 'select',
    choices: [
        {value: '', label: 'Yes or no'},
        {value: 'true', label: 'Yes'},
        {value: 'false', label: 'No'},
    ],
  },
  {
    field: 'isDeleted',
    label: 'Show deleted',
    type: 'checkbox',
  },
];

const defaultFilterValues = {
  isDeleted: false,
};

const defaultSortValues = [
  {
    field: 'order.reference',
    direction: 'asc',
  },
];

const SageOrderExports = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default SageOrderExports;
