// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Video courses';
const apiEndpoint = 'video-courses';
const resourceName = 'video course';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'createdAt',
    label: 'Created',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.createdAt),
  },
  {
    field: 'displayOrder',
    label: 'Display order',
    sortable: true,
    flex: 1,
  },
  {
    field: 'subjects',
    label: 'Subjects',
    flex: 1,
    formatter: (course) => course.subjects.join(", ")
  },
  {
    field: '_status',
    label: 'Status',
    sortable: false,
    flex: 0.5,
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: (resource) => {
      const { isArchived } = resource;
      const color = isArchived ? 'warning' : 'success';
      return <MDBadge color={color} badgeContent={isArchived ? 'Archived' : 'Active'} />;
    },
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const VideoCourses = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default VideoCourses;