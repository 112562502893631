/* eslint-disable no-shadow, react/prop-types, react/destructuring-assignment, no-restricted-syntax, prefer-destructuring */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

// Material UI
import {Card, CardContent, Icon, Checkbox, Grid, TextField, Select, MenuItem} from "@mui/material";

// MDPR
import MDButton from "mdpr2/components/MDButton";
import { DatePicker } from '@mui/x-date-pickers';

// Charts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

// WRM
import MembershipCountWidget from 'components/shared/MembershipWidget/MembershipCountWidget';
import MembershipCountWidgetStyles from 'components/shared/MembershipWidget/MembershipCountWidget.module.scss';
import LoadingSpinner from "components/LoadingSpinner";
import { getRequestQuery } from 'api/apiRequest';
import {ordinalSuffixOf} from "utils/helpers";
import CardHeader from "components/shared/CardHeader";
import componentStyles from './Analytics.module.scss';
import MDBox from "../../../mdpr2/components/MDBox";


// https://mokole.com/palette.html
const seriesColours = [
    "#2f4f4f", "#556b2f", "#a0522d", "#800000", "#191970", "#006400", "#808000", "#778899", "#3cb371", "#bc8f8f", "#008b8b", "#9acd32", "#00008b", "#daa520", "#7f007f", "#8fbc8f", "#b03060", "#ff0000", "#ff8c00", "#ffff00", "#0000cd", "#40e0d0", "#00ff00", "#9400d3", "#00ff7f", "#dc143c", "#00bfff", "#f4a460", "#9370db", "#adff2f", "#ff00ff", "#f0e68c", "#fa8072", "#6495ed", "#dda0dd", "#afeeee", "#ee82ee", "#98fb98", "#ff69b4", "#ffe4c4", 
];

const groupedStatsKeys = [
    "Grand Total",
    "Infant Total",
    "Infant Science Total",
    "Infant Maths Total",
    "Primary Total",
    "Primary Science Total",
    "Primary Maths Total",
    "Secondary Total",
    "Secondary Science Total",
    "Secondary Maths Total",
    "Individual Total",
    "Individual Science Total",
    "Individual Maths Total",
    "On Demand",
];

function GraphFilterOptions(props) {
    const graphSeriesElement = props.graphSeriesElement;
    const isHidden = props.graphSeriesHidden.includes(graphSeriesElement);
    return (
        <div className={componentStyles['graph-legend-item']}>
            <span>
                <Checkbox
                    id={graphSeriesElement}
                    style={{ display: 'none' }}
                    onChange={() => { props.onToggleGraphSeriesElement(graphSeriesElement) }}
                />
                <label
                    htmlFor={graphSeriesElement}
                    style={{ color: seriesColours[props.graphSeries.indexOf(graphSeriesElement)] }}
                >{
                    isHidden ? <strike>{ graphSeriesElement }</strike> : graphSeriesElement
                }</label>
            </span>
        </div>
    )
}

function GraphFilters(props) {
    const standaloneStats = props.graphSeries.filter(
        graphSeriesElement => !groupedStatsKeys.includes(graphSeriesElement)
    );
    const standaloneStats1 = standaloneStats.splice(0, Math.ceil(standaloneStats.length / 2));
    const standaloneStats2 = standaloneStats;
    return <MDBox>
        <div className={componentStyles['graph-legend']}>
        <h3>Filters</h3>
        <p><em>Please note, filtering by Learning Group or Order Type is only available post C2 go live (29th of June 2023). Using those filters will hide data pre the launch.</em></p>
        <Grid container spacing={4} style={{marginTop: '-5px', marginBottom: '30px'}}>
            <Grid item xs={3}>
                <DatePicker
                    closeOnSelect={false}
                    inputFormat="dd/MM/yyyy"
                    label="Start"
                    onChange={(newStart) => props.setFilters({...props.filters, start: newStart.toISOString().split('T')[0]})}
                    renderInput={(inputProps) => (
                        <TextField
                            fullWidth
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...inputProps}
                        />
                    )}
                    value={props.filters.start}
                    componentsProps={{
                        actionBar: {
                            actions: ['c2', 'today', 'accept', 'cancel']
                        }
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <DatePicker
                    closeOnSelect={false}
                    inputFormat="dd/MM/yyyy"
                    label="End"
                    onChange={(newEnd) => props.setFilters({...props.filters, end: newEnd})}
                    renderInput={(inputProps) => (
                        <TextField
                            fullWidth
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...inputProps}
                        />
                    )}
                    value={props.filters.end}
                    componentsProps={{
                        actionBar: {
                            actions: ['today', 'accept', 'cancel']
                        }
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Select
                    fullWidth
                    name="Learning Group Type"
                    onChange={(e) => props.setFilters({...props.filters, learningGroupType: e.target.value})}
                    value={props.filters.learningGroupType}
                >
                    <MenuItem value="All">All Learning Groups</MenuItem>
                    <MenuItem value="school">School Learning Groups</MenuItem>
                    <MenuItem value="custom">Custom Learning Groups</MenuItem>
                    <MenuItem value="individual">Individual Learning Groups</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3}>
                <Select
                    fullWidth
                    name="International/Domestic Orders"
                    onChange={(e) => props.setFilters({...props.filters, orderType: e.target.value})}
                    value={props.filters.orderType}
                >
                    <MenuItem value="All">All Orders</MenuItem>
                    <MenuItem value="domestic">Domestic Orders</MenuItem>
                    <MenuItem value="international">International Orders</MenuItem>
                </Select>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <h3>Grouped Stats <MDButton
                style={{marginLeft: '20px'}}
                color="info"
                size="small"
                variant="gradient"
                onClick={(e) => {
                    e.preventDefault()
                    props.setGraphSeriesHidden(props.graphSeries.filter(
                        graphSeriesElement => !groupedStatsKeys.includes(graphSeriesElement)
                    ))
                }}>
                <Icon>timeline</Icon>&nbsp;select all
            </MDButton></h3>
                { groupedStatsKeys.map(graphSeriesElement => <GraphFilterOptions
                    key={graphSeriesElement}
                    graphSeries={props.graphSeries}
                    graphSeriesElement={graphSeriesElement}
                    graphSeriesHidden={props.graphSeriesHidden}
                    onToggleGraphSeriesElement={props.onToggleGraphSeriesElement}
                />) }
            </Grid>
            <Grid item xs={8}>
                <MDButton
                    style={{float: 'right'}}
                    color="info"
                    size="small"
                    variant="gradient"
                    onClick={props.onToggleAll}>
                    <Icon>timeline</Icon>&nbsp;toggle all
                </MDButton>
                <h3>Standalone Stats <MDButton
                style={{marginLeft: '20px'}}
                color="info"
                size="small"
                variant="gradient"
                onClick={(e) => {
                    e.preventDefault()
                    props.setGraphSeriesHidden(props.graphSeries.filter(
                        graphSeriesElement => groupedStatsKeys.includes(graphSeriesElement)
                    ))
                }}>
                <Icon>timeline</Icon>&nbsp;select all
            </MDButton></h3>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        { standaloneStats1.map(graphSeriesElement => <GraphFilterOptions
                            key={graphSeriesElement}
                            graphSeries={props.graphSeries}
                            graphSeriesElement={graphSeriesElement}
                            graphSeriesHidden={props.graphSeriesHidden}
                            onToggleGraphSeriesElement={props.onToggleGraphSeriesElement}
                        />) }
                    </Grid>
                    <Grid item xs={6}>
                        { standaloneStats2.map(graphSeriesElement => <GraphFilterOptions
                            key={graphSeriesElement}
                            graphSeries={props.graphSeries}
                            graphSeriesElement={graphSeriesElement}
                            graphSeriesHidden={props.graphSeriesHidden}
                            onToggleGraphSeriesElement={props.onToggleGraphSeriesElement}
                        />) }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </div>
    </MDBox>
}

function enhanceAnalytics (membershipAnalytics) {
    const dayKeys = Object.keys(membershipAnalytics.day);
    const lastYearKeys = Object.keys(membershipAnalytics.lastYear);

    const day = dayKeys.reduce((accumulator, key) => {
        const data = membershipAnalytics.day[key];

        if(key.indexOf('Membership Resources – Year') >= 0) {
            return { ...accumulator,
                'Individuals': {
                    dayTotal: (accumulator?.Individuals?.dayTotal ?? 0)
                        + parseInt(data.dayTotal, 10)
                }
            }
        }

        return { ...accumulator, [key]: data }
    }, {});

    const lastYear = lastYearKeys.reduce((accumulator, key) => {
        const data = membershipAnalytics.lastYear[key];

        if(key.indexOf('Membership Resources – Year') >= 0) {
            return { ...accumulator,
                'Individuals': {
                    dayTotal: (accumulator?.Individuals?.dayTotal ?? 0)
                        + parseInt(data.dayTotal, 10)
                }
            }
        }

        return { ...accumulator, [key]: data }
    }, {});

    return { ...membershipAnalytics, day, lastYear };
}

function hydrateDynamicFields (data) {
    const result = {
        ...data,
        'Grand Total': 0,
    };

    for (const key of Object.keys(data)) {
        const value = Number(data[key]);
        result['Grand Total']+= value;
        // Individuals
        if (key.includes('Membership Resources – Year ')) {
            result['Individual Total'] = (result['Individual Total'] ?? 0) + value;
            if (key.includes('Science')) {
                result['Individual Science Total'] = (result['Individual Science Total'] ?? 0) + value;
            } else {
                result['Individual Maths Total'] = (result['Individual Maths Total'] ?? 0) + value;
            }
        }
        // Infant
        if (key.includes('Membership Resources – Infant School')) {
            result['Infant Total'] = (result['Infant Total'] ?? 0) + 
                Number(data[key]);
            if (key.includes('Science')) {
                result['Infant Science Total'] = (result['Infant Science Total'] ?? 0) + value;
            } else {
                result['Infant Maths Total'] = (result['Infant Maths Total'] ?? 0) + value;
            }
        }
        // Primary
        if (key.includes('Membership Resources – Primary School')) {
            result['Primary Total'] = (result['Primary Total'] ?? 0) + 
                Number(data[key]);
            if (key.includes('Science')) {
                result['Primary Science Total'] = (result['Primary Science Total'] ?? 0) + value;
            } else {
                result['Primary Maths Total'] = (result['Primary Maths Total'] ?? 0) + value;
            }
        }
        // Secondary
        if (key.includes('Membership Resources – Secondary School')) {
            result['Secondary Total'] = (result['Secondary Total'] ?? 0) + 
                Number(data[key]);
            if (key.includes('Science')) {
                result['Secondary Science Total'] = (result['Secondary Science Total'] ?? 0) + value;
            } else {
                result['Secondary Maths Total'] = (result['Secondary Maths Total'] ?? 0) + value;
            }
        }
        // Rename one.
        if (key === 'Course – All Courses (Team)') {
            result['On Demand'] = result['Course – All Courses (Team)'];
            delete result['Course – All Courses (Team)'];
        }
    }
    
    return result;
}

const xAxisFormatterShort = (time) => {
    const day = moment(time);

    return `${day.format('D')} ${day.format('MMM')} ${day.format('YY')}`;
}

const xAxisFormatterLong = (time) => {
    const day = moment(time);

    return `${day.format('MMM')} ${day.format('YY')}`;
}

const tooltipLabelFormatter = (key) => {
    const day = moment(key);

    return `${ ordinalSuffixOf(day.format('D')) } ${ day.format('MMMM') } ${ day.format('YYYY') }`;
}

const Analytics = () => {
    const [analyticsLoading, setAnalyticsLoading] = useState(false);
    const [membershipAnalytics, setMembershipAnalytics] = useState(null);

    const [graphData, setGraphData] = useState(null);
    const [graphSeries, setGraphSeries] = useState(null);
    const [graphSeriesHidden, setGraphSeriesHidden] = useState([]);
    const [filters, setFilters] = useState({
        start: '2019-07-18',
        end: (new Date()).toISOString().split('T')[0],
        orderType: 'All',
        learningGroupType: 'All',
    });

    // sort function that tries to find numeric values in the
    // given a and b strings and sorting in ascending order
    const sortSeriesLabels = (a, b) => {
        const aNumbers = a.match(/\d+/gi);
        const bNumbers = b.match(/\d+/gi);

        if(aNumbers && aNumbers.length && bNumbers && bNumbers.length) {
            const aNumber = parseFloat(aNumbers[aNumbers.length - 1]);
            const bNumber = parseFloat(bNumbers[bNumbers.length - 1]);

            return aNumber < bNumber ? -1 : 1;
        }

        return -1;
    }

    useEffect(() => {
        if(Array.isArray(graphSeries)) {
            setGraphSeries(graphSeries.sort().sort(sortSeriesLabels));
        }
    }, [graphSeries]);

    useEffect(() => {
        const date = moment();
        const hours = date.get('hours');

        if(hours <= 14) {
            if(hours === 14) {
                if(date.get('minutes') >= 30) {
                    date.subtract(1, 'day');
                }
            } else {
                date.subtract(1, 'day');
            }
        }

        setAnalyticsLoading(true)

        const statisticsEndpoint = `analytics/memberships-counts-by-date-and-plan`;
        const statisticsRequestQuery = getRequestQuery(statisticsEndpoint);

        const membershipEndpoint = `analytics/memberships?date=${date ? date.format('YYYY-MM-DD') : ''}`;
        const membershipRequestQuery = getRequestQuery(membershipEndpoint);

        Promise.all([
            axios(statisticsRequestQuery),
            axios(membershipRequestQuery)
        ])

            .then((responses) => {
                const [statisticsResponse, membershipsResponse] = responses;

                if(!statisticsResponse
                    || statisticsResponse.status !== 200
                    || !statisticsResponse.data
                ) {
                    return;
                }

                const converted = {};
                const graphSeries = {};

                Object.keys(statisticsResponse.data).forEach(
                    orderType => Object.keys(statisticsResponse.data[orderType]
                ).forEach(learningGroupType => Object.keys(statisticsResponse.data[orderType][learningGroupType]).forEach(date => {
                    const statistic = hydrateDynamicFields(statisticsResponse.data[orderType][learningGroupType][date]);
                    const statisticObject = {
                        name: date
                    };
                    Object.keys(statistic).forEach(membershipPlanName => {
                        statisticObject[membershipPlanName] = parseInt(statistic[membershipPlanName], 10);
                        graphSeries[membershipPlanName] = true;
                    });
                    if (!converted[orderType]) {
                        converted[orderType] = {};
                    }
                    if (!converted[orderType][learningGroupType]) {
                        converted[orderType][learningGroupType] = [];
                    }
                    converted[orderType][learningGroupType].push(statisticObject);
                })));

                setGraphData(converted);
                setGraphSeries(Object.keys(graphSeries));
                setGraphSeriesHidden(Object.keys(graphSeries).filter(key => !groupedStatsKeys.includes(key)));

                if(membershipsResponse && membershipsResponse.status === 200) {
                    setMembershipAnalytics(enhanceAnalytics(membershipsResponse.data));
                }
            })

            .finally(() => {
                setAnalyticsLoading(false);
            });
    }, []);

    const onToggleGraphSeriesElement = (graphSeriesElement) => {
        if (graphSeriesHidden.indexOf(graphSeriesElement) >= 0) {
            setGraphSeriesHidden(graphSeriesHidden.filter(element => element !== graphSeriesElement));
        } else {
            setGraphSeriesHidden([...graphSeriesHidden, graphSeriesElement]);
        }
    }

    const onToggleAll = () => {
        setGraphSeriesHidden(
            graphSeriesHidden.length !== graphSeries.length ? graphSeries : []
        );
    }

    if(analyticsLoading) {
        return <LoadingSpinner/>;
    }

    return (
        <Grid container sx={{'font-size': '1em'}}>
            <>
                <div className={MembershipCountWidgetStyles.widgets}>
                    { (membershipAnalytics && Object.keys(membershipAnalytics).length) &&
                    Object.keys(membershipAnalytics.day).length ? Object.keys(membershipAnalytics.day).map((key) => {
                            const day = membershipAnalytics.day[key];
                            const lastYear = membershipAnalytics.lastYear[key];
                            const change = day && lastYear ? day.dayTotal - lastYear.dayTotal : null;

                            return (
                                <MembershipCountWidget
                                    key={key}
                                    label={key.replace('Membership Resources – ', '')}
                                    dayTotal={day ? day.dayTotal : undefined}
                                    lastYearTotal={lastYear ? lastYear.dayTotal : undefined}
                                    change={change} />
                            )
                        }) :

                        <p className={MembershipCountWidgetStyles.error}>
                            Membership count analytics could not be presented due to insufficient snapshot data for { moment().format('DD/MM/YYYY') }.
                        </p> }
                </div>
                {
                    graphData && graphSeries &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info">
                                Membership Totals
                            </CardHeader>
                            <CardContent>
                                <div className={componentStyles['graph-container']}>
                                    <ResponsiveContainer width="100%" height={600}>
                                        <LineChart
                                            data={(graphData[filters.orderType][filters.learningGroupType] ?? []).filter((data) => {
                                                if (data.name < filters.start) {
                                                    return false;
                                                }
                                                if (data.name > filters.end) {
                                                    return false;
                                                }
                                                
                                                return true;
                                            })}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 20,
                                            }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="name"
                                                label={{ value: "Time", position: "bottom" }}
                                                tickFormatter={
                                                    new Date(filters.end).getTime() - new Date(filters.start).getTime()
                                                        < 35 * 24 * 60 * 60 * 1000 // 35 days
                                                        ? xAxisFormatterShort
                                                        : xAxisFormatterLong
                                                }
                                                minTickGap={45}
                                                style={{
                                                    fontSize: '1rem'
                                                }}
                                            />
                                            <YAxis
                                                domain={[0, "dataMax"]}
                                                label={{ value: 'Volume', angle: -90, position: 'left' }}
                                                style={{
                                                    fontSize: '1rem',
                                                }}
                                            />
                                            <Tooltip
                                                itemStyle={{
                                                    padding: 0,
                                                    fontSize: '0.7em',
                                                }}
                                                itemSorter={item => -item.value}
                                                labelFormatter={tooltipLabelFormatter}
                                            />
                                            <ReferenceLine x="2023-06-29" stroke="#adadad" label="C2" />
                                            { graphSeries.map((graphSeriesElement, index) => {
                                                if (graphSeriesHidden.indexOf(graphSeriesElement) >= 0) {
                                                    return null;
                                                }
                                                return <Line
                                                    key={graphSeriesElement}
                                                    strokeWidth={2}
                                                    dataKey={graphSeriesElement}
                                                    stroke={seriesColours[index]}
                                                    dot={false}
                                                />
                                            })}
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <GraphFilters
                                        onToggleAll={onToggleAll}
                                        onToggleGraphSeriesElement={onToggleGraphSeriesElement}
                                        graphSeries={graphSeries}
                                        graphSeriesHidden={graphSeriesHidden}
                                        setGraphSeriesHidden={setGraphSeriesHidden}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </>

        </Grid>
    );
}

export default Analytics;
