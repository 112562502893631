import {Box, CircularProgress} from "@mui/material";
import MDTypography from "mdpr2/components/MDTypography";
import MDButton from "mdpr2/components/MDButton";
import ListResourcesTable from "components/shared/ListResources/ListResourcesTable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {resourceApi} from "api/resource-api";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {editPath} from "../../../utils/learning-group-helpers";
import {learningGroupPrettyType} from "../../../utils/helpers";

const columns = [
    {
        field: 'openInNewTab',
        label: 'Group',
        flex: 0.1,
        formatter: (resource) => {
            const url = editPath(resource.learningGroup);
            return (
                <MDButton color="info" iconOnly className="open-in-new-tab" onClick={() => window.open(url)}><OpenInNewIcon /></MDButton>
            );
        }
    },
    {
        field: 'learningGroupType',
        label: 'Type',
        flex: 1,
        formatter: (resource) => learningGroupPrettyType(resource?.learningGroup),
    },
    {
        field: 'learningGroupName',
        label: 'Name',
        flex: 1,
        formatter: (resource) => resource?.learningGroup?.name ?? 'Unknown',
    },
    {
        field: 'role',
        label: 'Role',
        flex: 1,
        formatter: (resource) => resource.role,
    },
    {
        field: 'isActive',
        label: 'Active?',
        sortable: true,
        flex: 1,
        formatter: (resource) => resource.isActive ? 'Yes' : 'No',
    },
];

/* keep this in its own general component, so we can use for other types of LearningGroup */
const UserLearningGroupAccesses = ({user}) => {

    const [accesses, setAccesses] = useState(null);

    const loadAccesses = async () => {
        const response = await resourceApi.getResources({
            apiEndpoint: 'learning-group-accesses',
            filterValues: {
                user: user['@id'],
            },
            sortValues: [
                {field: 'user.firstName', direction: 'asc'},
            ],
            pagination: {
                itemsPerPage: 999,
            },
        });

        setAccesses(response.resources);
    }

    useEffect(
        loadAccesses,
        [user]
    );

    const onAddEditModalClose = () => {
        loadAccesses();
    }

    if(accesses === null) {
        return (<Box marginTop={5} justifyContent="center"><CircularProgress/> Loading...</Box>);
    }

    return (
        <>
            <MDTypography variant="h6">Group access - {accesses.length} group(s)</MDTypography>
            <Box sx={{mt: 2}}>
                <ListResourcesTable
                    apiEndpoint="learning-group-accesses"
                    columns={columns}
                    resources={accesses}
                    resourceCount={accesses.length}
                    // disableEditResource
                    disableOpenInNewTab
                    isAddEditModal
                    onAddEditModalClose={onAddEditModalClose}
                />
            </Box>
        </>
    );
}

UserLearningGroupAccesses.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
};

export default UserLearningGroupAccesses;