const getDateLocaleString = (dateAsString, format) => {
  if (!dateAsString) return '';
  const date = new Date(dateAsString);
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return '';
  }
  if (format === 'date') return date.toLocaleDateString();
  if (format === 'time') return date.toLocaleTimeString();
  return date.toLocaleString();
};

export default getDateLocaleString;
