import { withStyles } from '@material-ui/core/styles';
import DownloadIcon from "@mui/icons-material/Download";
import {Box, Card, CardContent, Tooltip} from "@mui/material";
import {requestApi} from "api/request-api";
import MDButton from "mdpr2/components/MDButton";
import MDTypography from "mdpr2/components/MDTypography";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import RefreshRounded from "@mui/icons-material/RefreshRounded";

const reportUrl = (key) => `reports/download/${encodeURIComponent(key)}`;

const reportFilename = (key) => {
    const offset = key.lastIndexOf('/');
    return key.substring(offset + 1);
};

const localStyles = {
    rotateIcon: {
        animation: "$spin 1s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    }
};

// eslint-disable-next-line no-promise-executor-return
const delay = ms => new Promise(res => setTimeout(res, ms));

// eslint-disable-next-line react/prop-types
const DownloadReport = ({classes}) => {
    const [downloading, setDownloading] = useState(false);
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');

    const handleDownloadReport = async () => {
        setDownloading(true);

        let attempt = 0;
        let response = null;

        while(attempt <= 3)
        {
            attempt += 1;
            try {
                // eslint-disable-next-line no-await-in-loop
                response = await requestApi.getResponse({
                    url: reportUrl(key),
                    rawResponse: true,
                });
                break;
            }
            catch (e) {
                console.log('Error while downloading report');
            }
            // pause for 2 seconds before next attempt
            // eslint-disable-next-line no-await-in-loop
            await delay(2000);
        }

        // failed to get a response
        if(response == null) {
            setDownloading(false);
            return false;
        }

        const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
        if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
        const blob = new Blob([response.data]);
        const objectUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = objectUrl;
        downloadLink.download = filename;
        downloadLink.click();
        window.URL.revokeObjectURL(objectUrl);
        setDownloading(false);
        return true;
    }

    useEffect(handleDownloadReport, []);

    return (
        <Card>
            <CardContent>
                <MDTypography variant="subtitle2">
                    <h4>{ reportFilename(key) }</h4>
                    <Box marginTop={2}>
                        <Tooltip title="Download Report File">
                            <MDButton
                                disabled={downloading}
                                color="info"
                                onClick={handleDownloadReport}
                                variant="outlined"
                            >
                                Download&nbsp;{ downloading ?
                                    // eslint-disable-next-line react/prop-types
                                    (<RefreshRounded className={classes.rotateIcon} />) :
                                    (<DownloadIcon />)
                                }
                            </MDButton>
                        </Tooltip>
                    </Box>

                </MDTypography>
            </CardContent>
        </Card>
    );
};

export default withStyles({ ...localStyles })(DownloadReport);