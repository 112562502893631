import { Fragment, useEffect, useCallback, useState } from 'react';

// MUI
import { Tooltip } from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PublishIcon from '@mui/icons-material//Publish';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UpdateIcon from '@mui/icons-material/Update';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OpenInNew from '@mui/icons-material/OpenInNew';
import MDButton from 'mdpr2/components/MDButton';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import { requestApi } from 'api/request-api';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import ListResources from 'components/shared/ListResources';

const title = 'Products';
const apiEndpoint = 'products';
const resourceName = 'product';

const typeChoices = [
  {value: 'simple', label: 'Simple'},
  {value: 'variable', label: 'Variable'},
  {value: 'group', label: 'Group'},
];

const statusChoices = [
  {value: 'active', label: 'Active'},
  {value: 'draft', label: 'Draft'},
  {value: 'deleted', label: 'Deleted'},
];

const columns = [
  {
    field: 'sku',
    label: 'SKU',
    sortable: true,
    flex: 1,
  },
  {
    field: 'open',
    label: '',
    sortable: false,
    flex: 1,
    formatter: (resource) => (
        <MDButton
          color="dark"
          onClick={() => window.open(`${process.env.REACT_APP_WEB_FE_BASE_URL}/product/${resource.slug}`, '_blank')}
          variant="outlined"
          iconOnly
          title="view on website"
        >
          <OpenInNew />
        </MDButton>
    )
  },
  {
    field: 'name',
    label: 'Product',
    sortable: false,
    flex: 2,
  },
  {
    field: 'slug',
    label: 'Slug',
    sortable: true,
    flex: 2,
  },
  {
    field: 'productCategories',
    label: 'Categories',
    sortable: false,
    flex: 2,
    formatter: (resource) => resource.productCategories?.map((productCategory, index) =>
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`fragment-${index}`}>{productCategory.name}{ index < (resource.productCategories.length - 1) && ', '}</Fragment>
    ),
  },
  {
    field: 'packingUnits',
    label: 'Packing units',
    sortable: true,
    flex: 1,
    formatter: (resource) => {
      if (resource.packingUnits === null) {
        return resource.type === 'group' ? 'Group product' : 'No shipping';
      }
      return resource.packingUnits;
    }
  },
  {
    field: '_type',
    label: 'Type',
    sortable: false,
    flex: 0.7,
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: (resource) => {
      const { type } = resource;

      const typeColor = {
        simple: 'light',
        variable: 'dark',
        group: 'error'
      }

      return <MDBadge color={typeColor[type]} badgeContent={type} />;
    },
  },
  {
    field: '_options',
    label: 'Options',
    flex: 1,
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: (resource) => {
      const { availability, excludeFromSearch, isBundle, isSticky, isPreOrder } = resource;
      const isBundleIcon = isBundle ? <Tooltip title="Product is a bundle containing other products"><GroupWorkIcon color="disabled" fontSize="medium" /></Tooltip> : '';
      const excludeFromSearchIcon = excludeFromSearch ? <Tooltip title="Exclude from search"><VisibilityOffIcon color="disabled" fontSize="medium" /></Tooltip> : '';
      const isPreOrderIcon = isPreOrder ? <Tooltip title="Pre-order only"><UpdateIcon color="disabled" fontSize="medium" /></Tooltip> : '';
      const isStickyIcon = isSticky ? <Tooltip title="Sticky within search results"><PublishIcon color="disabled" fontSize="medium" /></Tooltip> : '';
      let availabilityIcon = '';
      if (availability === 'purchasable') availabilityIcon = <Tooltip title="Purchasable"><ShoppingCartIcon color="disabled" fontSize="medium" /></Tooltip>;
      if (availability === 'not_purchasable') availabilityIcon = <Tooltip title="Not purchasable"><RemoveShoppingCartIcon color="disabled" fontSize="medium" /></Tooltip>;
      return (
        <div>
          {availabilityIcon}
          {isBundleIcon}
          {excludeFromSearchIcon}
          {isPreOrderIcon}
          {isStickyIcon}
        </div>
      );
    },
  },
  {
    field: 'displayOrder',
    label: 'Disp',
    sortable: true,
    flex: 0.5,
  },
];

const filters = [
  {
    field: 'sku',
    label: 'SKU',
    type: 'text',
  },
  {
    field: 'product',
    label: 'Product',
    type: 'text',
  },
  {
    field: 'type',
    label: 'Type',
    type: 'select',
    choices: typeChoices,
  },
  {
    field: 'status',
    label: 'Status',
    type: 'select',
    choices: statusChoices,
  },
  {
    field: 'productReportingCategory',
    label: 'Reporting category',
    type: 'select',
  },
];

const defaultFilterValues = { status: 'active' };

const defaultSortValues = [{field: 'name', direction: 'asc'}];
const fixedSortValues = [{field: 'createdAt', direction: 'desc'}];

const Products = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const noChoice = { value: '', label: '--- Remove filter ---' }
      let productReportingCategoryChoices = await requestApi.getResponse({ url: 'product-reporting-categories/choices' });
      productReportingCategoryChoices = productReportingCategoryChoices.filter(
        productReportingCategoryChoice => productReportingCategoryChoice.canHaveProductsAssigned
      );
      const productReportingCategoryFilter = filters.find((filter) => filter.field === 'productReportingCategory');
      productReportingCategoryFilter.choices = [noChoice, ...productReportingCategoryChoices];
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultFilterValues={defaultFilterValues}
      defaultSortValues={defaultSortValues}
      fixedSortValues={fixedSortValues}
      filters={filters}
      resourceName={resourceName}
      title={title}
    />
  );
};

export default Products;
