import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'iplicit-product-promotion-codes';
const resourceName = 'Iplicit product/promotion code';

const fields = [
  {
    name: 'product.sku',
    label: 'Product',
    type: 'static',
  },
  {
    name: 'promotion.name',
    label: 'Promotion',
    type: 'static',
  },
  {
    name: 'nominalCode',
    label: 'Nominal code',
  },
];

const validationSchema = Yup.object().shape({
});

const toApiValuesCustom = (formValues, fieldsToMap) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fieldsToMap);

  // Remove product and promotion from apiValues: these are read-only and should not be written
  delete(apiValues.product);
  delete(apiValues.promotion);

  return apiValues;
}

const AddEditIplicitProductPromotionCode = () => {
    const params = useParams();
    const id = params.id ? Number(params.id) : null;

    return (
      <AddEditResource
        apiEndpoint={apiEndpoint}
        fields={fields}
        id={id}
        resourceName={resourceName}
        toApiValuesCustom={toApiValuesCustom}
        validationSchema={validationSchema}
      />
    );
  };

  export default AddEditIplicitProductPromotionCode;
  