/* eslint-disable */
import { useEffect, useState } from 'react';
import { getIn } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import {
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography, FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// WRM
import { requestApi } from '../../../api/request-api';

const SelectAsTable = (props) => {
  const {
    choices: initialChoices = [],
    choicesEndpoint = null,
    formik,
    label,
    name,
    getApiError
  } = props;

  const [choices, setChoices] = useState(initialChoices);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const selectedChoices = [];
    getIn(formik.values.selectAsTableHelpers, name)?.forEach((initialChoice) => {
      selectedChoices.push(initialChoice);
    });
    getIn(formik.values, name)?.forEach((value) => {
      const selectedChoice = choices.find((choice) => choice.value === value);
      if (selectedChoice) selectedChoices.push(selectedChoice);
    });
    setSelectedChoices(selectedChoices);
  }, []);

  useEffect(() => {
    formik.setFieldValue(name, selectedChoices.map((choice) => choice.value));
  }, [selectedChoices]);

  const deleteSelectedChoice = (deleteIndex) => {
    setSelectedChoices(selectedChoices.filter((choice, index) => index !== deleteIndex));
  };

  const handleAutocompleteOnChange = (event, value) => {
    if (!value) return;
    setSelectedChoices([...selectedChoices, value]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedChoices = [];
    const movedChoice = selectedChoices[result.source.index];
    selectedChoices.forEach((selectedChoice, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedChoices.push(movedChoice);
      }
      if (index !== result.source.index) {
        reorderedChoices.push(selectedChoice);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedChoices.push(movedChoice);
      }
    });
    setSelectedChoices(reorderedChoices);
  };

  const handleSearchOnChange = async (event) => {
    if (choicesEndpoint && event.target.value.length >= 3) {
      // eslint-disable-next-line no-shadow
      const choices = await requestApi.getResponse({ url: `${choicesEndpoint}?search=${event.target.value}` });
      setChoices(choices);
      const filtered = choices?.filter((choice) => !selectedChoices.includes(choice));
    }
  };

  return (
    <>
      <Typography
        sx={{ mt: 1 }}
        variant="h6"
      >
        {label}
      </Typography>
      <Table>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            direction="vertical"
            droppableId="selected-choices-droppable"
          >
            {
              (droppableProvided) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...droppableProvided.droppableProps}
                >
                  {
                    selectedChoices.map((choice, index) => (
                      <Draggable
                        draggableId={`selected-choice-${choice.value}`}
                        index={index}
                        key={`selected-choice-${choice.value}`}
                      >
                        {
                          (draggableProvided, snapshot) => (
                            <TableRow
                              ref={draggableProvided.innerRef}
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...draggableProvided.draggableProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                              }}
                            >
                              <TableCell style={{ width: '1px' }}>
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                              </TableCell>
                              <TableCell>
                                {choice.label}
                              </TableCell>
                              <TableCell style={{ width: '1px' }}>
                                <DeleteIcon
                                  color="error"
                                  fontSize="small"
                                  onClick={() => deleteSelectedChoice(index)}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </Draggable>
                    ))
                  }
                  {droppableProvided.placeholder}
                </TableBody>
              )
            }
          </Droppable>
        </DragDropContext>
      </Table>

      <Autocomplete
        inputValue={searchValue}
        options={choices?.filter((choice) => !selectedChoices.includes(choice))}
        onChange={handleAutocompleteOnChange}
        onInputChange={(event, value, reason) => setSearchValue(reason === 'reset' ? '' : value)}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            label="Search"
            onChange={handleSearchOnChange}
          />
        )}
      />

      {
          (Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))) && (
              <FormHelperText error>{getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}</FormHelperText>
          )
      }

    </>
  );
};

export default SelectAsTable;
