/* eslint-disable */
import { getIn } from 'formik';

// MUI
import {
  InputAdornment,
  TextField,
} from '@mui/material';

const Currency = (props) => {
  const {
    formik,
    getApiError,
    label,
    name,
    onChange,
  } = props;

  return (
    <TextField
      error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
      fullWidth
      helperText={getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">£</InputAdornment>
        ),
      }}
      label={label}
      name={name}
      onBlur={formik.handleBlur}
      onChange={onChange || formik.handleChange}
      value={getIn(formik.values, name)}
    />
  );
};

export default Currency;
