import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './EmailRecipientsHelper.module.scss';

const EmailRecipientsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedEmailRecipients = [];
    const movedEmailRecipients = formik.values.emailRecipientsHelper[result.source.index];
    formik.values.emailRecipientsHelper.forEach((emailRecipient, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedEmailRecipients.push(movedEmailRecipients);
      }
      if (index !== result.source.index) {
        reorderedEmailRecipients.push(emailRecipient);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedEmailRecipients.push(movedEmailRecipients);
      }
    });
    formik.setFieldValue('emailRecipientsHelper', reorderedEmailRecipients);
  };

  return (
    <FieldArray name="emailRecipientsHelper" render={fieldArrayHelpers => (
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <h4>Email recipients</h4>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <MDButton
                className={componentStyles['add-email-recipient-button']}
                color="info"
                onClick={() => {
                  const prototype = {};
                  prototype.emailRecipient = '';
                  prototype.uuid = moment().valueOf();
                  fieldArrayHelpers.push(prototype);
                }}
                size="small"
                startIcon={<AddIcon/>}
                variant="gradient"
              >
                Add email recipient
              </MDButton>
            </Grid>


            <Grid
              item
              xs={12}
            >
              <Table>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                    direction="vertical"
                    droppableId="email-recipients-droppable"
                  >
                    {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            formik.values.emailRecipientsHelper?.map((emailRecipient, index) => {
                              const emailRecipientFieldName = `emailRecipientsHelper[${index}].emailRecipient`;
                              return (
                                <Draggable
                                  draggableId={`email-recipient-${emailRecipient.uuid}`}
                                  index={index}
                                  key={`email-recipient-condition-${emailRecipient.uuid}`}
                                >
                                  {
                                    (draggableProvided, snapshot) => (
                                      <TableRow
                                        ref={draggableProvided.innerRef}
                                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                                        {...draggableProvided.draggableProps}
                                        style={{
                                          ...draggableProvided.draggableProps.style,
                                          background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                        }}
                                      >
                                        <TableCell style={{ width: '1px' }}>
                                          <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                        </TableCell>
                                        <TableCell>
                                          <FormField
                                            name={emailRecipientFieldName}
                                            label="Email recipient"
                                            formik={formik}
                                            getApiError={getApiError}
                                          />
                                        </TableCell>
                                        <TableCell style={{ width: '1px' }}>
                                          <MDButton
                                            color="error"
                                            iconOnly
                                            onClick={() => {
                                              fieldArrayHelpers.remove(index);
                                            }}
                                            variant="gradient"
                                          >
                                            <DeleteIcon/>
                                          </MDButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                </Draggable>
                              );
                            })
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      )} />
  );
};

EmailRecipientsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default EmailRecipientsHelper;
