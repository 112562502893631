import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import Autocomplete from '@mui/lab/Autocomplete';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';
import TimelineItem from 'mdpr2/examples/Timeline/TimelineItem';

// WRM
import { resourceApi } from 'api/resource-api';
import { requestApi } from 'api/request-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';
import getDateLocaleString from 'utils/get-date-locale-string';
import getUserName from 'utils/get-user-name';
import ConfirmModal from "components/shared/ConfirmModal";
import {useAppContext} from "../../../contexts/app-context";

const AddEditMembershipForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    isModal,
    onFormSubmit,
    onModalClose,
    resource: membership,
    validationSchema,
  } = props;

  const [membershipNoteStories, setMembershipNoteStories] = useState([]);
  const [userChoices, setUserChoices] = useState([]);
  const [allowReassignment, setAllowReassignment] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [showRemoveTeamMemberModal, setShowRemoveTeamMemberModal] = useState(false);
  const [noteText, setNoteText] = useState('');

  const { userFeedbackSuccess } = useAppContext();
  const isMounted = useMounted();
  const navigate = useNavigate();

  const refreshMembershipNoteStories = async () => {
    const membershipNotesResponse = await resourceApi.getResources({
      apiEndpoint: 'membership-notes',
      filterValues: {
        membership: `/memberships/${membership.id}`,
      },
      sortValues: [
        {field: 'createdAt', direction: 'desc'},
        {field: 'id', direction: 'desc'},
      ],
      pagination: {
        itemsPerPage: 999,
      },
    });
    const membershipNotes = membershipNotesResponse.resources;
    // eslint-disable-next-line no-shadow
    const membershipNoteStories = [];
    membershipNotes.forEach((membershipNote) => {
      membershipNoteStories.push({
        description: membershipNote.content,
        title:
            renderToStaticMarkup(<>
              {getDateLocaleString(membershipNote.createdAt)}
              {
                  membershipNote.createdByNameLegacy &&
                  <>
                    &nbsp;by {membershipNote.createdByNameLegacy}
                  </>
              }
              {
                  membershipNote.createdByUser &&
                  <>
                    &nbsp;by {getUserName(membershipNote.createdByUser)}
                  </>
              }
            </>),
        dateTime: '',
        color: 'warning',
        icon: 'comment',
        key: `membership-note-${membershipNote.id}`,
      });
    });
    setMembershipNoteStories(membershipNoteStories);
  }

  const initialise = useCallback(async () => {
    if (isMounted()) {
      await refreshMembershipNoteStories();
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  const handleReassignClick = () => {
    setAllowReassignment(true);
  }
  const handleCancelReassignClick = () => {
    setAllowReassignment(false);
  }

  const deleteMembership = async () => {
    try {
      await resourceApi.deleteResource({
        apiEndpoint: 'memberships',
        id: membership.id,
      });
    }

    catch(error) {
      return;
    }

    finally {
      setShowRemoveTeamMemberModal(false);
      if(isModal) {
        // not sure why this is called onModalClose and not just closeModal, or closeSelf?
        onModalClose()
      }
      else {
        navigate(`/admin/team-memberships/edit/${membership.teamMembership.id}`);
      }
    }
  };

  const searchUsers = async (event) => {
    const search = event.target.value;
    if (search.length) {
      // eslint-disable-next-line no-shadow
      const userChoices = await requestApi.getResponse({ url: `users/choices?search=${encodeURIComponent(search)}` });
      setUserChoices(userChoices);
    } else {
      setUserChoices([]);
    }
  };

  const createNewMembershipNote = async () => {
    const data = {
      membership: membership['@id'],
      content: noteText
    };

    await resourceApi.saveResource({
      apiEndpoint: 'membership-notes',
      data,
      id: null,
    });

    userFeedbackSuccess('Note added successfully');

    setNoteText('');

    await refreshMembershipNoteStories();
  }

  return (
    <>
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => {
        let userField;
        if (!membership.user || allowReassignment) {
          // If the Membership User is not set (or being reassigned), allow the User to be searched/selected
          userField =
            <>
              {/* Render user as a hidden field that is updated by the 'search users' helper */}
              <Field name="user" type="hidden" style={{display: "none"}} />
              <Field
                component={Autocomplete}
                options={userChoices}
                getOptionLabel={(option) => option.label}
                renderInput={(fieldParams) => (
                  <>
                    <TextField
                      {...fieldParams}
                      label="Search for user"
                      variant="outlined"
                      onChange={searchUsers}
                      error={formik.errors.user && true}
                      InputProps={{
                        ...fieldParams.InputProps,
                        startAdornment:
                          <InputAdornment position="end">
                            <IconButton onClick={handleCancelReassignClick}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                    {(getApiError('user') || formik.errors.user) && <span>{getApiError('user') || formik.errors.user}</span>}
                  </>
                )}
                onChange={(event, value) => { if (value) { formik.setFieldValue('user', value.value); setNewUser(value.label); } }}
              />
            </>
        } else {
          // If the Membership User is already set (and not being reassigned)Lose, display it in a disabled field
          const userName = membership.user ? `${getUserName(membership.user)} (${membership.user.email})` : membership.user.email;
          userField =
            <TextField
              variant="outlined"
              fullWidth
              disabled
              defaultValue={newUser || userName}
              label="User"
              InputProps={{
                startAdornment:
                  <InputAdornment position="start">
                    <IconButton onClick={handleReassignClick}>
                      <CachedIcon />
                    </IconButton>
                  </InputAdornment>
              }}
            />
        }

        return (
          <Form formik={formik}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={8}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      mt={1}
                      spacing={2}
                    >
                      <
                        Grid
                        item
                        xs={12}
                      >
                        {userField}
                      </Grid>

                      {
                        !membership.teamMembership && (
                          <>
                            <FormField
                              {...fields.find((field) => field.name === 'membershipPlan')}
                              formik={formik}
                              getApiError={getApiError}
                            />

                            <FormField
                              {...fields.find((field) => field.name === 'startsAt')}
                              formik={formik}
                              getApiError={getApiError}
                            />

                            <FormField
                              {...fields.find((field) => field.name === 'expiresAt')}
                              formik={formik}
                              getApiError={getApiError}
                            />
                          </>
                        )
                      }
                      {
                        membership.teamMembership && (
                          <>
                            <FormField
                              type="static"
                              label="Membership plan"
                              value={membership.membershipPlan.name}
                            />

                            <FormField
                              type="static"
                              label="Starts"
                              value={getDateLocaleString(membership.teamMembership.startsAt, 'date')}
                            />

                            <FormField
                              type="static"
                              label="Expires"
                              value={membership.teamMembership.expiresAt ? getDateLocaleString(membership.teamMembership.expiresAt, 'date') : 'Never'}
                            />
                          </>
                        )
                      }

                      <FormField
                        {...fields.find((field) => field.name === 'isActive')}
                        formik={formik}
                        getApiError={getApiError}
                      />

                      {
                          membership.teamMembership && (
                              <FormField
                                  {...fields.find((field) => field.name === 'isTeamMembershipManager')}
                                  formik={formik}
                                  getApiError={getApiError}
                              />
                          )
                      }

                      {
                          membership.user && membership.teamMembership &&
                          <Box sx={{ml: 2, mt: 1}}>
                            <MDButton variant="outlined" color="error" onClick={() => { setShowRemoveTeamMemberModal(true); }}>
                              <DeleteIcon /> Remove From Team
                            </MDButton>
                          </Box>
                      }
                    </Grid>
                  </CardContent>
                  <CardActions sx={{flexWrap: 'wrap', m: 1}}>
                    <SaveCancelButtons
                      cancelLocation={cancelLocation}
                      formik={formik}
                      isModal={isModal}
                      onModalClose={onModalClose}
                    />
                  </CardActions>
                </Card>
              </Grid>
              <
                Grid
                item
                xs={4}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <
                    Grid
                    item
                    pt={1}
                    xs={12}
                  >
                    <Card>
                      <CardContent>
                        <MDTypography variant="h6">Orders</MDTypography>
                        {
                          membership.order &&
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={8}>
                              <MDTypography variant="subtitle2">Created from order
                                #{membership.order.reference}</MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} style={{textAlign: 'right'}}>
                              <MDButton
                                sx={{mr: 1}}
                                color="info"
                                iconOnly
                                onClick={() => navigate(`/admin/orders/edit/${membership.order.id}`)}
                                variant="gradient"
                              >
                                <EditIcon/>
                              </MDButton>
                              <MDButton
                                color="info"
                                iconOnly
                                onClick={() => window.open(`/admin/orders/edit/${membership.order.id}`, '_blank')}
                                variant="gradient"
                              >
                                <OpenInNewIcon/>
                              </MDButton>
                            </Grid>
                          </Grid>
                        }
                        {
                          membership.membershipRenewals?.map((membershipRenewal) => (
                            <Grid container alignItems="center">
                              <Grid item xs={8}>
                                Renewed from order #{membershipRenewal.order.reference}
                                {
                                  membershipRenewal.isRevoked && <>&nbsp;<MDBadge color="error" badgeContent="Cancelled" /></>
                                }
                              </Grid>
                              <Grid item xs={4} style={{textAlign: 'right'}}>
                                <MDButton
                                  sx={{mr: 1}}
                                  color="primary"
                                  iconOnly
                                  onClick={() => navigate(`/admin/orders/edit/${membershipRenewal.order.id}`)}
                                  variant="gradient"
                                >
                                  <EditIcon/>
                                </MDButton>
                                <MDButton
                                  color="info"
                                  iconOnly
                                  onClick={() => window.open(`/admin/orders/edit/${membershipRenewal.order.id}`, '_blank')}
                                  variant="gradient"
                                ><OpenInNewIcon/></MDButton>
                              </Grid>
                            </Grid>
                          ))
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                  {
                    membership.id && (
                      <Grid item marginTop={1} xs={12}>
                            <TextField
                                fullWidth
                                label="Add Note"
                                multiline
                                name="add-note"
                                rows={3}
                                placeholder="New Note Here"
                                onChange={(e) => setNoteText(e.target.value)}
                                value={noteText}
                            />
                            <MDButton
                                sx={{mt: 1}}
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={createNewMembershipNote}
                            >
                              <CommentIcon sx={{mr: 1}}/> Add Note
                            </MDButton>
                      </Grid>
                    )
                  }
                  { !membership.id && (
                      <Grid item marginTop={1} xs={12}>
                        <h4>Please save this membership before adding notes</h4>
                      </Grid>
                  )}
                  {
                    membershipNoteStories.length > 0 && (
                      <
                          Grid
                          item
                          xs={12}
                          marginTop={-2}
                      >
                        <TimelineList title="Notes">
                          {
                            membershipNoteStories.map((membershipNoteStory) => <TimelineItem {...membershipNoteStory} />)
                          }
                        </TimelineList>
                      </Grid>
                      )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
    {/* remove team member confirmation modal  */}
    <ConfirmModal
        open={showRemoveTeamMemberModal}
        body={`Are you sure you want to remove ${getUserName(membership.user)} from ${membership?.teamMembership?.name ?? ''}?`}
        title="Remove Team Member"
        setOpen={(open) => setShowRemoveTeamMemberModal(open)}
        confirmCallback={deleteMembership}
    />
    </>
  );
};

AddEditMembershipForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  isModal: PropTypes.bool,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

AddEditMembershipForm.defaultProps = {
  isModal: false,
  onModalClose: null,
};

export default AddEditMembershipForm;
