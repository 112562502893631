/* eslint-disable */
import { getIn } from 'formik';
import moment from 'moment';

// MUI
import { DatePicker } from '@mui/x-date-pickers';
import {FormHelperText, TextField} from '@mui/material';

const Date = (props) => {
  const {
    formik,
    getApiError = () => false,
    label,
    name,
  } = props;

  const errorMessage = getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name));

  return (
      <>
        <DatePicker
          closeOnSelect={false}
          inputFormat="dd/MM/yyyy"
          label={label}
          onChange={(newDate) => formik.setFieldValue(name, newDate ? moment(newDate) : null)}
          renderInput={(inputProps) => (
            <TextField
              fullWidth
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...inputProps}
            />
          )}
          value={getIn(formik.values, name)}
          componentsProps={{
              actionBar: {
                  actions: ['clear', 'today', 'accept', 'cancel']
              }
          }}
        />
        {
            errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>
        }
      </>
  );
};

export default Date;
