const getUserName = (user) => {
  if (!user) return '';

  const nameParts = [];
  if (user.firstName) nameParts.push(user.firstName);
  if (user.lastName) nameParts.push(user.lastName);
  return nameParts.join(' ');
};

export default getUserName;
