import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import AddOrder from '../Orders/AddOrder';

const EditQuote = (props) => {
  const { setBreadcrumbs } = props;

  const params = useParams();
  const resourceId = params.id ? params.id : null;

  return (
    <AddOrder {...{ basketId: resourceId, setBreadcrumbs }} />
  );
}

EditQuote.propTypes = {
  setBreadcrumbs: PropTypes.func,
};

EditQuote.defaultProps = {
  setBreadcrumbs: null,
};

export default EditQuote;
