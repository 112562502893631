import React from 'react';
import PropTypes from 'prop-types';

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

// WRM
import FileBrowser from './index';

const FileBrowserDialog = (props) => {
  const {
    callbackOnSelection,
    closeFileBrowser,
    fieldToUpdate,
    setFieldValue,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      onClose={closeFileBrowser}
      open
      scroll="paper"
    >
      <DialogTitle>File browser</DialogTitle>
      <DialogContent>
        <FileBrowser
          callbackOnSelection={callbackOnSelection}
          closeFileBrowser={closeFileBrowser}
          fieldToUpdate={fieldToUpdate}
          setFieldValue={setFieldValue}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeFileBrowser}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FileBrowserDialog.propTypes = {
  callbackOnSelection: PropTypes.func,
  closeFileBrowser: PropTypes.func.isRequired,
  fieldToUpdate: PropTypes.string,
  setFieldValue: PropTypes.func,
};

FileBrowserDialog.defaultProps = {
  callbackOnSelection: null,
  fieldToUpdate: null,
  setFieldValue: null,
};

export default FileBrowserDialog;
