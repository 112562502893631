import { useCallback, useState } from 'react';

// MUI
import Icon from '@mui/material/Icon';

// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Product reporting categories';
const apiEndpoint = 'product-reporting-categories';
const resourceName = 'product reporting category';

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'canHaveProductsAssigned',
    label: 'Products can be assigned',
    type: 'checkbox',
  },
  {
    field: 'orderAsTree',
    label: 'Tree view',
    type: 'checkbox',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const ProductReportingCategories = () => {
  // A local state for filterValues that will be percolated up from ListResources so that columns
  // can turn indentation on/off when the orderAsTree filter is true
  const [filterValues, setFilterValues] = useState({});

  const columns = [
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      flex: 1,
      formatter: useCallback((resource) => {
        const { name, namePath } = resource;
        // If orderAsTree is not true, render only the WebPage name
        if (!filterValues.orderAsTree) {
          return name;
        }
        // If orderAsTree is true, render the required indentation
        const namePathPartsLength = (namePath ?? '').split(' | ').filter((namePathPart) => namePathPart.trim() !== '').length;
        return (
          <>
            {[...Array(namePathPartsLength)].map(() => (
              <Icon fontSize="small" sx={{padding: '5px 0px'}}>chevron_right</Icon>
            ))}
            {name}
          </>
        );
      }),
    },
    {
      field: 'parentProductReportingCategory',
      label: 'Parent category',
      flex: 1,
      formatter: (resource) => resource.parentProductReportingCategory?.name,
    },
  ];

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultSortValues={defaultSortValues}
      filters={filters}
      resourceName={resourceName}
      setFilterValues={setFilterValues}
      title={title}
    />
  );
};

export default ProductReportingCategories;
