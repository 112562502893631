import { useState } from 'react';
import axios from 'axios';
import { putRequestQuery } from 'api/apiRequest';
import capitalise from 'utils/capitalise';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import toApiValuesCustom from 'pages/auth/Orders/to-api-values-custom';
import MDButton from 'mdpr2/components/MDButton';
import FormField from '../FormField';

const ManualAdjustments = (props) => {
  const {
    formik,
    fields,
    getApiError,
    entityName,
    basket,
    setBasket,
    userFeedbackSuccess,
  } = props;

  const [isTogglePercentageDiscount, setIsTogglePercentageDiscount] = useState(false);

  const updateBasket = async (formValues) => {
    const data = Object.keys(formValues).length > 0 ? toApiValuesCustom(formValues, fields) : {};
    const response = await axios(putRequestQuery(`baskets/${basket.id}`, data));
    if (response && response.data) {
      setBasket(response.data);
      userFeedbackSuccess(`${capitalise(entityName)} has been updated`);
    }
  };

  const togglePercentageDiscount = () => {
    formik.values.manualDiscount = null;
    formik.setFieldValue('manualDiscount', null);
    formik.values.manualPercentageDiscount = null;
    formik.setFieldValue('manualPercentageDiscount', null);
    setIsTogglePercentageDiscount(!isTogglePercentageDiscount);
  };

  return (
    <Card>
      <CardHeader title="Manual adjustments" />
      <CardContent>
        <Grid container spacing={2}>
          {isTogglePercentageDiscount ? (
            <FormField
              {...fields.find((field) => field.name === 'manualPercentageDiscount')}
              formik={formik}
              getApiError={getApiError}
            />
          ) : (
            <FormField
              {...fields.find((field) => field.name === 'manualDiscount')}
              formik={formik}
              getApiError={getApiError}
            />
          )}
          <MDButton type="button" color="secondary"
                    onClick={() => togglePercentageDiscount()}
                    sx={{ height: '2.7rem', marginTop: 'auto' }}
          >
            {isTogglePercentageDiscount ? '£' : '%'}
          </MDButton>
          <FormField
            {...fields.find((field) => field.name === 'manualShippingServiceName')}
            formik={formik}
            getApiError={getApiError}
          />

          <FormField
            {...fields.find((field) => field.name === 'manualShippingServiceAmount')}
            formik={formik}
            getApiError={getApiError}
          />

          <Grid item xs={2}
          >
            <MDButton
              color="info"
              onClick={() => updateBasket(formik.values)}
              variant="gradient"
            >
              Update {entityName}
            </MDButton>

          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ManualAdjustments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  getApiError: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  basket: PropTypes.object.isRequired,
  setBasket: PropTypes.func.isRequired,
  userFeedbackSuccess: PropTypes.func.isRequired,
};

export default ManualAdjustments;