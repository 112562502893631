import { FieldArray, Formik, getIn } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

// MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import componentStyles from './AddEditPromotion.module.scss';

const AddEditPromotionForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    validationSchema,
  } = props;

  const conditionAttributeChoices = [
    {value: 'basket.subtotal', label: 'Basket value'},
    {value: 'basket.products', label: 'Basket contains product'},
    {value: 'basket.email', label: 'Basket user email'},
  ];

  const basketSubtotalComparatorChoices = [
    {value: '>', label: 'Greater than'},
    {value: '>=', label: 'Greater than or equal'},
    {value: '<', label: 'Less than'},
    {value: '<=', label: 'Less than or equal'},
    {value: '=', label: 'Is exactly'},
  ];

  const discountTypeChoices = [
    {value: 'percentage', label: 'Percentage'},
    {value: 'fixed', label: 'Fixed discount'},
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <FormField
                  {...fields.find((field) => field.name === 'name')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'code')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'status')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <Grid
                  item
                  key='grid-conditionsHelper'
                  md={12}
                  xs={12}
                >
                  <FieldArray name="conditionsHelper" render={conditionsFieldArrayHelpers => (
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <h4>Conditions</h4>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Button
                          className={componentStyles['add-condition-button']}
                          color="primary"
                          onClick={() => {
                            const prototype = {};
                            ['attribute', 'comparator', 'value'].forEach((conditionPart) => {
                              prototype[conditionPart] = '';
                            });
                            prototype.uuid = moment().valueOf();
                            conditionsFieldArrayHelpers.push(prototype);
                          }}
                          size="sm"
                          startIcon={<AddIcon/>}
                        >
                          Add condition
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        {
                          formik.values.conditionsHelper?.map((condition, index) => {
                            const attributeFieldName = `conditionsHelper[${index}].attribute`;
                            const attributeValue = getIn(formik.values, attributeFieldName);
                            const comparatorFieldName = `conditionsHelper[${index}].comparator`;
                            const valueFieldName = `conditionsHelper[${index}].value`;
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid
                                container
                                key={`Grid-conditionsHelper-${condition.uuid}`}
                                spacing={2}
                              >
                                <Grid
                                  item
                                  xs={3}
                                >
                                  <FormField
                                    name={attributeFieldName}
                                    label="Condition"
                                    type="select"
                                    choices={conditionAttributeChoices}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                >
                                  {
                                    attributeValue === 'basket.subtotal' && (
                                      <FormField
                                        name={comparatorFieldName}
                                        label="Comparator"
                                        type="select"
                                        choices={basketSubtotalComparatorChoices}
                                        formik={formik}
                                        getApiError={getApiError}
                                      />
                                    )
                                  }
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                >
                                  <FormField
                                    name={valueFieldName}
                                    label="Value"
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <MDButton
                                    color="error"
                                    iconOnly
                                    onClick={() => { conditionsFieldArrayHelpers.remove(index); }}
                                    variant="gradient"
                                  >
                                    <DeleteIcon />
                                  </MDButton>
                                </Grid>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  )} />
                </Grid>

                <Grid
                  item
                  key='grid-discountsHelper'
                  md={12}
                  xs={12}
                >
                  <FieldArray name="discountsHelper" render={discountsFieldArrayHelpers => (
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <h4>Discounts</h4>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        {
                          formik.values.discountsHelper.length === 0 && (
                            <Button
                              className={componentStyles['add-discount-button']}
                              color="primary"
                              onClick={() => {
                                const prototype = {};
                                ['type', 'value', 'products'].forEach((discountPart) => {
                                  prototype[discountPart] = '';
                                });
                                prototype.uuid = moment().valueOf();
                                discountsFieldArrayHelpers.push(prototype);
                              }}
                              size="sm"
                              startIcon={<AddIcon/>}
                            >
                              Add discount
                            </Button>
                          )
                        }
                      </Grid>
                      <Grid item xs={12}>
                        {
                          formik.values.discountsHelper?.map((discount, index) => {
                            const typeFieldName = `discountsHelper[${index}].type`;
                            const valueFieldName = `discountsHelper[${index}].value`;
                            const productsFieldName = `discountsHelper[${index}].products`;
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid
                                container
                                key={`Grid-discountsHelper-${discount.uuid}`}
                                spacing={2}
                              >
                                <Grid
                                  item
                                  xs={3}
                                >
                                  <FormField
                                    name={typeFieldName}
                                    label="Discount type"
                                    type="select"
                                    choices={discountTypeChoices}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                >
                                  <FormField
                                    name={valueFieldName}
                                    label="Discount"
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                >
                                  <FormField
                                    name={productsFieldName}
                                    label="Eligible products"
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <MDButton
                                    color="error"
                                    iconOnly
                                    onClick={() => { discountsFieldArrayHelpers.remove(index); }}
                                    variant="gradient"
                                  >
                                    <DeleteIcon />
                                  </MDButton>
                                </Grid>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  )} />
                </Grid>

                <FormField
                  {...fields.find((field) => field.name === 'startAt')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'endAt')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'maximumUses')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'maximumUsesPerEmail')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'promotionCodes')}
                  formik={formik}
                  getApiError={getApiError}
                />
                {
                  formik.values.promotionCodes?.length === 0 &&
                  <FormField
                    type="checkbox"
                    name="hasNoPromotionCodes"
                    label="This promotion has no discount codes. I understand that it will be applied to any orders made."
                    formik={formik}
                    getApiError={getApiError}
                  />
                }

              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                cancelLocation={cancelLocation}
                formik={formik}
              />
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

AddEditPromotionForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

AddEditPromotionForm.defaultProps = {
};

export default AddEditPromotionForm;
