/* eslint-disable */
// WRM
import { useAppContext } from 'contexts/app-context';
import usePrompt from "utils/react-router-extensions";

const Form = ({ formik, children, unsavedChangesPrompt = true }) => {
  const { userFeedbackError } = useAppContext();

  if (unsavedChangesPrompt) {
      usePrompt(
          "You have unsaved changes. Are you sure you want to leave?",
          formik.dirty && formik.submitCount === 0 && !formik.isSubmitting // un-submitted dirty form?
      );
  }

  return (
    <form onSubmit={(event) => {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          userFeedbackError('There are validation errors in this form');
        }
      });
      formik.handleSubmit(event);
    }}
    >
      {children}
    </form>
  );
};

export default Form;
