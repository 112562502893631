// WRM
import ListResources from 'components/shared/ListResources';

const title = '';
const apiEndpoint = '';
const resourceName = '';

const columns = [];

const filters = [];

const defaultFilterValues = {};

const defaultSortValues = [];

const fixedFilterValues = {};

const UserAuthenticators = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default UserAuthenticators;
