/* eslint-disable  react/jsx-no-useless-fragment */

import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// MUI
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// MDPR
import theme from 'mdpr2/assets/theme';
import themeDark from 'mdpr2/assets/theme-dark';
import { useMaterialUIController } from 'mdpr2/context';

// WRM
import AuthGuard from './authentication/authGuard';
import { AppProvider } from './contexts/app-context';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import BlankLayout from './layouts/BlankLayout';
import { adminHiddenRoutes, adminSidebarRoutes, authRoutes } from './routes';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const adminRouteComp = (prop) => (
    <>
    {
      prop.openInNew ? (
        <BlankLayout controller={controller} dispatch={dispatch}>
          <prop.component />
        </BlankLayout>
      ) : (
        <AdminLayout controller={controller} dispatch={dispatch}>
          <prop.component />
        </AdminLayout>
      )
    }
    </>
  );

  const getAdminRoutes = (routes) =>
    routes.map((prop) => {
      if (prop.collapse) {
        return getAdminRoutes(prop.collapse);
      }

      return (
        <Route
          path={prop.route}
          element={adminRouteComp(prop)}
          key={prop.key || prop.route}
        />
      );
    });

  const getAuthRoutes = (routes) =>
    routes.map((prop) => {
      if (prop.collapse) {
        return getAuthRoutes(prop.collapse);
      }
      return (
        <Route
          path={prop.route}
          element={
            <AuthLayout>
              <prop.component />
            </AuthLayout>
          }
          key={prop.key || prop.route}
        />
      );
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppProvider>
          <CssBaseline />
          <Toaster position="top-center" containerStyle={{ fontSize: '0.75em', }} />
          <Routes>
            <Route exact path="/" element={<Navigate to="/auth/login" />} />
            <Route element={<AuthGuard />}>{getAdminRoutes(adminHiddenRoutes)}</Route>
            <Route element={<AuthGuard />}>{getAdminRoutes(adminSidebarRoutes)}</Route>
            {getAuthRoutes(authRoutes)}
          </Routes>
        </AppProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
