import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import { tagsChoices } from './constants';
import AddEditStockChangeForm from './AddEditStockChangeForm';

const apiEndpoint = 'stock-change';
const resourceName = 'stock audit entry';

const flowChoices = [
  {
    value: 'add',
    label: 'Add to stock (return/new stock)',
  },
  {
    value: 'remove',
    label: 'Remove from stock',
  },
];

const fields = [
  {
    name: 'product',
    label: 'Product',
    type: 'select',
    choices: []
  },
  {
    name: 'flow',
    label: 'Add/remove',
    type: 'select',
    choices: flowChoices
  },
  {
    name: 'location',
    label: 'Location',
    type: 'select',
    choice: [],
    defaultValue: '/locations/1',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'number',
    defaultValue: 1,
  },
  {
    name: 'tags',
    label: 'Tags',
    type: 'selectAsTable',
    choices: tagsChoices,
    defaultValue: []
  },
  {
    name: 'notes',
    label: 'Notes',
    type: 'textarea',
  },
];

const validationSchema = Yup.object().shape({
  product: Yup.string()
    .required('Product is required')
    .notOneOf(['none'], 'Product is required'), // Capture 'no selection'
  quantity: Yup.number()
    .typeError('Invalid quantity')
    .min(1, 'Quantity cannot be negative')
    .required('Quantity is required'),
  notes: Yup.string()
    .required('Notes is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  if (resource.quantity < 0) {
    formValues.flow = 'remove';
    formValues.quantity = -resource.quantity;
  } else {
    formValues.flow = 'add';
  }
  formValues.notes = resource.notes?.join("\n");

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // If stock is being removed, quantity should be negative
  if (formValues.flow === 'remove') {
    apiValues.quantity = -formValues.quantity;
  }
  apiValues.notes = formValues.notes?.split("\n");

  return apiValues;
}

const AddEditStockChange = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const locationChoices = await requestApi.getResponse({ url: 'locations/choices' });
      const locationField = fields.find((field) => field.name === 'location');
      locationField.choices = locationChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditStockChangeForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditStockChange;
