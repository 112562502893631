// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'News articles';
const apiEndpoint = 'news-articles';
const resourceName = 'news article';

const columns = [
  {
    field: 'articleDate',
    label: 'Date',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.articleDate, 'date'),
  },
  {
    field: 'title',
    label: 'Title',
    sortable: true,
    flex: 4,
  },
];

const filters = [
  {
    field: 'title',
    label: 'Title',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'articleDate',
    direction: 'desc',
  },
];

const NewsArticles = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default NewsArticles;
