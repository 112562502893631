/* eslint-disable */
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  OutlinedInput,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box } from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import InvoiceIcon from '@mui/icons-material/Description';
import CommentIcon from '@mui/icons-material/Comment';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';
import TimelineItem from 'mdpr2/examples/Timeline/TimelineItem';
import MDAlert from "mdpr2/components/MDAlert";

// WRM
import { putRequestQuery } from 'api/apiRequest';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import ConfirmModal from 'components/shared/ConfirmModal';
import Form from 'components/shared/Form';
import { useAppContext } from 'contexts/app-context';
import { useUser } from 'contexts/userContext';
import useMounted from 'hooks/use-mounted';
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import getDateLocaleString from 'utils/get-date-locale-string';
import getUserName from 'utils/get-user-name';
import EditBillingAddress from './EditBillingAddress';
import EditDeliveryAddress from './EditDeliveryAddress';
import EditInvoiceNote from './EditInvoiceNote';
import componentStyles from './EditOrderForm.module.scss';
import OrderShipments from "./OrderShipments";
import ProductReturns from "../Warehouse/Orders/ProductReturns";
import AddOrderStatusAmendment from "./AddOrderStatusAmendment";
import IssueRefundModal from './IssueRefundModal';
import { getShipmentsFromOrder } from './helpers';
import LinkOrder from './LinkOrder';

const EditOrderForm = (props) => {
  const {
    context,
    formValues,
    onFormSubmit,
    resource,
    validationSchema,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [order, setOrder] = useState(resource);
  const [user, setUser] = useState(null);
  const [editModalState, setEditModalState] = useState({
    open: false,
    propertyKey: null
  });
  const [amendOrderStatusModalOpen, setAmendOrderStatusModalOpen] = useState(false);
  const [issueRefundModalOpen, setIssueRefundModalOpen] = useState(false);
  const [productReturnsModalOpen, setProductReturnsModalOpen] = useState(false);
  const [newOrderNoteContent, setNewOrderNoteContent] = useState('');
  // Modal state
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalError, setModalError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOrderRef, setModalConfirmOrderRef] = useState(false);

  const modalConfirmCallback = useRef();
  const [callbackData, setCallbackData] = useState({});
  // Refund state
  const [orderRefundTotals, setOrderRefundTotals] = useState({});
  const [refundMode, setRefundMode] = useState(false);
  const [refundData, setRefundData] = useState({
    quantities: {},
    unitPrices: {},
    orderLines: {},
    shipping: 0,
    unassigned: 0,
    total: 0
  });
  const [isOrderRefundValid, setIsOrderRefundValid] = useState(true);
  const [showMarkAsCompletedButton, setShowMarkAsCompletedButton] = useState(true);

  const navigate = useNavigate();
  const { userFeedbackSuccess } = useAppContext();
  const isMounted = useMounted();
  const { userHasRole } = useUser();

  // eslint-disable-next-line no-shadow
  const calculateOrderRefundTotals = (order) => {
    let amountTotal = 0;
    let shippingAmountTotal = 0;
    let unassignedAmountTotal = 0;
    order.orderRefunds.forEach(orderRefund => {
      amountTotal += orderRefund.amount;
      shippingAmountTotal += orderRefund.shippingAmount;
      unassignedAmountTotal += orderRefund.unassignedAmount;
    });
    setOrderRefundTotals({
      'amount': amountTotal,
      'shippingAmount': shippingAmountTotal,
      'unassignedAmount': unassignedAmountTotal,
      'unassignedAmountMax': order.totalIncTaxAfterDiscount - amountTotal
    });
  }

  const initialise = useCallback(async () => {
    // eslint-disable-next-line no-shadow
    const user = await resourceApi.getResource({ apiEndpoint: 'users', id: order.user.id });
    if (isMounted()) {
      setUser(user);
      calculateOrderRefundTotals(order);
      const shipments = getShipmentsFromOrder({ order });
      if (Object.keys(shipments).length > 1) {
        setShowMarkAsCompletedButton(false);
      }
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  let orderStatusColour = 'info';
  if (order.status === 'processing') {
    orderStatusColour = 'warning';
  } else if (order.status === 'completed') {
    orderStatusColour = 'success';
  } else if (order.status === 'cancelled') {
    orderStatusColour = 'error';
  }

  const updateOrderSetDespatchAsCompleted = async (shipment, despatchedSkus, note) => {
    const data = { shipment, despatchedSkus, note };
    const response = await axios(putRequestQuery(`orders/${order.id}/shipment-despatch`, data));
    if (response && response.data) return response.data;
    return null;
  };

  const updateOrderStatusAsCompleted = async (note) => {
    const data = { note };
    const response = await axios(putRequestQuery(`orders/${order.id}/completed`, data));
    if (response && response.data) return response.data;
    return null;
  };

  const updateOrderStatus = async (status) => {
    const response = await resourceApi.saveResource({ apiEndpoint: 'orders', id: order.id, data: { status } });
    if (response.resource) return response.resource;
    return null;
  };

  const updateOrderPaymentStatus = async (paymentStatus) => {
    const response = await resourceApi.saveResource({ apiEndpoint: 'orders', id: order.id, data: { paymentStatus } });
    if (response.resource) return response.resource;
    return null;
  };

  const createOrderNote = async (content) => {
    const data = {
      order: order['@id'],
      content
    };
    await resourceApi.saveResource({ apiEndpoint: 'order-notes', data });
  };

  const handleConfirmMarkAsPaymentExpected = async (note) => {
    let updatedOrder = await updateOrderPaymentStatus('expected');
    if (updatedOrder) {
      let orderNoteContent = "Order marked as 'payment expected', status set to 'Processing'";
      if (note) {
        orderNoteContent = `${orderNoteContent}: ${note}`;
      }
      await createOrderNote(orderNoteContent);
      userFeedbackSuccess("Order successfully marked as 'payment expected'");
      updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
      setOrder(updatedOrder);
      setModalOpen(false);
    }
  };
  const handleMarkAsPaymentExpected = () => {
    setModalTitle("Mark order as 'payment expected'")
    setModalBody("Are you sure you want to mark this order as 'payment expected'?");
    modalConfirmCallback.current = handleConfirmMarkAsPaymentExpected;
    setModalConfirmOrderRef(false);
    setModalOpen(true);
  };

  const handleConfirmMarkAsCompleted = async (note) => {
    const response = await updateOrderStatusAsCompleted(note);
    if (response) {
      const updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
      setOrder(updatedOrder);
      userFeedbackSuccess('Order successfully marked as completed');
      setModalOpen(false);
    }
  };

  const handleMarkAsCompleted = () => {
    setModalTitle('Mark order as completed')
    setModalBody('Are you sure you want to mark this order as completed?');
    modalConfirmCallback.current = handleConfirmMarkAsCompleted;
    setModalConfirmOrderRef(false);
    setModalOpen(true);
  };

  const handleConfirmMarkDespatchAsCompleted = async (shipment, selectedSkuArray, note) => {
    const response = await updateOrderSetDespatchAsCompleted(shipment, selectedSkuArray, note);
    if (response) {
      userFeedbackSuccess('Shipment successfully marked as completed');
      const updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
      setOrder(updatedOrder);
      setModalOpen(false);
    };
  };

  const handleMarkDespatchAsCompleted = (shipment, selectedSkuArray) => {
    setModalTitle(`Mark ${shipment.toUpperCase()} shipment as completed`)
    setModalBody('Are you sure you want to mark this shipment as completed?');
    modalConfirmCallback.current = (note) => handleConfirmMarkDespatchAsCompleted(shipment, selectedSkuArray, note);
    setModalConfirmOrderRef(false);
    setModalOpen(true);
  };

  const handleConfirmCancel = async (note) => {
    let errorMessage = 'Unknown Server Issue';
    try {
      let updatedOrderResponse = await resourceApi.saveResource({ apiEndpoint: 'orders', id: order.id, data: { status: 'cancelled' } });
      let updatedOrder = updatedOrderResponse.resource;
      if (!updatedOrder) {
        errorMessage = 'Unable to update order status to cancelled. (Probably because order has an uncancelled subsequent order.)';
        throw new Error(errorMessage);
      }
      let orderNoteContent = "Order marked as 'cancelled', status set to 'Cancelled'";
      if (note) {
        orderNoteContent = `${orderNoteContent}: ${note}`;
      }
      await createOrderNote(orderNoteContent);
      userFeedbackSuccess('Order successfully cancelled');
      updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
      setOrder(updatedOrder);
      setModalConfirmOrderRef(false);
      setModalOpen(false);
    } catch (e) {
      setModalError('Error: ' + errorMessage);
    }
  };
  const handleCancel = () => {
    setModalTitle('Cancel order');
    let body = (<>
      <p>Are you sure you want to <b>cancel</b> this order?</p>
      {(order.memberships?.length > 0 ||
        order.membershipRenewals?.length > 0 ||
        order.teamMembershipRenewals?.length > 0) && (
          <MDAlert sx={{ my: 2 }} color={'info'}>This order created or renewed memberships, which would also be removed/deleted!</MDAlert>
        )}

    </>);
    setModalBody(body);
    setModalError('');
    modalConfirmCallback.current = handleConfirmCancel;
    setModalConfirmOrderRef(true);
    setModalOpen(true);
  };
  const handleConfirmRevokeLicences = async (note) => {
    let errorMessage = 'Unknown Server Issue';
    try {
      const updatedOrderResponse = await axios(putRequestQuery(`orders/${order.id}/revoke-licences`, {}));
      if (updatedOrderResponse.status === 200 && updatedOrderResponse.data) {
        userFeedbackSuccess('Order licences successfully revoked');
        setOrder(updatedOrderResponse.data);
        if (note) {
          let orderNoteContent = "Revoking order licences: " + note;
          await createOrderNote(orderNoteContent);
        }
        setModalConfirmOrderRef(false);
        setModalOpen(false);
      }
    } catch (e) {
      errorMessage = 'Unable to revoke licences for order. (Probably because the licences have been renewed by a subsequent order.)';
      setModalError('Error: ' + errorMessage);
    }
  };
  const handleRevokeLicences = () => {
    setModalTitle('Revoke licences');
    let body = (<p>Are you sure you want to <b>revoke the licences</b> created by this order?</p>);
    setModalBody(body);
    modalConfirmCallback.current = handleConfirmRevokeLicences;
    setModalConfirmOrderRef(true);
    setModalOpen(true);
  };

  const handleAddNoteClick = async () => {
    await createOrderNote(newOrderNoteContent);
    userFeedbackSuccess('Note added successfully');
    const updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
    setOrder(updatedOrder);
    setNewOrderNoteContent('')
  }

  /* Refund logic */
  const handleRefundClick = () => {
    setRefundMode(true);
  };

  const handleCancelRefundClick = () => {
    setRefundMode(false);
  };

  const handleAmendOrderStatus = () => {
    setAmendOrderStatusModalOpen(true);
  }

  const onAmendOrderStatusModalClose = () => {
    setAmendOrderStatusModalOpen(false);
    refreshOrder();
  }

  // eslint-disable-next-line no-shadow
  const calculateRefundTotal = (refundData) => {
    let refundTotal = 0;
    Object.keys(refundData.orderLines).forEach(sku => {
      if (refundData.orderLines[sku] !== '') {
        refundTotal += Math.round(refundData.orderLines[sku] * 100);
      }
    });
    Object.keys(refundData.quantities).forEach(sku => {
      // If this SKU has already been added via orderLines, skip it
      if (!(sku in refundData.orderLines) || refundData.orderLines[sku] === '') {
        const quantity = refundData.quantities[sku] !== '' ? refundData.quantities[sku] : 0;
        refundTotal += (quantity * refundData.unitPrices[sku]);
      }
    });
    if (refundData.shipping) {
      const shipping = !Number.isNaN(refundData.shipping) ? refundData.shipping : 0;
      refundTotal += (shipping * 100);
    }
    if (refundData.unassigned) {
      const unassigned = !Number.isNaN(refundData.unassigned) ? refundData.unassigned : 0;
      refundTotal += (unassigned * 100);
    }
    return refundTotal;
  };

  const handleChangeRefundQuantity = (orderLine, refundQuantity) => {
    const { sku } = orderLine.orderItem;
    const unitPrice = orderLine.prices.price.incTax;
    let quantity = refundQuantity.trim();
    if (quantity !== '') {
      // If quantity is greater than the allowable value (taking into account the quantity already cancelled), limit it
      quantity = Number.isInteger(parseInt(quantity, 10)) ? parseInt(quantity, 10) : 0;
      if (quantity > (orderLine.quantity - orderLine.quantityCancelled)) {
        quantity = orderLine.quantity - orderLine.quantityCancelled;
      }
      // don't allow negative numbers
      if (quantity < 0) {
        quantity = 0;
      }
    }
    const newRefundData = { ...refundData };
    newRefundData.quantities[sku] = quantity;
    newRefundData.unitPrices[sku] = unitPrice;
    newRefundData.total = calculateRefundTotal(newRefundData);
    setRefundData(newRefundData);
    // If quantity results in an over-refund, prevent submission
    if ((order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total) < 0) {
      setIsOrderRefundValid(false);
    } else {
      setIsOrderRefundValid(true);
      // Update unassignedAmountMax
      const newOrderRefundTotals = { ...orderRefundTotals };
      newOrderRefundTotals.unassignedAmountMax = order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total;
      setOrderRefundTotals(newOrderRefundTotals);
    }
  };

  const handleChangeRefundOrderLine = (orderLine, refundAmount) => {
    const { sku } = orderLine.orderItem;
    const amount = refundAmount.trim();
    const newRefundData = { ...refundData };
    newRefundData.orderLines[sku] = amount;
    newRefundData.total = calculateRefundTotal(newRefundData);
    setRefundData(newRefundData);
    // If amount results in an over-refund, prevent submission
    if ((order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total) < 0) {
      setIsOrderRefundValid(false);
    } else {
      setIsOrderRefundValid(true);
      // Update unassignedAmountMax
      const newOrderRefundTotals = { ...orderRefundTotals };
      newOrderRefundTotals.unassignedAmountMax = order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total;
      setOrderRefundTotals(newOrderRefundTotals);
    }
  };

  const handleChangeRefundShipping = (refundShipping) => {
    let shipping = refundShipping.trim();
    if (shipping !== '') {
      // If shipping is greater than the allowable value (taking into account the shipping already refunded), limit it
      if ((shipping * 100) > (order.shippingTotalAfterDiscount - orderRefundTotals.shippingAmount)) {
        shipping = ((order.shippingTotalAfterDiscount - orderRefundTotals.shippingAmount) / 100);
      }
    }
    const newRefundData = { ...refundData };
    newRefundData.shipping = shipping;
    newRefundData.total = calculateRefundTotal(newRefundData);
    setRefundData(newRefundData);
    // If shipping results in an over-refund, prevent submission
    if ((order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total) < 0) {
      setIsOrderRefundValid(false);
    } else {
      setIsOrderRefundValid(true);
      // Update unassignedAmountMax
      const newOrderRefundTotals = { ...orderRefundTotals };
      newOrderRefundTotals.unassignedAmountMax = order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total;
      setOrderRefundTotals(newOrderRefundTotals);
    }
  };

  const handleChangeRefundUnassigned = (refundUnassigned) => {
    const unassigned = refundUnassigned.trim();
    const newRefundData = { ...refundData };
    newRefundData.unassigned = unassigned;
    newRefundData.total = calculateRefundTotal(newRefundData);
    setRefundData(newRefundData);
    // If unassigned results in an over-refund, prevent submission
    if ((order.totalIncTaxAfterDiscount - orderRefundTotals.amount - newRefundData.total) < 0) {
      setIsOrderRefundValid(false);
    } else {
      setIsOrderRefundValid(true);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleConfirmRefund = async ({ reason, reasonCategory, callbackData }) => {
    const requestData = { ...refundData };
    requestData.order = `/orders/${order.id}`;
    requestData.shipping = !Number.isNaN(requestData.shipping) ? parseInt((requestData.shipping * 100), 10) : 0;
    requestData.unassigned = !Number.isNaN(requestData.unassigned) ? parseInt((requestData.unassigned * 100), 10) : 0;
    requestData.total = parseInt(requestData.total, 10);
    requestData.reason = reason;
    requestData.reasonCategory = reasonCategory;
    requestData.fulfilment = callbackData.fulfilment ?? null
    const response = await resourceApi.saveResource({ apiEndpoint: 'order-refunds', data: requestData });
    if (response.resource) {
      const updatedOrder = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
      setOrder(updatedOrder);
      calculateOrderRefundTotals(updatedOrder);
      userFeedbackSuccess(`Successfully issued refund`);
    }
    setModalOpen(false);
    setRefundMode(false);
    setIssueRefundModalOpen(false);
    setRefundData({
      quantities: {},
      unitPrices: {},
      orderLines: {},
      shipping: 0,
      unassigned: 0,
      total: 0
    });
  }
  const handleConfirmRefundClick = (fulfilment) => {
    setCallbackData({ fulfilment });
    setIssueRefundModalOpen(true);
  };
  /* END: Refund logic */

  const handleDownloadOrderInvoice = async () => {
    const response = await requestApi.getResponse({
      url: `orders/${order.id}/invoice`,
      rawResponse: true,
    });
    const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
    if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    return downloadLink.click();
  }

  const handleDownloadOrderPackingSlip = async () => {
    const response = await requestApi.getResponse({
      url: `orders/${order.id}/packing-slip`,
      rawResponse: true,
    });
    const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
    if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    return downloadLink.click();
  }

  const refreshOrder = async () => {
    const resource = await resourceApi.getResource({ apiEndpoint: 'orders', id: order.id });
    setOrder(resource);
  }

  const onEditModalClose = () => {
    setEditModalState({
      open: false,
      propertyKey: null,
    });
    refreshOrder();
  }

  // eslint-disable-next-line no-shadow
  const orderNoteStories = [];
  order.orderNotes.forEach((orderNote) => {
    orderNoteStories.push({
      description: orderNote.content,
      title:
        renderToStaticMarkup(<>
          {getDateLocaleString(orderNote.createdAt)}
          {
            orderNote.createdByNameLegacy &&
            <>
              &nbsp;by {orderNote.createdByNameLegacy}
            </>
          }
          {
            orderNote.createdByUser &&
            <>
              &nbsp;by {getUserName(orderNote.createdByUser)}
            </>
          }
        </>),
      dateTime: '',
      color: 'dark',
      icon: 'comment',
      key: `order-note-${orderNote.id}`,
    });
  });

  const orderEmail = order.email ?? 'N/A';
  const userFullName = order.user ? getUserName(order.user) : 'N/A';
  const userEmail = order?.user?.email ?? 'N/A';

  const billingAddressParts = [];
  billingAddressParts.push(`${order.billingAddress.firstName} ${order.billingAddress.lastName}`);
  if (order.billingAddress.line1) billingAddressParts.push(order.billingAddress.line1);
  if (order.billingAddress.line2) billingAddressParts.push(order.billingAddress.line2);
  if (order.billingAddress.line3) billingAddressParts.push(order.billingAddress.line3);
  if (order.billingAddress.townCity) billingAddressParts.push(order.billingAddress.townCity);
  if (order.billingAddress.county) billingAddressParts.push(order.billingAddress.county);
  if (order.billingAddress.postcode) billingAddressParts.push(order.billingAddress.postcode);
  if (order.billingAddress.country) billingAddressParts.push(order.billingAddress.country);
  const billingAddressLine2Parts = [];
  if (order.billingAddress.email) billingAddressLine2Parts.push(order.billingAddress.email);
  if (order.billingAddress.phone) billingAddressLine2Parts.push(order.billingAddress.phone);
  if (order.billingAddress.company) billingAddressLine2Parts.push(order.billingAddress.company);

  const deliveryAddressParts = [];
  deliveryAddressParts.push(`${order.deliveryAddress.firstName} ${order.deliveryAddress.lastName}`);
  if (order.deliveryAddress.line1) deliveryAddressParts.push(order.deliveryAddress.line1);
  if (order.deliveryAddress.line2) deliveryAddressParts.push(order.deliveryAddress.line2);
  if (order.deliveryAddress.line3) deliveryAddressParts.push(order.deliveryAddress.line3);
  if (order.deliveryAddress.townCity) deliveryAddressParts.push(order.deliveryAddress.townCity);
  if (order.deliveryAddress.county) deliveryAddressParts.push(order.deliveryAddress.county);
  if (order.deliveryAddress.postcode) deliveryAddressParts.push(order.deliveryAddress.postcode);
  if (order.deliveryAddress.country) deliveryAddressParts.push(order.deliveryAddress.country);
  const deliveryAddressLine2Parts = [];
  if (order.deliveryAddress.email) deliveryAddressLine2Parts.push(order.deliveryAddress.email);
  if (order.deliveryAddress.phone) deliveryAddressLine2Parts.push(order.deliveryAddress.phone);
  if (order.deliveryAddress.company) deliveryAddressLine2Parts.push(order.deliveryAddress.company);

  const orderDetailsTableData = [];
  orderDetailsTableData.push([
    <MDTypography variant="subtitle2">
      <h4>Customer</h4>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {userFullName} / {userEmail}
        </Grid>
        <Grid item xs={6} align="right">
          <>
            &nbsp;
          </>
          <>
            {
              context === 'orders' && userHasRole('ROLE_USER_ADMIN') &&
              <>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => navigate(`/admin/users/edit/${order.user.id}`)}
                  variant="text"
                  iconOnly
                >
                  <EditIcon />
                </MDButton>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => window.open(`/admin/users/edit/${order.user.id}`, '_blank')}
                  variant="text"
                  iconOnly
                >
                  <OpenInNewIcon />
                </MDButton>
              </>
            }
          </>
        </Grid>
      </Grid>
    </MDTypography>
  ]);
  orderDetailsTableData.push([
    <MDTypography variant="subtitle2">
      <h4>Order Email</h4>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {orderEmail}
        </Grid>
        <Grid item xs={6} align="right">
          <>
            {
              order.buyerType && (
                <>
                  <MDBadge color="dark" badgeContent={order.buyerType} />
                  &nbsp;
                </>
              )
            }
          </>
        </Grid>
      </Grid>
    </MDTypography>
  ]);

  orderDetailsTableData.push([
    <MDTypography variant="subtitle2">
      <h4>Billing address</h4>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <>
            {billingAddressParts.join(', ')}<br />
            {billingAddressLine2Parts.join(', ')}
          </>
        </Grid>
        <Grid item xs={2} align="right">
          {
            context === 'orders' &&
            <MDButton
              color="dark"
              iconOnly
              onClick={() => { setEditModalState({ open: true, propertyKey: 'billingAddress' }) }}
              variant="text"
              iconOnly
            >
              <EditIcon />
            </MDButton>
          }
        </Grid>
      </Grid>
    </MDTypography>
  ]);
  orderDetailsTableData.push([
    <MDTypography variant="subtitle2">
      <h4>Delivery address</h4>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <>
            {deliveryAddressParts.join(', ')}<br />
            {deliveryAddressLine2Parts.join(', ')}
          </>
        </Grid>
        <Grid item xs={2} align="right">
          <>
            {
              context === 'orders' &&
              <MDButton
                color="dark"
                iconOnly
                onClick={() => { setEditModalState({ open: true, propertyKey: 'deliveryAddress' }) }}
                variant="text"
                iconOnly
              >
                <EditIcon />
              </MDButton>
            }
          </>
        </Grid>
      </Grid>
    </MDTypography>
  ]);
  if (order.customerNote) {
    orderDetailsTableData.push([
      // Type
      <h4>Customer note</h4>,
      // Data
      order.customerNote,
      // Icon
      <></>,
      // Action
      ''
    ]);
  }

  if (context === 'orders') {
    // Render any Memberships/TeamMemberships that were created by this Order
    let isLabelRendered = false;
    const teamMembershipIdsCreatedRendered = [];
    order.memberships?.forEach(membership => {
      // If the Membership is associated with a TeamMembership, provide a link to that
      let url = null;
      let badge = null;
      let teamMembershipName = null;
      if (membership.teamMembership) {
        url = `/admin/team-memberships/edit/${membership.teamMembership.id}`
        badge = 'Team';
        teamMembershipName = `${membership.teamMembership.name}`;
      } else {
        url = `/admin/memberships/edit/${membership.id}`
        badge = 'Individual';
      }
      let badgeColour = 'error'
      if (membership.status === 'current') {
        if (membership.isActive) {
          badgeColour = 'success'
        } else {
          badgeColour = 'warning'
        }
      }
      if (!membership.teamMembership || teamMembershipIdsCreatedRendered.indexOf(membership.teamMembership.id) === -1) {
        orderDetailsTableData.push([
          <>
            {!isLabelRendered && (
              <>
                <br />
                <h4>Memberships created</h4>
              </>
            )}
            <Grid container>
              <Grid item xs={8}>
                {membership.membershipPlan.name} - <strong>{teamMembershipName}</strong>
              </Grid>
              <Grid item xs={4} align="right">
                <>
                  {badge && <MDBadge color={badgeColour} badgeContent={badge} />}
                </>
                &nbsp;
                <>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => navigate(url)}
                    variant="text"
                    iconOnly
                  >
                    <EditIcon />
                  </MDButton>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => window.open(url, '_blank')}
                    variant="text"
                    iconOnly
                  >
                    <OpenInNewIcon />
                  </MDButton>
                </>
              </Grid>
            </Grid>
          </>
        ]);
        isLabelRendered = true;
        if (membership.teamMembership) {
          teamMembershipIdsCreatedRendered.push(membership.teamMembership.id);
        }
      }
    });
    // Render any TeamMemberships that may not have already been rendered
    order.teamMemberships?.forEach(teamMembership => {
      const url = `/admin/team-memberships/edit/${teamMembership.id}`
      const badge = 'Team';
      let badgeColour = 'error'
      if (teamMembership.status === 'current') {
        badgeColour = 'success'
      }
      if (teamMembershipIdsCreatedRendered.indexOf(teamMembership.id) === -1) {
        orderDetailsTableData.push([
          <>
            <h4>{!isLabelRendered && <>Memberships created</>}</h4>,
            <Grid container>
              <Grid item xs={8}>
                {teamMembership.membershipPlan.name} - <strong>{teamMembership.name}</strong>
              </Grid>
              <Grid item xs={4} align="right">
                <>
                  {badge && <MDBadge color={badgeColour} badgeContent={badge} />}
                </>
                &nbsp;
                <>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => navigate(url)}
                    variant="text"
                    iconOnly
                  >
                    <EditIcon />
                  </MDButton>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => window.open(url, '_blank')}
                    variant="text"
                    iconOnly
                  >
                    <OpenInNewIcon />
                  </MDButton>
                </>
              </Grid>
            </Grid>
          </>
        ]);
        isLabelRendered = true;
        teamMembershipIdsCreatedRendered.push(teamMembership.id);
      }
    });

    // Render any Memberships/TeamMemberships that were renewed by this Order
    isLabelRendered = false;
    const teamMembershipIdsRenewedRendered = [];
    // New style
    order.membershipRenewals?.forEach((membershipRenewal) => {
      const { membership } = membershipRenewal;
      const url = `/admin/memberships/edit/${membership.id}`
      const badge = 'Individual';
      let badgeColour = 'error'
      if (membership.status === 'current') {
        if (membership.isActive) {
          badgeColour = 'success'
        } else {
          badgeColour = 'warning'
        }
      }
      orderDetailsTableData.push([
        <>
          <h4>{!isLabelRendered && <>Memberships renewed</>}</h4>
          <Grid container>
            <Grid item xs={8}>
              {membership.membershipPlan.name}
            </Grid>
            <Grid item xs={4} align="right">
              <>
                {badge && <MDBadge color={badgeColour} badgeContent={badge} />}
              </>
              &nbsp;
              <>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => navigate(url)}
                  variant="text"
                  iconOnly
                >
                  <EditIcon />
                </MDButton>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => window.open(url, '_blank')}
                  variant="text"
                  iconOnly
                >
                  <OpenInNewIcon />
                </MDButton>
              </>
            </Grid>
          </Grid>
        </>
      ]);
      isLabelRendered = true;
    });
    order.teamMembershipRenewals?.forEach((teamMembershipRenewal) => {
      const { teamMembership } = teamMembershipRenewal;
      const url = `/admin/team-memberships/edit/${teamMembership.id}`
      let badgeColour = 'error'
      if (teamMembership.status === 'current') {
        badgeColour = 'success'
      }
      orderDetailsTableData.push([
        <>
          <h4>{!isLabelRendered && <>Memberships renewed</>}</h4>
          <Grid container>
            <Grid item xs={8}>
              {teamMembership.membershipPlan.name} - <strong>{teamMembership.name}</strong>
            </Grid>
            <Grid item xs={4} align="right">
              <>
                <MDBadge color={badgeColour} badgeContent="Team" />
              </>
              &nbsp;
              <>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => navigate(url)}
                  variant="text"
                  iconOnly
                >
                  <EditIcon />
                </MDButton>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => window.open(url, '_blank')}
                  variant="text"
                  iconOnly
                >
                  <OpenInNewIcon />
                </MDButton>
              </>
            </Grid>
          </Grid>
        </>
      ]);
      isLabelRendered = true;
    });
    // Old style
    order.membershipsRenewed?.forEach(membership => {
      // If the Membership is associated with a TeamMembership, provide a link to that
      let url = null;
      let badge = null;
      let teamMembershipName = null;
      if (membership.teamMembership) {
        url = `/admin/team-memberships/edit/${membership.teamMembership.id}`
        badge = 'Team';
        teamMembershipName = `${membership.teamMembership.name}`;
      } else {
        url = `/admin/memberships/edit/${membership.id}`
        badge = 'Individual';
      }
      let badgeColour = 'error'
      if (membership.status === 'current') {
        if (membership.isActive) {
          badgeColour = 'success'
        } else {
          badgeColour = 'warning'
        }
      }
      if (!membership.teamMembership || teamMembershipIdsRenewedRendered.indexOf(membership.teamMembership.id) === -1) {
        orderDetailsTableData.push([
          <>
            <h4>{!isLabelRendered && <>Memberships renewed</>}</h4>
            <Grid container>
              <Grid item xs={8}>
                {membership.membershipPlan.name} - <strong>{teamMembershipName}</strong>
              </Grid>
              <Grid item xs={4} align="right">
                <>
                  {badge && <MDBadge color={badgeColour} badgeContent={badge} />}
                </>
                &nbsp;
                <>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => navigate(url)}
                    variant="text"
                    iconOnly
                  >
                    <EditIcon />
                  </MDButton>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => window.open(url, '_blank')}
                    variant="text"
                    iconOnly
                  >
                    <OpenInNewIcon />
                  </MDButton>
                </>
              </Grid>
            </Grid>
          </>
        ]);
        isLabelRendered = true;
        if (membership.teamMembership) {
          teamMembershipIdsRenewedRendered.push(membership.teamMembership.id);
        }
      }
    });
    // Render any TeamMemberships that may not have already been rendered
    order.teamMembershipsRenewed?.forEach(teamMembership => {
      const url = `/admin/team-memberships/edit/${teamMembership.id}`
      let badgeColour = 'error'
      if (teamMembership.status === 'current') {
        badgeColour = 'success'
      }
      if (teamMembershipIdsRenewedRendered.indexOf(teamMembership.id) === -1) {
        orderDetailsTableData.push([
          <>
            <h4>{!isLabelRendered && <>Memberships renewed</>}</h4>
            <Grid container>
              <Grid item xs={8}>
                {teamMembership.membershipPlan.name} - <strong>{teamMembership.name}</strong>
              </Grid>
              <Grid item xs={4} align="right">
                <>
                  <MDBadge color={badgeColour} badgeContent="Team" />
                </>
                &nbsp;
                <>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => navigate(url)}
                    variant="text"
                    iconOnly
                  >
                    <EditIcon />
                  </MDButton>
                  <MDButton
                    color="dark"
                    iconOnly
                    onClick={() => window.open(url, '_blank')}
                    variant="text"
                    iconOnly
                  >
                    <OpenInNewIcon />
                  </MDButton>
                </>
              </Grid>
            </Grid>
          </>
        ]);
        isLabelRendered = true;
        teamMembershipIdsRenewedRendered.push(teamMembership.id);
      }
    });

    // Render any active Memberships for the User that created the Order
    isLabelRendered = false;
    user.memberships.forEach((membership) => {
      // If the Membership is associated with a TeamMembership, provide a link to that
      let url = null;
      let badge = null;
      let teamMembershipName = null;
      if (membership.teamMembership) {
        url = `/admin/team-memberships/edit/${membership.teamMembership.id}`
        badge = 'Team';
        teamMembershipName = `${membership.teamMembership.name}`;
      } else {
        url = `/admin/memberships/edit/${membership.id}`
        badge = 'Individual';
      }
      let badgeColour = 'error'
      if (membership.status === 'current') {
        if (membership.isActive) {
          badgeColour = 'success'
        } else {
          badgeColour = 'warning'
        }
      }
      // Only render current active Memberships
      if (membership.status === 'current' && membership.isActive) {
        orderDetailsTableData.push([
          <>
            {!isLabelRendered && (
              <>
                <br />
                <h4>Active Memberships</h4>
              </>
            )}
            <Grid container>
              <Grid item xs={8}>
                {membership.membershipPlan.name} - <strong>{teamMembershipName}</strong>
              </Grid>
              <Grid item xs={4} align="right">
                {badge && <MDBadge color={badgeColour} badgeContent={badge} />}
                &nbsp;
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => navigate(url)}
                  variant="text"
                  iconOnly
                >
                  <EditIcon />
                </MDButton>
                <MDButton
                  color="dark"
                  iconOnly
                  onClick={() => window.open(url, '_blank')}
                  variant="text"
                  iconOnly
                >
                  <OpenInNewIcon />
                </MDButton>
              </Grid>
            </Grid>
          </>
        ]);
        isLabelRendered = true;
      }
    });
  }

  const renderOrderTable = () => (
    <MDTypography variant="subtitle2">
      <Grid container py={2} style={{ borderBottom: '1px solid #dee2e6' }} alignItems="flex-start">
        <Grid item xs={5} align="left"><strong>Product</strong></Grid>
        <Grid item xs={3} align="left"><strong>Price</strong></Grid>
        <Grid item xs={2} align="left"><strong>Quantity</strong></Grid>
        <Grid item xs={2} align="right"><strong>Total</strong></Grid>
      </Grid>
      {
        order.orderLines.map((orderLine, i) => (
          <Grid container spacing={1} key={`order_grid_${i}`} py={2} style={{ borderBottom: '1px solid #dee2e6' }} alignItems="top">
            <Grid item xs={5}>
              <h4>{orderLine.orderItem.name}</h4>
              <Grid item spacing={2}>
                {orderLine.orderItem.sku}
                {
                  orderLine.orderItem.attributes.map((attribute, index) => {
                    return (
                      <Fragment key={`fragment-${index}`}>
                        <br />
                        {attribute.name}: {attribute.value}
                      </Fragment>
                    );
                  })
                }
                {
                  // New orderData format
                  Array.isArray(orderLine.orderData) &&
                  orderLine.orderData.map((orderDataElement, index) => {
                    return (
                      <Fragment key={`fragment-${index}`}>
                        <br />
                        {orderDataElement.label}: {orderDataElement.value}
                      </Fragment>
                    );
                  })
                }
                {
                  // Old orderData format - remove when Order data is clean
                  !Array.isArray(orderLine.orderData) &&
                  Object.keys(orderLine.orderData).map(orderDataAttribute => {
                    return (
                      <>
                        <br />
                        {orderDataAttribute}: {orderLine.orderData[orderDataAttribute]}
                      </>
                    );
                  })
                }
                {
                  refundMode && (
                    <MDTypography variant="h6" color="warning">
                      £{convertPriceToPounds(orderLine.linePrice.incTax)} inc VAT after discounts applied
                    </MDTypography>
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={3} align="left">
              £{convertPriceToPounds(orderLine.prices.price.excTax)}
            </Grid>
            <Grid item xs={2} align="left">
              {
                (orderLine.quantityCancelled > 0)
                  ?
                  <>
                    <strike>{orderLine.quantity}</strike>
                    <Tooltip title={`${orderLine.quantityCancelled} refunded`}>
                      <div className={componentStyles['quantity-cancelled']}>
                        <UndoIcon style={{ fontSize: 14 }} />{orderLine.quantityCancelled}
                      </div>
                    </Tooltip>
                    {orderLine.quantity - orderLine.quantityCancelled}
                  </>
                  :
                  <>{orderLine.quantity}</>
              }
              {
                refundMode &&
                <>
                  <br />
                  <OutlinedInput
                    sx={{ minWidth: 60 }}
                    type="number"
                    className={componentStyles['refund-quantity']}
                    onChange={(e) => handleChangeRefundQuantity(orderLine, e.target.value)}
                    value={orderLine.orderItem.sku in refundData.quantities ? refundData.quantities[orderLine.orderItem.sku] : 0}
                  />
                </>
              }
            </Grid>
            <Grid item xs={2} align="right">
              <>
                £{convertPriceToPounds(orderLine.linePriceBeforeDiscount.excTax)}
                {
                  refundMode &&
                  <>
                    <br />
                    <OutlinedInput
                      type="number"
                      className={componentStyles['refund-order-line']}
                      onChange={(e) => handleChangeRefundOrderLine(orderLine, e.target.value)}
                      value={orderLine.orderItem.sku in refundData.orderLines ? refundData.orderLines[orderLine.orderItem.sku] : ''}
                    />
                  </>
                }
              </>
            </Grid>
          </Grid>
        ))
      }

      {
        order.promotions?.length > 0 && (
          <Grid container spacing={1} key={`discounts_applied`} py={2} style={{ borderBottom: '1px solid #dee2e6' }} alignItems="center">
            <Grid item xs={12}>
              <h4>Discounts applied</h4>
              <Grid item spacing={2}>
                {
                  order.promotions.map((promotion) => (
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item xs={8} align="left">
                        {promotion.promotionCode == 'retrospective-credit-system' ?
                          'Credit from unused subscriptions ' :
                          `${promotion.promotionName} `}
                      </Grid>
                      <Grid item xs={4} align="right">
                        -£{convertPriceToPounds(promotion.discountPrice.excTax)}
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
        )
      }

      {
        orderRefundTotals.unassignedAmount > 0 && (
          <Grid container>
            <Grid item xs={4}>
              Other amount refunded
            </Grid>
            <Grid item xs={8}>
              <Tooltip title={`£${convertPriceToPounds(orderRefundTotals.unassignedAmount)} refunded`}>
                <div className={componentStyles['quantity-cancelled']}>
                  <UndoIcon style={{ fontSize: 14 }} />£{convertPriceToPounds(orderRefundTotals.unassignedAmount)}
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
      {
        refundMode && (
          <>
            <br />
            <Grid container>
              <Grid item xs={8} align="right">
                Other refund amount (max £{convertPriceToPounds(orderRefundTotals.unassignedAmountMax)})
              </Grid>
              <Grid item xs={4} align="right">
                <OutlinedInput
                  type="number"
                  className={componentStyles['refund-unassigned']}
                  onChange={(e) => handleChangeRefundUnassigned(e.target.value)}
                  value={refundData.unassigned}
                />
              </Grid>
            </Grid>
          </>
        )
      }
      {
        !refundMode && (
          <>
            <br />
            <Grid container>
              <Grid item xs={8} align="right">
                Subtotal
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(order.totalExcTaxBeforeDiscount - order.shippingTotalAfterDiscount)}
              </Grid>
            </Grid>
            {
              order.totalIncTaxBeforeDiscount - order.totalIncTaxAfterDiscount > 0 && (
                <Grid container>
                  <Grid item xs={8} align="right">
                    Discount {(order.promotions?.map((promo) => `${promo.promotionCodeCode ?? ' '} `)) ?? ''}
                  </Grid>
                  <Grid item xs={4} align="right">
                    -£{convertPriceToPounds(order.totalExcTaxBeforeDiscount - order.totalExcTaxAfterDiscount)}
                  </Grid>
                </Grid>
              )
            }
          </>
        )
      }
      <Grid container sx={{ my: 2 }}>
        <Grid item xs={8} align="right">
          Shipping<br />{order.shippingServiceName}
          {
            order.additionalShippingDescription !== null && (
              <>
                {` (Includes additional shipping: ${order.additionalShippingDescription})`}
              </>
            )
          }
        </Grid>
        <Grid item xs={4} align="right">
          £{convertPriceToPounds(order.shippingTotalAfterDiscount)}
          {
            orderRefundTotals.shippingAmount > 0 && (
              <Tooltip title={`£${convertPriceToPounds(orderRefundTotals.shippingAmount)} refunded`}>
                <div className={componentStyles['quantity-cancelled']}>
                  <UndoIcon style={{ fontSize: 14 }} />£{convertPriceToPounds(orderRefundTotals.shippingAmount)}
                </div>
              </Tooltip>
            )
          }
          {
            refundMode && (
              <>
                <br />
                <OutlinedInput
                  type="number"
                  className={componentStyles['refund-shipping']}
                  onChange={(e) => handleChangeRefundShipping(e.target.value)}
                  value={refundData.shipping}
                />
              </>
            )
          }
        </Grid>
      </Grid>
      {
        !refundMode && (
          <>
            <Grid container sx={{ my: 2, mt: 1 }}>
              <Grid item xs={8} align="right">
                <Tooltip title="Subtotal - Discount + Shipping" disableInteractive>
                  <strong>Total Excl. Tax</strong>
                </Tooltip>
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(order.totalExcTaxAfterDiscount)}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={8} align="right">
                VAT
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(order.totalIncTaxAfterDiscount - order.totalExcTaxAfterDiscount)}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={8} align="right">
                <Tooltip title="(Subtotal - Discount + Shipping) + Tax" disableInteractive>
                  <strong>Total Incl. Tax</strong>
                </Tooltip>
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(order.totalIncTaxAfterDiscount)}
              </Grid>
            </Grid>
            {
              orderRefundTotals.amount > 0 && (
                <>
                  <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={8} align="right">
                      Refunded
                    </Grid>
                    <Grid item xs={4} align="right">
                      <strong>£{convertPriceToPounds(orderRefundTotals.amount)}</strong>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={8} align="right">
                      <strong>Net total</strong>
                    </Grid>
                    <Grid item xs={4} align="right">
                      <strong>£{convertPriceToPounds(order.totalIncTaxAfterDiscount - orderRefundTotals.amount)}</strong>
                    </Grid>
                  </Grid>
                </>
              )
            }
          </>
        )
      }
      <Box sx={{ maxHeight: '20rem', overflow: 'auto', pt: '1rem', mt: '1rem', borderTop: '1px solid #dee2e6' }}>
        {order.orderRefunds.length > 0 && (<h4>Refund history:</h4>)}
        {order.orderRefunds.map((orderRefund, i) => (
          <Grid container key={`order_refund_${i}`} sx={{ my: 2 }}>
            <Grid item xs={1}>
              <MDBadge
                color="warning"
                size="sm"
                badgeContent={<UndoIcon fontSize="medium" />}
              />
            </Grid>
            <Grid item xs={7}>
              {orderRefund.fulfilment === 'manual' && 'Manual refund'}
              {orderRefund.fulfilment === 'payment_gateway' && 'Refund via payment gateway'}
              {orderRefund.fulfilment !== 'manual' && orderRefund.fulfilment !== 'payment_gateway' && 'Refund'}
              &nbsp;
              {orderRefund.legacyId}
              {getDateLocaleString(orderRefund.createdAt)}
              {orderRefund.createdByUser && <> by {getUserName(orderRefund.createdByUser)}</>}
              {orderRefund.reason && <p>Refund note: {orderRefund.reason}</p>}
              {
                orderRefund.refundStatus === 'completed' &&
                <p>
                  Refund successful&nbsp;
                  <a target="_blank" rel="noreferrer"
                    href={`https://www.braintreegateway.com/merchants/pvqg7n5qgz8qvqpc/transactions/${orderRefund.refundReference}`}>
                    ({orderRefund.refundReference})
                  </a>
                </p>
              }
              {orderRefund.refundStatus === 'failed' && <p>Refund failed - please process manually</p>}
            </Grid>
            <Grid item xs={4} align="right">
              -£{convertPriceToPounds(orderRefund.amount)}
            </Grid>
          </Grid>
        ))
        }
      </Box>
      {
        refundMode && (
          <>
            <br />
            <Grid container>
              <Grid item xs={8} align="right">
                Amount already refunded
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(orderRefundTotals.amount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} align="right">
                Total available to refund
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(order.totalIncTaxAfterDiscount - orderRefundTotals.amount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} align="right">
                Refund amount
              </Grid>
              <Grid item xs={4} align="right">
                £{convertPriceToPounds(refundData.total)}
              </Grid>
            </Grid>
            <br />
          </>
        )
      }
      {
        order.status !== 'cancelled' && !refundMode &&
        <MDButton
          color="dark"
          onClick={handleRefundClick}
          variant="outlined"
        >
          Issue refund
        </MDButton>
      }
      {
        refundMode && !isOrderRefundValid && (
          <strong>The total refund cannot be more than the order total</strong>
        )
      }
      {
        refundMode && isOrderRefundValid &&
        <>
          {
            order.paymentMethod === 'card' && (
              <>
                <MDButton
                  color="success"
                  disabled={!refundData.total}
                  onClick={() => handleConfirmRefundClick('payment_gateway')}
                  variant="outlined"
                >
                  Refund £{convertPriceToPounds(refundData.total)} via card
                </MDButton>
                &nbsp;
              </>
            )
          }
          {
            order.paymentMethod === 'paypal' && (
              <>
                <MDButton
                  color="success"
                  disabled={!refundData.total}
                  onClick={() => handleConfirmRefundClick('payment_gateway')}
                  variant="outlined"
                >
                  Refund £{convertPriceToPounds(refundData.total)} via PayPal
                </MDButton>
                &nbsp;
              </>
            )
          }
          <MDButton
            color="success"
            onClick={() => handleConfirmRefundClick('manual')}
            variant="outlined"
          >
            Refund £{convertPriceToPounds(refundData.total)} manually
          </MDButton>
          &nbsp;
          <MDButton
            color="error"
            onClick={handleCancelRefundClick}
            variant="outlined"
          >
            Cancel
          </MDButton>
        </>
      }
    </MDTypography>
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <Form formik={formik}>
              <Grid
                container
                mt={-2}
                spacing={4}
              >
                {/* Order summary */}
                <Grid
                  item
                  xs={12}
                >
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={8}
                    >
                      <MDTypography variant="h3">
                        Order #{order.reference}: <MDTypography variant="span" color={orderStatusColour}>{order.status.replace('_', ' ').toUpperCase()}</MDTypography>
                      </MDTypography>
                      <MDTypography variant="subtitle2">Order placed {getDateLocaleString(order.createdAt) || 'N/A'}</MDTypography>

                      <MDTypography variant="subtitle2">
                        {
                          order.paymentStatus === 'unpaid' &&
                          <>
                            Awaiting payment via {order.paymentMethod}
                          </>
                        }
                        {
                          order.paymentStatus === 'expected' &&
                          <>
                            Payment expected via {order.paymentMethod}
                          </>
                        }
                        {
                          order.paymentStatus === 'paid' &&
                          <>
                            Payment via {order.paymentMethod}&nbsp;
                            {
                              order.paymentReference &&
                              <>
                                <a target="_blank" rel="noreferrer"
                                  href={`https://www.braintreegateway.com/merchants/pvqg7n5qgz8qvqpc/transactions/${order.paymentReference}`}>
                                  ({order.paymentReference})
                                </a>
                                &nbsp;
                              </>
                            }
                            on {getDateLocaleString(order.paidAt) || 'N/A'}
                          </>
                        }
                      </MDTypography>
                    </Grid>
                    <Grid item align="right" xs={4}>
                      {
                        context === 'orders' && order.paymentStatus === 'unpaid' && (
                          <MDButton
                            color="success"
                            onClick={handleMarkAsPaymentExpected}
                            variant="outlined"
                          >
                            Mark as &apos;payment expected&apos;
                          </MDButton>
                        )
                      }
                      {
                        context === 'orders' && (order.licencesCreated && order.licencesCreated.length) ? (
                          <MDButton
                            color="error"
                            onClick={handleRevokeLicences}
                            variant="outlined"
                          >
                            <RemoveIcon />&nbsp;
                            Revoke licences
                          </MDButton>
                        ) : null
                      }
                      {
                        context === 'orders' && order.status !== 'cancelled' && (
                          <MDButton
                            color="error"
                            onClick={handleCancel}
                            variant="outlined"
                            style={{ marginLeft: 4 }}
                          >
                            <DeleteIcon />&nbsp;
                            Cancel order
                          </MDButton>
                        )
                      }
                      {
                        order.status === 'processing' && (context === 'orders' || (context === 'warehouse' && showMarkAsCompletedButton)) && (
                          <>
                            &nbsp;
                            <MDButton
                              color="success"
                              onClick={handleMarkAsCompleted}
                              variant="outlined"
                            >
                              Mark as &apos;completed&apos;
                            </MDButton>
                          </>
                        )
                      }
                    </Grid>
                  </Grid>
                </Grid>

                {/* Left column */}
                <Grid
                  item
                  xs={8}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <Grid container sx={{ 'mb': 1 }}>
                            <Grid item xs={12}>
                              {orderDetailsTableData[0]}
                            </Grid>
                          </Grid>
                          <Grid container sx={{ 'mb': 1 }}>
                            <Grid item xs={12}>
                              {orderDetailsTableData[1]}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              {orderDetailsTableData[2]}
                            </Grid>
                            <Grid item xs={6}>
                              {orderDetailsTableData[3]}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <MDTypography variant="subtitle2">
                            <h4>Subscriptions created (C2)</h4>
                            <br />
                            {
                              order.licencesCreated?.map(licence => {
                                let badgeColour = 'error'
                                if (licence.status === 'current') {
                                  if (licence.isActive) {
                                    badgeColour = 'success'
                                  } else {
                                    badgeColour = 'warning'
                                  }
                                }
                                let url = `/admin/licences/edit/${licence.id}`;
                                let type = 'individual';
                                if (licence.ownedBy.type === 'SchoolLearningGroup') {
                                  url = `/admin/school-learning-groups/edit/${licence.ownedBy.id}`;
                                  type = 'school';
                                }
                                if (licence.ownedBy.type === 'CustomLearningGroup') {
                                  url = `/admin/custom-learning-groups/edit/${licence.ownedBy.id}`;
                                  type = 'custom';
                                }

                                return (
                                  <Grid container>
                                    <Grid item xs={9}>
                                      {licence.membershipPlan.name} for {licence.ownedBy.name} ({type})
                                    </Grid>
                                    <Grid item xs={3} align="right">
                                      <>
                                        <MDBadge color={badgeColour} badgeContent={licence.status} />
                                      </>
                                      &nbsp;
                                      <>
                                        <MDButton
                                          color="dark"
                                          iconOnly
                                          onClick={() => navigate(url)}
                                          variant="text"
                                          iconOnly
                                        >
                                          <EditIcon />
                                        </MDButton>
                                        <MDButton
                                          color="dark"
                                          iconOnly
                                          onClick={() => window.open(url, '_blank')}
                                          variant="text"
                                          iconOnly
                                        >
                                          <OpenInNewIcon />
                                        </MDButton>
                                      </>
                                    </Grid>
                                  </Grid>
                                );
                              })
                            }
                            {
                              order.licenceRenewals?.map(licenceRenewal => {
                                // An Order's LicenceRenewals do not exist for anything bought after C2, but
                                // historical MembershipRenewals/TeamMembershipRenewals have been migrated in this way
                                const licence = licenceRenewal.licence;
                                let badgeColour = 'error'
                                if (licence.status === 'current') {
                                  if (licence.isActive) {
                                    badgeColour = 'success'
                                  } else {
                                    badgeColour = 'warning'
                                  }
                                }
                                let url = `/admin/licences/edit/${licence.id}`;
                                let type = 'individual';
                                if (licence.ownedBy.type === 'SchoolLearningGroup') {
                                  url = `/admin/school-learning-groups/edit/${licence.ownedBy.id}`;
                                  type = 'school';
                                }
                                if (licence.ownedBy.type === 'CustomLearningGroup') {
                                  url = `/admin/custom-learning-groups/edit/${licence.ownedBy.id}`;
                                  type = 'custom';
                                }

                                return (
                                  <Grid container>
                                    <Grid item xs={9}>
                                      {licence.membershipPlan.name} for {licence.ownedBy.name} ({type} renewal)
                                    </Grid>
                                    <Grid item xs={3} align="right">
                                      <>
                                        <MDBadge color={badgeColour} badgeContent={licence.status} />
                                      </>
                                      &nbsp;
                                      <>
                                        <MDButton
                                          color="dark"
                                          iconOnly
                                          onClick={() => navigate(url)}
                                          variant="text"
                                          iconOnly
                                        >
                                          <EditIcon />
                                        </MDButton>
                                        <MDButton
                                          color="dark"
                                          iconOnly
                                          onClick={() => window.open(url, '_blank')}
                                          variant="text"
                                          iconOnly
                                        >
                                          <OpenInNewIcon />
                                        </MDButton>
                                      </>
                                    </Grid>
                                  </Grid>
                                );
                              })
                            }


                          </MDTypography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <MDTypography variant="subtitle2">
                            <h4>Memberships (pre-C2)</h4>
                            {orderDetailsTableData.slice(4, orderDetailsTableData.length).map((utd) => (
                              <>
                                {utd}
                              </>
                            ))}
                          </MDTypography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          {renderOrderTable()}
                        </CardContent>
                      </Card>
                    </Grid>

                  </Grid>
                </Grid>

                {/* Right column */}
                <Grid
                  item
                  xs={4}
                >
                  <Grid
                    container
                    spacing={4}
                  >

                    {/* Shipments / Returns */}
                    {context === 'warehouse' && (
                      <Grid
                        item
                        xs={12}
                      >
                        <Card>
                          <CardContent>
                            <MDTypography variant="subtitle2">
                              <h4>Shipments</h4>
                              <Box mt={2}>
                                <MDButton
                                  color="dark"
                                  onClick={() => setProductReturnsModalOpen(true)}
                                  variant="outlined"
                                >
                                  Product Returns
                                </MDButton>
                              </Box>
                              <OrderShipments
                                order={order}
                                {...{ handleMarkAsCompleted, handleMarkDespatchAsCompleted }}
                              />
                            </MDTypography>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Developer Tools */}
                    {userHasRole('ROLE_DEVELOPER') && (
                      <Grid
                        item
                        xs={12}
                      >
                        <Card>
                          <CardContent>
                            <MDTypography variant="subtitle2">
                              <h4>Developer Tools</h4>
                            </MDTypography>
                            <MDButton
                              disabled={(order.validStatusAmends ?? []).length === 0}
                              color="info"
                              onClick={handleAmendOrderStatus}
                              variant="outlined">
                              <InvoiceIcon />&nbsp;&nbsp; Amend Order Status
                            </MDButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                    }

                    {/* Documents */}
                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <MDTypography variant="subtitle2">
                            <h4>Order Printing</h4>
                            {
                              context === 'orders' && (
                                <Tooltip title="Download invoice">
                                  <MDButton
                                    color="info"
                                    onClick={handleDownloadOrderInvoice}
                                    variant="outlined"
                                    iconOnly
                                  >
                                    <InvoiceIcon />
                                  </MDButton>
                                </Tooltip>
                              )
                            }
                            &nbsp;
                            <Tooltip title="Download packing slip">
                              <MDButton
                                color="info"
                                onClick={handleDownloadOrderPackingSlip}
                                variant="outlined"
                                iconOnly
                              >
                                <LocalShippingIcon />
                              </MDButton>
                            </Tooltip>
                          </MDTypography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <MDTypography variant="subtitle2">
                            <LinkOrder
                              order={order}
                            />

                          </MDTypography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Invoice note */}
                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <MDTypography variant="subtitle2">
                            <h4>Invoice Notes</h4>
                            {order.invoiceNote ? <MDTypography variant="body2">{order.invoiceNote}</MDTypography> : ''}
                            {
                              context === 'orders'
                              &&
                              <>
                                <MDButton
                                  color="info"
                                  onClick={() => { setEditModalState({ open: true, propertyKey: 'invoiceNote' }) }}
                                  variant="outlined"
                                >
                                  <InvoiceIcon />
                                  {order.invoiceNote ? 'Edit' : 'Add'} invoice note
                                </MDButton>
                                <br />
                              </>
                            }
                          </MDTypography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Notes */}
                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardContent>
                          <TimelineList title="">
                            <Grid
                              container
                              mb={2}
                              spacing={1}
                            >
                              <Grid
                                item
                                xs={12}
                              >
                                <TextField
                                  color="white"
                                  fullWidth
                                  type="textarea"
                                  name="newOrderNoteContent"
                                  multiline
                                  label="Note"
                                  onChange={(e) => setNewOrderNoteContent(e.target.value)}
                                  value={newOrderNoteContent}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: 'right' }}
                              >
                                <MDButton
                                  color="info"
                                  size="small"
                                  onClick={handleAddNoteClick}
                                  variant="text"
                                >
                                  <CommentIcon />&nbsp;
                                  Add note
                                </MDButton>
                              </Grid>
                            </Grid>
                            {
                              orderNoteStories.map((orderNoteStory, index) => <TimelineItem key={`timeline-item-${index}`} {...orderNoteStory} />)
                            }
                          </TimelineList>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>

      <ConfirmModal
        body={<>{modalError ? <span style={{ display: 'block', color: 'red', padding: "4px" }}>{modalError}</span> : ''}{modalBody}</>}
        callbackData={callbackData}
        confirmCallback={modalConfirmCallback.current}
        hasNote
        open={modalOpen}
        modalConfirmOrderRef={modalConfirmOrderRef ? order.reference : null}
        setOpen={setModalOpen}
        title={modalTitle}
      />

      {
        context === 'orders'
        &&
        <Dialog
          open={editModalState.open}
          fullWidth
          maxWidth="md">
          <DialogContent>
            {editModalState.propertyKey === 'billingAddress' ?
              <EditBillingAddress resourceId={order.id} billingAddress={order.billingAddress} onClose={onEditModalClose} />
              : null}

            {editModalState.propertyKey === 'deliveryAddress' ?
              <EditDeliveryAddress resourceId={order.id} deliveryAddress={order.deliveryAddress} onClose={onEditModalClose} />
              : null}

            {editModalState.propertyKey === 'invoiceNote' ?
              <EditInvoiceNote resourceId={order.id} onClose={onEditModalClose} />
              : null}
          </DialogContent>
        </Dialog>
      }
      {
        context === 'warehouse'
        &&
        <Dialog
          open={productReturnsModalOpen}
          fullWidth
          maxWidth="xl">
          <DialogContent>
            <ProductReturns order={order} setModalOpen={setProductReturnsModalOpen} />
          </DialogContent>
        </Dialog>
      }
      {
        userHasRole('ROLE_DEVELOPER') &&
        <Dialog
          open={amendOrderStatusModalOpen}
          onClose={onAmendOrderStatusModalClose}
          fullWidth
          maxWidth="md">
          <DialogContent>
            <AddOrderStatusAmendment
              orderId={order.id}
              onModalClose={onAmendOrderStatusModalClose}
              validStatusAmends={order.validStatusAmends ?? []}
            />
          </DialogContent>
        </Dialog>
      }
      {issueRefundModalOpen &&
        <IssueRefundModal
          confirmCallback={handleConfirmRefund}
          callbackData={callbackData}
          onClose={() => setIssueRefundModalOpen(false)}
        />}
    </>
  );
};

EditOrderForm.propTypes = {
  context: PropTypes.string,
};

EditOrderForm.defaultProps = {
  context: 'orders',
};

export default EditOrderForm;
