import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';
import { saleOrderOverrideChoices } from './constants';

const apiEndpoint = 'iplicit-product-codes';
const resourceName = 'Iplicit product code';

const noOverrideChoice = {
  value: null,
  label: '--- No override ---',
};

const fields = [
  {
    name: 'product.sku',
    label: 'Product',
    type: 'static',
  },
  {
    name: 'nominalCode',
    label: 'Nominal code',
  },
  {
    name: 'departmentCode',
    label: 'Department code',
  },
  {
    name: 'saleOrderOverride',
    label: 'Sale order override',
    type: 'select',
    choices: [ noOverrideChoice, ...saleOrderOverrideChoices ],
  },
];

const validationSchema = Yup.object().shape({
});

const toApiValuesCustom = (formValues, fieldsToMap) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fieldsToMap);

  // Remove product from apiValues: this is read-only and should not be written
  delete(apiValues.product);

  return apiValues;
}

const AddEditIplicitProductCode = () => {
    const params = useParams();
    const id = params.id ? Number(params.id) : null;
  
    return (
      <AddEditResource
        apiEndpoint={apiEndpoint}
        fields={fields}
        id={id}
        resourceName={resourceName}
        toApiValuesCustom={toApiValuesCustom}
        validationSchema={validationSchema}
      />
    );
  };
  
  export default AddEditIplicitProductCode;
  