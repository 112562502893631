// MUI
import {
  Card,
  CardContent,
  Grid,
} from '@mui/material';

// MDPR
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import CardHeader from 'components/shared/CardHeader';

const Dashboard = () => (
  <Grid
    container
    mt={-2}
    spacing={4}
  >
    <Grid
      item
      xs={12}
    >
      <Card>
        <CardHeader color="info">
          Dashboard
        </CardHeader>
        <CardContent>
          <MDTypography variant="body2">
            Dashboard
          </MDTypography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default Dashboard;
