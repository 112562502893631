const specials = '!#@$%^&*)(+=._-';

const hasMixedCase = (password) => /[a-z]/.test(password) && /[A-Z]/.test(password);

const hasNumber = (password) => /[0-9]/.test(password);

const hasSpecial = (password) => /[{specials}]/.test(password);

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const generatePassword = () => {
  const lowercases = 'abcdefghijklmnopqrstuvwxyz';
  const uppercases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  const passwordParts = [];
  for (let i = 0; i < 9; i += 1) {
    passwordParts.push(lowercases[Math.floor(Math.random() * lowercases.length)]);
  }
  passwordParts.push(uppercases[Math.floor(Math.random() * uppercases.length)]);
  passwordParts.push(numbers[Math.floor(Math.random() * numbers.length)]);
  passwordParts.push(specials[Math.floor(Math.random() * specials.length)]);
  shuffleArray(passwordParts);

  return passwordParts.join('');
};

export const getPasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 8) strength += 1;
  if (password.length >= 12) strength += 1;
  if (hasMixedCase(password)) strength += 1;
  if (hasNumber(password)) strength += 1;
  if (hasSpecial(password)) strength += 1;

  if (strength >= 5) return { label: 'Very strong', color: 'green' };
  if (strength === 4) return { label: 'Strong', color: 'green' };
  if (strength === 3) return { label: 'Medium', color: 'yellow' };
  if (strength === 2) return { label: 'Weak', color: 'red' };

  // strength 1 or 0
  return { label: 'Very weak', color: 'red' };
};
