import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';
import AddEditMenuForm from './AddEditMenuForm';
import { mapMenuToTree, mapTreeNodeToMenuItem } from './menu-tree-mapper';

const apiEndpoint = 'menus';
const resourceName = 'menu';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Code',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  code: Yup.string()
    .required('Code is required'),
});

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  const menuItems = [];
  formValues.treeData?.forEach((treeNode, index) => {
    menuItems.push(mapTreeNodeToMenuItem(treeNode, index));
  });
  apiValues.menuItems = menuItems;

  return apiValues;
}

const AddEditMenu = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    // Perform standard mapping
    const formValues = toFormValues(resource, fields);

    if(id === null) {
      // eslint-disable-next-line no-param-reassign
      resource.menuItems = [];
    }

    formValues.treeData = mapMenuToTree(resource);

    return formValues;
  }

  return (
    <AddEditResource
      addEditForm={AddEditMenuForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditMenu;
