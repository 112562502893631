/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import MergeLearningGroupForm from './MergeLearningGroupForm';

export const MergeLearningGroupModal = ({ formRef, mergeRequestErrored, setMergeLearningGroupModal, onSubmitForm }) => (
  <Dialog open aria-labelledby="merge-learning-group" aria-describedby="merge-learning-group" fullWidth maxWidth="md">
    <DialogTitle id="onvert-learning-group-title">Merge group into another group</DialogTitle>
    <DialogContent>
      <MergeLearningGroupForm formRef={formRef} mergeRequestErrored={mergeRequestErrored} />
    </DialogContent>
    <DialogActions>
      <MDButton color="info" onClick={onSubmitForm} variant="gradient" data-testid="merge-group-modal-submit">
        Perform merge
      </MDButton>
      <MDButton
        color="secondary"
        onClick={() => setMergeLearningGroupModal(false)}
        variant="gradient"
        data-testid="merge-group-modal-cancel"
      >
        Cancel
      </MDButton>
    </DialogActions>
  </Dialog>
);

MergeLearningGroupModal.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  mergeRequestErrored: PropTypes.bool.isRequired,
  setMergeLearningGroupModal: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

export default MergeLearningGroupModal;
