import { requestApi } from 'api/request-api';
import jwtDecode from 'jwt-decode';

const changeLearningGroupContext = async (learningGroupIri, setUserProfile) => {
  const response = await requestApi.putResponse({
    url: 'learning-group-context',
    data: { learningGroup: learningGroupIri },
  });
  const decodedToken = jwtDecode(response.token);
  if (decodedToken) {
    setUserProfile(decodedToken);
    localStorage.setItem('accessToken', response.token);
    localStorage.setItem('refreshToken', response.refresh_token);
  }
};

export default changeLearningGroupContext;
