import jwtDecode from 'jwt-decode';
import {Navigate, Outlet } from 'react-router-dom';

import { adminSession } from '../constants';

const AuthGuard = () => {
  const session = localStorage.getItem(adminSession);
  if (session) {
    const decodedToken = jwtDecode(session);
    if (decodedToken) {
      const { username, is_guest_user: isGuestUser } = decodedToken;
      if (username !== '' && !isGuestUser) {
        return <Outlet />;
      }
    }
  }

  const loginState = {
    redirectAfterLogin: window.location.pathname + window.location.search
  };

  return <Navigate to="/auth/login" state={loginState} />;
};

export default AuthGuard;
