// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Menus';
const apiEndpoint = 'menus';
const resourceName = 'menu';

const columns = [
  {
    field: 'name',
    label: 'Name',
  },
  {
    field: 'code',
    label: 'Code'
  },
];

const filters = [];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const Menus = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default Menus;
