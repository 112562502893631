import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

// MUI
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { requestApi } from 'api/request-api';
import CardHeader from 'components/shared/CardHeader';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import StockItemsCollectionFormField from "./StockItemsCollectionFormField";

const getField = (fields, fieldName) => fields.filter((field) => field.name === fieldName)[0] ?? null;
const validateApiEndpoint = 'stock-changes/stock-count/validate';

const AddStockCountRequestForm = (props) => {
    const {
        cancelLocation,
        fields,
        formValues,
        getApiError,
        onFormSubmit,
        validationSchema,
    } = props;
    const [validatedStockCount, setValidatedStockCount] = React.useState([]);
    const [isValidating, setIsValidating] = React.useState(false);
    const [generateCsv, setGenerateCsv] = React.useState(false);

    const canBeSubmitted = (currentFormValuesItems) => currentFormValuesItems.every((el) => !(!el.product || !el.quantity))
    const validateStockCounts = async (currentFormValues) => {
        const data = {};
        if (!canBeSubmitted(currentFormValues.items)) {
            toast.error('Please make sure validated products have both the item and quantity set.');
            return;
        }
        setIsValidating(true);
        data.items = currentFormValues.items.map((el) => ({ ...el, quantity: parseInt(el.quantity, 10), notes: [el.notes] }));
        data.location = currentFormValues.location;
        data.notes = [currentFormValues.notes];
        try {
            const stockCountsWithDifferential = await requestApi.postResponse({url: validateApiEndpoint, data});
            setValidatedStockCount(stockCountsWithDifferential.stockCountValidateItems);
            if (generateCsv) {
                data.generateCsv = true;
                await requestApi.postResponse({url: validateApiEndpoint, data, rawResponse: true}).then((response) => {
                    const [, filename] = response.headers['content-disposition'].split('filename=');
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    toast.error('Error downloading file. If this problem persists, please reduce the number of records input.');
                    console.error('Error downloading file:', error);
                });
            }
        } catch (e) {
            toast.error('An error has occurred.');
            // eslint-disable-next-line no-console
            console.error({ e });
        } finally {
            setIsValidating(false);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={onFormSubmit}
            validateOnChange={false}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <Form formik={formik}>
                    <Card>
                        <CardHeader color="info" icon>
                            Stock counts
                        </CardHeader>
                        <CardContent>
                            <Box mb={2}>
                                <Typography fontSize="medium">
                                    Submit manual counts of products at a given location.
                                </Typography>
                            </Box>
                            <Grid container rowSpacing={1.5}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormField
                                                {...getField(fields, 'location')}
                                                formik={formik}
                                                getApiError={getApiError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <StockItemsCollectionFormField
                                        {...getField(fields, 'items')}
                                        formik={formik}
                                        getApiError={getApiError}
                                        setValidatedStockCount={setValidatedStockCount}
                                        validatedStockCount={validatedStockCount}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        {...getField(fields, 'notes')}
                                        formik={formik}
                                        getApiError={getApiError}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                            <SaveCancelButtons
                                cancelLocation={cancelLocation}
                                formik={formik}
                            />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={e => setGenerateCsv(e.target.checked)} />} label="Generate CSV?" />
                            </FormGroup>
                            <MDButton
                                color="warning"
                                variant="gradient"
                                type="button"
                                onClick={() => validateStockCounts(formik.values)}
                                disabled={isValidating}
                            >{isValidating ? <CircularProgress
                              style={{ height: '20px', width: '20px', color: 'white' }} /> : <>Validate</>}
                            </MDButton>
                        </CardActions>
                    </Card>
                </Form>
            )}
        </Formik>
    );
};

AddStockCountRequestForm.propTypes = {
    cancelLocation: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formValues: PropTypes.object.isRequired,
    getApiError: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    validationSchema: PropTypes.object,
};

AddStockCountRequestForm.defaultProps = {
    validationSchema: {},
};

export default AddStockCountRequestForm;
