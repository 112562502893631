import { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Grid, TextField,
} from '@mui/material';

// WRM
import { requestApi } from'api/request-api';
import Form from 'components/shared/Form';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';

const AddEditProductRelationshipForm = (props) => {
  const {
    formValues,
    onFormSubmit,
    onModalClose,
    validationSchema,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productChoices, setProductChoices] = useState([]);

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    // eslint-disable-next-line no-shadow
    const productChoices = await requestApi.getResponse({ url: `products/searchable-products-as-choices` });
    if (isMounted()) {
      setProductChoices(productChoices);
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    onChange={(event, choice) => { formik.setFieldValue('relatedProduct', choice.value); }}
                    options={productChoices}
                    renderInput={(params) => (
                      <TextField label="Product" {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                isModal
                onModalClose={onModalClose}
                formik={formik}
              />
            </CardActions>
          </Card>

        </Form>
      )}
    </Formik>
  );
};

AddEditProductRelationshipForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
}

export default AddEditProductRelationshipForm;