import { useEffect, useCallback, useState } from 'react';

// WRM
import { requestApi } from 'api/request-api';
import ListResources from 'components/shared/ListResources';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const title = 'Home learning pages';
const apiEndpoint = 'home-learning-pages';
const resourceName = 'home learning page';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    field: '_parentHomeLearningPageName',
    label: 'Parent page',
    formatter: (resource) => resource.parentHomeLearningPage?.name,
  },
  {
    field: 'uri',
    label: 'Link',
    sortable: true,
  },
];

const filters = [
  {
    field: '_homeLearningPageSearch',
    label: 'Search',
    type: 'text',
    apiAttribute: 'homeLearningPageSearch',
  },
  {
    field: 'parentPage',
    label: 'Parent page',
    type: 'select',
  },
  {
    field: '_topLevelOnly',
    label: 'Top level only',
    type: 'checkbox',
    apiAttribute: 'exists[parentPage]',
    apiValue: 'false'
  },
  {
    field: '_excludeArchived',
    label: 'Exclude archived',
    type: 'checkbox',
    apiAttribute: 'isArchived',
    apiValue: 'false',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const HomeLearningPages = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const homeLearningPageChoices = await requestApi.getResponse({ url: 'home-learning-pages/choices' });
      const noChoice = { value: '', label: '--- Remove filter ---' }
      const parentPageFilter = filters.find((filter) => filter.field === 'parentPage');
      parentPageFilter.choices = [noChoice, ...homeLearningPageChoices];
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultSortValues={defaultSortValues}
      filters={filters}
      resourceName={resourceName}
      title={title}
    />
  );
};

export default HomeLearningPages;
