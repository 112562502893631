import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import { metaDataFields } from 'constants';
import AddEditHomeLearningPageForm from './AddEditHomeLearningPageForm';

const apiEndpoint = 'home-learning-pages';
const resourceName = 'home learning page';

const visibilityChoices = [
  {
    value: 'public',
    label: 'Public: visible to everyone',
  },
  {
    value: 'private',
    label: 'Private: only visible to site admins',
  },
];

const fields = [
  metaDataFields,
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'slug',
    label: 'Slug'
  },
  {
    name: 'parentHomeLearningPage',
    label: 'Parent',
    type: 'select',
  },
  {
    name: 'visibility',
    label: 'Visibility',
    type: 'select',
    choices: visibilityChoices,
  },
  {
    name: 'displayOrder',
    label: 'Display order',
    type: 'number',
  },
  {
    name: 'isArchived',
    label: 'Archived',
    type: 'checkbox',
  },
  {
    name: 'departments',
    label: 'Page departments',
    type: 'selectAsTable',
    choices: [],
    defaultValue: [],
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // visibility is an array in the data, map it to a scalar, default public
  formValues.visibility = resource.visibility[0] ?? 'public';

  // Additional mapping for pageBlocks
  formValues.pageBlocks = [];
  resource.pageBlocks?.forEach(pageBlock => {
    const pageBlockFormValues = {};
    // Set @id, pageBlockType and identifier form values for the PageBlock
    pageBlockFormValues['@id'] = pageBlock['@id'];
    pageBlockFormValues.pageBlockType = pageBlock.pageBlockType['@id'];
    pageBlockFormValues.identifier = pageBlock.identifier;
    // Set the content form values for the PageBlock
    // If content is empty, it arrives here as an empty array - we need to cast this into an object
    if (Object.prototype.toString.call(pageBlock.content) === '[object Array]') {
      pageBlockFormValues.content = {};
    } else {
      pageBlockFormValues.content = pageBlock.content;
    }
    formValues.pageBlocks.push(pageBlockFormValues);
  })

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // visibility is a scalar in the data, map it to an array, default public
  apiValues.visibility = formValues.visibility ? [formValues.visibility] : ['public'];

  // Additional mapping for pageBlocks
  apiValues.pageBlocks = [];
  formValues.pageBlocks.forEach(pageBlock => {
    const pageBlockApiValues = {};
    // Set @id, pageBlockType and identifier API values for the PageBlock
    pageBlockApiValues['@id'] = pageBlock['@id'];
    pageBlockApiValues.pageBlockType = pageBlock.pageBlockType;
    pageBlockApiValues.identifier = pageBlock.identifier;
    // Set the content API values for the PageBlock
    pageBlockApiValues.content = pageBlock.content;
    apiValues.pageBlocks.push(pageBlockApiValues);
  });

  if (apiValues.metaData['@id'] === "") {
    delete apiValues.metaData['@id'];
  }

  return apiValues;
};

const AddEditHomeLearningPage = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const departmentChoices = await requestApi.getResponse({ url: 'departments/choices' });
      const departmentsField = fields.find((field) => field.name === 'departments');
      departmentsField.choices = departmentChoices;
      const homeLearningPageChoices = await requestApi.getResponse({ url: 'home-learning-pages/choices' });
      const noParentChoice = { value: 'none', label: 'No parent' }
      const parentHomeLearningPageField = fields.find((field) => field.name === 'parentHomeLearningPage');
      parentHomeLearningPageField.choices = [noParentChoice, ...homeLearningPageChoices];
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditHomeLearningPageForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditHomeLearningPage;
