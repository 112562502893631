import { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Alert from '@mui/lab/Alert';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { requestApi } from 'api/request-api';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const useComponentStyles = makeStyles((theme) => ({
  productOrProductVariantSku: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    width: '20%',
  },
  productOrProductVariantName: {
    fontSize: theme.typography.pxToRem(15),
  },
  productBundlePartCount: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'right'
  },
  searchTerm: {
    flex: 1,
    float: 'right',
    marginTop: '-30px',
  }
}));

const ProductBundles = () => {
  const [initialised, setInitialised] = useState(false);
  const [productBundlesData, setProductBundlesData] = useState();
  const [productBundleWarnings, setProductBundleWarnings] = useState();

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const componentClasses = useComponentStyles();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      // eslint-disable-next-line no-shadow
      const productBundlesData = await requestApi.getResponse({
        url: 'product-bundle-parts/summary',
      });
      setProductBundlesData(productBundlesData);
      // eslint-disable-next-line no-shadow
      const productBundleWarnings = await requestApi.getResponse({
        url: 'product-bundle-parts/warnings',
      });
      setProductBundleWarnings(productBundleWarnings);
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const handleSearchTermOnChange = (e) => {
    // Use CSS rather than rerendering
    const searchTerm = e.target.value;
    const filteredProductBundlesData = productBundlesData.filter((productBundleData) => productBundleData.productOrProductVariantSku.toUpperCase().includes(searchTerm.toUpperCase()));
    document.querySelectorAll('.product-bundle').forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.display = 'none';
    });
    filteredProductBundlesData.forEach((filteredProductBundleData) => {
      document.querySelector(`#div-product-or-product-variant-sku-${filteredProductBundleData.productOrProductVariantSku}`).style.display = 'block';
    })
  }

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <MDTypography variant="h5">Product bundles</MDTypography>
        <MDBox display="flex">
          <MDBox ml={1}>
            <div className={componentClasses.searchTerm}>
              <TextField
                label="Bundle SKU"
                name="searchTerm"
                onChange={handleSearchTermOnChange}
                variant="outlined"
              />
            </div>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>
        {
          productBundleWarnings.length > 0 &&
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="error">
                <ul>
                  {
                    productBundleWarnings.map(productBundleWarning => <li>{productBundleWarning}</li>)
                  }
                </ul>
              </Alert>
            </Grid>
          </Grid>
        }
        {
          productBundlesData.map((productBundleData) => {
            const { productOrProductVariantSku, productOrProductVariantName, productBundleParts } = productBundleData;
            const productBundlePartCount = Object.keys(productBundleParts).length;
            return (
              <div
                className="product-bundle"
                id={`div-product-or-product-variant-sku-${productOrProductVariantSku}`}
              >
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={componentClasses.productOrProductVariantSku}>{productOrProductVariantSku}</Typography>
                    <Typography className={componentClasses.productOrProductVariantName}>{productOrProductVariantName}</Typography>
                    <Typography className={componentClasses.productBundlePartCount}>
                      {productBundlePartCount} product{productBundlePartCount > 1 && 's'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table striped>
                      <TableHead>
                        <TableRow>
                          <TableCell>Child SKU</TableCell>
                          <TableCell>Child name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Shipment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          Object.keys(productBundleParts).map((partProductOrProductVariantSku) => {
                            const productBundlePart = productBundleParts[partProductOrProductVariantSku];
                            return (
                              <TableRow>
                                <TableCell>{partProductOrProductVariantSku}</TableCell>
                                <TableCell>{productBundlePart.productName}</TableCell>
                                <TableCell>{productBundlePart.quantity}</TableCell>
                                <TableCell>{productBundlePart.shipment?.toUpperCase()}</TableCell>
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        }
      </Card>
    </MDBox>
  );
}

export default ProductBundles;
