import React, {useCallback, useEffect, useState} from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { requestApi } from 'api/request-api';
import FormField from 'components/shared/FormField';
import useMounted from 'hooks/use-mounted';
import componentStyles from './ProductCategoryConditionsHelper.module.scss';

const ProductCategoryConditionsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productCategoryChoices, setProductCategoryChoices] = useState([]);

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    // eslint-disable-next-line no-shadow
    const productCategoryChoices = await requestApi.getResponse({ url: 'product-categories/choices?exists[parentProductCategory]=false&slugAsValue=1' });
    if (isMounted()) {
      setProductCategoryChoices(productCategoryChoices);
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedProductCategoryConditions = [];
    const movedProductCategoryCondition = formik.values.productCategoryConditionsHelper[result.source.index];
    formik.values.productCategoryConditionsHelper.forEach((productCategoryCondition, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedProductCategoryConditions.push(movedProductCategoryCondition);
      }
      if (index !== result.source.index) {
        reorderedProductCategoryConditions.push(productCategoryCondition);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedProductCategoryConditions.push(movedProductCategoryCondition);
      }
    });
    formik.setFieldValue('productCategoryConditionsHelper', reorderedProductCategoryConditions);
  };

  return (
    <FieldArray name="productCategoryConditionsHelper" render={fieldArrayHelpers => (
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <h4>Product category conditions (match any)</h4>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MDButton
              className={componentStyles['add-product-category-condition-button']}
              color="info"
              onClick={() => {
                const prototype = {};
                prototype.productCategoryCondition = '';
                prototype.uuid = moment().valueOf();
                fieldArrayHelpers.push(prototype);
              }}
              size="small"
              startIcon={<AddIcon/>}
              variant="gradient"
            >
              Add product category
            </MDButton>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Table>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  direction="vertical"
                  droppableId="product-category-conditions-droppable"
                >
                  {
                    (droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {
                          formik.values.productCategoryConditionsHelper?.map((productCategoryCondition, index) => {
                            const productCategoryConditionFieldName = `productCategoryConditionsHelper[${index}].productCategoryCondition`;
                            return (
                              <Draggable
                                draggableId={`product-category-condition-${productCategoryCondition.uuid}`}
                                index={index}
                                key={`product-category-condition-${productCategoryCondition.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                      }}
                                    >
                                      <TableCell style={{ width: '1px' }}>
                                        <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          type="select"
                                          name={productCategoryConditionFieldName}
                                          label="Product category"
                                          choices={productCategoryChoices}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: '1px' }}>
                                        <MDButton
                                          color="error"
                                          iconOnly
                                          onClick={() => {
                                            fieldArrayHelpers.remove(index);
                                          }}
                                          variant="gradient"
                                        >
                                          <DeleteIcon/>
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </TableBody>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    )} />
  );
};

ProductCategoryConditionsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default ProductCategoryConditionsHelper;
