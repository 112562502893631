import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  FormControl, FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select, Switch,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// MDPR
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MDButton from 'mdpr2/components/MDButton';
import Icon from "@mui/material/Icon";
import TypeaheadFilter from "./TypeaheadFilter";

const ListResourcesFilters = (props) => {
  const {
    filters,
    filterValues,
    setFilterValues,
    defaultFilterValues,
    children
  } = props;

  // eslint-disable-next-line no-shadow
  const toDirtyFilterValues = (filterValues) => {
    const dirtyFilterValues = {};
    Object.keys(filterValues).forEach((filterKey) => {
      const filter = filters.find((f) => (f.field === filterKey || f.apiAttribute === filterKey));
      if (filter) {
        if (filter.apiAttribute) {
          if (filter.type === 'checkbox' && filter.apiValue && filterValues[filter.apiAttribute] === filter.apiValue) {
            dirtyFilterValues[filter.field] = true;
          } else {
            dirtyFilterValues[filter.field] = filterValues[filterKey];
          }
        } else {
          dirtyFilterValues[filterKey] = filterValues[filterKey];
        }
      }
    });
    return dirtyFilterValues;
  };

  const toFilterValues = (dirtyFilterValues) => {
    // eslint-disable-next-line no-shadow
    const filterValues = {};
    Object.keys(dirtyFilterValues).forEach((dirtyFilterKey) => {
      const filter = filters.find((f) => f.field === dirtyFilterKey);
      if (filter) {
        if (filter.apiAttribute) {
          if (filter.type === 'checkbox' && filter.apiValue) {
            if (dirtyFilterValues[dirtyFilterKey] === true) {
              filterValues[filter.apiAttribute] = filter.apiValue;
            }
          } else if(filter.type === 'datetime'){
            filterValues[filter.apiAttribute] = new Date(dirtyFilterValues[dirtyFilterKey]).toISOString();
          } else {
            filterValues[filter.apiAttribute] = dirtyFilterValues[dirtyFilterKey];
          }
        } else {
          filterValues[dirtyFilterKey] = dirtyFilterValues[dirtyFilterKey];
        }
      }
    });
    return filterValues;
  };

  const [dirtyFilterValues, setDirtyFilterValues] = useState(toDirtyFilterValues(filterValues));

  if (filters.length === 0) return null;

  const filterValueOnChange = (event) => {
    const newDirtyFilterValues = { ...dirtyFilterValues };
    newDirtyFilterValues[event.target.name] = event.target.value;
    setDirtyFilterValues(newDirtyFilterValues);
  };

  const filterValueCheckboxOnChange = (event) => {
    const newDirtyFilterValues = { ...dirtyFilterValues };
    newDirtyFilterValues[event.target.name] = event.target.checked;
    setDirtyFilterValues(newDirtyFilterValues);
  };

  const filterValueOnChangeCustom = (fieldName, value) => {
    const newDirtyFilterValues = { ...dirtyFilterValues };
    newDirtyFilterValues[fieldName] = value;
    setDirtyFilterValues(newDirtyFilterValues);
  };

  const handleSearchOnClick = () => {
    setFilterValues(toFilterValues(dirtyFilterValues));
  };
  const handleSearchOnEnterKey = () => {
    setFilterValues(toFilterValues(dirtyFilterValues));
  };
  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSearchOnEnterKey();
    }
  }
  const handleResetOnClick = () => {
    setDirtyFilterValues(defaultFilterValues);
    setFilterValues(defaultFilterValues);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        p: 2,
        flexWrap: 'wrap'
      }}
      onKeyUp={onKeyUp}
    >
      {
        filters.map((filter) => {
          if (filter.type === 'checkbox') {
            return (
              <Box key={`box-filter-${filter.field}`} sx={{ m: 0.5 }}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={dirtyFilterValues[filter.field] || false}
                      name={filter.field}
                      onChange={(e) => filterValueCheckboxOnChange(e)}
                    />
                  )}
                  label={filter.label}
                />
              </Box>
            );
          }

          if (filter.type === 'text') {
            return (
              <Box key={`box-filter-${filter.field}`} sx={{ m: 0.5 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  label={filter.label}
                  name={filter.field}
                  onChange={(e) => filterValueOnChange(e)}
                  value={dirtyFilterValues[filter.field] || ''}
                />
              </Box>
            );
          }

          if (filter.type === 'datetime') {
            return (
              <Box key={`box-filter-${filter.field}`} sx={{ m: 0.5 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TextField
                    id={filter.field}
                    name={filter.field}
                    label={filter.label}
                    type="datetime-local"
                    onChange={(e) => filterValueOnChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dirtyFilterValues[filter.field] || ''}

                  />
                </LocalizationProvider>
              </Box>
            );
          }

          if (filter.type === 'select') {
            return (
              <Box key={`box-filter-${filter.field}`} sx={{ m: 0.5 }}>
                <FormControl sx={{ m: 1, width: 240 }}>
                  <InputLabel>{filter.label}</InputLabel>
                  <Select
                    input={<OutlinedInput label={filter.label} />}
                    name={filter.field}
                    multiple={Array.isArray(dirtyFilterValues[filter.field])}
                    onChange={(e) => filterValueOnChange(e)}
                    value={dirtyFilterValues[filter.field] || ''}
                  >
                    {
                      filter.choices?.map((choice) => (
                        <MenuItem
                          key={choice.value}
                          value={choice.value}
                        >
                          {choice.label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            );
          }

          if (filter.type === 'typeahead') {
            return (
                <Box key={`box-filter-${filter.field}`} sx={{ m: 0.5 }}>
                  <TypeaheadFilter
                      filter={filter}
                      filterValueOnChange={filterValueOnChangeCustom}
                      dirtyFilterValue={dirtyFilterValues[filter.field] ?? null}
                  />
                </Box>
            );
          }

          return '';
        })
      }
      <Box sx={{ m: 0.5 }}>
        <MDButton
          color="dark"
          onClick={handleSearchOnClick}
          variant="gradient"
          iconOnly
        >
          <Icon>search</Icon>
        </MDButton>
      </Box>
      <Box>
        <MDButton
          color="white"
          onClick={handleResetOnClick}
          variant="gradient"
          iconOnly
        >
          <Icon>refresh</Icon>
        </MDButton>
      </Box>
      <Box sx={{ m: 0.5 }}>
        {children}
      </Box>
    </Box>
  );
};

ListResourcesFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  filterValues: PropTypes.object,
  setFilterValues: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultFilterValues: PropTypes.object,
  children: PropTypes.node,
};

ListResourcesFilters.defaultProps = {
  filters: [],
  filterValues: {},
  setFilterValues: null,
  defaultFilterValues: {},
  children: null
};

export default ListResourcesFilters;
