import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/lab/Alert';

// MDPR
import MDButton from "mdpr2/components/MDButton";
import MDTypography from "mdpr2/components/MDTypography";

// WRM
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { useAppContext } from 'contexts/app-context';
import ConfirmModal from 'components/shared/ConfirmModal';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import getDateLocaleString from 'utils/get-date-locale-string';

const AddEditIplicitOrderExportForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    refreshResource,
    resource: iplicitOrderExport,
    validationSchema,
  } = props;

  const [deleteIplicitOrderExportModalOpen, setDeleteIplicitOrderExportModalOpen] = useState(false);
  const [isGoToNextOrderDisabled, setIsGoToNextOrderDisabled] = useState(false);

  const navigate = useNavigate();
  const { userFeedbackSuccess } = useAppContext();

  const selectCandidateIplicitCustomer = async (candidateIplicitCustomerIri) => {
    await resourceApi.saveResource({
      apiEndpoint: 'iplicit-order-exports',
      id: iplicitOrderExport.id,
      data: {
        'confirmedIplicitCustomer': candidateIplicitCustomerIri,
      },
    });
    refreshResource();
  };

  const editNextIplicitOrderExport = async () => {
    const iplicitOrderExportsResponse = await resourceApi.getResources({
      apiEndpoint: 'iplicit-order-exports',
      filterValues: {
        isExportable: false,
        isExported: false,
        isDeleted: false,
        // Only retrieve IplicitOrderExports with ID greater than the current IplicitOrderExport ID.
        // This allows the Finance team to continue sequentially from the current IplicitOrderExport.
        'id[gt]': iplicitOrderExport.id,
      },
      sortValues: [
        {field: 'order.reference', direction: 'asc'},
      ],
      pagination: {
        itemsPerPage: 1,
      },
    });

    if ((iplicitOrderExportsResponse.resources?.length ?? 0) > 0) {
      const nextIplicitOrderExport = iplicitOrderExportsResponse.resources[0];
      navigate(`/admin/iplicit-order-exports/edit/${nextIplicitOrderExport.id}`);
    } else {
      setIsGoToNextOrderDisabled(true);
    }
  }

  const handleDeleteIplicitOrderExport = () => {
    setDeleteIplicitOrderExportModalOpen(true);
  }

  const handleDeleteIplicitOrderExportConfirm = async (note) => {
    const data = {};
    if (note) {
      data.reasonForDeletion = note;
    }
    const response = await requestApi.putResponse({
      url: `iplicit-order-exports/${iplicitOrderExport.id}/delete`,
      data,
    });
    if (response) {
      setDeleteIplicitOrderExportModalOpen(false);
      navigate('/admin/iplicit-order-exports');
      // Add user feedback in a 'one millisecond' timeout to outlive the navigation
      setTimeout(
        () => { userFeedbackSuccess('Iplicit order export deleted successfully'); },
        1
      );
    }
  }

  let statusColour = 'info';
  if (iplicitOrderExport.isExported) {
    statusColour = 'success';
  } else if (iplicitOrderExport.isExportable) {
    statusColour = 'warning';
  } else {
    statusColour = 'error';
  }

  let orderStatusColour = 'info';
  if (iplicitOrderExport.order?.status === 'processing') {
    orderStatusColour = 'warning';
  } else if (iplicitOrderExport.order?.status === 'completed') {
    orderStatusColour = 'success';
  } else if (iplicitOrderExport.order?.status === 'cancelled') {
    orderStatusColour = 'error';
  }

  const { billingAddress } = iplicitOrderExport.order;

  // The same data as output on EditOrderForm
  const billingAddressParts = [];
  billingAddressParts.push(`${billingAddress.firstName} ${billingAddress.lastName}`);
  if (billingAddress.line1) billingAddressParts.push(billingAddress.line1);
  if (billingAddress.line2) billingAddressParts.push(billingAddress.line2);
  if (billingAddress.line3) billingAddressParts.push(billingAddress.line3);
  if (billingAddress.townCity) billingAddressParts.push(billingAddress.townCity);
  if (billingAddress.county) billingAddressParts.push(billingAddress.county);
  if (billingAddress.postcode) billingAddressParts.push(billingAddress.postcode);
  if (billingAddress.country) billingAddressParts.push(billingAddress.country);
  const billingAddressLine2Parts = [];
  if (billingAddress.email) billingAddressLine2Parts.push(billingAddress.email);
  if (billingAddress.phone) billingAddressLine2Parts.push(billingAddress.phone);
  if (billingAddress.company) billingAddressLine2Parts.push(billingAddress.company);

  const { confirmedIplicitCustomer } = iplicitOrderExport;
  const confirmedIplicitCustomerParts = [];
  if (confirmedIplicitCustomer?.code) confirmedIplicitCustomerParts.push(`Customer code ${confirmedIplicitCustomer.code}`);
  if (confirmedIplicitCustomer?.name) confirmedIplicitCustomerParts.push(confirmedIplicitCustomer.name);
  if (confirmedIplicitCustomer?.fullAddress) confirmedIplicitCustomerParts.push(confirmedIplicitCustomer.fullAddress);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <MDTypography variant="h3">
                  Order #{iplicitOrderExport.order?.reference}: <MDTypography variant="span" color={statusColour}>{iplicitOrderExport.status.toUpperCase()}</MDTypography>
                </MDTypography>
                <MDTypography variant="subtitle2">Order placed {getDateLocaleString(iplicitOrderExport.order?.createdAt) || 'N/A'}</MDTypography>
              </Grid>

              {/* If the IplicitOrderExport has been attempted, but failed, render the import errors from Iplicit */}
              {
                iplicitOrderExport.importErrors?.length > 0 &&
                <Grid
                  item
                  xs={12}
                >
                  <Alert severity="error">
                    <h4>Import errors from Iplicit</h4>
                    <ul>
                      {iplicitOrderExport.importErrors.map((importError) => <li>{importError}</li>)}
                    </ul>
                  </Alert>
                </Grid>
              }

              <Grid
                item
                xs={12}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2" color={orderStatusColour}>
                          <h4>Order status</h4>
                          {iplicitOrderExport.order?.status.replace('_', ' ').toUpperCase()}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Billing address</h4>
                          {billingAddressParts.join(', ')}<br />
                          {billingAddressLine2Parts.join(', ')}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Billing email</h4>
                          {billingAddress.email}
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                      >
                        <MDTypography variant="subtitle2">
                          <h4>Customer note</h4>
                          {iplicitOrderExport.order?.customerNote || 'N/A'}
                        </MDTypography>
                      </Grid>

                      {/* If the IplicitOrderExport has a confirmedIplicitCustomer, render it and allow 'unselection' */}
                      {
                        confirmedIplicitCustomer &&
                        <Grid
                          item
                          xs={12}
                        >
                          <MDTypography variant="subtitle2">
                            <h4>Confirmed Iplicit customer</h4>
                            {confirmedIplicitCustomerParts.join(', ')}
                            {
                              !iplicitOrderExport.isExported &&
                              <>
                                <br />
                                <MDButton
                                  color="warning"
                                  onClick={() => selectCandidateIplicitCustomer(null)}
                                  size="small"
                                  variant="gradient"
                                >
                                  Unselect this Customer
                                </MDButton>
                              </>
                            }
                          </MDTypography>
                        </Grid>
                      }

                      {/* If the IplicitOrderExport has been exported to Iplicit, render the sale order details */}
                      {
                        iplicitOrderExport.isExported &&
                        <Grid
                          item
                          xs={12}
                        >
                          <MDTypography variant="subtitle2">
                            <h4>Exported</h4>
                            {getDateLocaleString(iplicitOrderExport.exportedAt)} as Iplicit sale order&nbsp;
                            {
                              Object.keys(iplicitOrderExport.saleOrderNumbers).map((saleOrderWre, index, arr) => (
                                <>
                                  {iplicitOrderExport.saleOrderNumbers[saleOrderWre].saleOrder ?? 'N/A'} ({saleOrderWre})
                                  {index === arr.length - 1 ? '' : ' / '}
                                </>
                              ))
                            }
                          </MDTypography>
                        </Grid>
                      }

                      {/* If the IplicitOrderExport has been converted to Iplicit sale invoices, render the sale invoice details */}
                      {
                        iplicitOrderExport.isConvertedToSaleInvoice &&
                        <Grid
                          item
                          xs={12}
                        >
                          <MDTypography variant="subtitle2">
                            <h4>Converted to sale invoices</h4>
                            {getDateLocaleString(iplicitOrderExport.convertedToSaleInvoiceAt)} as Iplicit sale invoice&nbsp;
                            {
                              Object.keys(iplicitOrderExport.saleInvoiceNumbers).map((saleInvoiceWre, index, arr) => (
                                <>
                                  {iplicitOrderExport.saleInvoiceNumbers[saleInvoiceWre].saleInvoice ?? 'N/A'} ({saleInvoiceWre})
                                  {index === arr.length - 1 ? '' : ' / '}
                                </>
                              ))
                            }
                          </MDTypography>
                        </Grid>
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      mt={1}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Table>
                          <TableBody>
                            {
                              iplicitOrderExport.saleOrdersData.map((saleOrderData) => (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={9}>
                                      <strong>Order #{saleOrderData.theirRef}</strong>
                                      { saleOrderData.theirRef.slice(-1) === 'S' && '  - Subscriptions' }
                                      { saleOrderData.theirRef.slice(-1) === 'M' && '  - Main shipment' }
                                      { saleOrderData.theirRef.slice(-1) === 'D' && '  - Deferred shipment' }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Sku</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Unit price</TableCell>
                                    <TableCell>Nominal code</TableCell>
                                    <TableCell>Department code</TableCell>
                                    <TableCell>Cost centre</TableCell>
                                    <TableCell>Tax band ID</TableCell>
                                    <TableCell>Tax band</TableCell>
                                  </TableRow>
                                  {
                                    saleOrderData.details.map((line) => (
                                      <TableRow>
                                        <TableCell>{line.sku}</TableCell>
                                        <TableCell>{line.description}</TableCell>
                                        <TableCell>{line.quantity}</TableCell>
                                        <TableCell>{line.currencyUnitPrice}</TableCell>
                                        <TableCell>{line.accountId || <MDTypography variant="inherit" fontWeight="bold" color="error">N/A</MDTypography>}</TableCell>
                                        <TableCell>{line.post.department || <MDTypography variant="inherit" fontWeight="bold" color="error">N/A</MDTypography>}</TableCell>
                                        <TableCell>{line.post.costCentre || <MDTypography variant="inherit" fontWeight="bold" color="error">N/A</MDTypography>}</TableCell>
                                        <TableCell>{line.taxBandId}</TableCell>
                                        <TableCell>{line.taxBandName}</TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* If the IplicitOrderExport has not been exported to Iplicit, render candidateIplicitCustomers and a manual 'search' */}
              {
                !iplicitOrderExport.isExported &&
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          mt={1}
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{ mt: 1 }}
                              variant="h6"
                            >
                              Matching Iplicit Customers
                            </Typography>
                            <Table>
                              <TableBody>
                                {
                                  iplicitOrderExport.candidateIplicitCustomers.map((candidateIplicitCustomer) => (
                                    <TableRow>
                                      <TableCell>{candidateIplicitCustomer.code}</TableCell>
                                      <TableCell>{candidateIplicitCustomer.name}</TableCell>
                                      <TableCell>{candidateIplicitCustomer.fullAddress}</TableCell>
                                      <TableCell>{candidateIplicitCustomer.email}</TableCell>
                                      <TableCell>
                                        <MDButton
                                          color="info"
                                          onClick={() => selectCandidateIplicitCustomer(candidateIplicitCustomer['@id'])}
                                          variant="gradient"
                                        >
                                          Select this Customer
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </Grid>

                          <FormField
                            {...fields.find((field) => field.name === 'manuallyConfirmedIplicitCustomer')}
                            formik={formik}
                            getApiError={getApiError}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          mt={1}
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{ mt: 1 }}
                              variant="h6"
                            >
                              Country code
                            </Typography>
                          </Grid>

                          <FormField
                            {...fields.find((field) => field.name === 'countryCode')}
                            formik={formik}
                            getApiError={getApiError}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Card>
                      <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                        <SaveCancelButtons
                          cancelLocation={cancelLocation}
                          formik={formik}
                        />
                        {
                          !iplicitOrderExport.isDeleted &&
                          <MDButton
                            color="error"
                            type="button"
                            variant="gradient"
                            onClick={handleDeleteIplicitOrderExport}
                          >
                            Delete this Iplicit order export
                          </MDButton>
                        }
                        {
                          iplicitOrderExport.isExportable &&
                          <MDButton
                            color="info"
                            disabled={isGoToNextOrderDisabled}
                            type="button"
                            variant="gradient"
                            onClick={editNextIplicitOrderExport}
                          >
                            Go to next order
                          </MDButton>
                        }
                      </CardActions>
                    </Card>
                  </Grid>
                </>
              }
            </Grid>
          </Form>
        )}
      </Formik>
      <ConfirmModal
        body='This Iplicit order export will not be exported to Iplicit if you delete it'
        confirmCallback={handleDeleteIplicitOrderExportConfirm}
        hasNote
        isDelete
        open={deleteIplicitOrderExportModalOpen}
        setOpen={(open) => setDeleteIplicitOrderExportModalOpen(open)}
        title='Are you sure?'
      />
    </>
  );
};

AddEditIplicitOrderExportForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  refreshResource: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditIplicitOrderExportForm;
