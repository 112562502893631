import { debounce } from 'lodash';
import { useMemo } from 'react';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';
import schoolFilterSearch from 'utils/schoolFilterSearch';
import onboardingApplicationStatusChoices from './OnboardingApplicationStatusChoices';

const OnboardingApplications = () => {
  const apiEndpoint = 'admin/infinity/onboarding-applications';
  const adminEndpoint = 'infinity/onboarding-applications';
  const resourceName = 'onboarding application ';

  const columns = [
    {
      field: 'id',
      label: 'id',
      flex: 1,
    },
    {
      field: 'schoolName',
      label: 'School Name',
      flex: 3,
    },
    {
      field: 'basket.quoteReference',
      label: 'Quote ref',
      formatter: (resource) => resource.basket?.quoteReference ?? '',
      flex: 3,
    },
    {
      field: 'status',
      label: 'Status',
      formatter: (resource) => {
        const statusChoice = onboardingApplicationStatusChoices.find((status) => status.value === resource.status);
        return statusChoice?.label ?? '';
      },
      flex: 2,
    },
    {
      field: 'estimatedGoLiveBy',
      label: 'Go-live by',
      flex: 1,
      formatter: (resource) => getDateLocaleString(resource.estimatedGoLiveBy, 'date'),
      sortable: true,
    },
    {
      field: 'createdAt',
      label: 'Created At',
      flex: 1,
      formatter: (resource) => getDateLocaleString(resource.createdAt),
      sortable: true,
    },
  ];

  const debouncedSchoolFilterSearch = useMemo(
    () => debounce((search, setOptions) => schoolFilterSearch(search, setOptions), 280),
    []
  );

  const filters = [
    {
      field: 'schoolName',
      label: 'Name',
      type: 'text',
    },
    {
      field: 'learningGroup.school',
      label: 'School',
      type: 'typeahead',
      search: debouncedSchoolFilterSearch,
    },
    {
      field: 'status',
      label: 'Status',
      type: 'select',
      choices: onboardingApplicationStatusChoices,
    },
    {
      field: 'basket.quoteReference',
      label: 'Quote ref',
      type: 'text',
    },
  ];

  const defaultSortValues = [
    {
      field: 'createdAt',
      direction: 'desc',
    },
  ];

  const fixedFilterValues = {
    isArchived: false,
  };

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      adminEndpoint={adminEndpoint}
      columns={columns}
      defaultSortValues={defaultSortValues}
      fixedFilterValues={fixedFilterValues}
      filters={filters}
      resourceName={resourceName}
      title=""
    />
  );
};

export default OnboardingApplications;
