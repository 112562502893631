import { useState } from 'react';
import PropTypes from 'prop-types';

// MDPR
import { setMiniSidenav } from 'mdpr2/context';
import DashboardLayout from 'mdpr2/examples/LayoutContainers/DashboardLayout';
import Sidenav from 'mdpr2/examples/Sidenav';

// WRM
import brandLogo from 'assets/images/logo-wre-white.svg';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardNavbar from 'components/Navbar';
import { useAppContext } from 'contexts/app-context';
import { useUser } from 'contexts/userContext';
import { adminSidebarRoutes } from 'routes';

const AdminLayout = ({ children, controller }) => {
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { showLoadingSpinner } = useAppContext();
  const { userHasRole } = useUser();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (controller.miniSidenav && !onMouseEnter) {
      setMiniSidenav(controller.dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(controller.dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Filter adminSidebarRoutes
  const filterRoutes = (routes) => {
    const filteredRoutes = [];
    routes.forEach((route) => {
      let rolesRequired = [];
      if (route.role) {
        rolesRequired = Array.isArray(route.role) ? route.role : [route.role];
      }
      let userHasAccess = (rolesRequired.length === 0);
      rolesRequired.forEach((roleRequired) => {
        if (userHasRole(roleRequired)) userHasAccess = true;
      });
      if (userHasAccess) {
        if (route.collapse) {
          // eslint-disable-next-line no-param-reassign
          route.collapse = filterRoutes(route.collapse);
        }
        filteredRoutes.push(route);
      }
    });

    return filteredRoutes;
  };
  const filteredAdminSidebarRoutes = filterRoutes(adminSidebarRoutes);

  return (
    <>
      <Sidenav
        color={controller.sidenavColor}
        brandName=''
        brand={brandLogo}
        routes={filteredAdminSidebarRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <DashboardLayout>
        <DashboardNavbar />
        {children}
      </DashboardLayout>
      {showLoadingSpinner && <LoadingSpinner />}
      <form id="web-fe-login-form" style={{display: 'none'}} method="POST" action={`${process.env.REACT_APP_WEB_FE_BASE_URL}/switch-user`} target="_blank">
        <input type="hidden" name="token" id="web-fe-login-form-token" />
        <input type="hidden" name="userId" id="web-fe-login-form-user-id"/>
      </form>
    </>
  );
};

AdminLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  controller: PropTypes.object.isRequired,
};

export default AdminLayout;
