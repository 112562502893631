// eslint-disable-next-line import/prefer-default-export
export const saleOrderOverrideChoices = [
  {
    value: 'main',
    label: 'Main',
  },
  {
    value: 'deferred',
    label: 'Deferred',
  },
  {
    value: 'subscription',
    label: 'Subscription',
  },
];
