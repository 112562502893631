import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditPromotionForm from './AddEditPromotionForm';

const apiEndpoint = 'promotions';
const resourceName = 'promotion';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Identifier',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    choices: [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
    defaultValue: 'inactive',
  },
  {
    name: 'conditions',
    json: true,
    defaultValue: [],
  },
  {
    name: 'discounts',
    json: true,
    defaultValue: [],
  },
  {
    name: 'startAt',
    label: 'Starts',
    type: 'date',
  },
  {
    name: 'endAt',
    label: 'Ends',
    type: 'date',
  },
  {
    name: 'maximumUses',
    label: 'Maximum uses',
    type: 'number',
  },
  {
    name: 'maximumUsesPerEmail',
    label: 'Maximum uses per email',
    type: 'number',
  },
  {
    name: 'promotionCodes',
    label: 'Discount codes',
    type: 'fieldCollection',
    defaultValue: [
      {code: ''}
    ],
    childFields: [
      {
        name: 'code',
        label: 'Code',
      }
    ],
  },
];

Yup.addMethod(Yup.array, 'conditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-conditions-helper`, errorMessage, function (value) {
    const { createError } = this;

    const errors = [];
    value.forEach((condition, index) => {
      const conditionAttribute = condition.attribute ?? '';
      const conditionComparator = condition.comparator ?? '';
      const conditionValue = (condition.value ?? '').trim();
      if (!conditionAttribute) {
        errors.push(new ValidationError(
          `Condition is required`,
          '',
          `conditionsHelper[${index}].attribute`
        ));
      }
      if (conditionAttribute === 'basket.products') {
        if (!conditionValue) {
          errors.push(new ValidationError(
            `One or more product SKUs required`,
            '',
            `conditionsHelper[${index}].value`
          ));
        }
      }
      if (conditionAttribute === 'basket.subtotal') {
        if (conditionComparator === '') {
          errors.push(new ValidationError(
            `Basket value comparator required`,
            '',
            `conditionsHelper[${index}].comparator`
          ));
        }
        if (conditionValue === '') {
          errors.push(new ValidationError(
            `Basket value required`,
            '',
            `conditionsHelper[${index}].value`
          ));
        } else {
          const numberRegExp = /^[0-9]+(.[0-9]{2})?$/;
          if (!numberRegExp.test(conditionValue)) {
            errors.push(new ValidationError(
              `Basket value invalid`,
              '',
              `conditionsHelper[${index}].value`
            ));
          }
        }
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'discountsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-discounts-helper`, errorMessage, function (value) {
    const { createError } = this;

    const errors = [];
    value.forEach((discount, index) => {
      const discountType = discount.type ?? '';
      const discountValue = (discount.value ?? '').trim();
      if (!discountType) {
        errors.push(new ValidationError(
          `Discount type is required`,
          '',
          `discountsHelper[${index}].type`
        ));
      }
      if (discountType === 'percentage') {
        if (!discountValue) {
          errors.push(new ValidationError(
            `Discount percentage required`,
            '',
            `discountsHelper[${index}].value`
          ));
        } else {
          const numberRegExp = /^[0-9]+(.[0-9]{2})?$/;
          if (!numberRegExp.test(discountValue)) {
            errors.push(new ValidationError(
              `Discount percentage invalid`,
              '',
              `discountsHelper[${index}].value`
            ));
          } else if (discountValue > 100) {
            errors.push(new ValidationError(
              `Discount cannot be greater than 100%`,
              '',
              `discountsHelper[${index}].value`
            ));
          }
        }
      }
      if (discountType === 'fixed') {
        if (!discountValue) {
          errors.push(new ValidationError(
            `Discount amount required`,
            '',
            `discountsHelper[${index}].value`
          ));
        } else {
          const numberRegExp = /^[0-9]+(.[0-9]{2})?$/;
          if (!numberRegExp.test(discountValue)) {
            errors.push(new ValidationError(
              `Discount amount invalid`,
              '',
              `discountsHelper[${index}].value`
            ));
          }
        }
      }
    });

    return createError({
      message: () => errors
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  code: Yup.string()
    .required('Code is required'),
  status: Yup.string()
    .required('Status is required'),
  conditions: Yup.string()
    .required('Conditions are required')
    .test(
      'is-json',
      'Promotion conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  conditionsHelper: Yup.array()
    .conditionsHelper('Invalid conditions'),
  discounts: Yup.string()
    .required('Promotion discounts are required')
    .test(
      'is-json',
      'Promotion discounts is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  discountsHelper: Yup.array()
    .discountsHelper('Invalid discounts'),
  startAt: Yup.date()
    .typeError('Invalid date format')
    .nullable(),
  endAt: Yup.date()
    .typeError('Invalid date format')
    .nullable()
    .min(Yup.ref('startAt'), 'End date must be later than start date'),
  maximumUses: Yup.number().nullable()
    .typeError('Invalid value')
    .min(2, 'Must be greater than one'),
  maximumUsesPerEmail: Yup.number().nullable()
    .typeError('Invalid value')
    .min(1, 'Must be greater than zero'),
  hasNoPromotionCodes: Yup.boolean()
    .when(['promotionCodes'], (promotionCodes, schema) => (promotionCodes?.length === 0) ? schema.required().oneOf([true], 'Please confirm that this promotion has no discount codes') : schema)
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // Map conditions to conditionsHelper
  formValues.conditionsHelper = [];
  resource.conditions.forEach((condition, index) => {
    const conditionAttribute = condition.attribute ?? null;
    const conditionComparator = condition.comparator ?? null;
    const conditionValue = condition.value ?? null;
    const conditionWithUuid = {};
    conditionWithUuid.attribute = conditionAttribute;
    conditionWithUuid.uuid = index;
    if (conditionAttribute === 'basket.products') {
      conditionWithUuid.value = Array.isArray(conditionValue) ? conditionValue.join(',') : '';
    }
    if (conditionAttribute === 'basket.subtotal') {
      conditionWithUuid.comparator = conditionComparator;
      conditionWithUuid.value = (conditionValue !== null) ? String((conditionValue / 100).toFixed(2)) : '';
    }
    if (conditionAttribute === 'basket.email') {
      conditionWithUuid.value = conditionValue ?? '';
    }
    formValues.conditionsHelper.push(conditionWithUuid);
  });

  // Map discounts to discountsHelper
  formValues.discountsHelper = [];
  resource.discounts.forEach((discount, index) => {
    const discountType = discount.type ?? null;
    const discountValue = discount.value ?? null;
    const discountProducts = discount.products ?? null;
    const discountWithUuid = {};
    discountWithUuid.type = discountType;
    if (discountType === 'fixed') {
      discountWithUuid.value = (discountValue !== null) ? String((discountValue / 100).toFixed(2)) : '';
    } else {
      discountWithUuid.value = (discountValue !== null) ? String(discountValue) : '';
    }
    discountWithUuid.products = Array.isArray(discountProducts) ? discountProducts.join(',') : '';
    discountWithUuid.uuid = index;
    formValues.discountsHelper.push(discountWithUuid);
  });

  formValues.hasNoPromotionCodes = false;

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // Map conditionsHelper to conditions
  apiValues.conditions = [];
  formValues.conditionsHelper.forEach((conditionHelper) => {
    const conditionAttribute = conditionHelper.attribute ?? null;
    const conditionComparator = conditionHelper.comparator ?? null;
    const conditionValue = conditionHelper.value ?? null;
    const condition = {};
    condition.attribute = conditionAttribute;
    if (conditionHelper.attribute === 'basket.products') {
      condition.value = conditionValue.split(',').map(v => v.trim());
    }
    if (conditionHelper.attribute === 'basket.subtotal') {
      condition.comparator = conditionComparator;
      condition.value = conditionValue.trim() * 100;
    }
    if (conditionHelper.attribute === 'basket.email') {
      condition.value = conditionValue.trim();
    }
    apiValues.conditions.push(condition);
  });

  // Map discountsHelper to discounts
  apiValues.discounts = [];
  formValues.discountsHelper.forEach((discountHelper) => {
    const discountType = discountHelper.type ?? null;
    const discountValue = discountHelper.value ?? null;
    const discountProducts = discountHelper.products ?? null;
    const discount = {};
    discount.type = discountType;
    if (discountType === 'fixed') {
      discount.value = (discountValue !== null) ? (discountValue.trim() * 100) : null;
    } else {
      discount.value = (discountValue !== null) ? discountValue.trim() : null;
    }
    if (discountProducts) {
      discount.products = discountProducts.split(',').map(v => v.trim());
    }
    apiValues.discounts.push(discount);
  });

  return apiValues;
}

const AddEditPromotion = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={AddEditPromotionForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditPromotion.propTypes = {
};

AddEditPromotion.defaultProps = {
}

export default AddEditPromotion;
