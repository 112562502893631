import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@mui/material';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import ConditionsHelper from './ConditionsHelper';

const AddEditShippingServiceForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    validationSchema,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <FormField
                  {...fields.find((field) => field.name === 'name')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'code')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                    {...fields.find((field) => field.name === 'countries')}
                    formik={formik}
                    getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'shippingServiceZone')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'status')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'description')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'price')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                    {...fields.find((field) => field.name === 'displayMessage')}
                    formik={formik}
                    getApiError={getApiError}
                />

                <ConditionsHelper
                  formik={formik}
                  getApiError={getApiError}
                />
              </Grid>
            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                cancelLocation={cancelLocation}
                formik={formik}
              />
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

AddEditShippingServiceForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditShippingServiceForm;
