import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import { SplitLearningGroupForm } from './SplitLearningGroupForm';

export const SplitLearningGroupModal = ({
  formRef,
  splitRequestErrored,
  setSplitLearningGroupModalOpen,
  onSubmitForm,
  id,
}) => (
  <Dialog open aria-labelledby="split-learning-group" aria-describedby="split-learning-group" fullWidth maxWidth="lg">
    <DialogTitle id="split-learning-group-title">Split group</DialogTitle>
    <DialogContent>
      <SplitLearningGroupForm formRef={formRef} splitRequestErrored={splitRequestErrored} id={id} />
    </DialogContent>
    <DialogActions>
      <MDButton color="info" onClick={onSubmitForm} variant="gradient" data-testid="split-group-modal-submit">
        Perform split
      </MDButton>
      <MDButton
        color="secondary"
        onClick={() => setSplitLearningGroupModalOpen(false)}
        variant="gradient"
        data-testid="split-group-modal-cancel"
      >
        Cancel
      </MDButton>
    </DialogActions>
  </Dialog>
);

SplitLearningGroupModal.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  splitRequestErrored: PropTypes.bool.isRequired,
  setSplitLearningGroupModalOpen: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default SplitLearningGroupModal;
