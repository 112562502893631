import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import AddEditLearningGroupForm from './AddEditLearningGroupForm';

const apiEndpoint = 'custom-learning-groups';
const resourceName = 'Custom Group';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const AddEditCustomLearningGroup = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const fields = [
    {
      name: 'name',
      label: 'Custom Group Name',
      flex: 7,
    },
  ];

  return (
    <AddEditResource
      addEditForm={AddEditLearningGroupForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditCustomLearningGroup;
