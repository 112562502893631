/* eslint-disable */
import { getIn } from 'formik';

// MUI
import { TextField } from '@mui/material';

const Text = (props) => {
  const {
    formik,
    getApiError,
    label,
    name,
    onChange,
    disabled = false
  } = props;

  return (
    <TextField
      error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
      fullWidth
      helperText={getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}
      label={label}
      name={name}
      onBlur={formik.handleBlur}
      onChange={onChange || formik.handleChange}
      value={getIn(formik.values, name)}
      disabled={disabled}
    />
  );
};

export default Text;
