import * as Yup from 'yup';
import PropTypes from 'prop-types';

import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import {Grid} from "@mui/material";
import MDBox from "mdpr2/components/MDBox";
import MDTypography from "mdpr2/components/MDTypography";

const apiEndpoint = 'order-status-amendments';
const resourceName = 'order status amendment';

const buildFields = (validStatusAmends) => {
    const fields = [
        {
            name: 'status',
            label: 'New Order Status',
            type: 'select',
            choices: validStatusAmends.map((status) => ({
                value: status,
                label: status
            })),
            defaultValue: validStatusAmends.length > 0 ? validStatusAmends[0] : null
        },
        {
            label: 'Notes/Reason',
            name: 'note',
            type: 'textarea'
        }
    ];

    return fields;
};

const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    note: Yup.string().required()
});

const AddOrderStatusAmendment = (props) => {
    const {
        orderId,
        onModalClose,
        validStatusAmends
    } = props;

    const fields = buildFields(validStatusAmends);

    // eslint-disable-next-line no-shadow
    const toApiValuesCustom = (formValues, fields) => {
        // Perform standard mapping
        const apiValues = toApiValues(formValues, fields);
        apiValues.order = `/orders/${orderId}`;
        return apiValues;
    }

    return (
        <>
            <MDBox mb={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <MDTypography variant="h5">
                            Amend Order Status
                        </MDTypography>
                    </Grid>
                </Grid>
            </MDBox>
            <AddEditResource
                showTitleText={false}
                apiEndpoint={apiEndpoint}
                fields={fields}
                isModal
                onModalClose={onModalClose}
                resourceName={resourceName}
                toApiValuesCustom={toApiValuesCustom}
                validationSchema={validationSchema}
            />
        </>
    );
}

AddOrderStatusAmendment.propTypes = {
    orderId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    validStatusAmends: PropTypes.array.isRequired,
    onModalClose: PropTypes.func
};

AddOrderStatusAmendment.defaultProps = {
    onModalClose: () => {}
};

export default AddOrderStatusAmendment;
