/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// MUI
import {
    OutlinedInput,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Stack
} from "@mui/material";

// WRM
import LoadingSpinner from "components/LoadingSpinner";
import getDateLocaleString from "utils/get-date-locale-string";
import { requestApi } from "api/request-api";
import { putRequestQuery } from "api/apiRequest";

// MDPR
import MDButton from "mdpr2/components/MDButton";

const ProductReturns = (props) => {
    const {
        order,
        setModalOpen,
    } = props;

    const [productSkusAndNames, setProductSkusAndNames] = useState();
    const [StockChangeLogsShipped, setStockChangeLogsShipped] = useState();
    const [quantitiesShipped, setQuantitiesShipped] = useState();
    const [StockChangeLogsReturned, setStockChangeLogsReturned] = useState();
    const [quantitiesReturned, setQuantitiesReturned] = useState();
    const [stockLoaded, setStockLoaded] = useState(false);

    const [productReturnQuantities, setProductReturnQuantities] = useState({});
    const [productReturnErrors, setProductReturnErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (!stockLoaded) {

            requestApi
                .getResponse({ url: `orders/${order.id}/returns-context` })
                .then(response => {

                    if (response) {

                        const productNames = {};
                        const StockChangeLogsShipped = {};
                        const quantitiesShipped = {};
                        const StockChangeLogsReturned = {};
                        const quantitiesReturned = {};

                        const StockChangeLogDespatches = response.despatches;
                        const StockChangeLogReturns = response.returns;

                        StockChangeLogDespatches.forEach((stockChangeLog) => {

                            const productSku = stockChangeLog.actualProduct.sku;
                            const productName = stockChangeLog.actualProduct.name;

                            if (!productNames[productSku]) productNames[productSku] = productName;

                            if (typeof StockChangeLogsShipped[productSku] === 'undefined') {
                                StockChangeLogsShipped[productSku] = [];
                            }
                            StockChangeLogsShipped[productSku].push(stockChangeLog);
                            if (typeof quantitiesShipped[productSku] === 'undefined') {
                                quantitiesShipped[productSku] = 0;
                            }
                            quantitiesShipped[productSku] += stockChangeLog.quantity;
                        });

                        StockChangeLogReturns.forEach((stockChangeLog) => {

                            const productSku = stockChangeLog.actualProduct.sku;
                            const productName = stockChangeLog.actualProduct.name;

                            if (!productNames[productSku]) productNames[productSku] = productName;

                            if (typeof StockChangeLogsReturned[productSku] === 'undefined') {
                                StockChangeLogsReturned[productSku] = [];
                            }
                            StockChangeLogsReturned[productSku].push(stockChangeLog);
                            if (typeof quantitiesReturned[productSku] === 'undefined') {
                                quantitiesReturned[productSku] = 0;
                            }
                            quantitiesReturned[productSku] += stockChangeLog.quantity;
                        });



                        // eslint-disable-next-line no-shadow
                        const productSkusAndNames = [];
                        Object.keys(productNames).sort().forEach((productSku) => {
                            productSkusAndNames.push({
                                'sku': productSku,
                                'name': productNames[productSku]
                            })
                        })
                        setProductSkusAndNames(productSkusAndNames);
                        setStockChangeLogsShipped(StockChangeLogsShipped);
                        setQuantitiesShipped(quantitiesShipped);
                        setStockChangeLogsReturned(StockChangeLogsReturned);
                        setQuantitiesReturned(quantitiesReturned);
                        setStockLoaded(true);
                    }
                });
        }
    }, [stockLoaded]);

    const handleSaveReturnOnClick = async () => {
        setIsFormValid(false);
        const data = {
            'products': []
        };
        Object.keys(productReturnQuantities).forEach((productSku) => {
            if (productReturnQuantities[productSku] > 0) {
                data.products.push({
                    'sku': productSku,
                    'quantity': productReturnQuantities[productSku],
                });
            }
        });
        await axios(putRequestQuery(`orders/${order.id}/return-products`, data));
        setProductReturnQuantities({});
        setStockLoaded(false);
    }

    if (!stockLoaded) return <LoadingSpinner />;

    const handleReturnQuantityChange = (event) => {
        const returnQuantityName = event.target.name;
        const returnQuantityValue = event.target.value ?? '';
        const productSku = returnQuantityName.replace('return_quantity_for_product_sku_', '');

        const newProductReturnQuantities = { ...productReturnQuantities };
        if (returnQuantityValue !== '') {
            newProductReturnQuantities[productSku] = returnQuantityValue;
        } else {
            delete newProductReturnQuantities[productSku];
        }
        setProductReturnQuantities(newProductReturnQuantities);

        const newProductReturnErrors = { ...productReturnErrors };
        const quantityRemaining = (quantitiesShipped[productSku] ?? 0) - (quantitiesReturned[productSku] ?? 0) - returnQuantityValue;
        if (returnQuantityValue < 0 || quantityRemaining < 0) {
            newProductReturnErrors[productSku] = true;
        } else {
            delete newProductReturnErrors[productSku];
        }
        setProductReturnErrors(newProductReturnErrors);

        const nonZeroQuantities = Object.values(newProductReturnQuantities).filter((quantity) => quantity > 0);
        setIsFormValid(Object.keys(newProductReturnErrors).length === 0 && nonZeroQuantities.length > 0);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead sx={{ display: 'table-header-group' }} >
                    <TableRow>
                        <TableCell width="10%">SKU</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Shipped</TableCell>
                        <TableCell>Product returns</TableCell>
                        <TableCell>Return quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        productSkusAndNames.map((productSkuAndName) => {
                            const productSku = productSkuAndName.sku;
                            const productName = productSkuAndName.name;



                            return (
                                <TableRow>
                                    <TableCell width="10%">{productSku}</TableCell>
                                    <TableCell>{productName}</TableCell>
                                    <TableCell>
                                        {
                                            StockChangeLogsShipped[productSku] &&
                                            StockChangeLogsShipped[productSku].map((stockChangeLog) => (
                                                <><strong>{stockChangeLog.quantity} x {productSku}</strong> ({getDateLocaleString(stockChangeLog.createdAt)})<br /></>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            StockChangeLogsReturned[productSku] &&
                                            StockChangeLogsReturned[productSku].map((stockChangeLog) => (
                                                <><strong>{stockChangeLog.quantity} x {productSku}</strong> ({getDateLocaleString(stockChangeLog.createdAt)})<br /></>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell style={{ width: 100 }}>
                                        <OutlinedInput
                                            type="number"
                                            name={`return_quantity_for_product_sku_${productSku}`}
                                            onChange={handleReturnQuantityChange}
                                            value={productReturnQuantities[productSku]}
                                            error={productReturnErrors[productSku] ?? false}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>

            <Stack m={2} direction="row" spacing={2}>
                <MDButton
                    color="primary"
                    disabled={!isFormValid}
                    onClick={handleSaveReturnOnClick}
                >
                    Save return
                </MDButton>
                <MDButton
                    color="dark"
                    onClick={() => setModalOpen(false)}
                    variant="outlined"
                >
                    Close
                </MDButton>
            </Stack>


        </TableContainer>
    );
}

ProductReturns.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    order: PropTypes.object.isRequired,
    setModalOpen: PropTypes.func.isRequired,
};

export default ProductReturns;