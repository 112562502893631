/* eslint-disable */
import { getIn } from 'formik';
import React, { useEffect, useState } from 'react';

// MUI
import { Autocomplete, FormControl, TextField } from '@mui/material';

const Typeahead = (props) => {
  const { formik, getApiError, label, name, search } = props;

  const [options, setOptions] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [value, setValue] = useState(null);

  const clearOptions = () => {
    setOptions([]);
  };

  // init component
  useEffect(() => {
    const fieldValue = getIn(formik.values, name);

    if (fieldValue === null) {
      setTextFieldValue('');
      setValue(null);
    } else {
      setTextFieldValue(fieldValue.label ?? '?');
      setValue(fieldValue);
    }

    clearOptions();
  }, []);

  return (
    <FormControl sx={{ m: 0, width: '100%' }}>
      <Autocomplete
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
            helperText={getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}
            label={label}
            onChange={(e) => {
              const { value } = e.target;
              setTextFieldValue(value);
              search(value, setOptions);
            }}
            value={textFieldValue}
          />
        )}
        options={options}
        getOptionDisabled={(option) => option?.disabled}
        onChange={(event, newValue) => {
          setValue(newValue);

          if (newValue === null) {
            // ie - when the clear button is clicked...
            clearOptions();
          }

          formik.setFieldValue(name, newValue ?? null);
        }}
        value={value}
      />
    </FormControl>
  );
};

export default Typeahead;
