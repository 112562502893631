/* eslint-disable */
import { getIn } from 'formik';
import moment from 'moment';

// MUI
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

const DateTime = (props) => {
  const {
    formik,
    label,
    name,
  } = props;

  return (
    <DateTimePicker
      inputFormat="dd/MM/yyyy hh:mm a"
      label={label}
      onChange={(newDate) => formik.setFieldValue(name, moment(newDate))}
      renderInput={(inputProps) => (
        <TextField
          fullWidth
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...inputProps}
        />
      )}
      value={getIn(formik.values, name)}
    />
  );
};

export default DateTime;
