// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Quotes';
const apiEndpoint = 'baskets';
const adminEndpoint = 'quotes';
const resourceName = 'quote';

const columns = [
  {
    field: 'quoteReference',
    label: 'Reference',
    flex: 1,
  },
  {
    field: 'firstName',
    label: 'First name',
    flex: 2,
    formatter: (resource) => resource.deliveryAddress?.firstName
  },
  {
    field: 'lastName',
    label: 'Last name',
    flex: 2,
    formatter: (resource) => resource.deliveryAddress?.lastName
  },
  {
    field: 'email',
    label: 'Email',
    flex: 2,
  },
  {
    field: 'createdAt',
    label: 'Created',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.createdAt)
  },
];

const filters = [
  {
    field: 'reference',
    label: 'Reference',
    type: 'text',
    apiAttribute: 'quoteReference',
  },
  {
    field: '_userSearch',
    label: 'Customer',
    type: 'text',
    apiAttribute: 'userSearch',
  },
];

const defaultFilterValues = {
  source: 'admin'
};

const defaultSortValues = [
  {
    field: 'createdAt',
    direction: 'desc',
  },
];

const fixedFilterValues = {
  isQuote: true,
};

const Quotes = () => (
  <ListResources
    adminEndpoint={adminEndpoint}
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    customItemsPerPage={10}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default Quotes;
