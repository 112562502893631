import {requestApi} from "api/request-api";

const userSearch = (search, setOptions) => {
    if (search.length) {
        requestApi.getResponse(
            {'url': `users/choices?search=${encodeURIComponent(search)}`}
        ).then(response => {

            if (!Array.isArray(response)) {
                setOptions([]);
            }

            const choices = response.map((row) => ({
                id: row.value,
                label: row.label
            }));

            setOptions(choices);
        });
    } else {
        setOptions([]);
    }
};

export default userSearch;