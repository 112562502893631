import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { metaDataFields } from 'constants';
import { toApiValues } from 'components/shared/resource/api-form-mapper';

const apiEndpoint = 'news-articles';
const resourceName = 'news article';

const fields = [
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'slug',
    label: 'Slug',
  },
  {
    name: 'articleDate',
    label: 'Date',
    type: 'datetime',
  },
  {
    name: 'imageUrl',
    label: 'Image',
    type: 'filePicker'
  },
  {
    name: 'thumbnailUrl',
    label: 'Thumbnail (optional)',
    type: 'filePicker',
  },
  {
    name: 'content',
    label: 'Content',
    type: 'richtext',
  },
  {
    name: 'isArchived',
    label: 'Archived?',
    type: 'checkbox',
  },
  metaDataFields,
];

const validationSchema = Yup.object().shape({
  articleDate: Yup.date()
    .typeError('Date must be a valid date'),
  title: Yup.string()
    .required('Title is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

const toApiValuesCustom = (formValues, formFields) => {
  const apiValues = toApiValues(formValues, formFields);

  if (apiValues.metaData['@id'] === "") {
    delete apiValues.metaData['@id'];
  }

  return apiValues;
};

const AddEditNewsArticle = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
      toApiValuesCustom={toApiValuesCustom}
    />
  );
};

export default AddEditNewsArticle;
