import axios from "axios";
import {useState} from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  Card,
  CardActions,
  CardContent, Dialog, DialogContent,
  Grid, Tab, Tabs,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';

// MDPR
import MDAlert from 'mdpr2/components/MDAlert';
import MDBadge from 'mdpr2/components/MDBadge';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import ListResourcesTable from 'components/shared/ListResources/ListResourcesTable';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import { adminSession } from 'constants/index';
import { useUser } from 'contexts/userContext';
import getDateLocaleString from 'utils/get-date-locale-string';
import { getPasswordStrength, generatePassword } from 'utils/password';
import { putRequestQuery } from 'api/apiRequest';
import {useNavigate} from "react-router-dom";
import ConfirmModal from "components/shared/ConfirmModal";
import {useAppContext} from "contexts/app-context";
import MDBox from "mdpr2/components/MDBox";
import {TabContext, TabPanel} from "@mui/lab";
import {makeStyles} from "@material-ui/core/styles";
import AddEditMembership from "../Memberships/AddEditMembership";
import LearningGroupLicences from "../LearningGroups/LearningGroupLicences";
import UserLearningGroupAccesses from "./UserLearningGroupAccesses";

const membershipColumns = [
  {
    field: 'membershipStatus',
    label: ' ',
    width: 100,
    formatter: (resource) => {
      const membership = resource;
      let badge = null;
      if (membership.teamMembership) {
        badge = 'Team';
      } else {
        badge = 'Individual';
      }
      let badgeColour = 'danger'
      if (membership.status === 'current') {
        if (membership.isActive) {
          badgeColour = 'success'
        } else {
          badgeColour = 'warning'
        }
      }
      return (
        <MDBadge color={badgeColour} badgeContent={badge} />
      );
    },
  },
  {
    field: 'membershipPlanName',
    label: 'Membership plan',
    flex: 2,
    formatter: (resource) => resource.membershipPlan?.name,
  },
  {
    field: 'teamName',
    label: 'Team',
    flex: 2,
    formatter: (resource) => {
      const membership = resource;
      if (membership.teamMembership) {
        return (
          <>
            <MDButton color="info" onClick={() => window.open(`/admin/team-memberships/edit/${membership.teamMembership.id}`, '_blank')}><OpenInNewIcon/></MDButton>
            &nbsp;
            {membership.teamMembership.name}
            {membership.isTeamMembershipManager && <>&nbsp;(Manager)</>}
          </>
        );
      }
      return 'N/A';
    },
  },
  {
    field: 'membershipStartsAt',
    label: 'Starts',
    flex: 1,
    formatter: (resource) => {
      const membership = resource;
      if (membership.teamMembership) {
        return getDateLocaleString(membership.teamMembership.startsAt, 'date');
      }
      return getDateLocaleString(membership.startsAt, 'date');
    },
  },
  {
    field: 'membershipExpiresAt',
    label: 'Expires',
    flex: 1,
    formatter: (resource) => {
      const membership = resource;
      if (membership.teamMembership) {
        return getDateLocaleString(membership.teamMembership.expiresAt, 'date') || 'Never';
      }
      return getDateLocaleString(membership.expiresAt, 'date') || 'Never';
    },
  },
  {
    field: 'membershipIsActive',
    label: 'Active?',
    flex:1,
    formatter: (resource) => resource.isActive ? 'Yes' : 'No',
  },
];

const handleLogIntoWebFe = (userId) => {
  if (!userId) {
    return;
  }
  document.getElementById('web-fe-login-form-token').value = localStorage.getItem(adminSession);
  document.getElementById('web-fe-login-form-user-id').value = userId;
  document.getElementById('web-fe-login-form').submit();
}

const useStyles = makeStyles(() => ({
  tabPanel: {
    "padding": '0px',
    "margin-top": '0.5em'
  },
}));

const AddEditUserForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: user,
    validationSchema,
    refreshResource
  } = props;

  const classes = useStyles();

  const { individualLearningGroup = null } = user;

  const [selectedTab, setSelectedTab] = useState(individualLearningGroup !== null ? 'accesses' : 'memberships');

  const [passwordStrength, setPasswordStrength] = useState();
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  const [membershipToEdit, setMembershipToEdit] = useState(null);
  const [addEditMembershipModalOpen, setAddEditMembershipModalOpen] = useState(false);

  const { userFeedbackError, userFeedbackSuccess } = useAppContext();

  const { userHasRole } = useUser();
  const navigate = useNavigate();

  const editMembership = (resource) => {
    setMembershipToEdit(resource);
    setAddEditMembershipModalOpen(true);
  };

  const editMembershipModalClosed = () => {
    setAddEditMembershipModalOpen(false);
    refreshResource();
  }

  const handlePasswordOnChange = (e, setFieldValue) => {
    setFieldValue('password', e.target.value);
    setPasswordStrength(getPasswordStrength(e.target.value));
  }

  const handleGenerateOnClick = (setFieldValue) => {
    const generatedPassword = generatePassword();
    setFieldValue('password', generatedPassword);
    setPasswordStrength(getPasswordStrength(generatedPassword));
  }

  const handleDeleteUserOnClick = () => {
    setDeleteUserModalOpen(true);
  }

  const deleteUser = async () => {
    const response = await axios(putRequestQuery(`users/${user.id}/archive`));
    setDeleteUserModalOpen(false);
    if (response.status === 200) {
      userFeedbackSuccess('User deleted');
      navigate(`/admin/users`);
    } else {
      userFeedbackError('There was a problem deleting this user');
    }
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsSection = () => (
        <Box marginBottom={2}>
          <Card>
            <CardContent>
              <TabContext value={selectedTab}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs">
                  <Tab label="Group Access" value="accesses" />
                  <Tab label="Owned Licences" value="licences" />
                  <Tab label="Memberships (old)" value="memberships" />
                </Tabs>
                <TabPanel value="accesses" variant="fullWidth" className={classes.tabPanel}>
                  { user && (
                      <UserLearningGroupAccesses user={user}/>
                  )}
                </TabPanel>
                <TabPanel value="licences" className={classes.tabPanel}>
                  { individualLearningGroup &&
                      <LearningGroupLicences learningGroup={individualLearningGroup}/>
                  }
                  { !individualLearningGroup && (
                      <Box marginTop={3} textAlign="center">No learning group exists for this user.<br/>Licences cannot be added without a learning group.</Box>
                  )}
                </TabPanel>
                <TabPanel value="memberships" variant="fullWidth" className={classes.tabPanel}>
                  <MDAlert color="error">
                    <MDTypography variant="caption" color="white">
                      White Rose Maths memberships are shown here for information only.
                      All WRM memberships have been migrated to White Rose Education groups in the <strong>Group Access</strong> tab.
                    </MDTypography>
                  </MDAlert>
                  <Box marginTop={3}>
                    <ListResourcesTable
                        columns={membershipColumns}
                        resources={user.memberships ?? []}
                        onRowDoubleClick={editMembership}
                        disableOpenInNewTab
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Box>
    );


  return (
    <>
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Card>
            <CardContent>
              <Grid
                container
                mt={1}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mt={-11}>
                    <MDBox />
                    <MDBox display="flex">
                      <MDBox ml={1}>
                        <MDTypography variant="h6">
                          {
                            user.lastLoggedInAt
                              ?
                              <>Last logged in {getDateLocaleString(user.lastLoggedInAt)}</>
                              :
                              <>Never logged in</>
                          }
                          &nbsp;
                          <MDButton
                            color="error"
                            onClick={() => handleLogIntoWebFe(user.id ?? null)}
                          >
                            Log into website
                          </MDButton>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>

                <FormField
                  {...fields.find((field) => field.name === 'firstName')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'lastName')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <FormField
                  {...fields.find((field) => field.name === 'email')}
                  formik={formik}
                  getApiError={getApiError}
                />

                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={8}
                    >
                      <FormField
                        {...fields.find((field) => field.name === 'password')}
                        formik={formik}
                        getApiError={getApiError}
                        onChange={(e) => handlePasswordOnChange(e, formik.setFieldValue)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                    >
                      <MDButton
                        color="warning"
                        onClick={() => handleGenerateOnClick(formik.setFieldValue)}
                        variant="gradient"
                      >
                        Generate
                      </MDButton>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ color: passwordStrength?.color }}
                    >
                      <strong>{passwordStrength?.label}</strong>
                    </Grid>
                  </Grid>
                </Grid>

                {
                  (userHasRole('ROLE_SLT') || userHasRole('ROLE_ADMIN'))  && (
                    <FormField
                      {...fields.find((field) => field.name === 'roles')}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  )
                }

                <FormField
                  {...fields.find((field) => field.name === 'isEnabled')}
                  formik={formik}
                  getApiError={getApiError}
                />

                {
                  !user.id && (
                    <FormField
                      {...fields.find((field) => field.name === 'sendWelcomeEmail')}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  )
                }

                <Grid item xs={12}>
                  { tabsSection() }
                </Grid>

                <FormField
                  {...fields.find((field) => field.name === 'schools')}
                  formik={formik}
                  getApiError={getApiError}
                />
              </Grid>

            </CardContent>
            <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
              <SaveCancelButtons
                cancelLocation={cancelLocation}
                formik={formik}
              />
              {
                  user.id && !user.isArchived &&
                  <MDButton
                      color="error"
                      onClick={handleDeleteUserOnClick}
                  >
                    <DeleteIcon/> Delete user
                  </MDButton>
              }
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
    {/* delete user confirmation */}
    <ConfirmModal
        body="Are you sure you want to delete this user? THIS ACTION CANNOT BE UNDONE"
        callbackData={null}
        confirmCallback={deleteUser}
        open={deleteUserModalOpen}
        setOpen={(open) => setDeleteUserModalOpen(open)}
        title="Delete user?"
    />
    {/* Add Edit Membership Modal */}
    <Dialog
        open={addEditMembershipModalOpen}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-membership"
        aria-describedby="add-edit-membership"
    >
      <DialogContent>
        <AddEditMembership
            isModal
            membershipId={membershipToEdit?.id}
            onModalClose={editMembershipModalClosed}
        />
      </DialogContent>
    </Dialog>
    </>
  );
};

AddEditUserForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
  refreshResource: PropTypes.func.isRequired,
};

export default AddEditUserForm;
