import React, {useState} from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import PropTypes from 'prop-types';
import moment from "moment";

// MUI
import {Grid, Menu, MenuItem} from '@mui/material';
import FormField from 'components/shared/FormField';

// MDPR
import MDButton from "mdpr2/components/MDButton";

const DateRangeForm = ({ initStartDate , initEndDate, onUpdate }) => {

    const [startDate, setStartDate] = useState(initStartDate);
    const [endDate, setEndDate] = useState(initEndDate);

    // Preset dates
    const [presetDatesMenuAnchorEl, setPresetDatesMenuAnchorEl] = useState(null);

    const presetDatesMenuOpen = Boolean(presetDatesMenuAnchorEl);

    const handlePresetDatesOnClick = (event) => {
        setPresetDatesMenuAnchorEl(event.currentTarget);
    };

    const handlePresetDatesMenuOnClose = () => {
        setPresetDatesMenuAnchorEl(null);
    };

    const formSubmitted = (values) => {
        onUpdate(values);
    }

    const presetDateLabels = [
        'Today',
        'Yesterday',
        'Last week',
        'Week to date',
        'Month to date',
        'Last month',
        'Year to date'
    ];

    const handlePresetDatesOnSelect = (label) => {
        if (label === 'Today') {
            setStartDate(moment().utc());
            setEndDate(moment().utc());
        } else if (label === 'Yesterday') {
            setStartDate(moment().utc().subtract(1, 'days'));
            setEndDate(moment().utc().subtract(1, 'days'));
        } else if (label === 'Last week') {
            setStartDate(moment().utc().subtract(1, 'weeks').startOf('isoWeek'));
            setEndDate(moment().utc().subtract(1, 'weeks').endOf('isoWeek'));
        } else if (label === 'Week to date') {
            setStartDate(moment().utc().subtract(6, 'days'));
            setEndDate(moment().utc());
        } else if (label === 'Month to date') {
            setStartDate(moment().utc().startOf('month'));
            setEndDate(moment().utc());
        } else if (label === 'Last month') {
            setStartDate(moment().utc().subtract(1, 'months').startOf('month'));
            setEndDate(moment().utc().subtract(1, 'months').endOf('month'));
        } else if (label === 'Year to date') {
            setStartDate(moment().utc().startOf('year'));
            setEndDate(moment().utc());
        }
        setPresetDatesMenuAnchorEl(null);
    }

    return (
        <Formik
                enableReinitialize
                initialValues={{ startDate, endDate }}
                validationSchema={Yup.object().shape({
                    startDate: Yup.date()
                        .typeError('Invalid date format')
                        .required('Start date is required'),
                    endDate: Yup.date()
                        .typeError('Invalid date format')
                        .required('End date is required')
                        .min(Yup.ref('startDate'), 'End date must be later than start date'),
                })}
                onSubmit={formSubmitted}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={2}>
                                <MDButton
                                    color="info"
                                    onClick={handlePresetDatesOnClick}
                                    variant="gradient"
                                >
                                    Preset dates
                                </MDButton>
                                <Menu
                                    anchorEl={presetDatesMenuAnchorEl}
                                    onClose={handlePresetDatesMenuOnClose}
                                    open={presetDatesMenuOpen}
                                >
                                    { presetDateLabels.map(
                                        (presetDateLabel) =>
                                            <MenuItem onClick={() => handlePresetDatesOnSelect(presetDateLabel)}>
                                                { presetDateLabel }
                                            </MenuItem>
                                    )}
                                </Menu>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <FormField
                                    type="date"
                                    name="startDate"
                                    label="Start date"
                                    formik={formik}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <FormField
                                    type="date"
                                    name="endDate"
                                    label="End date"
                                    formik={formik}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <MDButton type="submit" color="primary">
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
    );
}

DateRangeForm.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    initStartDate: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    initEndDate:  PropTypes.object,
    onUpdate: PropTypes.func.isRequired
}

DateRangeForm.defaultProps = {
    initStartDate: moment().utc(),
    initEndDate: moment().utc(),
}

export default DateRangeForm;