/* eslint-disable */
import { useState } from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  Grid,
  Tab,
  Tabs as MuiTabs,
} from '@mui/material';

// WRM
// eslint-disable-next-line import/no-cycle
import FormField from './index';

const TabPanel = (props) => {
  const {
    children,
    index,
    value,
  } = props;

  return (
    <div
      hidden={value !== index}
      id={`tab-panel-${index}`}
    >
      {
        value === index
        && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )
      }
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Tabs = (props) => {
  const {
    childFields,
    formik,
    getApiError,
  } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabHasError = (childField) => {
    // eslint-disable-next-line no-shadow
    let tabHasError = false;
    // eslint-disable-next-line no-shadow
    childField.childFields?.forEach((childField) => {
      if (getIn(formik.errors, childField.name)) {
        tabHasError = true;
      }
    });
    return tabHasError;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          onChange={handleChange}
          value={value}
        >
          {
            childFields.map((childField, tabIndex) => (
              <Tab
                /* eslint-disable-next-line react/no-array-index-key */
                key={`tab-${tabIndex}`}
                label={childField.label}
                style={{ color: tabHasError(childField) ? 'red' : '' }}
              />
            ))
          }
        </MuiTabs>
      </Box>
      {
        childFields.map((childField, tabIndex) => (
          <TabPanel
            index={tabIndex}
            /* eslint-disable-next-line react/no-array-index-key */
            key={`tab-panel-${tabIndex}`}
            value={value}
          >
            <Grid
              container
              spacing={2}
            >
              {
                // eslint-disable-next-line no-shadow
                childField.childFields?.map((childField, fieldIndex) => (
                  <Grid
                    item
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`grid-${tabIndex}-${fieldIndex}`}
                    md={12}
                    xs={12}
                  >
                    <FormField
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...childField}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </TabPanel>
        ))
      }
    </Box>
  );
};

export default Tabs;
