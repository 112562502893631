import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'face-to-face-courses';
const resourceName = 'face-to-face course';

const subjectChoices = [
  {
    value: 'maths',
    label: 'Maths',
  },
  {
    value: 'science',
    label: 'Science',
  }
];

const audienceChoices = [
  {
    value: 'school',
    label: 'School',
  },
  {
    value: 'teacher',
    label: 'Teacher',
  }
];

const yearChoices = [
  {
    value: 'early-years',
    label: 'Early Years',
  },
  {
    value: 'primary',
    label: 'Primary (Y1-6)',
  },
  {
    value: 'secondary',
    label: 'Secondary (Y7-11)',
  },
  {
    value: 'year-1',
    label: 'Year 1',
  },
  {
    value: 'year-2',
    label: 'Year 2',
  },
  {
    value: 'year-3',
    label: 'Year 3',
  },
  {
    value: 'year-4',
    label: 'Year 4',
  },
  {
    value: 'year-5',
    label: 'Year 5',
  },
  {
    value: 'year-6',
    label: 'Year 6',
  },
  {
    value: 'year-7',
    label: 'Year 7',
  },
  {
    value: 'year-8',
    label: 'Year 8',
  },
  {
    value: 'year-9',
    label: 'Year 9',
  },
  {
    value: 'year-10',
    label: 'Year 10',
  },
  {
    value: 'year-11',
    label: 'Year 11',
  },
];

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'slug',
    label: 'Slug',
  },
  {
    name: 'subjects',
    label: 'Subject',
    type: 'selectAsTable',
    choices: subjectChoices,
  },
  {
    name: 'description',
    label: 'Description',
    type: 'richtext',
  },
  {
    name: 'imageUri',
    label: 'Image URL',
    type: 'filePicker',
  },
  {
    name: 'isWebinar',
    label: 'Delivered as a webinar?',
    type: 'checkbox',
  },
  {
    name: 'duration',
    label: 'Duration',
  },
  {
    name: 'suitability',
    label: 'Suitability',
  },
  {
    name: 'priceFrom',
    label: 'Price from',
    type: 'currency',
  },
  {
    name: 'audiences',
    label: 'Audience',
    type: 'selectAsTable',
    choices: audienceChoices,
  },
  {
    name: 'years',
    label: 'Years',
    type: 'selectAsTable',
    choices: yearChoices
  },
  {
    name: 'isSticky',
    label: 'Sticky within search results?',
    type: 'checkbox',
  },
  {
    name: 'isArchived',
    label: 'Archived',
    type: 'checkbox',
  },
];

const webinarValidDurations = ['45 minutes', '60 minutes', '90 minutes'];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
  duration: Yup.string().when('isWebinar', {
    is: true,
    then: Yup.string().oneOf(webinarValidDurations, `Webinar duration must be one of ${webinarValidDurations.join(', ')}`),
    otherwise: Yup.string()
  })
});

const AddEditFaceToFaceCourse = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditFaceToFaceCourse;
