/* eslint-disable */
import { FastField } from 'formik';
import PropTypes from 'prop-types';

// MUI
import { Grid } from '@mui/material';

// WRM
import Checkbox from './Checkbox';
import Currency from './Currency';
import Date from './Date';
import DateTime from './DateTime';
// eslint-disable-next-line import/no-cycle
import FieldCollection from './FieldCollection';
// eslint-disable-next-line import/no-cycle
import FieldGroup from './FieldGroup';
import FilePicker from './FilePicker';
import Password from './Password';
import Percentage from './Percentage';
import RichText from './RichText';
import Select from './Select';
import SelectAsTable from './SelectAsTable';
import Static from './Static';
// eslint-disable-next-line import/no-cycle
import Tabs from './Tabs';
import Text from './Text';
import Textarea from './Textarea';
import Typeahead from './Typeahead';

const FormField = (props) => {
  const {
    checkFieldRender,
    flex = 12,
    formik,
    getType = null,
    name,
    parent,
    showFileBrowser,
    type: formFieldType = 'text',
  } = props;

  const type = getType ? getType(formik.values, name) : formFieldType;

  const renderField = () => {
    if (type === 'checkbox') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Checkbox {...props} />
      );
    }

    if (type === 'currency') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Currency {...props} />
      );
    }

    if (type === 'percentage') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Percentage {...props} />
      );
    }

    if (type === 'date') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Date {...props} />
      );
    }

    if (type === 'datetime') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DateTime {...props} />
      );
    }

    if (type === 'fieldCollection') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FieldCollection {...props} />
      );
    }

    if (type === 'fieldGroup') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FieldGroup {...props} />
      );
    }

    if (['filePicker', 'imagePicker'].includes(type)) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FilePicker {...props} />
      );
    }

    if (type === 'password') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Password {...props} />
      );
    }

    if (type === 'richtext') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RichText {...props} showFileBrowser={showFileBrowser} />
      );
    }

    if (type === 'select') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Select {...props} />
      );
    }

    if (type === 'typeahead') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Typeahead {...props} />
      );
    }

    if (type === 'selectAsTable') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectAsTable {...props} />
      );
    }

    if (type === 'static') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Static {...props} />
      );
    }

    if (type === 'tabs') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Tabs {...props} />
      );
    }

    if (['decimal', 'number', 'text'].includes(type)) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Text {...props} />
      );
    }

    if (type === 'textarea') {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Textarea {...props} />
      );
    }

    return '';
  };

  const renderImagePickerAltTextField = () => {
    if (type === 'imagePicker') {
      const altTextProps = {...props};
      altTextProps.name = `${altTextProps.name}AltText`;
      altTextProps.label = `${altTextProps.label} alt text`;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Text {...altTextProps} />
      );
    }
  };

  if (checkFieldRender && parent && !checkFieldRender(parent, formik.values[parent], name)) return '';

  // A special case for the imagePicker field, which is rendered as two fields: a <FilePicker> and a <Text>
  if (type === 'imagePicker') {
    return (
      <Grid item key={`grid-${name}-container`} xs={flex}>
        <Grid
          container
          spacing={2}
        >
          <Grid item key={`grid-${name}`} xs={6}>
            <FastField name={name}>{renderField}</FastField>
          </Grid>
          <Grid item key={`grid-${name}AltText`} xs={6}>
            <FastField name={`${name}AltText`}>{renderImagePickerAltTextField}</FastField>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item key={`grid-${name}`} xs={flex}>
      <FastField name={name}>{renderField}</FastField>
    </Grid>
  );
};

FormField.propTypes = {
  checkFieldRender: PropTypes.func,
};

FormField.defaultProps = {
  checkFieldRender: null,
};

export default FormField;
