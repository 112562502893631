import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { debounce } from 'lodash';
import webSchoolFilterSearch from 'utils/webSchoolFilterSearch';
import AddEditLearningGroupForm from './AddEditLearningGroupForm';

const apiEndpoint = 'school-learning-groups';
const resourceName = 'School Group';

const buildFields = ({ debouncedSchoolFilterSearch }) => {
  const fields = [
    {
      name: 'name',
      label: 'School Group Name',
      flex: 7,
    },
    {
      name: 'school',
      label: 'School',
      type: 'typeahead',
      search: debouncedSchoolFilterSearch,
      formValue: (resource) =>
        resource?.school !== null ? { id: resource.school['@id'], label: resource.school.name } : null,
      flex: 7,
    },
  ];

  return fields;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  school: Yup.object().required('School is required').typeError('School is required'),
});

const AddEditSchoolLearningGroup = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const debouncedSchoolFilterSearch = useMemo(
    () => debounce((search, setOptions) => webSchoolFilterSearch(search, setOptions), 280),
    []
  );

  const fields = buildFields({ debouncedSchoolFilterSearch });

  return (
    <AddEditResource
      addEditForm={AddEditLearningGroupForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditSchoolLearningGroup;
