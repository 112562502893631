import getDateLocaleString from 'utils/get-date-locale-string';

// MUI
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// MDPR
import MDBadge from 'mdpr2/components/MDBadge';
import MDButton from "mdpr2/components/MDButton";

import componentStyles from './helpers.module.scss';

const shipmentIncomplete = (order, shipment) => !((order?.fulfilmentStatus?.shipments) ?? [])[shipment]?.completed;

const shipmentComplete = (order, shipment) => !shipmentIncomplete(order, shipment);

const bundlePartQuantityTotal = (order, returnsContext, orderLine, bundlePart) => {
    const { qtyShipped = {} } = returnsContext;
    const skuQtyShipped = qtyShipped[bundlePart.sku] ?? null;
    const shipment = bundlePart.shipment || 'main';

    // for bundle parts in completed shipments, show what we dispatched, not what is currently cancelled
    // as it may have been cancelled after the shipment was completed
    if(shipmentComplete(order, shipment) && skuQtyShipped !== null && orderLine.quantityCancelled > 0)
    {
        return Math.min(skuQtyShipped, orderLine.quantity * bundlePart.quantity);
    }

    // otherwise, use original calculation...
    return ((orderLine.quantity - orderLine.quantityCancelled) * bundlePart.quantity);
}

const getShipmentsFromOrder = ({ order, stockChangesData = {}, returnsContext = {} }) => {
    const shipments = [];
    shipments.main = [];

    const addLineDataToShipments = (shipment, lineData) => {
        const { sku, name, quantityTotal, quantityCancelled } = lineData;
        const { qtyShipped = {} } = returnsContext;
        const skuQtyShipped = qtyShipped[sku] ?? null;

        if (shipments[shipment] === undefined) {
            shipments[shipment] = [];
        }

        let line = shipments[shipment].find(existingLine => existingLine.sku === sku);

        // create a new line and add it to our shipments...
        if (!line) {
            line = { sku, name, quantityCancelled: 0, quantityTotal: 0 };
            shipments[shipment].push(line);
        }

        line.quantityTotal += quantityTotal;
        line.quantityCancelled += quantityCancelled;

        if (sku in stockChangesData && stockChangesData[sku][shipment]) {
            line.quantityDespatched = stockChangesData[sku][shipment].quantity || 0;
            line.despatchedAt = getDateLocaleString(stockChangesData[sku][shipment]?.createdAt, 'date');
        } else if (sku in stockChangesData && stockChangesData[sku].null) {
            // If we do not know what shipment a stock change is from, we cannot get despatch data
            line.quantityDespatched = 'unknown';
            line.despatchedAt = 'unknown';
        } else {
            // If product has no despatch stock changes
            line.quantityDespatched = 0;
            line.despatchedAt = 'date'
        }

        if (shipmentComplete(order, shipment) && line.quantityCancelled > 0 && skuQtyShipped !== null) {
            line.quantityTotal = Math.min(skuQtyShipped, line.quantityTotal);
        }
    }

    let quantityTotal = 0;

    order.orderLines.forEach(orderLine => {
        if (!orderLine.orderItem.isPhysicalProduct) return;

        if (!orderLine.orderItem.bundleParts || orderLine.orderItem.bundleParts.length === 0) {
            quantityTotal = orderLine.quantity - orderLine.quantityCancelled;
            if (quantityTotal === 0) {
                return;
            }
            if (!orderLine.orderItem.isPhysicalProduct) {
                return;
            }
            addLineDataToShipments('main', {
                sku: orderLine.orderItem.sku,
                name: orderLine.orderItem.name,
                quantityCancelled: orderLine.quantityCancelled,
                quantityTotal,
            });
            return;
        }

        orderLine.orderItem.bundleParts.forEach((bundlePart) => {
            if (!shipments[bundlePart.shipment || 'main']) {
                shipments[bundlePart.shipment || 'main'] = [];
            }
            quantityTotal = bundlePartQuantityTotal(order, returnsContext, orderLine, bundlePart);
            if (quantityTotal === 0) {
                return;
            }
            if (!bundlePart.isPhysicalProduct) {
                return;
            }
            addLineDataToShipments(bundlePart.shipment || 'main', {
                sku: bundlePart.sku,
                name: bundlePart.name,
                quantityCancelled: orderLine.quantityCancelled * bundlePart.quantity,
                quantityTotal,
            });
        });
    });
    if (shipments.main.length === 0) {
        delete shipments.main;
    }

    return shipments;
}

const buildShipmentStories = (order, stockChangesData, returnsContext, handleMarkAsCompleted, handleMarkDespatchAsCompleted) => {
    const shipments = getShipmentsFromOrder({ order, stockChangesData, returnsContext });

    const handleDespatch = (checkedArray, shipment) => {
        // remove any instances of non-checked values.
        const selectedSkus = checkedArray.reduce((acc, [selected, sku]) => {
            if (selected) acc.push(sku);
        
            return acc;
        }, []);
        if (selectedSkus.length < 1) {
            alert('Please select SKU(s) to despatch!');
            return;
        }

        handleMarkDespatchAsCompleted(shipment, selectedSkus);
    }

    const shipmentStories = [];
    const incompleteShipments = [];

    const findShipmentContainer = (event) => (event.target.closest('.shipment-item-container'));

    Object.keys(shipments).forEach((shipment) => {
        if (shipmentIncomplete(order, shipment)) {
            incompleteShipments.push(shipment);
        }
    });

    Object.keys(shipments).forEach(shipment => {
        let shipmentFulfilment = {};

        if(Object.keys(order.orderFulfilments).length > 0) {
            order.orderFulfilments.map((el) => {
                if (el.orderShipment.name === shipment) {
                    shipmentFulfilment = el;
                    return true;
                }
                return false;
            });
        }
        const shipmentFulfilled = shipmentFulfilment.isComplete;

        const previousDespatchedSkus = shipmentFulfilment.orderDespatches?.reduce((acc, curr) => {
            acc.push(...curr.stockChangeDespatches.map(i => i.actualProduct.sku))
            return acc;
        }, [])

        const expandedContainer = (element) => {
            findShipmentContainer(element).classList.remove(componentStyles['minimised-container']); // Remove so no need to worry about filtering on non-complete shipments
        }

        if (shipments[shipment].length === 0) {
            return;
        }
        let checkedArray = [];
        const description = (
            <>
                <div
                    className={`${shipmentFulfilled && componentStyles['minimised-container']} ${componentStyles['shipment-item-container']} shipment-item-container`} 
                    onClick={(e) => expandedContainer(e)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          expandedContainer(e);
                        }
                    }}
                    role='button'
                    tabIndex={0}
                    key={order.reference + shipment}
                >
                    <div 
                        className={componentStyles['shipment-item']} 
                    >
                        <input
                            className={componentStyles['item-checkbox']}
                            type='checkbox'
                            disabled={shipmentFulfilled}
                            onClick={(e) => {
                                const container = e.target.closest('.shipment-item-container');
                                const checkboxes = container.querySelectorAll('input[type="checkbox"]:enabled');
                                // eslint-disable-next-line no-param-reassign, no-return-assign
                                const checkboxArray = Array.from(checkboxes, checkbox => [checkbox.checked = e.target.checked, checkbox.value]).slice(1);
                                checkedArray = checkboxArray;
                            }}
                        />
                        <div className={componentStyles['item-information-container']}>
                            <div className={componentStyles['item-information']} >
                                <strong>All Shipment Products</strong>
                            </div>
                        </div>
                    </div>
                    {shipments[shipment].map((shipmentData) => (
                        <div 
                            className={componentStyles['shipment-item']} 
                            key={order.reference + shipment + shipmentData.sku}
                        >
                            <input
                                key={`shipmentSku-${shipmentData.sku}`}
                                className={componentStyles['item-checkbox']}
                                type='checkbox'
                                disabled={shipmentFulfilled || previousDespatchedSkus?.find((el) => el === shipmentData.sku)}
                                onClick={(e) => {
                                    const clickedSku = e.target.value;
                                    const checkedState = e.target.checked;
                                    const index = checkedArray.findIndex(arr => arr[1] === clickedSku);
                                    if (index !== -1) {
                                        checkedArray[index] = [checkedState, clickedSku];
                                    } else {
                                        checkedArray.push([checkedState, clickedSku]);
                                    }
                                    if (!checkedState) {
                                        const container = e.target.closest('.shipment-item-container');
                                        const checkboxes = container.querySelectorAll('input[type="checkbox"]');
                                        checkboxes[0].checked = false;
                                    }
                                }}
                                value={shipmentData.sku}
                            />

                            <div className={componentStyles['item-information-container']}>
                                <div className={componentStyles['item-information']} >
                                    <strong>{shipmentData.sku} x {shipmentData.quantityTotal}</strong>
                                    <MDBadge
                                        color={previousDespatchedSkus?.find((el) => el === shipmentData.sku) ? 'success' : 'warning'}
                                        size="sm"
                                        badgeContent={<><LocalShippingIcon />&nbsp;{shipmentData.quantityDespatched}</>}
                                    />
                                </div>
                                <small>{shipmentData.name}</small>
                            </div>
                        </div>
                    ))}
                </div>

                <div key={`despatch-management-container-${shipment}`}>
                    {shipmentFulfilled ? (
                            <>
                                <strong>Despatches:</strong>
                                <br />
                                    {shipmentFulfilment.orderDespatches.map((el) => (
                                        <>
                                            <span key={`despatch-id-${el.id}`}>Completed at: {getDateLocaleString(el.despatchedAt)}</span>
                                            <br />
                                        </>
                                        )
                                    )}
                            </> 
                            ) : order.status === 'processing' && (
                                <>
                                    <br />
                                    <MDButton
                                        size="small"
                                        variant="gradient"
                                        color="success"
                                        onClick={() => handleDespatch(checkedArray, shipment)}>
                                        Despatch Selected Sku&apos;s
                                    </MDButton>
                                </>
                            )
                    }
                </div>
            </>
        );
        const shipmentColor = shipmentFulfilled ? 'success' : 'warning';
        shipmentStories.push(
            {
                title: `${shipment.toUpperCase()} shipment`,
                icon: "local_shipping",
                color: shipmentColor,
                description
            }
        );
    });
    return shipmentStories;
}

export {
    getShipmentsFromOrder,
    buildShipmentStories
}