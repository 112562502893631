import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './ShippingServiceNameConditionsHelper.module.scss';

const ShippingServiceNameConditionsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedShippingServiceNameConditions = [];
    const movedShippingServiceNameCondition = formik.values.shippingServiceNameConditionsHelper[result.source.index];
    formik.values.shippingServiceNameConditionsHelper.forEach((shippingServiceNameCondition, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedShippingServiceNameConditions.push(movedShippingServiceNameCondition);
      }
      if (index !== result.source.index) {
        reorderedShippingServiceNameConditions.push(shippingServiceNameCondition);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedShippingServiceNameConditions.push(movedShippingServiceNameCondition);
      }
    });
    formik.setFieldValue('shippingServiceNameConditionsHelper', reorderedShippingServiceNameConditions);
  };

  return (
    <FieldArray name="shippingServiceNameConditionsHelper" render={fieldArrayHelpers => (
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <h4>Shipping service name conditions (match any)</h4>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <MDButton
                className={componentStyles['add-shipping-service-name-condition-button']}
                color="info"
                onClick={() => {
                  const prototype = {};
                  prototype.shippingServiceNameCondition = '';
                  prototype.uuid = moment().valueOf();
                  fieldArrayHelpers.push(prototype);
                }}
                size="small"
                startIcon={<AddIcon/>}
                variant="gradient"
              >
                Add shipping service name
              </MDButton>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Table>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                    direction="vertical"
                    droppableId="shipping-service-name-conditions-droppable"
                  >
                    {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            formik.values.shippingServiceNameConditionsHelper?.map((shippingServiceNameCondition, index) => {
                              const shippingServiceNameConditionFieldName = `shippingServiceNameConditionsHelper[${index}].shippingServiceNameCondition`;
                              return (
                                <Draggable
                                  draggableId={`shipping-service-name-condition-${shippingServiceNameCondition.uuid}`}
                                  index={index}
                                  key={`shipping-service-name-condition--${shippingServiceNameCondition.uuid}`}
                                >
                                  {
                                    (draggableProvided, snapshot) => (
                                      <TableRow
                                        ref={draggableProvided.innerRef}
                                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                                        {...draggableProvided.draggableProps}
                                        style={{
                                          ...draggableProvided.draggableProps.style,
                                          background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                        }}
                                      >
                                        <TableCell style={{ width: '1px' }}>
                                          <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                        </TableCell>
                                        <TableCell>
                                          <FormField
                                            name={shippingServiceNameConditionFieldName}
                                            label="Shipping service name"
                                            formik={formik}
                                            getApiError={getApiError}
                                          />
                                        </TableCell>
                                        <TableCell style={{ width: '1px' }}>
                                          <MDButton
                                            color="error"
                                            iconOnly
                                            onClick={() => {
                                              fieldArrayHelpers.remove(index);
                                            }}
                                            variant="gradient"
                                          >
                                            <DeleteIcon/>
                                          </MDButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                </Draggable>
                              );
                            })
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      )} />
  );
};

ShippingServiceNameConditionsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default ShippingServiceNameConditionsHelper;
