import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './PaymentMethodConditionsHelper.module.scss';

const PaymentMethodConditionsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const paymentMethodChoices = [
    {
      value:'invoice',
      label: 'Invoice',
    },
    {
      value:'card',
      label: 'Card',
    },
    {
      value:'paypal',
      label: 'PayPal',
    },
  ];

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedPaymentMethodConditions = [];
    const movedPaymentMethodCondition = formik.values.paymentMethodConditionsHelper[result.source.index];
    formik.values.paymentMethodConditionsHelper.forEach((paymentMethodCondition, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedPaymentMethodConditions.push(movedPaymentMethodCondition);
      }
      if (index !== result.source.index) {
        reorderedPaymentMethodConditions.push(paymentMethodCondition);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedPaymentMethodConditions.push(movedPaymentMethodCondition);
      }
    });
    formik.setFieldValue('paymentMethodConditionsHelper', reorderedPaymentMethodConditions);
  };

  return (
    <FieldArray name="paymentMethodConditionsHelper" render={fieldArrayHelpers => (
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <h4>Payment method conditions (match any)</h4>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MDButton
              className={componentStyles['add-payment-method-condition-button']}
              color="info"
              onClick={() => {
                const prototype = {};
                prototype.paymentMethodCondition = '';
                prototype.uuid = moment().valueOf();
                fieldArrayHelpers.push(prototype);
              }}
              size="small"
              startIcon={<AddIcon/>}
              variant="gradient"
            >
              Add payment method
            </MDButton>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Table>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  direction="vertical"
                  droppableId="payment-method-conditions-droppable"
                >
                  {
                    (droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {
                          formik.values.paymentMethodConditionsHelper?.map((paymentMethodCondition, index) => {
                            const paymentMethodConditionFieldName = `paymentMethodConditionsHelper[${index}].paymentMethodCondition`;
                            return (
                              <Draggable
                                draggableId={`payment-method-condition-${paymentMethodCondition.uuid}`}
                                index={index}
                                key={`payment-method-condition-${paymentMethodCondition.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                      }}
                                    >
                                      <TableCell style={{ width: '1px' }}>
                                        <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          type="select"
                                          name={paymentMethodConditionFieldName}
                                          label="Status"
                                          choices={paymentMethodChoices}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: '1px' }}>
                                        <MDButton
                                          color="error"
                                          iconOnly
                                          onClick={() => {
                                            fieldArrayHelpers.remove(index);
                                          }}
                                          variant="gradient"
                                        >
                                          <DeleteIcon/>
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </TableBody>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    )} />
  );
};

PaymentMethodConditionsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default PaymentMethodConditionsHelper;
