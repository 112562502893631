// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Webinars';
const apiEndpoint = 'webinar-courses';
const resourceName = 'webinar';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 3,
  },
  {
    field: 'startsAt',
    label: 'Starts',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.startsAt)
  },
  {
    field: 'endsAt',
    label: 'Ends',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateLocaleString(resource.endsAt)
  },
  {
    field: 'subjects',
    label: 'Subjects',
    flex: 1,
    formatter: (webinar) => webinar.subjects.join(", ")
  },
  {
    field: '_status',
    label: 'Status',
    sortable: false,
    flex: 0.5,
    formatter: (resource) => {
      const { isArchived } = resource;
      const color = isArchived ? 'warning' : 'success';
      return <MDBadge color={color} badgeContent={isArchived ? 'Archived' : 'Active'} />;
    },
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
];

const WebinarCourses = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default WebinarCourses;
