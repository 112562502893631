import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'orders';
const resourceName = 'invoice note';

const fields = [
  {
    name: 'invoiceNote',
    label: 'Invoice note',
    type: 'textarea',
  },
];

const validationSchema = Yup.object().shape({
  invoiceNote: Yup.string().nullable().default('')
});

const EditInvoiceNote = (props) => {
  const {
    onClose,
    resourceId
  } = props;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={resourceId}
      isModal
      onModalClose={onClose}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

EditInvoiceNote.propTypes = {
  onClose: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired,
}

export default EditInvoiceNote;
