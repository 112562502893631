import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import FileBrowserDialog from 'components/shared/FileBrowser/FileBrowserDialog';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';
import ProductBundleParts from './ProductBundleParts';
import ProductGroupParts from './ProductGroupParts';
import ProductRelationships from './ProductRelationships';
import ProductSummary from './ProductSummary';
import ProductVariants from './ProductVariants';

const AddEditProductForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: product,
    validationSchema,
  } = props;
  const [initialised, setInitialised] = useState(false);
  // Product summary
  const [productSummaryModalOpen,setProductSummaryModalOpen] = useState(false);
  // File browser
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [fileBrowserField, setFileBrowserField] = useState(null);
  const fileBrowserCallback = useRef(null);

  const isMounted = useMounted();

  // eslint-disable-next-line no-shadow
  const initialiseForm = useCallback(async ({ fields, product }) => {
    try {
      const productAttributeSet = await resourceApi.getResource({
        apiEndpoint: 'product-attribute-sets',
        id: product?.productAttributeSet?.id ?? 1 // use default,
      });

      const productAttributeGroupsResponse = await resourceApi.getResources({ apiEndpoint: 'product-attribute-groups' });
      const productAttributeGroups = productAttributeGroupsResponse.resources;
      const productAttributesResponse = await resourceApi.getResources({ apiEndpoint: 'product-attributes' });
      const productAttributes = productAttributesResponse.resources;

      if (productAttributeSet) {
        const productAttributeValueFields = [];
        productAttributeSet.productAttributeGroups.forEach((productAttributeGroup) => {
          const productAttributeGroupResource = productAttributeGroups.find((pag) => pag.id === productAttributeGroup.id);
          if (productAttributeGroupResource) {
            productAttributeGroupResource.productAttributes.forEach((productAttribute) => {
              const productAttributeResource = productAttributes.find((pa) => pa.id === productAttribute.id);
              if (productAttributeResource) {
                const fieldName = productAttributeResource.code;
                const fieldLabel = productAttributeResource.name;
                let fieldType = productAttributeResource.dataType;
                let fieldChoices = [];
                if (fieldType === 'single_select') {
                  fieldType = 'select';
                  fieldChoices = productAttributeResource.options;
                } else if (fieldType === 'multi_select') {
                  fieldType = 'selectAsTable';
                  fieldChoices = productAttributeResource.options;
                }
                productAttributeValueFields.push({
                  choices: fieldChoices,
                  label: fieldLabel,
                  name: fieldName,
                  type: fieldType,
                });
              }
            });
          }
        });
        const productAttributeValuesField = fields.find((field) => field.name === 'productAttributeValues');
        if (productAttributeValuesField) {
          productAttributeValuesField.childFields = productAttributeValueFields;
        }
      }
      if (isMounted()) {
        setInitialised(true);
      }
    } catch (error) {
      // TODO
    }
  }, [isMounted]);

  useEffect(() => {
    initialiseForm({ fields, product });
  }, [initialiseForm, fields, product]);

  if (!initialised) return '';

  // Product summary
  const showProductSummary = () => {
    setProductSummaryModalOpen(true);
  };
  const closeProductSummary = () => {
    setProductSummaryModalOpen(false);
  };

  // File browser
  const showFileBrowser = ((fieldOrCallback) => {
    if (typeof fieldOrCallback === 'string') {
      setFileBrowserField(fieldOrCallback);
    }
    if (typeof fieldOrCallback === 'function') {
      fileBrowserCallback.current = fieldOrCallback;
    }
    setFileBrowserOpen(true);
  });
  const closeFileBrowser = () => {
    setFileBrowserOpen(false);
  };

  const renderField = (field, formik) => {
    const { values } = formik;
    switch (field.name) {
      case 'packingUnits': // we want to render packingUnits and isPackingUnitsNull together
        return (
          <>
            <Grid
              item
              xs={9}
            >
              {
                values.isPackingUnitsNull && (
                  <FormField
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    name="packingUnitsDisabled"
                    label={field.label}
                    type={field.type}
                    formik={formik}
                    getApiError={getApiError}
                    disabled
                  />
                )
              }
              {
                !values.isPackingUnitsNull && (
                  <FormField
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...field}
                    formik={formik}
                    getApiError={getApiError}
                  />
                )
              }
            </Grid>
            <Grid
              item
              xs={3}
            >
              <FormField
                name="isPackingUnitsNull"
                type="checkbox"
                label="No shipping (eg digital product)"
                formik={formik}
              />
            </Grid>
          </>
        );
      default: // default case - applies to the majority of fields
        return(
          <FormField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            formik={formik}
            getApiError={getApiError}
            key={`form-field-${field.name}`}
            showFileBrowser={field.type === 'filePicker' || field.type === 'fieldCollection' ? showFileBrowser : null}
          />
        );
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <Form formik={formik}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    mt={1}
                    spacing={2}
                  >
                    <Grid
                      align="right"
                      item
                      xs={12}
                    >
                      <MDButton variant="gradient"color="info" onClick={showProductSummary}>
                        <AccountTreeIcon />&nbsp; Product summary
                      </MDButton>
                    </Grid>
                    {
                      fields.map(field => renderField(field, formik))
                    }
                    {/* Product variants */}
                    {
                      formik.values.type === 'variable' && (
                        <Grid
                          item
                          xs={12}
                        >
                          {
                            product.id
                              ?
                              <ProductVariants product={product} />
                              :
                              <h4>Please save this product before creating its variants</h4>
                          }
                        </Grid>
                      )
                    }

                    {/* Products in group */}
                    {
                      formik.values.type === 'group' && (
                        <Grid
                          item
                          xs={12}
                        >
                          {
                            product.id && (
                              <ProductGroupParts
                                formik={formik}
                                product={product}
                              />
                            )
                          }
                          {
                            !product.id &&
                            <h4>Please save this product before defining the products in the group</h4>
                          }
                        </Grid>
                      )
                    }

                    {/* Products in bundle */}
                    {
                      formik.values.isBundle && formik.values.type === 'simple' && (
                        <Grid
                          item
                          xs={12}
                        >
                          {
                            product.id && (
                              <ProductBundleParts
                                formik={formik}
                                product={product}
                              />
                            )
                          }
                          {
                            !product.id && (
                              <h4>Please save this product before defining its bundle products</h4>
                            )
                          }
                        </Grid>
                      )
                    }

                    {/* Related products */}
                    <Grid
                      item
                      xs={12}
                    >
                      {
                        product.id && (
                          <ProductRelationships
                            formik={formik}
                            product={product}
                          />
                        )
                      }
                      {
                        !product.id && (
                          <h4>Please save this product before defining its related products</h4>
                        )
                      }
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                  <SaveCancelButtons
                    cancelLocation={cancelLocation}
                    formik={formik}
                  />
                </CardActions>
              </Card>
            </Form>
            {
              fileBrowserOpen
              && (
                <FileBrowserDialog
                  fieldToUpdate={fileBrowserField}
                  setFieldValue={formik.setFieldValue}
                  callbackOnSelection={fileBrowserCallback.current || null}
                  closeFileBrowser={closeFileBrowser}
                />
              )
            }
          </>
        )}
      </Formik>
      <Dialog
        aria-labelledby="view-product-summary"
        aria-describedby="view-product-summary"
        fullWidth
        maxWidth={false}
        open={productSummaryModalOpen}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">Product summary</DialogTitle>
        <DialogContent>
          <ProductSummary product={product} />
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" onClick={closeProductSummary}>
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddEditProductForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
};

AddEditProductForm.defaultProps = {
  validationSchema: {},
};

export default AddEditProductForm;
