import { useMemo } from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { resourceApi } from 'api/resource-api';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';
import AddEditSageOrderExportForm from './AddEditSageOrderExportForm';

const apiEndpoint = 'sage-order-exports';
const resourceName = 'Sage order export';

const sageCustomerSearch = (search, setChoices) => {
  if (search.length) {
    resourceApi.getResources({
      apiEndpoint: 'sage-customers',
      filterValues: { search },
      sortValues: [{ field: 'name', direction: 'asc' }],
    }).then((response) => {
        const sageCustomers = response.resources;
        if (!Array.isArray(sageCustomers)) {
          setChoices([]);
        }
        const choices = sageCustomers.map((sageCustomer) => {
          const sageCustomerParts = [];
          if (sageCustomer?.code) sageCustomerParts.push(sageCustomer.code);
          if (sageCustomer?.name) sageCustomerParts.push(sageCustomer.name);
          if (sageCustomer?.fullAddress) sageCustomerParts.push(sageCustomer.fullAddress);
          return {
            id: sageCustomer['@id'],
            label: sageCustomerParts.join(' / '),
          };
        });
        setChoices(choices);
      });
  } else {
    setChoices([]);
  }
};

const fields = [
  {
    name: 'manuallyConfirmedSageCustomer',
    label: 'Or manually search for a Sage Customer',
    type: 'typeahead',
    formValue: () => null,
  }
];

const validationSchema = Yup.object().shape({
});

const toApiValuesCustom = (formValues, fieldsToMap) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fieldsToMap);

  if (formValues.manuallyConfirmedSageCustomer?.id) {
    apiValues.confirmedSageCustomer = formValues.manuallyConfirmedSageCustomer.id;
  }

  return apiValues;
}

const AddEditSageOrderExport = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const debounceDelayInMilliseconds = 280;
  const debouncedSageCustomerSearch = useMemo(
    () => debounce((search, setChoices) => sageCustomerSearch(search, setChoices), debounceDelayInMilliseconds),
    []
  );
  const manuallyConfirmedSageCustomerField = fields.find((field) => field.name === 'manuallyConfirmedSageCustomer');
  manuallyConfirmedSageCustomerField.search = debouncedSageCustomerSearch;

  return (
    <AddEditResource
      addEditForm={AddEditSageOrderExportForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditSageOrderExport;
