import { useState } from 'react';
import { Formik, setIn } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { requestApi } from 'api/request-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import { useAppContext } from 'contexts/app-context';
import AccessRolesHelper from './AccessRolesHelper';
import BillingCountryConditionsHelper from './BillingCountryConditionsHelper';
import CustomConditionsHelper from './CustomConditionsHelper';
import EmailRecipientsHelper from './EmailRecipientsHelper';
import ExportFieldsHelper from './ExportFieldsHelper';
import PaymentMethodConditionsHelper from './PaymentMethodConditionsHelper';
import ProductCategoryConditionsHelper from './ProductCategoryConditionsHelper';
import StatusConditionsHelper from './StatusConditionsHelper';
import ShippingServiceNameConditionsHelper from './ShippingServiceNameConditionsHelper';

const AddEditOrderReportForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: orderReport,
    validationSchema,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [orderReportGenerating, setOrderReportGenerating] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();

  const handleRunReportOnClick = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  let runReportInitialValues = {
    startDate: null,
    endDate: null,
  }
  orderReport.customConditions?.forEach((customCondition) => {
    if (customCondition.type === 'select') {
      runReportInitialValues = setIn(runReportInitialValues, customCondition.property, []);
    }
  });

  // eslint-disable-next-line no-shadow
  const handleRunReportNowOnClick = async (formValues) => {
    const startDate = formValues.startDate ? formValues.startDate : '';
    const endDate = formValues.endDate ? formValues.endDate : '';

    const customParameters = [];
    Object.keys(formValues).forEach(formValueKey => {
      const orderFormValues = formValues[formValueKey];
      if (typeof orderFormValues === 'boolean') {
        customParameters.push(`${formValueKey}=${orderFormValues}`);
      }
      if (formValueKey === 'Order' || formValueKey === 'OrderLine') {
        Object.keys(orderFormValues).forEach(orderFormValueProperty => {
          let orderFormValuePropertyValue = orderFormValues[orderFormValueProperty];
          if (orderFormValuePropertyValue.includes(',')) {
            orderFormValuePropertyValue = orderFormValuePropertyValue.split(',');
            orderFormValuePropertyValue = orderFormValuePropertyValue.map(value => value.trim())
          }
          if (Array.isArray(orderFormValuePropertyValue)) {
            orderFormValuePropertyValue.forEach(orderFormValuePropertyValueElement => {
              customParameters.push(`${formValueKey}.${orderFormValueProperty}[]=${orderFormValuePropertyValueElement}`);
            });
          } else {
            customParameters.push(`${formValueKey}.${orderFormValueProperty}=${orderFormValuePropertyValue}`);
          }
        });
      }
    });

    setShowLoadingSpinner(true);
    setOrderReportGenerating(true);
    const generateEndpoint = `order-reports/${orderReport.id}/generate?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}${customParameters.length > 0 ? `&${customParameters.join('&')}` : ''}`;
    const response = await requestApi.getResponse({
      url: generateEndpoint,
      rawResponse: true,
    });
    const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
    if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    setOrderReportGenerating(false);
    setShowLoadingSpinner(false);
    return downloadLink.click();
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
            <Card>
              <CardContent>
                <Grid
                  container
                  mt={1}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <MDButton
                      color="info"
                      onClick={handleRunReportOnClick}
                      variant="gradient"
                    >
                      Run report
                    </MDButton>
                  </Grid>

                  <FormField
                    {...fields.find((field) => field.name === 'name')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'filterByDateType')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <StatusConditionsHelper formik={formik} getApiError={getApiError} />

                  <ShippingServiceNameConditionsHelper formik={formik} getApiError={getApiError} />
                  <FormField
                    {...fields.find((field) => field.name === 'shippingServiceNameNotMatch')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <BillingCountryConditionsHelper formik={formik} getApiError={getApiError} />
                  <FormField
                    type="checkbox"
                    name="billingCountryConditionsNotHelper"
                    label="Match orders that do not match any of these billing countries"
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <PaymentMethodConditionsHelper formik={formik} getApiError={getApiError} />

                  <ProductCategoryConditionsHelper formik={formik} getApiError={getApiError} />

                  <FormField
                    {...fields.find((field) => field.name === 'includeCancelledOrderUnits')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'includeAllOrderUnitsInFilteredOrders')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <CustomConditionsHelper formik={formik} getApiError={getApiError} />

                  <ExportFieldsHelper formik={formik} getApiError={getApiError} />

                  <FormField
                    {...fields.find((field) => field.name === 'filenameFormat')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'schedule')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <EmailRecipientsHelper formik={formik} getApiError={getApiError} />

                  <FormField
                    {...fields.find((field) => field.name === 'emailSubject')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <AccessRolesHelper formik={formik} getApiError={getApiError} />

                  <FormField
                    {...fields.find((field) => field.name === 'isActive')}
                    formik={formik}
                    getApiError={getApiError}
                  />
                </Grid>
              </CardContent>
              <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                <SaveCancelButtons
                  cancelLocation={cancelLocation}
                  formik={formik}
                />
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>

      <Dialog
        open={modalOpen}
        onClose={closeModal}
        maxWidth="md"
        scroll="paper"
        aria-labelledby="run-order-report"
        aria-describedby="run-order-report"
      >
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <MDTypography variant="body3">
                        Dry Run Report: <b>{orderReport.name}</b>
                      </MDTypography>
                      <MDTypography variant="body2">
                        Please note that this will run a snapshot of the report, 
                        fetching you the data within the requested time while not 
                        updating the &quot;last run at&quot; time.
                      </MDTypography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <Formik
                        initialValues={runReportInitialValues}
                        validationSchema={Yup.object().shape({
                          startDate: Yup.date()
                            .nullable(),
                          endDate: Yup.date()
                            .nullable()
                            .min(Yup.ref('startDate'), 'End date must be later than start date'),
                        })}
                      >
                        {(formik) => (
                          <Grid
                            container
                            spacing={2}
                          >
                            <Grid
                              item
                              xs={5}
                            >
                              <FormField
                                type="date"
                                name="startDate"
                                label="Start date"
                                formik={formik}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={5}
                            >
                              <FormField
                                type="date"
                                name="endDate"
                                label="End date"
                                formik={formik}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                            >
                              <MDButton
                                color="info"
                                disabled={orderReportGenerating}
                                onClick={() => handleRunReportNowOnClick(formik.values)}
                                variant="gradient"
                              >
                                Run report
                              </MDButton>
                            </Grid>
                            {
                              orderReport.customConditions?.map((customCondition, index) => (
                                <Grid
                                  item
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={`grid-custom-condition-${index}`}
                                  xs={5}
                                >
                                  <FormField
                                    type={customCondition.type}
                                    name={customCondition.property}
                                    label={customCondition.label}
                                    choices={customCondition.choices ?? []}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                              ))
                            }
                          </Grid>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            color="info"
            onClick={closeModal}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddEditOrderReportForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddEditOrderReportForm;
