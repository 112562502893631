// WRM
import ListResources from 'components/shared/ListResources';
import { saleOrderOverrideChoices } from './constants';

const title = 'Iplicit product codes';
const apiEndpoint = 'iplicit-product-codes';
const resourceName = 'Iplicit product code';

const columns = [
  {
    field: 'product.sku',
    label: 'Product SKU',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.product.sku,
  },
  {
    field: 'product.name',
    label: 'Product name',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.product.name,
  },
  {
    field: 'nominalCode',
    label: 'Nominal code',
    sortable: true,
    flex: 1,
  },
  {
    field: 'departmentCode',
    label: 'Department code',
    sortable: true,
    flex: 1,
  },
  {
    field: 'saleOrderOverride',
    label: 'Sale order override',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.saleOrderOverride?.toUpperCase(),
  },
];

const filters = [
  {
    field: 'search',
    label: 'Search',
    type: 'text',
  },
  {
    field: 'saleOrderOverride',
    label: 'Sale order override',
    type: 'select',
    choices: saleOrderOverrideChoices,
  },
  {
    field: 'hasMissingCodes',
    label: 'Has missing code?',
    type: 'checkbox',
  },
];

const defaultFilterValues = {
  saleOrderOverride: [],
};

const defaultSortValues = [];

const IplicitProductCodes = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    disableAddResource
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default IplicitProductCodes;
