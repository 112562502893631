const mapMenuItemToTreeNode = (menuItem) => {
  const treeNode = {};
  // Work out the title for the tree node: use the label by preference or the WebPage/ResourcePage name/directUri
  if (menuItem.type === 'webPage') {
    treeNode.title = menuItem.label ? menuItem.label : menuItem.webPage?.name;
    treeNode.subtitle = `Web page: ${menuItem.webPage?.name}`;
  }
  if (menuItem.type === 'resourcePage') {
    treeNode.title = menuItem.label ? menuItem.label : menuItem.resourcePage?.name;
    treeNode.subtitle = `Resource page: ${menuItem.resourcePage?.name}`;
  }
  if (menuItem.type === 'directUri') {
    treeNode.title = menuItem.label ? menuItem.label : menuItem.directUri;
    treeNode.subtitle = `Direct URL: ${menuItem.directUri}`;
  }
  if (menuItem.type === 'imageUrl') {
    treeNode.title = menuItem.label ? menuItem.label : menuItem.imageUrl;
    treeNode.subtitle = `Image URL: ${menuItem.imageUrl}`;
  }

  // If this MenuItem was previously expanded, re-expand it
  const expandedMenuItemIds = JSON.parse(localStorage.getItem(`menus_expandedMenuItemIds`)) || [];
  treeNode.expanded = expandedMenuItemIds.includes(menuItem.id);

  // Iterate the MenuItem's childMenuItems and add the same number of tree children
  treeNode.children = [];
  menuItem.childMenuItems.forEach(childMenuItem => {
    treeNode.children.push(mapMenuItemToTreeNode(childMenuItem));
  });

  // Attach the MenuItem to the tree node and nullify its childMenuItems (the menu structure is in the tree itself)
  // deep copy menuItem when using in treeNode, otherwise we are losing/blanking childMenuItems on our original resource
  treeNode.menuItem = { ...menuItem };
  treeNode.menuItem.childMenuItems = [];

  return treeNode;
};

export const mapMenuToTree = (menu) => {
  const tree = [];
  menu.menuItems.forEach(menuItem => {
    tree.push(mapMenuItemToTreeNode(menuItem));
  });

  return tree;
};

export const mapTreeNodeToMenuItem = (treeNode, index) => {
  // make sure this recursive structure is a deep copy
  const menuItem = {...treeNode.menuItem};
  menuItem.childMenuItems = [];
  menuItem.displayOrder = index + 1;
  // eslint-disable-next-line no-shadow
  treeNode.children.forEach((childTreeNode, index) => {
    menuItem.childMenuItems.push(mapTreeNodeToMenuItem(childTreeNode, index));
  });

  return menuItem;
};
