import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import EditPaymentTransactionForm from './EditPaymentTransactionForm';

const apiEndpoint = 'payment-transactions';
const resourceName = 'payment';

const fields = [];

const validationSchema = Yup.object().shape();

const EditPaymentTransaction = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={EditPaymentTransactionForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default EditPaymentTransaction;
