import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// MUI
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from '@mui/material';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import CardHeader from "components/shared/CardHeader";
import StockItemsCollectionFormField from "./StockItemsCollectionFormField";

const getField = (fields, fieldName) => fields.filter((field) => field.name === fieldName)[0] ?? null

const AddStockReceivedRequestForm = (props) => {
    const {
        cancelLocation,
        fields,
        formValues,
        getApiError,
        onFormSubmit,
        validationSchema,
    } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={onFormSubmit}
            validateOnChange={false}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <Form formik={formik}>
                    <Card>
                        <CardHeader color="info" icon>
                            Stock received
                        </CardHeader>
                        <CardContent>
                            <Box mb={2}>
                                <Typography fontSize="medium">
                                    Record stock being received into a specific location.
                                </Typography>
                            </Box>
                            <Grid container rowSpacing={1.5}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <FormField
                                                {...getField(fields, 'location')}
                                                formik={formik}
                                                getApiError={getApiError}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} >
                                            <FormField
                                                {...getField(fields, 'supplier')}
                                                formik={formik}
                                                getApiError={getApiError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <StockItemsCollectionFormField
                                        {...getField(fields, 'items')}
                                        formik={formik}
                                        getApiError={getApiError}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormField
                                        {...getField(fields, 'notes')}
                                        formik={formik}
                                        getApiError={getApiError}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                            <SaveCancelButtons
                                cancelLocation={cancelLocation}
                                formik={formik}
                            />
                        </CardActions>
                    </Card>
                </Form>
            )}
        </Formik>
    );
};

AddStockReceivedRequestForm.propTypes = {
    cancelLocation: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formValues: PropTypes.object.isRequired,
    getApiError: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    validationSchema: PropTypes.object,
};

AddStockReceivedRequestForm.defaultProps = {
    validationSchema: {},
};

export default AddStockReceivedRequestForm;
