import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import PropTypes from 'prop-types';
import FormField from "../FormField"


const DeliveryAddress = (props) => {

    const {
        formik,
        fields,
        getApiError,
        resource,
    } = props;

    return (
        <Card>
            <CardHeader title="Delivery address" />
            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.company')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.line1')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.line2')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.townCity')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.postcode')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    {!resource.isQuote && (
                        <FormField
                            {...fields.find((field) => field.name === 'deliveryAddress.country')}
                            formik={formik}
                            getApiError={getApiError}
                            disabled={resource.isQuote}
                        />
                    )}

                    <FormField
                        {...fields.find((field) => field.name === 'customerNote')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />
                </Grid>
            </CardContent>
        </Card>
    )
}

DeliveryAddress.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    formik: PropTypes.any.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    getApiError: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
};

export default DeliveryAddress