import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';
import AddEditOrderReportForm from './AddEditOrderReportForm';

const apiEndpoint = 'order-reports';
const resourceName = 'order report';

const filterByDateTypeChoices = [
  {
    value: 'createdAt',
    label: 'Order date',
  },
  {
    value: 'paidAt',
    label: 'Paid date',
  },
];

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'filterByDateType',
    type: 'select',
    choices: filterByDateTypeChoices,
    defaultValue: 'createdAt',
  },
  {
    name: 'statusConditions',
    label: 'Status conditions (match any)',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'shippingServiceNameConditions',
    label: 'Shipping service name conditions (match any)',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'shippingServiceNameNotMatch',
    label: 'Match orders that do not match any of these shipping services',
    type: 'checkbox',
  },
  {
    name: 'billingCountryConditions',
    label: 'Billing country conditions (match any)',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'paymentMethodConditions',
    label: 'Payment menthod conditions (match any)',
    json: true,
    defaultValue: [],
  },
  {
    name: 'productCategoryConditions',
    label: 'Product category conditions (match any)',
    type: 'textarea',
    json: true,
    defaultValue: []
  },
  {
    name: 'includeCancelledOrderUnits',
    label: 'Include cancelled order lines?',
    type: 'checkbox'
  },
  {
    name: 'includeAllOrderUnitsInFilteredOrders',
    label: 'Include all products in filtered orders?',
    type: 'checkbox',
  },
  {
    name: 'customConditions',
    label: 'Custom conditions',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'exportFields',
    label: 'Export fields',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'filenameFormat',
    label: 'Filename format',
  },
  {
    name: 'schedule',
    label: 'Schedule',
  },
  {
    name: 'emailRecipients',
    label: 'Email recipients',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'emailSubject',
    label: 'Email subject',
  },
  {
    name: 'accessRoles',
    label: 'Access Roles',
    type: 'textarea',
    json: true,
    defaultValue: [],
  },
  {
    name: 'isActive',
    label: 'Active?',
    type: 'checkbox',
  },
];

Yup.addMethod(Yup.array, 'statusConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-status-conditions-helper`, errorMessage, function (conditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    conditionsHelperValues?.forEach((conditionsHelperValue, index) => {
      const { statusCondition } = conditionsHelperValue;
      if (!statusCondition) {
        errors.push(new ValidationError(
          `Status is required`,
          '',
          `statusConditionsHelper[${index}].statusCondition`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'shippingServiceNameConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-shipping-service-name-conditions-helper`, errorMessage, function (shippingServiceNameConditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    shippingServiceNameConditionsHelperValues?.forEach((shippingServiceNameConditionsHelperValue, index) => {
      const { shippingServiceNameCondition } = shippingServiceNameConditionsHelperValue;
      if (!shippingServiceNameCondition) {
        errors.push(new ValidationError(
          `Shipping service name is required`,
          '',
          `shippingServiceNameConditionsHelper[${index}].shippingServiceNameCondition`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'billingCountryConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-billing-country-conditions-helper`, errorMessage, function (billingCountryConditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    billingCountryConditionsHelperValues?.forEach((billingCountryConditionsHelperValue, index) => {
      const { billingCountryCondition } = billingCountryConditionsHelperValue;
      if (!billingCountryCondition) {
        errors.push(new ValidationError(
          `Billing country is required`,
          '',
          `billingCountryConditionsHelper[${index}].billingCountryCondition`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'paymentMethodConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-payment-method-conditions-helper`, errorMessage, function (paymentMethodConditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    paymentMethodConditionsHelperValues?.forEach((paymentMethodConditionsHelperValue, index) => {
      const { paymentMethodCondition } = paymentMethodConditionsHelperValue;
      if (!paymentMethodCondition) {
        errors.push(new ValidationError(
          `Payment method is required`,
          '',
          `paymentMethodConditionsHelper[${index}].paymentMethodCondition`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'productCategoryConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-product-category-conditions-helper`, errorMessage, function (productCategoryConditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    productCategoryConditionsHelperValues?.forEach((productCategoryConditionsHelperValue, index) => {
      const { productCategoryCondition } = productCategoryConditionsHelperValue;
      if (!productCategoryCondition) {
        errors.push(new ValidationError(
          `Product category is required`,
          '',
          `productCategoryConditionsHelper[${index}].productCategoryCondition`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'customConditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-custom-conditions-helper`, errorMessage, function (customConditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    customConditionsHelperValues?.forEach((customConditionsHelperValue, index) => {
      const { type, label, property } = customConditionsHelperValue;
      if (!type) {
        errors.push(new ValidationError(
          `Field type is required`,
          '',
          `customConditionsHelper[${index}].type`
        ));
      }
      if (!label) {
        errors.push(new ValidationError(
          `Field label is required`,
          '',
          `customConditionsHelper[${index}].label`
        ));
      }
      if (!property) {
        errors.push(new ValidationError(
          `Field property is required`,
          '',
          `customConditionsHelper[${index}].property`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'exportFieldsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-export-fields-helper`, errorMessage, function (exportFieldsHelperValues) {
    const { createError } = this;

    const errors = [];
    exportFieldsHelperValues?.forEach((exportFieldsHelperValue, index) => {
      const { label } = exportFieldsHelperValue;
      if (!label) {
        errors.push(new ValidationError(
          `Field label is required`,
          '',
          `exportFieldsHelper[${index}].label`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'emailRecipientsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-email-recipients-helper`, errorMessage, function (emailRecipientsHelperValues) {
    const { createError } = this;

    const errors = [];
    emailRecipientsHelperValues?.forEach((emailRecipientsHelperValue, index) => {
      const { emailRecipient } = emailRecipientsHelperValue;
      if (!emailRecipient) {
        errors.push(new ValidationError(
          `Email recipient is required`,
          '',
          `emailRecipientsHelper[${index}].emailRecipient`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

Yup.addMethod(Yup.array, 'accessRolesHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-access-roles-helper`, errorMessage, function (accessRolesHelperValues) {
    const { createError } = this;

    const errors = [];
    accessRolesHelperValues?.forEach((accessRolesHelperValue, index) => {
      const { accessRole } = accessRolesHelperValue;
      if (!accessRole) {
        errors.push(new ValidationError(
          `Access Role is required`,
          '',
          `accessRolesHelper[${index}].accessRole`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  filterByDateType: Yup.string()
    .required('Filter by date type is required'),
  statusConditions: Yup.string()
    .required('Status conditions is required')
    .test(
      'is-json',
      'Status conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  statusConditionsHelper: Yup.array()
    .statusConditionsHelper('Invalid status conditions'),
  shippingServiceNameConditions: Yup.string()
    .required('Shipping service name conditions is required')
    .test(
      'is-json',
      'Shipping service name conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  shippingServiceNameConditionsHelper: Yup.array()
    .shippingServiceNameConditionsHelper('Invalid shipping service name conditions'),
  billingCountryConditions: Yup.string()
    .required('Billing country conditions is required')
    .test(
      'is-json',
      'Billing country conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  billingCountryConditionsHelper: Yup.array()
    .billingCountryConditionsHelper('Invalid billing country conditions'),
  paymentMethodConditions: Yup.string()
    .required('Payment method conditions is required')
    .test(
      'is-json',
      'Payment method conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  paymentMethodConditionsHelper: Yup.array()
    .paymentMethodConditionsHelper('Invalid payment method conditions'),
  productCategoryConditions: Yup.string()
    .required('Product category conditions is required')
    .test(
      'is-json',
      'Product category conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  productCategoryConditionsHelper: Yup.array()
    .productCategoryConditionsHelper('Invalid product category conditions'),
  customConditions: Yup.string()
    .required('Custom conditions is required')
    .test(
      'is-json',
      'Custom conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  customConditionsHelper: Yup.array()
    .customConditionsHelper('Invalid custom conditions'),
  exportFields: Yup.string()
    .required('Export fields is required')
    .test(
      'is-json',
      'Export fields is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  exportFieldsHelper: Yup.array()
    .exportFieldsHelper('Invalid export fields'),
  filenameFormat: Yup.string()
    .required('Filename format is required'),
  emailRecipients: Yup.string()
    .required('Email recipients is required')
    .test(
      'is-json',
      'Email recipients is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  emailRecipientsHelper: Yup.array()
    .emailRecipientsHelper('Invalid email recipients'),
  emailSubject: Yup.string()
    .required('Email subject is required'),
  accessRoles: Yup.string()
  .required('Access roles are required')
  .test(
    'is-json',
    'Email recipients is not valid JSON',
    (value) => {
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    }
  ),
  accessRolesHelper: Yup.array()
  .accessRolesHelper('Invalid status conditions'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // Map statusConditions to statusConditionsHelper
  formValues.statusConditionsHelper = [];
  resource.statusConditions?.forEach((statusCondition, index) => {
    const statusConditionWithUuid = {};
    statusConditionWithUuid.statusCondition = statusCondition;
    statusConditionWithUuid.uuid = index;
    formValues.statusConditionsHelper.push(statusConditionWithUuid);
  });

  // Map shippingServiceNameConditions to shippingServiceNameConditionsHelper
  formValues.shippingServiceNameConditionsHelper = [];
  resource.shippingServiceNameConditions?.forEach((shippingServiceNameCondition, index) => {
    const shippingServiceNameConditionWithUuid = {};
    shippingServiceNameConditionWithUuid.shippingServiceNameCondition = shippingServiceNameCondition;
    shippingServiceNameConditionWithUuid.uuid = index;
    formValues.shippingServiceNameConditionsHelper.push(shippingServiceNameConditionWithUuid);
  });

  // Map billingCountryConditions to billingCountryConditionsHelper
  formValues.billingCountryConditionsHelper = [];
  formValues.billingCountryConditionsNotHelper = false;
  resource.billingCountryConditions?.forEach((billingCountryCondition, index) => {
    if (billingCountryCondition.values) {
      /* Example of more complex billingCountryConditions, when the 'NOT' condition is supplied
      [
        {
          "values": [
            "GB",
            "GBR"
          ],
          "condition": "NOT"
        }
      ]
       */
      billingCountryCondition.values.forEach((value, complexConditionIndex) => {
        const billingCountryConditionWithUuid = {};
        billingCountryConditionWithUuid.billingCountryCondition = value;
        billingCountryConditionWithUuid.uuid = complexConditionIndex;
        formValues.billingCountryConditionsHelper.push(billingCountryConditionWithUuid);
        formValues.billingCountryConditionsNotHelper = true;
      });
    } else {
      const billingCountryConditionWithUuid = {};
      billingCountryConditionWithUuid.billingCountryCondition = billingCountryCondition;
      billingCountryConditionWithUuid.uuid = index;
      formValues.billingCountryConditionsHelper.push(billingCountryConditionWithUuid);
    }
  });

  // Map paymentMethodConditions to paymentMethodConditionsHelper
  formValues.paymentMethodConditionsHelper = [];
  resource.paymentMethodConditions?.forEach((paymentMethodCondition, index) => {
    const paymentMethodConditionWithUuid = {};
    paymentMethodConditionWithUuid.paymentMethodCondition = paymentMethodCondition;
    paymentMethodConditionWithUuid.uuid = index;
    formValues.paymentMethodConditionsHelper.push(paymentMethodConditionWithUuid);
  });

  // Map productCategoryConditions to productCategoryConditionsHelper
  formValues.productCategoryConditionsHelper = [];
  resource.productCategoryConditions?.forEach((productCategoryCondition, index) => {
    const productCategoryConditionWithUuid = {};
    productCategoryConditionWithUuid.productCategoryCondition = productCategoryCondition;
    productCategoryConditionWithUuid.uuid = index;
    formValues.productCategoryConditionsHelper.push(productCategoryConditionWithUuid);
  });

  // Map customConditions to customConditionsHelper
  formValues.customConditionsHelper = [];
  resource.customConditions?.forEach((customCondition, index) => {
    const customConditionWithUuid = {};
    customConditionWithUuid.type = customCondition.type;
    customConditionWithUuid.choices = JSON.stringify(customCondition.choices);
    customConditionWithUuid.property = customCondition.property;
    customConditionWithUuid.label = customCondition.label;
    customConditionWithUuid.description = customCondition.description;
    customConditionWithUuid.default = customCondition.default ?? null;
    customConditionWithUuid.uuid = index;
    formValues.customConditionsHelper.push(customConditionWithUuid);
  });

  // Map exportFields to exportFieldsHelper
  formValues.exportFieldsHelper = [];
  resource.exportFields?.forEach((exportField, index) => {
    const exportFieldWithUuid = {};
    exportFieldWithUuid.property = exportField.property;
    exportFieldWithUuid.label = exportField.label;
    exportFieldWithUuid.uuid = index;
    formValues.exportFieldsHelper.push(exportFieldWithUuid);
  });

  // Map emailRecipients to emailRecipientsHelper
  formValues.emailRecipientsHelper = [];
  resource.emailRecipients?.forEach((emailRecipient, index) => {
    const emailRecipientWithUuid = {};
    emailRecipientWithUuid.emailRecipient = emailRecipient;
    emailRecipientWithUuid.uuid = index;
    formValues.emailRecipientsHelper.push(emailRecipientWithUuid);
  });

  // Map accessRoles to accessRolesHelper
  formValues.accessRolesHelper = [];
  resource.accessRoles?.forEach((accessRole, index) => {
    const accessRoleWithUuid = {};
    accessRoleWithUuid.accessRole = accessRole;
    accessRoleWithUuid.uuid = index;
    formValues.accessRolesHelper.push(accessRoleWithUuid);
  });

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // Map statusConditionsHelper to statusConditions
  apiValues.statusConditions = [];
  formValues.statusConditionsHelper.forEach((statusConditionHelper) => {
    const statusCondition = statusConditionHelper.statusCondition ?? null;
    apiValues.statusConditions.push(statusCondition);
  });

  // Map shippingServiceNameConditionsHelper to shippingServiceNameConditions
  apiValues.shippingServiceNameConditions = [];
  formValues.shippingServiceNameConditionsHelper.forEach((shippingServiceNameConditionHelper) => {
    const shippingServiceNameCondition = shippingServiceNameConditionHelper.shippingServiceNameCondition ?? null;
    apiValues.shippingServiceNameConditions.push(shippingServiceNameCondition);
  });

  // Map billingCountryConditionsHelper to billingCountryConditions
  apiValues.billingCountryConditions = [];
  if (formValues.billingCountryConditionsNotHelper) {
    const billingCountryCondition = {};
    billingCountryCondition.condition = 'NOT';
    billingCountryCondition.values = [];
    formValues.billingCountryConditionsHelper.forEach((billingCountryConditionHelper) => {
      const billingCountryConditionValue = billingCountryConditionHelper.billingCountryCondition ?? null;
      billingCountryCondition.values.push(billingCountryConditionValue);
    });
    apiValues.billingCountryConditions.push(billingCountryCondition);
  } else {
    formValues.billingCountryConditionsHelper.forEach((billingCountryConditionHelper) => {
      const billingCountryCondition = billingCountryConditionHelper.billingCountryCondition ?? null;
      apiValues.billingCountryConditions.push(billingCountryCondition);
    });
  }

  // Map paymentMethodConditionsHelper to paymentMethodConditions
  apiValues.paymentMethodConditions = [];
  formValues.paymentMethodConditionsHelper.forEach((paymentMethodConditionHelper) => {
    const paymentMethodCondition = paymentMethodConditionHelper.paymentMethodCondition ?? null;
    apiValues.paymentMethodConditions.push(paymentMethodCondition);
  });

  // Map productCategoryConditionsHelper to productCategoryConditions
  apiValues.productCategoryConditions = [];
  formValues.productCategoryConditionsHelper.forEach((productCategoryConditionHelper) => {
    const productCategoryCondition = productCategoryConditionHelper.productCategoryCondition ?? null;
    apiValues.productCategoryConditions.push(productCategoryCondition);
  });

  // Map customConditionsHelper to customConditions
  apiValues.customConditions = [];
  formValues.customConditionsHelper.forEach((customConditionHelper) => {
    const customCondition = {
      type: customConditionHelper.type,
      choices: customConditionHelper.choices ? JSON.parse(customConditionHelper.choices) : [],
      property: customConditionHelper.property,
      label: customConditionHelper.label,
      description: customConditionHelper.description,
      default: customConditionHelper.default ?? null,
    };

    apiValues.customConditions.push(customCondition);
  });

  // Map exportFieldsHelper to exportFields
  apiValues.exportFields = [];
  formValues.exportFieldsHelper.forEach((exportFieldHelper) => {
    const exportField = {
      property: exportFieldHelper.property,
      label: exportFieldHelper.label,
    };
    apiValues.exportFields.push(exportField);
  });

  // Map emailRecipientsHelper to emailRecipients
  apiValues.emailRecipients = [];
  formValues.emailRecipientsHelper.forEach((emailRecipientHelper) => {
    const emailRecipient = emailRecipientHelper.emailRecipient ?? null;
    apiValues.emailRecipients.push(emailRecipient);
  });

  // Map accessRolesHelper to accessRoles
  apiValues.accessRoles = [];
  formValues.accessRolesHelper.forEach((accessRolesHelper) => {
    const accessRole = accessRolesHelper.accessRole ?? null;
    apiValues.accessRoles.push(accessRole);
  });

  return apiValues;
}

const AddEditOrderReport = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={AddEditOrderReportForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditOrderReport;
