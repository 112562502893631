import {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const userFeedbackError = (message) => {
    toast.error(message);
  };

  const userFeedbackSuccess = (message) => {
    toast.success(message);
  };

  const value = useMemo(() => ({
    showLoadingSpinner,
    setShowLoadingSpinner,
    userFeedbackError,
    userFeedbackSuccess,
  }), [showLoadingSpinner]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppContext = () => useContext(AppContext);
