export const sectionChoices = [
    {
      value: 'resources',
      label: 'Resources',
    },
    {
      value: 'parent-resources',
      label: 'Parent and child resources',
    },
    {
      value: 'ta-hub',
      label: 'TA Hub',
    },
    {
      value: 'australia-resources',
      label: 'Australia Resources',
    },
  ];
  
  export const myAccountSectionChoices = [
    {
      value: 'primary_maths',
      label: 'Primary Maths',
    },
    {
      value: 'fluency_bee',
      label: 'Fluency Bee',
    },
    {
      value: 'ta_hub',
      label: 'TA Hub',
    },
    {
      value: 'primary_intervention_programme',
      label: 'Primary Intervention Programme',
    },
    {
      value: 'train_the_tutor',
      label: 'Train the Tutor',
    },
    {
      value: 'primary_maths_archive',
      label: 'Primary Maths - Archive',
    },
    {
      value: 'primary_maths_mixed_age',
      label: 'Primary Maths - Mixed Age',
    },
    {
      value: 'primary_maths_mixed_age_archive',
      label: 'Primary Maths - Mixed Age - Archive',
    },
    {
      value: 'primary_science',
      label: 'Primary Science',
    },
    {
      value: 'secondary-maths',
      label: 'Secondary Maths',
    },
    {
      value: 'intervention',
      label: 'Intervention',
    },
  ];
  