import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import EditOrderForm from './EditOrderForm';

const apiEndpoint = 'orders';
const resourceName = 'order';

const fields = [];

const validationSchema = Yup.object().shape({});

const EditOrder = (props) => {
  const {
    context,
  } = props;

  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={EditOrderForm}
      addEditFormContext={context}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

EditOrder.propTypes = {
  context: PropTypes.string,
};

EditOrder.defaultProps = {
  context: 'orders',
}

export default EditOrder;
