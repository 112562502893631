import ListResources from 'components/shared/ListResources';
import PropTypes from 'prop-types';

const columns = [
  {
    field: 'name',
    label: 'Name',
    formatter: ({ user }) => `${user.firstName ?? ''} ${user.lastName ?? ''}`,
    flex: 1,
  },
  {
    field: 'user.email',
    label: 'email',
    formatter: ({ user }) => user.email,
    flex: 2,
  },
  {
    field: 'isMisVerified',
    label: 'Is MIS verified?',
    formatter: ({isMisVerified}) => {
      const color = isMisVerified ? 'green' : 'red';

      return <b style={{ color }}>{isMisVerified ? 'Yes' : 'No'}</b>;
    },
    flex: 2,
  },
];

const filters = [];

const MisVerifications = ({ learningGroupId }) => {
  const title = 'Group access verifications';
  const apiEndpoint = `admin/infinity/learning-groups/${learningGroupId}/verifications`;
  const resourceName = 'verifications';
  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      customItemsPerPage={20}
      filters={filters}
      resourceName={resourceName}
      title={title}
      disableAddResource
      disableEditResource
      disableOpenInNewTab
    />
  );
};

MisVerifications.propTypes = {
  learningGroupId: PropTypes.number.isRequired,
};

export default MisVerifications;
