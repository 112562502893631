export const getResourcesState = (entity, customItemsPerPage = null) => {
  if (localStorage.getItem(`${entity}__resources__`)) {
    return JSON.parse(localStorage.getItem(`${entity}__resources__`));
  }
  return {
    filterValues: {},
    sortValues: [],
    pagination: { itemsPerPage: customItemsPerPage ?? 30 },
  };
};

export const setResourcesState = (entity, resourcesState) => {
  localStorage.setItem(`${entity}__resources__`, JSON.stringify(resourcesState));
};
