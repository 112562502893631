import { useCallback, useEffect, useState } from 'react';

// MUI
import {
  Card,
  CardContent,
  CardHeader,
  TextField
} from '@mui/material';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRE
import { requestApi } from 'api/request-api';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const MultiFactorAuthentication = () => {
  const [secret, setSecret] = useState();
  const [qrCode, setQrCode] = useState();
  const [showQrCode, setShowQrCode] = useState(false);
  const [confirm, setConfirm] = useState();
  
  const { userFeedbackError, userFeedbackSuccess } = useAppContext();
  const isMounted = useMounted();

  const getGoogleAuthenticatorQrCode = async () => {
    const response = await requestApi.getResponse({
      url: 'generate-google-authenticator-qr-code'
    });
    setSecret(response.secret);
    setQrCode(response.qrCode);
    setShowQrCode(!response.isGoogleAuthenticatorEnabled);
  };

  const resetGoogleAuthenticatorQrCode = async () => {
    setShowQrCode(true);
  };

  const handleConfirm = async () => {
    const response = await requestApi.postResponse({
      url: 'confirm-google-authenticator-qr-code',
      data: {
        secret,
        confirm
      },
    });
    if (response.success) {
      userFeedbackSuccess('Multi-factor authentication configured successfully');
      setShowQrCode(false);
    } else {
      userFeedbackError('The MFA code entered is incorrect');
    }
  };

  const initialise = useCallback(async () => {
    if (!isMounted()) {
      return;
    }
  
    getGoogleAuthenticatorQrCode();
  }, [isMounted]);
  
  useEffect(() => {
    initialise();
  }, [initialise]);

  useEffect(() => {
    initialise();
  }, []);
  
  return (
    <MDBox>
      <MDBox mb={2}>
        <MDTypography variant="h5">Multi-factor authentication</MDTypography>
      </MDBox>
      <Card>
        <CardHeader color="info">
          Multi-factor authentication
        </CardHeader>
        <CardContent>
          {
            (showQrCode && qrCode) ?
            <>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <MDTypography variant="subtitle2">
                  Scan this QR code using Google Authenticator or Microsoft Authenticator and enter the first MFA code displayed
                </MDTypography>
              </MDBox>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <img alt="" src={`data:image/png;base64,${qrCode}`} /> 
              </MDBox>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <TextField
                  label="Enter MFA code (6 digits)"
                  variant="outlined"
                  onChange={(e) => setConfirm(e.target.value)}
                />
              </MDBox>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <MDButton
                  color="info"
                  type="submit"
                  onClick={handleConfirm}
                >
                  Confirm multi-factor authentication
                </MDButton>
              </MDBox>
            </>
            :
            <>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <MDTypography variant="subtitle2">Multi-factor authentication is enabled for your account</MDTypography>
              </MDBox>
              <MDBox
                mb={2}
                textAlign="center"
              >
                <MDButton
                  color="info"
                  type="submit"
                  onClick={resetGoogleAuthenticatorQrCode}
                >
                  Reset multi-factor authentication
                </MDButton>
              </MDBox>
            </>
          }
        </CardContent>
      </Card>
    </MDBox>
  );
}

export default MultiFactorAuthentication;
