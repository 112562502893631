import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import PropTypes from 'prop-types';
import FormField from "../FormField";

const BillingAddress = (props) => {

    const { formik, getApiError, fields, resource } = props;

    return (
        <Card>
            <CardHeader title="Billing address" />
            <CardContent>
                <Grid container spacing={2} >
                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.company')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.line1')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.line2')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.townCity')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.postcode')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />
                    {!resource.isQuote && (
                        <FormField
                            {...fields.find((field) => field.name === 'billingAddress.country')}
                            formik={formik}
                            getApiError={getApiError}
                            disabled={resource.isQuote}
                        />
                    )}

                </Grid>
            </CardContent>
        </Card>
    );
}

BillingAddress.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    formik: PropTypes.any.isRequired,
    setBasket: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    getApiError: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
};

export default BillingAddress;