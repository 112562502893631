import { customAlphabet } from 'nanoid';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';
import AddEditSingleUseDiscountForm from './AddEditSingleUseDiscountForm';

const apiEndpoint = 'promotions';
const adminEndpoint = 'single-use-discounts';
const resourceName = 'single use discount';

const typeChoices = [
  {
    value: '',
    label: '',
  },
  {
    value: 'fixed',
    label: 'Fixed discount',
  },
  {
    value: 'percentage',
    label: 'Percentage discount',
  },
];

const statusChoices = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];

const fields = [
  {
    name: 'promotionCodeCode',
    label: 'Discount code',
  },
  {
    name: 'type',
    label: 'Discount type',
    type: 'select',
    choices: typeChoices,
  },
  {
    name: 'fixedValue',
    label: 'Fixed discount',
    type: 'currency',
  },
  {
    name: 'percentageValue',
    label: 'Percentage discount',
    type: 'number',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    choices: statusChoices,
  },
];

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .nullable()
    .required('Discount type is required'),
  fixedValue: Yup.number().when('type', {
    is: 'fixed',
    then: Yup.number().typeError('Invalid amount').required('Fixed discount is required'),
    otherwise: Yup.number().nullable(),
  }),
  percentageValue: Yup.number().when('type', {
    is: 'percentage',
    then: Yup.number().typeError('Invalid percentage').required('Percentage discount is required'),
    otherwise: Yup.number().nullable(),
  }),
  description: Yup.string()
    .nullable(),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  formValues.name = resource.name || null;
  formValues.code = resource.code || null;
  formValues.status = resource.status || 'active';
  formValues.description = resource.description || null;
  formValues.conditions = resource.conditions || null;
  // Parse discounts into type, fixedValue, percentageValue
  formValues.type = resource.discounts?.length > 0 ? resource.discounts[0].type : null;
  formValues.fixedValue = (resource.discounts?.length > 0 && resource.discounts[0].type === 'fixed') ? (resource.discounts[0].value / 100) : null;
  formValues.percentageValue = (resource.discounts?.length > 0 && resource.discounts[0].type === 'percentage') ? resource.discounts[0].value : null;
  formValues.startsAt = resource.startsAt || null;
  formValues.endsAt = resource.endsAt || null;
  formValues.maxiumumUses = resource.maxiumumUses || null;
  if (resource.promotionCodes?.length > 0) {
    formValues.promotionCodeIri = resource.promotionCodes[0]['@id'];
    formValues.promotionCodeCode = resource.promotionCodes[0].code;
  } else {
    const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);
    formValues.promotionCodeCode = nanoid();
  }

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  apiValues.name = `Single use discount ${formValues.promotionCodeCode}`;
  apiValues.code =  `single_use_discount_${formValues.promotionCodeCode}`;
  apiValues.status = formValues.status || 'active';
  apiValues.description = formValues.description || null;
  apiValues.conditions = formValues.conditions || [];
  // Parse type, fixedValue, percentageValues into discounts
  apiValues.discounts = [
    {type: formValues.type, value: formValues.type === 'percentage' ? formValues.percentageValue || 0 : (formValues.fixedValue * 100)}
  ];
  apiValues.startsAt = formValues.startsAt || null;
  apiValues.endsAt = formValues.endsAt || null;
  apiValues.maximumUses = formValues.maximumUses || 1;
  const promotionCodes = [];
  if (formValues.promotionCodeCode) {
    const promotionCode = {};
    if (formValues.promotionCodeIri) {
      promotionCode['@id'] = formValues.promotionCodeIri;
    }
    promotionCode.code = formValues.promotionCodeCode;
    promotionCodes.push(promotionCode);
  }
  apiValues.promotionCodes = promotionCodes;

  return apiValues;
}

const AddEditSingleUseDiscount = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={AddEditSingleUseDiscountForm}
      adminEndpoint={adminEndpoint}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditSingleUseDiscount;
