import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import AddEditMembershipForm from './AddEditMembershipForm';

const apiEndpoint = 'memberships';
const resourceName = 'membership';

let preloadedMembershipPlanChoices = null;

const buildFields = (membershipPlanChoices, teamMembership) => [
  {
    name: 'user',
    label: 'User',
    type: 'select', // select because user is a subresource
    choices: [],
  },
  {
    name: 'teamMembership',
    label: 'Team',
    type: 'select',
    choices: [],
    defaultValue: teamMembership
  },
  {
    name: 'isTeamMembershipManager',
    label: 'Manager of team?',
    type: 'checkbox'
  },
  {
    name: 'membershipPlan',
    label: 'Membership plan',
    type: 'select',
    choices: membershipPlanChoices,
    ...(teamMembership ? { defaultValue: teamMembership.membershipPlan} : {})
  },
  {
    name: 'startsAt',
    label: 'Starts',
    type: 'date',
    defaultValue: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00'),
  },
  {
    name: 'expiresAt',
    label: 'Expires',
    type: 'date',
    defaultValue: moment().utc().add(1, 'Y').format('YYYY-MM-DDTHH:mm:ss+00:00')
  },
  {
    name: 'isActive',
    label: 'Active?',
    type: 'checkbox',
    defaultValue: true,
  },
];

const validationSchema = Yup.object().shape({
  user: Yup.string()
    .required('User is required')
    .notOneOf(['none'], 'User is required'), // Capture 'no selection'
  membershipPlan: Yup.string()
    .matches(/^\/membership/, 'Membership plan is required'),
  startsAt: Yup.date()
    .typeError('Invalid date format')
    .nullable(),
  expiresAt: Yup.date()
    .typeError('Invalid date format')
    .nullable()
    .min(Yup.ref('startsAt'), 'Expiry date must be later than start date'),
});

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields, resource) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // for team membership starts/expires fields are set at team membership level
  if (apiValues.teamMembership !== null) {
    delete apiValues.startsAt;
    delete apiValues.expiresAt;
    return apiValues;
  }

  // otherwise - non-team membership
  delete apiValues.isTeamMembershipManager;

  if (typeof formValues.startsAt === 'object' && formValues.startsAt !== null) {
    const oldStartsAt = resource.startsAt ? moment.utc(resource.startsAt) : null;
    const newStartsAt = formValues.startsAt;
    newStartsAt.set('hour', oldStartsAt ? oldStartsAt.get('hour') : 0);
    newStartsAt.set('minute', oldStartsAt ? oldStartsAt.get('minute') : 0);
    newStartsAt.set('second', oldStartsAt ? oldStartsAt.get('second') : 0);
    apiValues.startsAt = newStartsAt.format('YYYY-MM-DDTHH:mm:ss+00:00');
  }

  if (typeof formValues.expiresAt === 'object' && formValues.expiresAt !== null) {
    const oldExpiresAt = resource.expiresAt ? moment.utc(resource.expiresAt) : null;
    const newExpiresAt = formValues.expiresAt;
    newExpiresAt.set('hour', oldExpiresAt ? oldExpiresAt.get('hour') : 23);
    newExpiresAt.set('minute', oldExpiresAt ? oldExpiresAt.get('minute') : 59);
    newExpiresAt.set('second', oldExpiresAt ? oldExpiresAt.get('second') : 59);
    apiValues.expiresAt = newExpiresAt.format('YYYY-MM-DDTHH:mm:ss+00:00');
  }

  return apiValues;
}

const AddEditMembership = (props) => {
  const {
    isModal,
    membershipId,
    onModalClose,
    teamMembership
  } = props;

  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  let id;
  if (membershipId) {
    id = membershipId;
  } else if(teamMembership) {
    id = null;
  } else {
    id = params.id ? Number(params.id) : null;
  }

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      preloadedMembershipPlanChoices = await requestApi.getResponse({
        url: 'membership-plans/choices?isTeamMembershipPlan=false'
      });
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const fields = buildFields(preloadedMembershipPlanChoices, teamMembership);

  return (
    <AddEditResource
      addEditForm={AddEditMembershipForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal={isModal}
      onModalClose={onModalClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditMembership.propTypes = {
  isModal: PropTypes.bool,
  membershipId: PropTypes.number,
  onModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  teamMembership: PropTypes.object,
};

AddEditMembership.defaultProps = {
  isModal: false,
  membershipId: null,
  onModalClose: null,
  teamMembership: null,
}

export default AddEditMembership;
