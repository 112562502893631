/* eslint-disable */
import { Alert, Box, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Component, useEffect, useState } from 'react';
import loadAccesses from 'utils/load-accesses';
import loadLicences from 'utils/load-licences';
import getDateUtcString from "../../../../../utils/get-date-utc-string";
import { asTitleCase } from 'utils/helpers';

export const SplitLearningGroupForm = ({ formRef, splitRequestErrored, id }) => {
  const [accesses, setAccesses] = useState([]);
  const [licences, setLicences] = useState([]);

  const customTextInput = ({ field, ...props }) => {
    return <TextField inputProps={{ ...field, ...props }} label="New custom group name (required)" variant="outlined" fullWidth />;
  };

  const customCheckbox = ({ field, form: { values }, children, ...props }) => {
    return (
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ ...field, ...props }}
              checked={values[props.formikValueArray].includes(field.value)}
            />
          }
          label={<Typography variant='body'>{props.label}</Typography>}
        />
        {children}
      </Box>
    );
  };


  const removeWhiteSpace = (string) => {
    return string.replace(/\s+/g, '');
  };

  const AccessesCheckboxes = () => {
    return accesses.map((access) => {
      const {
        user: { firstName, lastName, email },
      } = access;
      const label = `${firstName} ${lastName} (${email})`;

      return (
        <Field
          type="checkbox"
          name="users"
          value={JSON.stringify(access?.user?.id)}
          component={customCheckbox}
          label={label}
          formikValueArray="users"
          data-testid={`split-group-modal-checkbox-${removeWhiteSpace(firstName).toLowerCase()}-${removeWhiteSpace(
            lastName
          ).toLowerCase()}`}
        />
      );
    });
  };

  const LicencesCheckboxes = () => {
    return licences.map((licence) => {
      const {
        membershipPlan: { name },
      } = licence;

      const testId = removeWhiteSpace(name);

      const licenceInfo =
        <Box paddingLeft={'2rem'} paddingBottom={'1rem'} sx={{display: 'flex', flexDirection: 'column', color:'gray'}} >
          <Typography variant="body3">{'Status: ' + (licence.isActive ? asTitleCase(licence.status) : 'Not active')}</Typography>
          <Typography variant="body3">{'Order #' + licence?.order?.reference}</Typography>
          <Typography variant="body3">{'Expires: ' + getDateUtcString(licence?.expiresAt, 'date')}</Typography>
          <Typography variant="body3">{'Billing Postcode: ' + (licence?.order?.billingAddress ? licence.order.billingAddress.postcode : '')}</Typography>
        </Box>


      return (
        <Field
          type="checkbox"
          name="licences"
          value={JSON.stringify(licence?.id)}
          component={customCheckbox}
          labelId={name}
          label={name}
          formikValueArray="licences"
          data-testid={`split-group-modal-checkbox-${testId.toLowerCase()}`}
          children={licenceInfo}
        >
        </Field>
      );
    });
  };

  useEffect(async () => {
    const licencesResponse = await loadLicences(id);
    const accessesResponse = await loadAccesses(id);

    setLicences(licencesResponse);
    setAccesses(accessesResponse);
  }, [id]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        groupName: '',
        users: [],
        licences: [],
      }}
      validateOnChange={false}
      innerRef={formRef}
      onSubmit={() => { }}
    >
      {(formik) => (
        <Form formik={formik}>
          <Typography variant="h6" gutterBottom>
            Enter a name for the new custom group, which can then be converted to a school group
          </Typography>
          <Box display={'flex'} width={1} paddingTop={'5px'}>
            <Field name="groupName" data-testid="split-group-modal-groupName" component={customTextInput} />
          </Box>
          <Box paddingTop={'10px'}>
            <Typography variant="h6" gutterBottom>
              Select users to transfer
            </Typography>
          </Box>
          <Paper variant="outlined">
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} padding={2}>
                <AccessesCheckboxes />
            </Box>
          </Paper>
          <Box paddingTop={'10px'}>
            <Typography variant="h6" gutterBottom>
              Select licences to transfer
            </Typography>
          </Box>
          <Paper variant="outlined">
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} padding={2}>
                <LicencesCheckboxes />
            </Box>
          </Paper>
          {splitRequestErrored && (
            <Alert severity="error" sx={{ mt: 2 }}>
              There has been a problem, please try again.
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
};

SplitLearningGroupForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  splitRequestErrored: PropTypes.bool.isRequired,
  schoolAleadyLearningGroup: PropTypes.bool.isRequired,
};

export default SplitLearningGroupForm;
