/* eslint-disable */
import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';

// MUI
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import AddEditMenuItem from './AddEditMenuItem';
import { mapMenuToTree } from './menu-tree-mapper';

const AddEditMenuForm = (props) => {
  const {
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource: menu,
    validationSchema,
  } = props;

  // Modal state
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [menuId] = useState(menu.id);
  const [menuItemIdToEdit, setMenuItemIdToEdit] = useState(null);
  const [menuItemIdToDelete, setMenuItemIdToDelete] = useState(null);
  const [parentMenuItemId, setParentMenuItemId] = useState(null);

  const getExpandedMenuItemIdsFromTreeNode = (treeNode) => {
    let expandedMenuItemIds = [];
    if (treeNode.expanded) {
      expandedMenuItemIds.push(treeNode.menuItem.id);
    }
    treeNode.children.forEach(childTreeNode => {
      expandedMenuItemIds = [...expandedMenuItemIds, ...getExpandedMenuItemIdsFromTreeNode(childTreeNode)]
    });

    return expandedMenuItemIds;
  };

  const addMenuItem = (parentMenuItemId) => {
    setMenuItemIdToEdit(null);
    setParentMenuItemId(parentMenuItemId);
    setAddEditModalOpen(true);
  }

  const editMenuItem = menuItemId => {
    setMenuItemIdToEdit(menuItemId);
    setParentMenuItemId(null);
    setAddEditModalOpen(true);
  };

  const deleteMenuItem = async menuItemId => {
    setMenuItemIdToDelete(menuItemId);
    setDeleteModalOpen(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const refreshMenu = async () => {
          const menu = await resourceApi.getResource({ apiEndpoint: 'menus', id: menuId });
          formik.setFieldValue('treeData', mapMenuToTree(menu));
        }

        const closeAddEditModal = async () => {
          setAddEditModalOpen(false);
          await refreshMenu();
        }

        const handleTreeOnChange = (treeData) => {
          formik.setFieldValue('treeData', treeData);

          // Find and store the expanded MenuItem IDs so that the tree can be re-expanded when reloaded
          let expandedMenuItemIds = [];
          treeData.forEach((treeNode) => {
            expandedMenuItemIds = [...expandedMenuItemIds, ...getExpandedMenuItemIdsFromTreeNode(treeNode)];
          })
          localStorage.setItem(`menus_expandedMenuItemIds`, JSON.stringify(expandedMenuItemIds));
        }

        return (
          <Form formik={formik} unsavedChangesPrompt={false}>
            <Card>
              <CardContent>
                <Grid
                  container
                  mt={1}
                  spacing={2}
                >
                  <FormField
                    {...fields.find((field) => field.name === 'name')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'code')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  { menuId > 0 && (
                      <Grid item xs={12}>
                        <MDButton
                            color="info"
                            variant="gradient"
                            onClick={() => {
                              addMenuItem(null);
                            }}
                        >
                          <AddCircleOutlineOutlinedIcon/>&nbsp;&nbsp;Add Root Menu Item
                        </MDButton>

                        <div style={{ height: 400 }}>
                          <SortableTree
                              /* See https://stackoverflow.com/questions/65180124/react-sortable-tree-not-working-on-react-17-0-1 */
                              treeData={formik.values.treeData}
                              onChange={handleTreeOnChange}
                              generateNodeProps={({node}) => ({
                                title: "",
                                buttons: [
                                  <IconButton
                                      color="info"
                                      onClick={() => {
                                        addMenuItem(node.menuItem.id);
                                      }}
                                  >
                                    <AddCircleOutlineOutlinedIcon/>
                                  </IconButton>,
                                  <IconButton
                                      color="info"
                                      onClick={() => {
                                        editMenuItem(node.menuItem.id);
                                      }}
                                  >
                                    <EditOutlinedIcon/>
                                  </IconButton>,
                                  <IconButton
                                      color="secondary"
                                      onClick={() => {
                                        deleteMenuItem(node.menuItem.id);
                                      }}
                                  >
                                    <DeleteOutlinedIcon/>
                                  </IconButton>
                                ],
                              })}
                          />
                        </div>
                      </Grid>
                  )}
                </Grid>
              </CardContent>
              <CardActions sx={{flexWrap: 'wrap', m: 1}}>
                <SaveCancelButtons
                  cancelLocation={cancelLocation}
                  formik={formik}
                />
              </CardActions>
            </Card>

            <Dialog
              open={addEditModalOpen}
              onClose={closeAddEditModal}
              maxWidth={false}
              fullWidth
              scroll="paper"
              aria-labelledby="add-edit-menu-item"
              aria-describedby="add-edit-menu-item"
            >
              <DialogContent>
                <AddEditMenuItem
                  onModalClose={closeAddEditModal}
                  menuId={menuId}
                  menuItemId={menuItemIdToEdit}
                  parentMenuItemId={parentMenuItemId}
                />
              </DialogContent>
            </Dialog>

            <Dialog
              open={deleteModalOpen}
              aria-labelledby="delete-menu-item"
              aria-describedby="delete-menu-item"
            >
              <DialogTitle id="scroll-dialog-title">Delete menu item</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this menu item?
              </DialogContent>
              <DialogActions>
                <MDButton
                  color="info"
                  onClick={async () => {
                    await resourceApi.deleteResource({
                      apiEndpoint: 'menu-items',
                      id: menuItemIdToDelete,
                    });
                    setDeleteModalOpen(false);
                    refreshMenu();
                  }}
                >
                  Yes
                </MDButton>
                <MDButton color="secondary" autoFocus onClick={() => setDeleteModalOpen(false)}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>


          </Form>
        );
      }}
    </Formik>
  );
}

AddEditMenuForm.propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  resource: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
};

AddEditMenuForm.defaultProps = {
  resource: {},
  validationSchema: {},
};

export default AddEditMenuForm;