

// WRM
import ListResources from 'components/shared/ListResources';

import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Quiz Versions';
const apiEndpoint = 'infinity/admin/quiz-versions';
const resourceName = 'quiz_versions';



const QuizVersions = () => {

  const userLink = (resource) => (
    (resource?.quiz?.teacher?.user?.id && resource?.quiz?.teacher?.user?.email) ?
      <a href={`/admin/users/edit/${resource.quiz.teacher.user.id}`} target="_blank" rel="noreferrer">{resource.quiz.teacher.user.email}</a> :
      ''
  );

  const columns = [
    {
      field: 'uuid',
      label: 'uuid',
      flex: 2,
    },
    {
      field: 'name',
      label: 'name',
      flex: 2,
      formatter: (resource) => resource.quiz.name
    },
    {
      field: 'quiz.teacher.user.email',
      label: 'user',
      flex: 2,
      formatter: (resource) => (userLink(resource))
    },
    {
      field: 'quiz.learningGroup',
      label: ' Learning Group',
      flex: 2,
      formatter: (resource) =>
      (
        (resource?.quiz?.learningGroup?.id) ?
          `#${resource.quiz.learningGroup.id} ${resource.quiz.learningGroup.name ?? ''}` :
          ''
      )

    },
    {
      field: 'status',
      label: 'Published?',
      flex: 1,
      formatter: (resource) => resource.status === 'published' ? 'Yes' : 'No'
    },
    {
      field: 'createdAt',
      label: 'Created At',
      flex: 1,
      sortable: true,
      formatter: (resource) => resource.createdAt ? getDateLocaleString(resource.createdAt) : ''
    },
    {
      field: 'quiz.tag',
      label: 'Tag',
      flex: 1,
      sortable: true,
      formatter: (resource) => resource.quiz?.tag ?? ''
    },
  ];

  const defaultSortValues = [
    {
      field: 'createdAt',
      direction: 'desc',
    },

  ];

  const filters = [
    {
      field: 'uuid',
      label: 'Uuid',
      type: 'text',
    },
    {
      field: 'quiz.name',
      label: 'Name',
      type: 'text',
    },
    {
      field: 'quiz.teacher.user.email',
      label: 'Email',
      type: 'text',
    },
    {
      field: 'quiz.learningGroup.id',
      label: 'Learning Group Id',
      type: 'text',
    },
    {
      field: 'quiz.learningGroup.name',
      label: 'Learning Group Name',
      type: 'text',
    },
    {
      field: 'quiz.tag',
      label: 'Tag',
      type: 'select',
      choices: [
        { value: 'wre_shared', label: 'wre_shared'},
      ],
    },
  ];

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      filters={filters}
      defaultSortValues={defaultSortValues}
      resourceName={resourceName}
      title={title}
      disableEditResource
      disableAddResource
      disableOpenInNewTab
    />
  )
};

export default QuizVersions;
