import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

// MUI
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableRow as MuiTableRow,
} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';

/* Table */
export const Table = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <MuiTableContainer>
      <MuiTable {...rest}>
        {children}
      </MuiTable>
    </MuiTableContainer>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rest: PropTypes.array,
};

Table.defaultProps = {
  rest: [],
}

/* TableBody */
export const TableBody = forwardRef(
  (props, ref) => {
    const {
      children,
      ...rest
    } = props;

    return (
      <MuiTableBody ref={ref} {...rest}>
        {children}
      </MuiTableBody>
    );
  }
);

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rest: PropTypes.array,
};

TableBody.defaultProps = {
  rest: [],
}

/* TableCell */
export const TableCell = (props) => {
  const {
    children,
    heading,
    ...rest
  } = props;

  return (
    <MuiTableCell {...rest}>
      {
        heading ?
          (
            <MDBox
              color="secondary"
              sx={({typography: { size, fontWeightBold }}) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: 'uppercase',
              })}
            >
              {children}
            </MDBox>
          ) :
          (
            <MDTypography variant="caption" fontWeight="regular">
              {children}
            </MDTypography>
          )
      }
    </MuiTableCell>
  )
};

TableCell.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rest: PropTypes.array,
};

TableCell.defaultProps = {
  children: false,
  heading: false,
  rest: [],
}

/* TableHead */
export const TableHead = (props) => {
  const {
    children,
  } = props;

  return (
    <MDBox component="thead">
      {children}
    </MDBox>
  );
};

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

/* TableRow */
export const TableRow = forwardRef(
  (props, ref) => {
    const {
      children,
      dragHandleProps,
      ...rest
    } = props;

    return (
      <MuiTableRow ref={ref} {...rest}>
        {
          dragHandleProps && (
            <MuiTableCell>
              <div {...dragHandleProps}><ReorderIcon /></div>
            </MuiTableCell>
          )
        }
        {children}
      </MuiTableRow>
    );
  }
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dragHandleProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  rest: PropTypes.array,
};

TableRow.defaultProps = {
  dragHandleProps: null,
  rest: [],
}
