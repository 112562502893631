/* eslint-disable */
import { useCallback, useEffect, useState } from 'react';
import { setIn } from 'formik';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// MUI
import {
  Grid,
} from '@mui/material';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import useMounted from 'hooks/use-mounted';
import { resourceApi } from 'api/resource-api';
import { useAppContext } from 'contexts/app-context';
import upperCaseFirst from 'utils/upper-case-first';
import { toFormValues, toApiValues } from '../resource/api-form-mapper';
import AddEditResourceForm from './AddEditResourceForm';

const AddEditResource = (props) => {
  const {
    addEditForm: AddEditForm,
    addEditFormContext,
    adminEndpoint,
    apiEndpoint,
    fields,
    id,
    isModal,
    redirectToEdit,
    onModalClose,
    resourceName,
    toApiValuesCustom,
    toFormValuesCustom,
    validationSchema,
    showTitleText
  } = props;

  const navigate = useNavigate();
  const isMounted = useMounted();
  const { setShowLoadingSpinner } = useAppContext();

  const [resource, setResource] = useState(null);
  const [apiErrors, setApiErrors] = useState([]);

  const { userFeedbackError, userFeedbackSuccess } = useAppContext();

  // eslint-disable-next-line no-shadow
  const applyDefaultValues = (resource, fields) => {
    fields.forEach((field) => {
      let isFieldPopulated = false;
      if (field.type === 'fieldCollection') {
        resource = setIn(resource, field.name, field.defaultValue || []);
        isFieldPopulated = true;
      }
      if (field.type === 'fieldGroup') {
        resource = applyDefaultValues(resource, field.childFields || []);
        isFieldPopulated = true;
      }
      if (field.type === 'tabs') {
        field.childFields?.forEach((childField) => {
          resource = applyDefaultValues(resource, childField.childFields || []);
        });
        isFieldPopulated = true;
      }
      if (!isFieldPopulated) {
        let defaultValue = '';
        if (field.type === 'checkbox') {
          defaultValue = false;
        }
        if (field.type === 'currency') {
          defaultValue = null;
        }
        if (field.type === 'number') {
          defaultValue = null;
        }
        if (field.type === 'selectAsTable') {
          defaultValue = [];
        }
        if (field.type === 'typeahead') {
          defaultValue = null;
        }
        resource = setIn(resource, field.name, field.defaultValue || defaultValue);
      }
    });
    return resource;
  };

  // eslint-disable-next-line no-shadow
  const getResource = useCallback(async ({ apiEndpoint, fields, id }) => {
    try {
      // eslint-disable-next-line no-shadow
      let resource = {};
      if (id) {
        resource = await resourceApi.getResource({ apiEndpoint, id });
      } else {
        resource = applyDefaultValues(resource, fields);
      }
      if (isMounted()) {
        setResource(resource);
      }
    } catch (error) {
      // TODO
    }
  }, [isMounted]);

  const refreshResource = () => {
    getResource({ apiEndpoint, fields, id });
  }

  useEffect(() => {
    refreshResource();
  }, [apiEndpoint, getResource, id]);

  const onFormSubmit = async (formValues) => {
    const apiValues = toApiValuesCustom ? toApiValuesCustom(formValues, fields, resource) : toApiValues(formValues, fields);
    try {
      setShowLoadingSpinner(true);
      const response = await resourceApi.saveResource({ apiEndpoint, data: apiValues, id });
      setShowLoadingSpinner(false);
      if (!response.resource && response.violations) {
        setApiErrors(response.violations);
        userFeedbackError('There are validation errors in this form');
      }
      if (response.resource) {
        if (isModal) {
          onModalClose && onModalClose();
        } else if (redirectToEdit && id===null){
          navigate(`/admin/${adminEndpoint}/edit/${response.resource.id}`);
        } else if (!redirectToEdit) {
          navigate(`/admin/${adminEndpoint || apiEndpoint}`);
        }
        // Add user feedback in a 'one millisecond' timeout to outlive the navigation
        setTimeout(
          () => { userFeedbackSuccess(`${upperCaseFirst(resourceName)} updated successfully`) },
          1
        );
      }

    }
    catch (e) {
      setShowLoadingSpinner(false);
      userFeedbackError('There were errors submitting this form');
    }
  };

  const getApiError = (field) => {
    if (apiErrors) {
      // eslint-disable-next-line no-shadow
      const apiError = apiErrors.find((apiError) => apiError.propertyPath === field);
      return apiError?.message;
    }
    return false;
  };

  if (!resource) return null;

  return (
    <MDBox>
      {
        showTitleText && (
          <MDBox mb={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h5">
                  {`${id ? 'Edit' : 'Add'} ${resourceName}`}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        )
      }
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          {
            AddEditForm
              ? (
                <AddEditForm
                  apiErrors={apiErrors}
                  cancelLocation={`/admin/${adminEndpoint || apiEndpoint}`}
                  context={addEditFormContext}
                  fields={fields}
                  formValues={toFormValuesCustom ? toFormValuesCustom(resource, fields) : toFormValues(resource, fields)}
                  getApiError={getApiError}
                  isModal={isModal}
                  onModalClose={onModalClose}
                  onFormSubmit={onFormSubmit}
                  refreshResource={refreshResource}
                  resource={resource}
                  setApiErrors={setApiErrors}
                  validationSchema={validationSchema}
                />
              )
              : (
                <AddEditResourceForm
                  apiErrors={apiErrors}
                  cancelLocation={`/admin/${adminEndpoint || apiEndpoint}`}
                  context={addEditFormContext}
                  fields={fields}
                  formValues={toFormValuesCustom ? toFormValuesCustom(resource, fields) : toFormValues(resource, fields)}
                  getApiError={getApiError}
                  isModal={isModal}
                  onModalClose={onModalClose}
                  onFormSubmit={onFormSubmit}
                  setApiErrors={setApiErrors}
                  validationSchema={validationSchema}
                />
              )
          }
        </Grid>
      </Grid>
    </MDBox>
  );
};

AddEditResource.propTypes = {
  addEditForm: PropTypes.func,
  addEditFormContext: PropTypes.string,
  adminEndpoint: PropTypes.string,
  apiEndpoint: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isModal: PropTypes.bool,
  redirectToEdit: PropTypes.bool,
  onModalClose: PropTypes.func,
  resourceName: PropTypes.string.isRequired,
  toApiValuesCustom: PropTypes.func,
  toFormValuesCustom: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object,
  showTitleText: PropTypes.bool,
};

AddEditResource.defaultProps = {
  addEditForm: null,
  addEditFormContext: null,
  adminEndpoint: null,
  id: null,
  isModal: false,
  redirectToEdit: false,
  onModalClose: null,
  toApiValuesCustom: null,
  toFormValuesCustom: null,
  validationSchema: null,
  showTitleText: true
};

export default AddEditResource;
