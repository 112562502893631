// MDPR
import MDBadge from 'mdpr2/components/MDBadge';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Promotions';
const apiEndpoint = 'promotions';
const resourceName = 'promotion';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 2,
  },
  {
    field: 'code',
    label: 'Identifier',
    sortable: true,
    flex: 2,
  },
  {
    field: 'startAt',
    label: 'Starts',
    flex: 2,
    formatter: (resource) => getDateLocaleString(resource.startsAt),
  },
  {
    field: 'endAt',
    label: 'Ends',
    flex: 2,
    formatter: (resource) => getDateLocaleString(resource.endsAt),
  },
  {
    field: 'usesTotal',
    label: 'Times used',
    flex: 1,
    formatter: (resource) => {
      let usesTotal = 0;
      resource.promotionCodes?.forEach(promotionCode => {
        usesTotal += promotionCode.uses;
      });
      return usesTotal;
    }
  },
  {
    field: '_status',
    label: 'Status',
    flex: 0.75,
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: (resource) => {
      const { status } = resource;
      const color = status === 'active' ? 'success' : 'danger';
      return <MDBadge color={color} badgeContent={status} />;
    },
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'code',
    label: 'Identifier',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const fixedFilterValues = {
  singleUse: false,
};

const Promotions = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default Promotions;
