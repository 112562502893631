import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import AddEditTeamMembershipForm from './AddEditTeamMembershipForm';

const apiEndpoint = 'team-memberships';
const resourceName = 'team';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'maxMembers',
    label: 'Maximum number of members',
    type: 'number',
  },
  {
    name: 'membershipPlan',
    label: 'Membership plan',
    type: 'select',
  },
  {
    name: 'startsAt',
    label: 'Starts',
    type: 'date',
    defaultValue: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00'),
  },
  {
    name: 'expiresAt',
    label: 'Expires',
    type: 'date',
    defaultValue: moment().utc().add(1, 'Y').format('YYYY-MM-DDTHH:mm:ss+00:00'),
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
      .required('Name is required'),
  maxMembers: Yup.number()
      .typeError('Invalid number')
      .positive('Maximum number of members must be positive')
      .required('Maximum number of members is required'),
  membershipPlan: Yup.string()
    .matches(/^\/membership/, 'Membership plan is required'),
  startsAt: Yup.date()
    .typeError('Invalid date format')
    .nullable(),
  expiresAt: Yup.date()
    .typeError('Invalid date format')
    .nullable()
    .min(Yup.ref('startsAt'), 'Expiry date must be later than start date'),
});

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields, teamMembership) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  if (typeof formValues.startsAt === 'object' && formValues.startsAt !== null) {
    const oldStartsAt = teamMembership.startsAt ? moment.utc(teamMembership.startsAt) : null;
    const newStartsAt = formValues.startsAt;
    newStartsAt.set('hour', oldStartsAt ? oldStartsAt.get('hour') : 0);
    newStartsAt.set('minute', oldStartsAt ? oldStartsAt.get('minute') : 0);
    newStartsAt.set('second', oldStartsAt ? oldStartsAt.get('second') : 0);
    apiValues.startsAt = newStartsAt.format('YYYY-MM-DDTHH:mm:ss+00:00');
  }

  if (typeof formValues.expiresAt === 'object' && formValues.expiresAt !== null) {
    const oldExpiresAt = teamMembership.expiresAt ? moment.utc(teamMembership.expiresAt) : null;
    const newExpiresAt = formValues.expiresAt;
    newExpiresAt.set('hour', oldExpiresAt ? oldExpiresAt.get('hour') : 23);
    newExpiresAt.set('minute', oldExpiresAt ? oldExpiresAt.get('minute') : 59);
    newExpiresAt.set('second', oldExpiresAt ? oldExpiresAt.get('second') : 59);
    apiValues.expiresAt = newExpiresAt.format('YYYY-MM-DDTHH:mm:ss+00:00');
  }

  return apiValues;
}

const AddEditTeamMembership = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const membershipPlanChoices = await requestApi.getResponse({ url: 'membership-plans/choices?isTeamMembershipPlan=true' });
      const membershipPlanField = fields.find((field) => field.name === 'membershipPlan');
      membershipPlanField.choices = membershipPlanChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditTeamMembershipForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditTeamMembership;
