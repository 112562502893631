import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'components/shared/Table';
import useMounted from 'hooks/use-mounted';
import AddEditProductBundlePart from './AddEditProductBundlePart';
import { defaultOrderShipments } from '../../../constants';

const ProductBundleParts = props => {
  const {
    formik,
    product,
    productVariant,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productBundleParts, setProductBundleParts] = useState([]);
  const [productBundlePartToEdit, setProductBundlePartToEdit] = useState(null);
  const [addEditProductBundlePartModalOpen, setAddEditProductBundlePartModalOpen] = useState(false);

  const isMounted = useMounted();

  const loadProductBundleParts = async () => {
    const data = await resourceApi.getResources({
      apiEndpoint: 'product-bundle-parts',
      filterValues: {
        product: product ? product['@id'] : '',
        productVariant: productVariant ? productVariant['@id'] : '',
      },
      sortValues: [
        {
          field: 'displayOrder',
          direction: 'asc',
        },
      ],
      pagination: {
        itemsPerPage: 999999,
      },
    });
    if (isMounted()) {
      setProductBundleParts(data.resources);
    }
  }

  const initialise = useCallback(async () => {
    await loadProductBundleParts();
    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedProductBundleParts = [];
    const movedProductBundlePart = productBundleParts[result.source.index];
    productBundleParts.forEach((productBundlePart, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedProductBundleParts.push(movedProductBundlePart);
      }
      if (index !== result.source.index) {
        reorderedProductBundleParts.push(productBundlePart);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedProductBundleParts.push(movedProductBundlePart);
      }
    });
    setProductBundleParts(reorderedProductBundleParts);

    // Reorder productBundleParts in the calling form
    const productBundlePartsValue = formik.values.productBundleParts;
    const reorderedProductBundlePartValues = [];
    const movedProductBundlePartValue = productBundlePartsValue[result.source.index];
    productBundlePartsValue.forEach((productBundlePartValue, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedProductBundlePartValues.push(movedProductBundlePartValue);
      }
      if (index !== result.source.index) {
        reorderedProductBundlePartValues.push(productBundlePartValue);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedProductBundlePartValues.push(movedProductBundlePartValue);
      }
    });
    formik.setFieldValue('productBundleParts', reorderedProductBundlePartValues);
  };

  const deleteProductBundlePart = (deleteIndex) => {
    setProductBundleParts(productBundleParts.filter((part, index) => index !== deleteIndex));

    const formProductBundleParts = formik.values.productBundleParts;
    formik.setFieldValue(
        'productBundleParts',
        formProductBundleParts.filter((part, index) => index !== deleteIndex)
      );
  };

  const addProductBundlePart = () => {
    setProductBundlePartToEdit(null);
    setAddEditProductBundlePartModalOpen(true);
  };

  const editProductBundlePart = (productBundlePart) => {
    setProductBundlePartToEdit(productBundlePart);
    setAddEditProductBundlePartModalOpen(true);
  };

  const closeAddEditProductBundlePartModal = async () => {
    setAddEditProductBundlePartModalOpen(false);
    loadProductBundleParts();
  }

  const findOrderShipmentName = (productBundlePart) => {
    const orderShipment = defaultOrderShipments.find((el) =>
       (el.value === productBundlePart.orderShipment)
    );
    return orderShipment?.label ?? 'Unknown';
  }

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Products in bundle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
          >
            <Grid
              item
              xs={12}
            >
              <Box display="flex" justifyContent="flex-end">
                <MDButton
                  color="info"
                  onClick={addProductBundlePart}
                  variant="gradient"
                >
                  Add product to bundle
                </MDButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={20} />
                    <TableCell heading>
                      SKU
                    </TableCell>
                    <TableCell heading>
                      Product
                    </TableCell>
                    <TableCell heading>
                      Quantity
                    </TableCell>
                    <TableCell heading>
                      Shipment (deprecated)
                    </TableCell>
                    <TableCell heading>
                      Order Shipment
                    </TableCell>
                    <TableCell heading>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="product-bundle-parts-droppable" direction="vertical">
                    {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            productBundleParts.length === 0 &&
                            <TableRow>
                              <TableCell align="center" colSpan={5}>
                                No products in bundle
                              </TableCell>
                            </TableRow>
                          }
                          {
                            productBundleParts.map((productBundlePart, index) => (
                              <Draggable
                                draggableId={`product-bundle-part-${productBundlePart.id}`}
                                index={index}
                                key={`product-bundle-part-${productBundlePart.id}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      dragHandleProps={draggableProvided.dragHandleProps}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none"
                                      }}
                                      onDoubleClick={() => editProductBundlePart(productBundlePart)}
                                    >
                                      <TableCell>
                                        {productBundlePart.partProduct?.sku}
                                      </TableCell>
                                      <TableCell>
                                        {productBundlePart.partProduct?.name}
                                      </TableCell>
                                      <TableCell>
                                        {productBundlePart.quantity}
                                      </TableCell>
                                      <TableCell>
                                        {productBundlePart.shipment?.toUpperCase()}
                                      </TableCell>
                                      <TableCell>
                                        {findOrderShipmentName(productBundlePart)}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                            onClick={() => deleteProductBundlePart(index)}
                                        >
                                          <DeleteIcon
                                              color="error"
                                              fontSize="small"
                                          />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            ))
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </Table>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={addEditProductBundlePartModalOpen}
        onClose={closeAddEditProductBundlePartModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-product-bundle-part"
        aria-describedby="add-edit-product-bundle-part"
      >
        <DialogContent>
          <AddEditProductBundlePart
            productBundlePart={productBundlePartToEdit}
            product={product}
            productVariant={productVariant}
            onClose={closeAddEditProductBundlePartModal}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

ProductBundleParts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  productVariant: PropTypes.object,
};

ProductBundleParts.defaultProps = {
  product: null,
  productVariant: null,
}

export default ProductBundleParts;
