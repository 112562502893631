import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

function Breadcrumbs({ route }) {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const breadcrumbs = [];
    let pathParts = [...route];
    pathParts.shift();
    pathParts = pathParts.filter(pathPart => !Number(parseInt(pathPart, 10)));
    pathParts.forEach((pathPart, i) => {
      const url = `/admin/${pathParts.slice(0, (i + 1)).join('/')}`;
      let text = pathPart.replace(/[_-]/g, " ");
      // Exceptions

      if (text === 'team memberships') {
        text = 'teams';
      }
      if (text === 'stock change') {
        text = 'stock audit';
      }
      if (text === 'stock audit') {
        text = 'stock report';
      }
      if (text === 'stock change transfer') {
        text = 'stock transfer';
      }
      if (text === 'stock change write off') {
        text = 'stock write off';
      }
      if (text === 'stock change receive stock') {
        text = 'receive stock';
      }
      if (text === 'stock change order stock') {
        text = 'order stock';
      }
      if (text === 'stock change correction') {
        text = 'stock correction';
      }
      if (text === 'stock audit draw down report') {
        text = 'stock draw down report';
      }
      text = text.replace('learning group', 'group');
      breadcrumbs.push({ url, text });
    });
    setBreadcrumbs(breadcrumbs);
  }, [route]);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { grey } }) => grey[600],
          },
        }}
      >
        <Link to="/admin/dashboard">
          <MDTypography
            component="span"
            variant="body2"
            color="dark"
            opacity={0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>home</Icon>
          </MDTypography>
        </Link>
        {
          breadcrumbs.map((breadcrumb, index) => {

            if (index < (breadcrumbs.length - 1)) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Link to={breadcrumb.url} key={`link-breadcrumb-${index}`}>
                  <MDTypography
                    color="dark"
                    component="span"
                    fontWeight="regular"
                    opacity={0.5}
                    sx={{ lineHeight: 0 }}
                    textTransform="capitalize"
                    variant="button"
                  >
                    {breadcrumb.text}
                  </MDTypography>
                </Link>
              )
            }
            return (
              <MDTypography
                color="dark"
                fontWeight="regular"
                key="link-breadcrumb-last"
                sx={{ lineHeight: 0 }}
                textTransform="capitalize"
                variant="button"
              >
                {breadcrumb.text}
              </MDTypography>
            );
          })
        }
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Breadcrumbs;
