/* eslint-disable */
// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// WRM
// eslint-disable-next-line import/no-cycle
import FormField from './index';

const FieldGroup = (props) => {
  const {
    asAccordion,
    childFields,
    formik,
    getApiError,
    label,
    name,
    deprecated,
  } = props;

  if (asAccordion) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            background: 'rgba(0, 0, 0, .025)',
          }}
        >
          <Typography variant="h6">{deprecated ? (
            <><strike>{label}</strike> (deprecated)</>
          ) : label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            paddingTop={2}
          >
            {
              childFields.map((childField) => {
                const field = { ...childField };
                field.name = `${name}[${childField.name}]`;
                return (
                  <Grid
                    item
                    key={`grid-${field.name}`}
                    md={12}
                    xs={12}
                    display={childField.hidden ? 'none' : 'inherit'}
                  >
                    <FormField
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...field}
                      formik={formik}
                      getApiError={getApiError}
                    />
                  </Grid>
                );
              })
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        paddingBottom={3}
        paddingTop={1}
      >
        <Grid item>
          <Typography variant="h6">
            {label}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        {
          childFields.map((childField) => {
            const field = { ...childField };
            field.name = `${name}[${childField.name}]`;
            return (
              <Grid
                item
                key={`grid-${field.name}`}
                md={12}
                xs={12}
              >
                <FormField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  formik={formik}
                  getApiError={getApiError}
                />
              </Grid>
            );
          })
        }
      </Grid>
    </>
  );
};

export default FieldGroup;
