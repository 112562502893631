import React, { useEffect, useState } from 'react';

// MUI
import { DataGrid } from "@mui/x-data-grid";
import { Box, Card, CardContent, FormControl, Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from '@mui/styles';

// WRM
import { requestApi } from "api/request-api";
import { resourceApi } from "api/resource-api";
import LoadingSpinner from "components/LoadingSpinner";
import CardHeader from 'components/shared/CardHeader';

// MDPR
import MDBadge from "mdpr2/components/MDBadge";

const useComponentStyles = makeStyles((theme) => ({
    headlineNumber: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        paddingRight: '0.2em',
    },
    headlineGreen: {
        color: '#097109'
    },
    locationSelect: {
        // width: '40em',
        flex: 1,
        float: 'right',
        marginTop: '-60px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
}));

// hard code this for now, make it selectable later if we start despatching from other warehouses
const despatchLocationId = 1;

/* eslint-disable */
const StockAuditDrawDownReport = () => {

    const [drawDownReport, setDrawDownReport] = useState(null);
    const [drawDownColumns, setDrawDownColumns] = useState([]);

    const componentClasses = useComponentStyles();

    useEffect(() => {

        // load draw down report data...
        requestApi
            .getResponse({ 'url': 'product-reports/generate-stock-audit-draw-down-report' })
            .then((response) => {
                if (response) setDrawDownReport(response);
            });

        // load location data and build columns from it...
        resourceApi
            .getResources({ apiEndpoint: 'locations' })
            .then(response => {
                if (!response) {
                    return;
                }

                const locations = response.resources;
                const columns = [
                    {
                        field: 'productSku',
                        headerName: 'Product/SKU',
                        flex: 1.5,
                        filterable: true,
                        renderCell: (params) => {
                            return (<Box>
                                <Box py={1} fontWeight="fontWeightBold">{params.row.sku}</Box>
                                <Box sx={{ width: 200 }} title={params.row.name} whiteSpace="normal" fontSize="small" fontStyle="italic">
                                    {params.row.name}
                                </Box>
                            </Box>);
                        },
                        valueGetter: (params) => params.row.sku,
                    },
                    {
                        type: 'number',
                        field: 'currentStock',
                        headerName: 'Stock',
                        flex: 1,
                        filterable: true,
                        renderCell: (params) => {
                            const { row } = params;
                            const stock = row.stockByLocation[despatchLocationId] ?? 0;

                            return (<>
                                <div className={componentClasses.headlineNumber}>{stock < 0 ? '<0' : stock.toLocaleString()}</div>
                                <small>stock</small>
                            </>);
                        },
                        valueGetter: (params) => {
                            return params.row.stockByLocation[despatchLocationId] ?? 0;
                        },
                    },
                    {
                        type: 'number',
                        field: 'stockReserved',
                        headerName: 'Reserved',
                        flex: 1,
                        filterable: true,
                        renderCell: (params) => {
                            const { row } = params;
                            const reserved = row.reservedByLocation[despatchLocationId] ?? 0;
                            return (<>
                                <div className={componentClasses.headlineNumber}>{reserved < 0 ? '<0' : reserved.toLocaleString()}</div>
                                <small>reserved</small>
                            </>);
                        },
                        valueGetter: (params) => {
                            return params.row.reservedByLocation[despatchLocationId] ?? 0;
                        },
                    },
                    {
                        type: 'number',
                        field: 'percentReserved',
                        headerName: '% Reserved',
                        flex: 1,
                        filterable: true,
                        renderCell: (params) => {
                            const { row } = params;
                            const percentReserved = row.pctReservedByLocation[despatchLocationId] ?? 0;
                            return (<>
                                <div className={componentClasses.headlineNumber}>{percentReserved > 200 ? '>200' : percentReserved}%</div>
                                <small>reserved</small>
                            </>);
                        },
                        valueGetter: (params) => {
                            return params.row.pctReservedByLocation[despatchLocationId] ?? 0;
                        },
                    },
                    {
                        type: 'number',
                        field: 'status',
                        headerName: 'Status',
                        flex: 0,
                        filterable: true,
                        renderCell: (params) => {
                            const { row } = params;
                            const percentReserved = row.pctReservedByLocation[despatchLocationId] ?? 0;
                            let badgeColor = 'success';
                            let icon = 'check';

                            if (percentReserved > 75 && percentReserved <= 100) {
                                badgeColor = 'warning';
                                icon = 'priority_high';
                            } else if (percentReserved > 100) {
                                badgeColor = 'error';
                                icon = 'priority_high';
                            }

                            const badgeIcon = (<Icon fontSize="small">{icon}</Icon>);

                            return (<>
                                <MDBadge
                                    color={badgeColor}
                                    size="md"
                                    container
                                    badgeContent={badgeIcon}
                                />
                            </>);
                        },
                        valueGetter: (params) => {
                            return params.row.pctReservedByLocation[despatchLocationId] ?? 0;
                        },
                    },
                    {
                        type: 'number',
                        field: 'totalOnOrder',
                        headerName: 'On Order',
                        flex: 1,
                        filterable: true,
                        renderCell: ({ value }) => {
                            return (<>
                                <div className={componentClasses.headlineNumber}>{value.toLocaleString()}</div>
                                <small>on order</small>
                            </>);
                        },
                        valueGetter: (params) => {
                            const { row } = params;
                            const { onOrderByLocation } = row;
                            let totalOnOrder = 0;
                            Object.keys(onOrderByLocation).forEach(locationId => totalOnOrder += onOrderByLocation[locationId] ?? 0);
                            return totalOnOrder;
                        },
                    },
                    {
                        type: 'number',
                        field: 'weeklyDespatchRate',
                        headerName: 'Despatch Rate',
                        flex: 1,
                        filterable: true,
                        renderCell: ({ value }) => {
                            return (<>
                                <div className={componentClasses.headlineNumber}>{value.toLocaleString()}</div>
                                <small>per week</small>
                            </>);
                        },
                        valueGetter: ({ row }) => {
                            return row.weeklyDrawDownByLocation[despatchLocationId] ?? 0;
                        }
                    },
                ];

                // eslint-disable-next-line no-shadow
                locations.forEach((location) => location.id !== despatchLocationId ? columns.push({
                    type: 'number',
                    field: 'location_' + location.id,
                    headerName: `${location.name}`,
                    flex: 0.75,
                    filterable: true,
                    valueGetter: ({ row }) => {
                        return row.stockByLocation[location.id] ?? 0;
                    },
                    valueFormatter: ({ value }) => value.toLocaleString()
                }) : null);

                setDrawDownColumns(columns);
            });
    }, []);

    const dataGrid = () => {
        if (drawDownReport === null || drawDownColumns.length === 0) {
            return (<LoadingSpinner />);
        }

        return (
            <DataGrid
                rowHeight={100}
                autoHeight
                columns={drawDownColumns}
                disableSelectionOnClick
                rows={drawDownReport}
                getRowId={(row) => row.id}
                pageSize={30}
            />
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <Icon>arrow_circle_down</Icon> Stock Draw Down Report
                    </CardHeader>
                    <CardContent>
                        <div className={componentClasses.locationSelect}>
                            <FormControl className={componentClasses.formControl}>
                                <InputLabel id="location-select-label">Despatch Location</InputLabel>
                                <Select
                                    labelId="location-select-label"
                                    id="location-select"
                                    value={despatchLocationId}
                                >
                                    <MenuItem value={despatchLocationId}>In House </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Box mb={2} fontSize="medium">
                            <p>
                                This report shows the current <b>current stock level</b> at this location, along with <b>reserved</b> stock. Also shown is a <b>percentage of total warehouse stock that has been reserved</b> at this location.
                            </p>
                            <p>
                                Stock <b>on order</b> across all locations, <b>weekly despatch rate</b>, as well as <b>stock counts for other locations</b> are also shown.
                            </p>
                        </Box>
                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    Key
                                </Grid>
                                <Grid item fontSize="medium">
                                    <MDBadge
                                        color="success"
                                        size="md"
                                        container
                                        badgeContent={<Icon fontSize="small">check</Icon>}
                                    />
                                    &nbsp;Under 75% of stock reserved
                                </Grid>
                                <Grid item fontSize="medium">
                                    <MDBadge
                                        color="warning"
                                        size="md"
                                        container
                                        badgeContent={<Icon fontSize="small">priority_high</Icon>}
                                    />
                                    &nbsp;75% - 100% of stock reserved
                                </Grid>
                                <Grid item fontSize="medium">
                                    <MDBadge
                                        color="error"
                                        size="md"
                                        container
                                        badgeContent={<Icon fontSize="small">priority_high</Icon>}
                                    />
                                    &nbsp;Over 100% of stock reserved
                                </Grid>
                            </Grid>
                        </Box>

                        {dataGrid()}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default StockAuditDrawDownReport;
