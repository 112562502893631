import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MDButton from 'mdpr2/components/MDButton';
import { Dialog, DialogContent, DialogActions, DialogTitle, Box, CircularProgress } from '@mui/material';
import MDTypography from 'mdpr2/components/MDTypography';
import { wondeApi } from 'api/wonde-api';
import useMounted from 'hooks/use-mounted';
import toast from 'react-hot-toast';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


const WondePreviewModal = ({
                             setWondePreviewModalOpen,
                             misSchoolExternalId,
                             importGroups,
                             importClasses,
                             groupTypes,
                           }) => {
  const [loadingClasses, setLoadingClasses] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(false);

  const [groupsAndClasses, setGroupsAndClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(loadingStudents || loadingEmployees || loadingClasses || loadingGroups);
  }, [loadingStudents, loadingEmployees, loadingClasses, loadingGroups]);

  const recursiveApiFetch = (apiEndpoint, setDataCall, loadingCallback, additionalFilter = '', filterValues = {}, page = 1) => {
    loadingCallback(true);
    wondeApi.getResources({
      apiEndpoint,
      id: misSchoolExternalId,
      filterValues: { page, per_page: 200, ...filterValues },
    }).then((response) => {
      if (additionalFilter) {
        const additionalFilterResult = response.resources.data.map(g => ({
          ...g,
          wondeType: additionalFilter,
        }));
        setDataCall(initialDataSet => [...initialDataSet, ...additionalFilterResult]);
      } else {
        setDataCall(initialDataSet => [...initialDataSet, ...response.resources.data]);
      }
      if (response.resources.meta.pagination.more) {
        recursiveApiFetch(apiEndpoint, setDataCall, loadingCallback, additionalFilter, filterValues, (page + 1));
      } else {
        loadingCallback(false);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error(`Error fetching ${apiEndpoint}. ${error.message}`);
      loadingCallback(false);
    });
  };

  const getEmployees = () => {
    recursiveApiFetch(
      'employees',
      setEmployees,
      setLoadingEmployees,
    );
  };

  const getStudents = () => {
    recursiveApiFetch(
      'students',
      setStudents,
      setLoadingStudents,
    );
  };

  const getClasses = () => {
    if (!importClasses) {
      return;
    }
    const filterValues = {
      has_students: true,
      include: ['employees', 'students'],
    };

    recursiveApiFetch(
      'classes',
      setGroupsAndClasses,
      setLoadingClasses,
      'Class',
      filterValues,
    );
  };

  const getGroups = () => {
    if (!importGroups) {
      return;
    }
    const filterValues = {
      type: '',
      has_students: true,
      include: ['employees', 'students'],
    };

    groupTypes.forEach((group) => {
      filterValues.type = group;

      recursiveApiFetch(
        'groups',
        setGroupsAndClasses,
        setLoadingGroups,
        'Group',
        filterValues,
      );
    });
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, filterable: false },
    { field: 'description', headerName: 'Description', flex: 1, filterable: false },
    { field: 'wondeType', headerName: 'Type', flex: 1, filterable: false },
    { field: 'type', headerName: 'Group type', flex: 1, filterable: false },
    {
      type: 'number', field: 'studentCount', headerName: 'Student count', flex: 1, filterable: false,
      valueGetter: (params) => params.row.students.data.length,
    },
    {
      type: 'number', field: 'employeeCount', headerName: 'Employee count', flex: 1, filterable: false,
      valueGetter: (params) => params.row.employees.data.length,
    },
    {
      field: 'employees', headerName: 'Employees', flex: 2, filterable: false,
      valueGetter: (params) => params.row.employees.data.map(employee => `${employee.forename} ${employee.surname}`).join(', ')
    }
  ];

  const isMounted = useMounted();
  useEffect(() => {
    setIsLoading(true);
    getEmployees();
    getStudents();
    getClasses();
    getGroups();
  }, [isMounted]);
  return (
    <Dialog open aria-labelledby="wonde-preview" aria-describedby="wonde-preview" fullWidth maxWidth="xl" >
      <DialogTitle id="wonde-preview-title">MIS School preview - {misSchoolExternalId} </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="space-between" padding={2}>
          {!isLoading ? (
              <>
                <MDTypography variant="h6">Total students: {students.length}</MDTypography>
                <MDTypography variant="h6">Total employees: {employees.length}</MDTypography>
                {(importGroups||importClasses) && groupsAndClasses && (
                  <DataGrid
                    getRowHeight={() => 'auto'}
                    sx={{
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    }}
                    autoHeight
                    disableColumnFilter
                    columns={columns}
                    disableSelectionOnClick
                    rows={groupsAndClasses}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[25, 50, 100]}
                    components={{ Toolbar: GridToolbar }}
                  />
                )}
              </>)
            :
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="4rem" />
            </Box>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <MDButton
          color="info"
          onClick={() => setWondePreviewModalOpen(false)}
          variant="gradient"
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

WondePreviewModal.propTypes = {
  setWondePreviewModalOpen: PropTypes.func.isRequired,
  misSchoolExternalId: PropTypes.string.isRequired,
  importGroups: PropTypes.bool.isRequired,
  importClasses: PropTypes.bool.isRequired,
  groupTypes: PropTypes.arrayOf(PropTypes.string),
};

WondePreviewModal.defaultProps = {
  groupTypes: [''], // Default to all groupTypes
};

export default WondePreviewModal;
