import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'digital-tools';
const resourceName = 'digital tool';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea'
  },
  {
    name: 'accessUrl',
    label: 'Access URL',
  },
  {
    name: 'imageUrl',
    label: 'Image',
    type: 'filePicker',
  },
  {
    name: 'displayOrder',
    label: 'Display order',
    type: 'number',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  accessUrl: Yup.string()
    .required('Access URL is required'),
  displayOrder: Yup.number()
    .typeError('Invalid display order')
    .min(0, 'Display order cannot be negative'),
});

const AddEditDigitalTool = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditDigitalTool;
