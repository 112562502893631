import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import useMounted from 'hooks/use-mounted';
import AddEditProductRelationshipForm from './AddEditProductRelationshipForm';

const apiEndpoint = 'product-relationships';
const resourceName = 'related product';

const fields = [
  {
    name: 'relatedProduct',
    label: 'Product',
  },
];

const validationSchema = Yup.object().shape({
  relatedProduct: Yup.string()
    .required('Product is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  if (resource.relatedProduct) {
    formValues.relatedProduct = resource.relatedProduct['@id'];
  }

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (values, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(values, fields);

  return apiValues;
}

const AddEditProductRelationship = (props) => {
  const {
    productRelationship: productRelationshipToEdit,
    product,
    onClose,
  } = props;

  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const id = productRelationshipToEdit ? Number(productRelationshipToEdit.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      if (!productRelationshipToEdit && product) {
        fields.push(
          {
            name: 'product',
            label: 'Product',
            type: 'hidden',
            defaultValue: product['@id'],
          },
        );
      }
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditProductRelationshipForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal
      onModalClose={onClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
}

AddEditProductRelationship.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productRelationship: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddEditProductRelationship.defaultProps = {
  productRelationship: null,
};

export default AddEditProductRelationship;
