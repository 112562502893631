// WRM
import ListResources from 'components/shared/ListResources';
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import getDateLocaleString from 'utils/get-date-locale-string';

const title = 'Braintree payment transactions';
const apiEndpoint = 'payment-transactions';
const resourceName = 'payment';

const statusChoices = [
  {
    value: 'success',
    label: 'Success',
  },
  {
    value: 'failure',
    label: 'Failure',
  },
  {
    value: 'payment_gateway_key_generated',
    label: 'Payment gateway key generated',
  },
];

const columns = [
  {
    field: 'createdAt',
    label: 'Date',
    sortable: true,
    formatter: (resource) => getDateLocaleString(resource.createdAt),
  },
  {
    field: 'email',
    label: 'Email',
    formatter: (resource) => resource.basket?.email,
  },
  {
    field: 'transactionAmount',
    label: 'Amount',
    formatter: (resource) => `£${convertPriceToPounds(resource.transactionAmount)}`
  },
  {
    field: 'status',
    label: 'Status',
    formatter: (resource) => resource.status.replaceAll('_', ' ').toUpperCase(),
  },
  {
    field: 'paymentGatewayTransactionId',
    label: 'Braintree transaction ID',
  },
  {
    field: 'order.id',
    label: 'Order',
    sortable: true,
    formatter: (resource) => resource.order ? `#${resource.order.reference}` : '',
  },
];

const filters = [
  {
    field: 'status',
    label: 'Status',
    type: 'select',
    choices: statusChoices,
  },
  {
    field: 'paymentGatewayTransactionId',
    label: 'Braintree transaction ID',
    type: 'text',
  },
  {
    field: '_userSearch',
    label: 'Customer',
    type: 'text',
    apiAttribute: 'userSearch',
  },
  {
    field: '_orderSearch',
    label: 'Order',
    type: 'text',
    apiAttribute: 'orderSearch',
  },
];

const defaultFilterValues = {
  status: [] // Force an array for multiselect
};

const defaultSortValues = [
  {
    field: 'createdAt',
    direction: 'desc'
  },
];

const PaymentTransactions = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    disableAddResource
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default PaymentTransactions;
