import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './ExportFieldsHelper.module.scss';

// Linked with wrm-api/api/src/Component/Report/Generator/OrderReportGenerator.php
const exportFieldProperties = [
  // source: Orders Table.
  {
    value: 'Order.id',
    label: 'Order.id',
  },
  {
    value: 'Order.user_id',
    label: 'Order.user_id',
  },
  {
    value: 'Order.reference',
    label: 'Order.reference',
  },
  {
    value: 'Order.customer_reference',
    label: 'Order.customer_reference',
  },
  {
    value: 'Order.email',
    label: 'Order.email',
  },
  {
    value: 'Order.status',
    label: 'Order.status',
  },
  {
    value: 'Order.total_exc_tax_before_discount',
    label: 'Order.total_exc_tax_before_discount',
  },
  {
    value: 'Order.total_inc_tax_before_discount',
    label: 'Order.total_inc_tax_before_discount',
  },
  {
    value: 'Order.total_exc_tax_after_discount',
    label: 'Order.total_exc_tax_after_discount',
  },
  {
    value: 'Order.total_inc_tax_after_discount',
    label: 'Order.total_inc_tax_after_discount',
  },
  {
    value: 'Order.shipping_service_name',
    label: 'Order.shipping_service_name',
  },
  {
    value: 'Order.shipping_service_code',
    label: 'Order.shipping_service_code',
  },
  {
    value: 'Order.shipping_date',
    label: 'Order.shipping_date',
  },
  {
    value: 'Order.shipping_total_before_discount',
    label: 'Order.shipping_total_before_discount',
  },
  {
    value: 'Order.shipping_total_after_discount',
    label: 'Order.shipping_total_after_discount',
  },
  {
    value: 'Order.shipping_promotion_name',
    label: 'Order.shipping_promotion_name',
  },
  {
    value: 'Order.shipping_promotion_code',
    label: 'Order.shipping_promotion_code',
  },
  {
    value: 'Order.shipping_discount_exc_tax',
    label: 'Order.shipping_discount_exc_tax',
  },
  {
    value: 'Order.shipping_discount_inc_tax',
    label: 'Order.shipping_discount_inc_tax',
  },
  {
    value: 'Order.payment_method',
    label: 'Order.payment_method',
  },
  {
    value: 'Order.created_at',
    label: 'Order.created_at',
  },
  {
    value: 'Order.updated_at',
    label: 'Order.updated_at',
  },
  {
    value: 'Order.legacy_id',
    label: 'Order.legacy_id',
  },
  {
    value: 'Order.currency',
    label: 'Order.currency',
  },
  {
    value: 'Order.billing_title',
    label: 'Order.billing_title',
  },
  {
    value: 'Order.billing_first_name',
    label: 'Order.billing_first_name',
  },
  {
    value: 'Order.billing_last_name',
    label: 'Order.billing_last_name',
  },
  {
    value: 'Order.billing_line1',
    label: 'Order.billing_line1',
  },
  {
    value: 'Order.billing_line2',
    label: 'Order.billing_line2',
  },
  {
    value: 'Order.billing_line3',
    label: 'Order.billing_line3',
  },
  {
    value: 'Order.billing_town_city',
    label: 'Order.billing_town_city',
  },
  {
    value: 'Order.billing_county',
    label: 'Order.billing_county',
  },
  {
    value: 'Order.billing_postcode',
    label: 'Order.billing_postcode',
  },
  {
    value: 'Order.billing_country',
    label: 'Order.billing_country',
  },
  {
    value: 'Order.billing_phone',
    label: 'Order.billing_phone',
  },
  {
    value: 'Order.delivery_title',
    label: 'Order.delivery_title',
  },
  {
    value: 'Order.delivery_first_name',
    label: 'Order.delivery_first_name',
  },
  {
    value: 'Order.delivery_last_name',
    label: 'Order.delivery_last_name',
  },
  {
    value: 'Order.delivery_line1',
    label: 'Order.delivery_line1',
  },
  {
    value: 'Order.delivery_line2',
    label: 'Order.delivery_line2',
  },
  {
    value: 'Order.delivery_line3',
    label: 'Order.delivery_line3',
  },
  {
    value: 'Order.delivery_town_city',
    label: 'Order.delivery_town_city',
  },
  {
    value: 'Order.delivery_county',
    label: 'Order.delivery_county',
  },
  {
    value: 'Order.delivery_postcode',
    label: 'Order.delivery_postcode',
  },
  {
    value: 'Order.delivery_country',
    label: 'Order.delivery_country',
  },
  {
    value: 'Order.delivery_phone',
    label: 'Order.delivery_phone',
  },
  {
    value: 'Order.payment_reference',
    label: 'Order.payment_reference',
  },
  {
    value: 'Order.paid_at',
    label: 'Order.paid_at',
  },
  {
    value: 'Order.customer_note',
    label: 'Order.customer_note',
  },
  {
    value: 'Order.buyer_type',
    label: 'Order.buyer_type',
  },
  {
    value: 'Order.billing_email',
    label: 'Order.billing_email',
  },
  {
    value: 'Order.billing_company',
    label: 'Order.billing_company',
  },
  {
    value: 'Order.delivery_email',
    label: 'Order.delivery_email',
  },
  {
    value: 'Order.delivery_company',
    label: 'Order.delivery_company',
  },
  {
    value: 'Order.is_mailing_list_opt_in',
    label: 'Order.is_mailing_list_opt_in',
  },
  {
    value: 'Order.payment_status',
    label: 'Order.payment_status',
  },
  {
    value: 'Order.invoice_note',
    label: 'Order.invoice_note',
  },
  {
    value: 'Order.fulfilment_status',
    label: 'Order.fulfilment_status',
  },
  {
    value: 'Order.contains_physical_products',
    label: 'Order.contains_physical_products',
  },
  {
    value: 'Order.hubspot_sync_at',
    label: 'Order.hubspot_sync_at',
  },
  {
    value: 'Order.is_auto_approved',
    label: 'Order.is_auto_approved',
  },
  {
    value: 'Order.source',
    label: 'Order.source',
  },
  {
    value: 'Order.additional_shipping_amount',
    label: 'Order.additional_shipping_amount',
  },
  {
    value: 'Order.additional_shipping_description',
    label: 'Order.additional_shipping_description',
  },
  {
    value: 'Order.hubspot_id',
    label: 'Order.hubspot_id',
  },
  {
    value: 'Order.school_id',
    label: 'Order.school_id',
  },
  {
    value: 'Order.owning_learning_group_id',
    label: 'Order.owning_learning_group_id',
  },
  
  // source: wrm-api/api/src/Component/Report/Generator/OrderReportGenerator.php
  {
    value: 'Order.billing_full_name',
    label: 'Order.billing_full_name',
  },
  {
    value: 'Order.billing_line1_and_line2',
    label: 'Order.billing_line1_and_line2',
  },
  {
    value: 'Order.delivery_line1_and_line2',
    label: 'Order.delivery_line1_and_line2',
  },
  {
    value: 'Order.billing_email_or_email',
    label: 'Order.billing_email_or_email',
  },
  {
    value: 'Order.order_lines_summary',
    label: 'Order.order_lines_summary',
  },
  {
    value: 'OrderLine.product_sku',
    label: 'OrderLine.product_sku',
  },
  {
    value: 'OrderLine.product_name',
    label: 'OrderLine.product_name',
  },
  {
    value: 'OrderLine.product_reporting_category_name',
    label: 'OrderLine.product_reporting_category_name',
  },
  {
    value: 'OrderLine.product_attribute_value.isbn',
    label: 'OrderLine.product_attribute_value.isbn',
  },
  {
    value: 'OrderLine.price_exc_tax_before_discount',
    label: 'OrderLine.price_exc_tax_before_discount',
  },
  {
    value: 'OrderLine.price_inc_tax_before_discount',
    label: 'OrderLine.price_inc_tax_before_discount',
  },
  {
    value: 'OrderLine.price_exc_tax_after_discount',
    label: 'OrderLine.price_exc_tax_after_discount',
  },
  {
    value: 'OrderLine.price_inc_tax_after_discount',
    label: 'OrderLine.price_inc_tax_after_discount',
  },
  {
    value: 'OrderLine.quantity',
    label: 'OrderLine.quantity',
  },
  {
    value: 'OrderLine.quantity_cancelled',
    label: 'OrderLine.quantity_cancelled',
  },
  // {
  //   value: 'OrderLine.order_data',
  //   label: 'OrderLine.order_data',
  // },
  {
    value: 'OrderLine.order_line_number',
    label: 'OrderLine.order_line_number',
  },
  // {
  //   value: 'OrderLine.product_variant_data',
  //   label: 'OrderLine.product_variant_data',
  // },
  {
    value: 'OrderLine.product_variant_data.Year_Group',
    label: 'OrderLine.product_variant_data.Year_Group',
  },
  {
    value: 'OrderLine.product_variant_data.Delivery',
    label: 'OrderLine.product_variant_data.Delivery',
  },
  {
    value: 'OrderLine.product_variant_data.Licences',
    label: 'OrderLine.product_variant_data.Licences',
  },
  {
    value: 'OrderLine.product_variant_data_and_order_data_as_string',
    label: 'OrderLine.product_variant_data_and_order_data_as_string',
  },
  {
    value: 'OrderLine.order_line_number',
    label: 'OrderLine.order_line_number',
  },
  {
    value: 'OrderLine.promotions.retrospective-credit-system',
    label: 'OrderLine.promotions.retrospective-credit-system',
  },
  {
    value: 'OrderLine.promotions.package_builder_10_percent_off_2_products',
    label: 'OrderLine.promotions.package_builder_10_percent_off_2_products',
  },
  {
    value: 'OrderLine.promotions.package_builder_15_percent_off_3_products',
    label: 'OrderLine.promotions.package_builder_15_percent_off_3_products',
  },
  {
    value: 'OrderLine.promotions.package_builder_20_percent_off_2_products',
    label: 'OrderLine.promotions.package_builder_20_percent_off_2_products',
  },
  {
    value: 'OrderLine.promotions.package_builder_30_percent_off_3_products',
    label: 'OrderLine.promotions.package_builder_30_percent_off_3_products',
  },
  {
    value: 'OrderLine.promotions.package_builder_40_percent_off_4_or_more_products',
    label: 'OrderLine.promotions.package_builder_40_percent_off_4_or_more_products',
  },
  {
    value: 'OrderLine.promotions.manual_discount',
    label: 'OrderLine.promotions.manual_discount',
  },
  {
    value: 'OrderLine.promotions._other_',
    label: 'OrderLine.promotions._other_',
  },
  {
    value: 'OrderLine.promotions._all_',
    label: 'OrderLine.promotions._all_',
  },

  // Extra
  {
    value: "",
    label: "blank",
  }
];

const ExportFieldsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedExportFields = [];
    const movedExportField = formik.values.exportFieldsHelper[result.source.index];
    formik.values.exportFieldsHelper.forEach((exportField, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedExportFields.push(movedExportField);
      }
      if (index !== result.source.index) {
        reorderedExportFields.push(exportField);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedExportFields.push(movedExportField);
      }
    });
    formik.setFieldValue('exportFieldsHelper', reorderedExportFields);
  };

  return (
    <FieldArray name="exportFieldsHelper" render={fieldArrayHelpers => (
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <h4>Export fields</h4>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MDButton
              className={componentStyles['add-export-field-button']}
              color="info"
              onClick={() => {
                const prototype = {};
                prototype.property = '';
                prototype.label = '';
                prototype.uuid = moment().valueOf();
                fieldArrayHelpers.push(prototype);
              }}
              size="small"
              startIcon={<AddIcon/>}
              variant="gradient"
            >
              Add export field
            </MDButton>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Table>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  direction="vertical"
                  droppableId="export-fields-droppable"
                >
                  {
                    (droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {
                          formik.values.exportFieldsHelper?.map((exportField, index) => {
                            const propertyFieldName = `exportFieldsHelper[${index}].property`;
                            const labelFieldName = `exportFieldsHelper[${index}].label`;
                            return (
                              <Draggable
                                draggableId={`export-field-${exportField.uuid}`}
                                index={index}
                                key={`export-field-${exportField.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                      }}
                                    >
                                      <TableCell style={{ width: '1px' }}>
                                        <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={propertyFieldName}
                                          label="Property"
                                          type="select"
                                          choices={exportFieldProperties}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={labelFieldName}
                                          label="Label"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: '1px' }}>
                                        <MDButton
                                          color="error"
                                          iconOnly
                                          onClick={() => {
                                            fieldArrayHelpers.remove(index);
                                          }}
                                          variant="gradient"
                                        >
                                          <DeleteIcon/>
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </TableBody>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    )} />
  );
};

ExportFieldsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default ExportFieldsHelper;
