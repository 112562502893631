// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Attribute groups';
const apiEndpoint = 'product-attribute-groups';
const resourceName = 'attribute group';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    field: 'numberOfAttributes',
    label: '# of attributes ',
    formatter: (resource) => resource.productAttributes?.length,
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const ProductAttributeGroups = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default ProductAttributeGroups;
