/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-param-reassign */
import { adminSession } from '../constants';

export const getRequestQuery = (apiEndpoint, responseType = 'json') => ({
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(adminSession)}`,
    'Content-Type': 'application/ld+json; charset=utf-8',
  },
  responseType,
});

export const postRequestQuery = (apiEndpoint, data, noAuth) => {
  const headers = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  if (!noAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem(adminSession)}`;
  }

  return {
    method: 'POST',
    url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`,
    headers,
    data: JSON.stringify(data),
  };
};

export const putRequestQuery = (apiEndpoint, data = {}, noAuth) => {
  const headers = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  if (!noAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem(adminSession)}`;
  }

  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`,
    headers,
    data: JSON.stringify(data),
  };
};
