import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import useMounted from 'hooks/use-mounted';
import AddEditProductAttributeForm from './AddEditProductAttributeForm';

const apiEndpoint = 'product-attributes';
const resourceName = 'attribute';

const dataTypeChoices = [
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'richtext',
    label: 'Rich text',
  },
  {
    value: 'single_select',
    label: 'Single select',
  },
  {
    value: 'multi_select',
    label: 'Multi select',
  },
];

const optionsGetHeading = values => values.label ? values.label : '<No label set>';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },
  {
    name: 'dataType',
    label: 'Data type',
    type: 'select',
    choices: dataTypeChoices,
  },
  {
    name: 'options',
    label: 'Options',
    type: 'fieldCollection',
    defaultValue: [],
    getHeading: optionsGetHeading,
    childFields: [
      {name: 'value', type: 'text', label: "Value"},
      {name: 'label', type: 'text', label: "Label"},
    ],
  },
  {
    name: 'defaultValue',
    label: 'Default value',
  },
  {
    name: 'isRequired',
    label: 'Required?',
    type: 'checkbox',
  },
];

Yup.addMethod(Yup.array, 'optionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`options-helper`, errorMessage, function (optionsValues) {
    const { createError } = this;

    const errors = [];
    optionsValues?.forEach((optionsHelperValue, index) => {
      const { value, label } = optionsHelperValue;
      if (!value) {
        errors.push(new ValidationError(
          `Value is required`,
          '',
          `options[${index}].value`
        ));
      }
      if (!label) {
        errors.push(new ValidationError(
          `Label is required`,
          '',
          `options[${index}].label`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  code: Yup.string()
    .required('Code is required'),
  dataType: Yup.string()
    .required('Data type is required')
    .notOneOf(['none'], 'Data type is required'), // Capture 'no selection'
  options: Yup.array().optionsHelper('Invalid options'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  formValues.options = [];

  resource.options?.forEach((option, index) => {
    const optionWithUuid = {};
    optionWithUuid.value = option.value;
    optionWithUuid.label = option.label
    optionWithUuid.uuid = index;
    formValues.options.push(optionWithUuid);
  });

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  apiValues.options = [];

  formValues.options.forEach((optionHelper) => {
    const option = {
      value: optionHelper.value,
      label: optionHelper.label,
    };
    apiValues.options.push(option);
  });

  return apiValues;
}

const AddEditProductAttribute = () => {
  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditProductAttributeForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditProductAttribute;
