import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import getDateLocaleString from 'utils/get-date-locale-string';

const LicenceExpiringBanner = (props) => {
  const { licence, membershipPlanName } = props;

  let message = `Learning group does not have ${membershipPlanName}`;
  let severity = 'error';
  const expiryDate = licence?.expiresAt ? new Date(licence.expiresAt) : null
  const dateDiffDays = expiryDate ? (expiryDate - new Date()) / (1000 * 60 * 60 * 24) : null;
  if (dateDiffDays && dateDiffDays <= 0) {
    message = `Learning group's ${membershipPlanName} licence has expired`;
  }
  else if (dateDiffDays <= 30 && dateDiffDays > 0) {
    message = `Learning group's ${membershipPlanName} licence is expiring in ${Math.floor(dateDiffDays)} days`;
  }
  else if (dateDiffDays <= 60 && dateDiffDays > 30) {
    message = `Learning group's ${membershipPlanName} licence is expiring in ${Math.floor(dateDiffDays)} days`;
    severity = 'warning';
  }
  else if (dateDiffDays > 60) {
    message = `Learning group's ${membershipPlanName} licence expires on ${getDateLocaleString(expiryDate, 'date')}`;
    severity = 'success';
  }

  return <Alert severity={severity}>{message}</Alert>;
};

LicenceExpiringBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  licence: PropTypes.object,
  membershipPlanName: PropTypes.string.isRequired,
};

LicenceExpiringBanner.defaultProps = {
  licence: null,
};

export default LicenceExpiringBanner;
