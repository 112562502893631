import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

import MDButton from 'mdpr2/components/MDButton';

import { resourceApi } from 'api/resource-api';
import useMounted from 'hooks/use-mounted';
import componentStyles from './AddOrderProductGroupParts.module.scss';

const AddOrderProductGroupParts = (props) => {
  const {
    addProductsToBasket,
    productIri,
    setModalOpen,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [products, setProducts] = useState();
  const [productQuantities, setProductQuantities] = useState({});

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    const data = await resourceApi.getResources({
      apiEndpoint: 'products',
      filterValues: { groupProduct: productIri },
      pagination: { itemsPerPage: 999999 },
    });
    if (isMounted()) {
      setProducts(data.resources);
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  // eslint-disable-next-line no-shadow
  const setProductQuantity = (productIri, quantity) => {
    const newProductQuantities = { ...productQuantities };
    if (!Number.isNaN(quantity)) {
      newProductQuantities[productIri] = quantity;
    } else {
      delete(newProductQuantities[productIri]);
    }
    setProductQuantities(newProductQuantities);
  }

  const handleAddToOrderOnClick = async () => {
    const productOrProductVariantIrisAndQuantities = [];
    // eslint-disable-next-line no-shadow
    Object.keys(productQuantities).forEach((productIri) => {
      productOrProductVariantIrisAndQuantities.push({
        productOrProductVariantIri: productIri,
        quantity: productQuantities[productIri],
      });
    });
    addProductsToBasket(productOrProductVariantIrisAndQuantities, {});
    setModalOpen(false);
  }

  return (
    <>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            products.map((product) => (
              <TableRow>
                <TableCell>{product.sku}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell className={componentStyles['quantity-table-cell']}>
                  <TextField
                    label="Quantity"
                    variant="outlined"
                    onChange={(event) => { setProductQuantity(product['@id'], parseInt(event.target.value, 10)); }}
                    value={productQuantities[product['@id']] ? productQuantities[product['@id']] : ''}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={2}
        >
          <MDButton
            color="info"
            fullWidth
            onClick={handleAddToOrderOnClick}
            variant="gradient"
          >
            Add selected products to order
          </MDButton>
        </Grid>
        <Grid
          item
          xs={2}>
          <MDButton
            color="secondary"
            fullWidth
            onClick={() => setModalOpen(false)}
            variant="gradient"
          >
            Close
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
}

AddOrderProductGroupParts.propTypes = {
  addProductsToBasket: PropTypes.func.isRequired,
  productIri: PropTypes.string.isRequired,
  setModalOpen: PropTypes.bool.isRequired,
};

export default AddOrderProductGroupParts;
