import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI
import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

// MDPR
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import {resourceApi} from 'api/resource-api';

const getCreatedByUserId = (teamMembership) => {
    const matches = teamMembership?.teamOwner?.createdByUser.match(/^\/users\/(\d+?)$/);
    if (!matches || matches.length < 2) {
        return null;
    }
    return matches[1];
}

const TeamOwnerCard = ({teamMembership}) => {
    const [createdByUserId] = useState(getCreatedByUserId(teamMembership));
    const [createdByUser, setCreatedByUser] = useState(null);
    const [teamOwner] = useState(teamMembership.teamOwner ?? null);

    const linkToUser = () => createdByUserId ? `/admin/users/edit/${createdByUserId}` : null;

    const fetchCreatedByUser = async () => {
        if (createdByUserId === null) {
            return;
        }
        const user = await resourceApi.getResource({ apiEndpoint: 'users', id: createdByUserId });
        setCreatedByUser(user);
    }

    useEffect(fetchCreatedByUser, [createdByUserId]);

    const addressLine = (lineText, label = null) => {
        if(lineText === null || lineText.trim().length === 0) {
            return null;
        }
        return (<>
            {label && (<b>{label}: </b>) }{ lineText.trim() }<br/>
        </>)
    };

    const createdByUserFullName = () => {
        if(createdByUser === null) {
            return '?';
        }

        return `${createdByUser.firstName} ${createdByUser.lastName}`;
    }

    const auditDetails = () => (<i>
        Team ownership set by <Link to={linkToUser()} target="_blank">{ createdByUserFullName() }</Link> on { moment(teamOwner.createdAt).format('DD/MM/YYYY ') }
        </i>);

    const ownershipDetails = () => {
        // no team owner set
        if(!teamOwner) {
            return (
                <MDTypography variant="subtitle2">
                    No school/organisation has been specified
                </MDTypography>
            );
        }
        // school record selected...
        if(teamOwner && teamOwner.school !== null) {
            return (
                <MDTypography variant="subtitle2">
                    <Box marginBottom={2}>
                        { teamOwner.school.name } <strong>#{teamOwner.school.id}</strong>
                        <br/>
                        { addressLine(teamOwner.school.addressStreet) }
                        { addressLine(teamOwner.school.addressLocality) }
                        { addressLine(teamOwner.school.addressTownCity) }
                        { addressLine(teamOwner.school.addressPostcode) }
                        { addressLine(teamOwner.school.addressCountry) }
                    </Box>
                    { linkToUser() !== null && auditDetails() }
                </MDTypography>
            );
        }

        // otherwise, freetext school/company given...
        return (
            <MDTypography variant="subtitle2">
                <Box marginBottom={2}>
                    { addressLine(teamOwner.ima, 'I am a') }
                    { addressLine(teamOwner.description, 'Description') }
                    { (teamOwner.ima != null || teamOwner.description != null) && (<Divider/>)}
                    { teamOwner.companyName }
                    <br/>
                    { addressLine(teamOwner.addressLine1) }
                    { addressLine(teamOwner.addressLine2) }
                    { addressLine(teamOwner.townCity) }
                    { addressLine(teamOwner.postcode) }
                    { addressLine(teamOwner.country) }
                </Box>
                { linkToUser() !== null && auditDetails() }
            </MDTypography>
        );
    }

    return (
        <Card>
            <CardContent>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={8}>
                        <MDTypography variant="h6">Team ownership</MDTypography>
                    </Grid>
                </Grid>
                { ownershipDetails() }
            </CardContent>
        </Card>
    );
}

TeamOwnerCard.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    teamMembership: PropTypes.object.isRequired,
};

export default TeamOwnerCard;