/* eslint-disable react/no-unstable-nested-components */
import {useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';

// MUI
import {
  Tooltip
} from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Check from '@mui/icons-material/Check';
import Update from '@mui/icons-material/Update';
import Pause from '@mui/icons-material/Pause';
import Close from '@mui/icons-material/Close';
import Refresh from '@mui/icons-material/Refresh';
import OpenInNew from '@mui/icons-material/OpenInNew';

// WRM
import ListResources from 'components/shared/ListResources';
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import getDateLocaleString from 'utils/get-date-locale-string';
import getUserName from 'utils/get-user-name';
import webSchoolFilterSearch from "utils/webSchoolFilterSearch";
import { requestApi } from "api/request-api";
import { resourceApi } from 'api/resource-api';
import {debounce} from "lodash";
import OrderPreviewDialog from "./OrderPreviewDialog";

const apiEndpoint = 'orders';
const resourceName = 'order';

const statusChoices = [
  {
    value: 'on_hold',
    label: 'On hold',
  },
  {
    value: 'processing',
    label: 'Processing',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    value: 'refunded',
    label: 'Refunded',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
];

const statusIcons = [
  {
    value: 'COMPLETED',
    color: 'success',
    icon: <Check />,
  },
  {
    value: 'PROCESSING',
    color: 'dark',
    icon: <Update />,
  },
  {
    value: 'ON HOLD',
    color: 'warning',
    icon: <Pause />,
  },
  {
    value: 'CANCELLED',
    color: 'error',
    icon: <Close />,
  },
  {
    value: 'REFUNDED',
    color: 'dark',
    icon: <Refresh />,
  }
];

const getStatusIcon = (status) => {
  const icons = statusIcons.filter((s) => s.value === status);

  if (icons.length > 0) return icons[0];

  return null;
};

const fulfilmentSearchChoices = [
  {
    value: 'completed_true_main',
    label: 'Main shipped',
  },
  {
    value: 'completed_true_autumn',
    label: 'Autumn shipped',
  },
  {
    value: 'completed_true_spring',
    label: 'Spring shipped',
  },
  {
    value: 'completed_true_summer',
    label: 'Summer shipped',
  },
  {
    value: 'completed_false_autumn',
    label: 'Autumn not shipped',
  },
  {
    value: 'completed_false_spring',
    label: 'Spring not shipped',
  },
  {
    value: 'completed_false_summer',
    label: 'Summer not shipped',
  },
  {
    value: 'completed_false_main',
    label: 'Main not shipped',
  },
  {
    value: 'completed_partial_autumn',
    label: 'Autumn partially shipped',
  },
  {
    value: 'completed_partial_spring',
    label: 'Spring partially shipped',
  },
  {
    value: 'completed_partial_summer',
    label: 'Summer partially shipped',
  },
  {
    value: 'completed_partial_main',
    label: 'Main partially shipped',
  },
];

const handleDownloadOrderInvoice = async (orderId) => {
  const response = await requestApi.getResponse({
    url: `orders/${orderId}/invoice`,
    rawResponse: true,
  });
  const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
  if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
  const blob = new Blob([response.data], {type: 'application/pdf'});
  const objectUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = objectUrl;
  downloadLink.download = filename;
  return downloadLink.click();
};

const handleDownloadOrderPackingSlip = async (orderId) => {
  const response = await requestApi.getResponse({
    url: `orders/${orderId}/packing-slip`,
    rawResponse: true,
  });
  const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
  if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
  const blob = new Blob([response.data], {type: 'application/pdf'});
  const objectUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = objectUrl;
  downloadLink.download = filename;
  return downloadLink.click();
};

const defaultFilterValues = {
  status: [],
};

const defaultSortValues = [
  {
    field: 'createdAt',
    direction: 'desc',
  },
];

const buildFixedFilterValues = (context, orderStatus) => {
  const fixedFilterValues = {};

  if (context === 'warehouse') {
    fixedFilterValues.containsPhysicalProducts = true;
    fixedFilterValues.status = orderStatus;
  }

  return fixedFilterValues;
}

const Orders = (props) => {
  const {
    context,
    orderStatus
  } = props;

  // Modal state
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewOrder, setPreviewOrder] = useState(null);
  const [fixedFilterValues, setFixedFilterValues] = useState(buildFixedFilterValues(context, orderStatus));

  useEffect(() => {
    setFixedFilterValues(buildFixedFilterValues(context, orderStatus));
  }, [context, orderStatus])

  const handlePreviewOrder = (id) => {
    resourceApi.getResource({apiEndpoint: 'orders', id }).then(resource => {
      if (resource) {
        setPreviewOrder(resource);
        setPreviewModalOpen(true);
      }
    });
  };

  const columns = [
    {
      field: 'reference',
      label: 'Order #',
      sortable: true,
      flex: 1,
    },
    {
      field: 'preview',
      label: ' ',
      flex: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (resource) => {
        const { id } = resource;
        return (
          <>
            <MDButton
              color="dark"
              onClick={() => window.open(`/admin/${context === 'warehouse' ? 'warehouse/orders' : 'orders' || apiEndpoint}/edit/${resource.id}`, '_blank')}
              variant="outlined"
              iconOnly
            >
              <OpenInNew />
            </MDButton>
            &nbsp;
            <MDButton
              color="dark"
              onClick={() => handlePreviewOrder(id)}
              variant="outlined"
              iconOnly
            >
              <RemoveRedEyeIcon />
            </MDButton>
          </>
        );
      },
    },
    {
      field: 'user',
      label: 'Customer',
      flex: 2,
      formatter: (resource) => (resource.user ? getUserName(resource.user) : 'N/A'),
    },
    {
      field: 'createdAt',
      label: 'Date',
      sortable: true,
      flex: 1,
      formatter: (resource) => getDateLocaleString(resource.createdAt),
    },
    {
      field: 'totalIncTaxAfterDiscount',
      label: 'Total',
      sortable: true,
      flex: 1,
      formatter: (resource) => (
        `${resource.totalIncTaxAfterDiscount ? `£${convertPriceToPounds(resource.totalIncTaxAfterDiscount)}` : ''}`
      ),
    },
    {
      field: 'status',
      label: 'Status',
      sortable: true,
      flex: 1,
      formatter: (resource) => {
        const status = resource.status.replace('_', ' ').toUpperCase();
        const icon = getStatusIcon(status);
        return (
          <>
            {icon && (
              <MDButton
                color={icon.color}
                variant="outlined"
                iconOnly
                circular
                size="small"
              >
                {icon.icon}
              </MDButton>
            )}
            &nbsp;
            {status}
          </>
        );
      },
    },
    {
      field: 'generate',
      label: ' ',
      flex: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (resource) => {
        const { containsPhysicalProducts, id } = resource;
        return (
          <>
            {
              context === 'orders'
              && (
                <Tooltip title="Download invoice">
                  <MDButton
                    color="info"
                    onClick={() => handleDownloadOrderInvoice(id)}
                    variant="outlined"
                    iconOnly
                  >
                    <CurrencyPoundIcon/>
                  </MDButton>
                </Tooltip>
              )
            }
            {
              containsPhysicalProducts
              && (
                <>
                  &nbsp;
                  <Tooltip title="Download packing slip">
                    <MDButton
                      color="info"
                      onClick={() => handleDownloadOrderPackingSlip(id)}
                      variant="outlined"
                      iconOnly
                    >
                      <LocalShippingIcon />
                    </MDButton>
                  </Tooltip>
                </>
              )
            }
          </>
        );
      },
    },
  ];

  const debouncedSchoolFilterSearch = useMemo(
      () => debounce((search, setOptions) => webSchoolFilterSearch(search, setOptions), 280),
      []
  );

  const filters = [
    {
      field: 'reference',
      label: 'Reference',
      type: 'text',
    },
    {
      field: 'userSearch',
      label: 'Customer',
      type: 'text',
      apiAttribute: 'userSearch',
    },
    {
      field: 'school',
      label: 'School',
      type: 'typeahead',
      search: debouncedSchoolFilterSearch,
      apiAttribute: 'school',
    },
  ];
  if (context === 'orders') {
    filters.push(
      {
        field: 'status',
        label: 'Status',
        type: 'select',
        choices: statusChoices,
      },
    );
  }
  if (context === 'warehouse') {
    filters.push(
      {
        field: '_fulfilmentSearch',
        label: 'Shipments',
        type: 'select',
        choices: fulfilmentSearchChoices,
        apiAttribute: 'fulfilmentSearch',
      },
    );
  }

  let title = 'Orders';
  if (context === 'warehouse') {

    title = 'Warehouse orders';

    if (orderStatus !== null) {
      title += `: ${orderStatus}`;
    }
  }

  return (
      <>
        <ListResources
          adminEndpoint={context === 'warehouse' ? 'warehouse/orders' : 'orders'}
          apiEndpoint={apiEndpoint}
          columns={columns}
          defaultFilterValues={defaultFilterValues}
          defaultSortValues={defaultSortValues}
          filters={filters}
          fixedFilterValues={fixedFilterValues}
          resourceName={resourceName}
          title={title}
          disableOpenInNewTab // we already have custom open in new tab button
          disableAddResource={context === 'warehouse'}
        />
        { previewModalOpen && (
            <OrderPreviewDialog
            open
            close={() => setPreviewModalOpen(false)}
            previewOrder={previewOrder}
            context={context}
            />
        )}
      </>
  );
};

Orders.propTypes = {
  context: PropTypes.string,
  orderStatus: PropTypes.string,
}

Orders.defaultProps = {
  context: 'orders',
  orderStatus: null
}

export default Orders;
