import React, { createContext, useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

import { adminSession, refreshToken, clientTimeOffset } from '../constants';
import { postRequestQuery } from '../api/apiRequest';

const UserContext = createContext();

function UserProvider(props) {
  const [userProfile, setUserProfile] = useState({});

  const refreshSession = async () => {
    const refreshTokenPayload = {
      refresh_token: localStorage.getItem(refreshToken),
    };

    const response = await axios(postRequestQuery('token/refresh', refreshTokenPayload, true));
    return response;
  };

  const checkSessionStatus = () => {
    const now = Math.floor(Date.now() / 1000) - (localStorage.getItem(clientTimeOffset) ?? 0);

    const decodedToken = jwtDecode(localStorage.getItem(adminSession));
    const { exp } = decodedToken;

    if (now > exp) {
      if (localStorage.getItem(refreshToken)) {
        refreshSession()
          .then((res) => {
            // eslint-disable-next-line no-shadow
            const decodedToken = jwtDecode(res.data.token);

            setUserProfile(decodedToken);
            localStorage.setItem(adminSession, res.data.token);
          })
          .catch(() => {
            localStorage.removeItem(adminSession);
            localStorage.removeItem(refreshToken);
            setUserProfile(false);
            window.location.replace('/auth/login');
          });
      } else {
        localStorage.removeItem(adminSession);
        setUserProfile({});
        window.location.replace('/auth/login');
      }
    }
  };

  const fetchUserPermissions = () => userProfile.roles;

  const userHasRole = (role) => userProfile.roles?.includes(role);

  setInterval(() => {
    if (localStorage.getItem(adminSession)) checkSessionStatus();
  }, 1000);

  useEffect(() => {
    if (Object.keys(userProfile).length === 0 && localStorage.getItem(adminSession) !== null) {
      const decodedToken = jwtDecode(localStorage.getItem(adminSession));
      if (decodedToken) setUserProfile(decodedToken);
      checkSessionStatus();
    }
  }, []);

  return (
    <UserContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        fetchUserPermissions,
        setUserProfile,
        userHasRole,
        userProfile,
      }}
      {...props}
    />
  );
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
