import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

import App from 'App';

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from 'mdpr2/context';

// WRM
import { UserProvider } from './contexts/userContext';

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
