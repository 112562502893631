import { requestApi } from 'api/request-api';

export const downloadQuote = async (basketId) => {
  const response = await requestApi.getResponse({
    url: `baskets/${basketId}/quote`,
    rawResponse: true,
  });
  const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
  if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const objectUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = objectUrl;
  downloadLink.download = filename;
  return downloadLink.click();
};

export const getOrderIdFromIri = (orderIri) => {
  const matches = orderIri.match(/^\/orders\/(\d+?)$/);
  if (matches) {
    return matches[1];
  }
  return null;
};
