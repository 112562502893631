import { resourceApi } from 'api/resource-api';

const sortLearningGroupAccesses = (learningGroupAccesses) => {
  const sortedManagers = learningGroupAccesses
    .filter((access) => access.role === 'manager')
    .sort((access) => (access.isActive ? -1 : 1));

  const sortedMembers = learningGroupAccesses
    .filter((access) => access.role !== 'manager')
    .sort((access) => (access.isActive ? -1 : 1));

  return [...sortedManagers, ...sortedMembers];
};

/**
 * The function `loadAccesses` loads learning group accesses for a specific resource and calls a
 * callback function with the sorted accesses.
 * resourceId = learningGroup id
 */
export const loadAccesses = async (resourceId) => {
  const response = await resourceApi.getResources({
    apiEndpoint: 'learning-group-accesses',
    filterValues: {
      learningGroup: `/learning-groups/${resourceId}`,
    },
    sortValues: [{ field: 'user.firstName', direction: 'asc' }],
    pagination: {
      itemsPerPage: 999,
    },
  });

  return sortLearningGroupAccesses(response.resources);
};

export default loadAccesses;
