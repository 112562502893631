import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'page-block-types';
const resourceName = 'page block type';

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'definition',
    label: 'Definition',
    type: 'textarea',
    json: true,
    defaultValue: []
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  code: Yup.string()
    .required('Code is required'),
  definition: Yup.string()
    .required('Definition is required')
    .test(
      'is-json',
      'Page block type definition is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
});

const AddEditPageBlockType = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditPageBlockType;
