import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import useMounted from 'hooks/use-mounted';
import AddEditProductGroupPartForm from './AddEditProductGroupPartForm';
import {resourceApi} from "../../../api/resource-api";

const apiEndpoint = 'product-group-parts';
const resourceName = 'product in group';

const fields = [
  {
    name: 'partProduct',
    label: 'Product',
  },
  {
    name: 'groupWithProductGroupPart',
    label: 'Group with (on product page)',
    type: 'select',
  },
];

const validationSchema = Yup.object().shape({
  partProduct: Yup.string()
    .required('Product is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  if (resource.partProduct) {
    formValues.partProduct = resource.partProduct['@id'];
  }

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (values, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(values, fields);

  return apiValues;
}

const AddEditProductGroupPart = props => {
  const {
    productGroupPart: productGroupPartToEdit,
    product,
    onClose,
  } = props;

  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const id = productGroupPartToEdit ? Number(productGroupPartToEdit.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      const productGroupPartsResponse = await resourceApi.getResources({
        apiEndpoint: 'product-group-parts',
        filterValues: { product: product['@id'] },
        sortValues: [{field: 'displayOrder', direction: 'asc'}],
        pagination: { itemsPerPage: 999999 },
      });
      const rootProductGroupParts = productGroupPartsResponse.resources.filter((resource) => !resource.groupWithProductGroupPart && resource.id !== id);
      const rootProductGroupPartChoices = [];
      rootProductGroupPartChoices.push({
        value: null,
        label: 'Ungrouped',
      })
      rootProductGroupParts.forEach((rootProductGroupPart) => {
        rootProductGroupPartChoices.push({
          value: rootProductGroupPart['@id'],
          label: `${rootProductGroupPart.partProduct.sku} (${rootProductGroupPart.partProduct.name}`,
        });
      });
      const groupWithProductGroupPartField = fields.find((field) => field.name === 'groupWithProductGroupPart');
      groupWithProductGroupPartField.choices = rootProductGroupPartChoices;

      if (!productGroupPartToEdit && product) {
        fields.push(
          {
            name: 'product',
            label: 'Product',
            type: 'hidden',
            defaultValue: product['@id'],
          },
          {
            name: 'displayOrder',
            type: 'hidden',
            defaultValue: 999999,
          }
        );
      }
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditProductGroupPartForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal
      onModalClose={onClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
}

AddEditProductGroupPart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productGroupPart: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddEditProductGroupPart.defaultProps = {
  productGroupPart: null,
};

export default AddEditProductGroupPart;
