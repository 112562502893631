import PropTypes from 'prop-types';

import { Card, CardContent, Grid, TextField } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';

import getDateLocaleString from 'utils/get-date-locale-string';
import { renderToStaticMarkup } from 'react-dom/server';
import getUserName from 'utils/get-user-name';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';
import TimelineItem from 'mdpr2/examples/Timeline/TimelineItem';
import MDButton from 'mdpr2/components/MDButton';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'contexts/app-context';
import { resourceApi } from 'api/resource-api';
import useMounted from 'hooks/use-mounted';
import { useUser } from 'contexts/userContext';

const Notes = (props) => {
  const { data, inputNotes, apiEndpoint, title } = props;

  const isMounted = useMounted();
  const { userFeedbackSuccess } = useAppContext();
  const { userProfile } = useUser();
  const [newNoteContent, setNewNoteContent] = useState('');
  const [notes, setNotes] = useState([]);

  const addNote = (note) => {
    const newNote = {
      description: note.content,
      title: renderToStaticMarkup(
        <>
          {getDateLocaleString(note.createdAt)}
          {note.createdByUser && <>&nbsp;by {getUserName(note.createdByUser)}</>}
        </>
      ),
      dateTime: '',
      color: 'dark',
      icon: 'comment',
      key: `note-${note.id}`,
    };
    setNotes((prevNotes) => [...prevNotes, newNote]);
  };

  const handleAddNoteClick = async () => {
    const response = await resourceApi.saveResource({
      apiEndpoint,
      data: { ...data, content: newNoteContent, createdByUser: `users/${userProfile.id}` },
    });
    userFeedbackSuccess('Note added successfully');
    addNote(response.resource);
    setNewNoteContent('');
  };

  const initialise = useCallback(async () => {
    if (isMounted()) {
      inputNotes.forEach((note) => {
        addNote(note);
      });
    }
  }, [isMounted]);

  useEffect(async () => {
    await initialise();
  }, [initialise]);

  return (
    <Card>
      <CardContent>
        <TimelineList title={title}>
          <Grid container mb={2} spacing={1}>
            <Grid item xs={12}>
              <TextField
                color="white"
                fullWidth
                type="textarea"
                name="newNoteContent"
                multiline
                label="Note"
                onChange={(e) => setNewNoteContent(e.target.value)}
                value={newNoteContent}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <MDButton color="info" size="small" onClick={handleAddNoteClick} variant="text">
                <CommentIcon />
                &nbsp; Add note
              </MDButton>
            </Grid>
          </Grid>
          {notes.map((note) => (
            <TimelineItem key={note.key} {...note} />
          ))}
        </TimelineList>
      </CardContent>
    </Card>
  );
};

Notes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputNotes: PropTypes.array.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Notes;
