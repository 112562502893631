import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { requestApi } from 'api/request-api';

const apiEndpoint = 'webinar-courses';
const resourceName = 'webinar';

const ticketStatusChoices = [
  {
    'label': 'Available',
    'value': 'available',
  },
  {
    'label': 'Sold out',
    'value': 'sold_out',
  },
]

const buildFields = (
  audienceChoices,
  subjectChoices,
  topicChoices,
  deliveryMethodChoices,
) => (
  [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'slug',
      label: 'Slug',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'richtext',
    },
    {
      name: 'imageUri',
      label: 'Image URL',
      type: 'filePicker',
    },
    {
      name: 'startsAt',
      label: 'Starts',
      type: 'datetime',
    },
    {
      name: 'endsAt',
      label: 'Ends',
      type: 'datetime',
    },
    {
      name: 'pricePerDelegate',
      label: 'Price per delegate',
      type: 'currency',
    },
    {
      name: 'pricePerDelegateMinimum',
      label: 'Price per delegate (min)',
      type: 'currency',
    },
    {
      name: 'pricePerDelegateMaximum',
      label: 'Price per delegate (max)',
      type: 'currency',
    },
    {
      name: 'bookingLink',
      label: 'Booking link',
    },
    {
      name: 'ticketStatus',
      label: 'Ticket status',
      type: 'select',
      choices: ticketStatusChoices,
    },
    {
      name: 'isSticky',
      label: 'Sticky within search results?',
      type: 'checkbox',
    },
    {
      name: 'trainingCourseDeliveryMethods',
      label: 'Delivery Methods',
      type: 'selectAsTable',
      choices: deliveryMethodChoices,
    },
    {
      name: 'trainingCourseTopics',
      label: 'Topics',
      type: 'selectAsTable',
      choices: topicChoices,
    },
    {
      name: 'trainingCourseSubjects',
      label: 'Subjects',
      type: 'selectAsTable',
      choices: subjectChoices,
    },
    {
      name: 'trainingCourseAudiences',
      label: 'Audiences',
      type: 'selectAsTable',
      choices: audienceChoices,
    },
  ]
);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

const AddEditWebinarCourse = () => {
  const [initialised, setInitialised] = useState(false);
  const [fields, setFields] = useState(null);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const getChoices = (requestApiResponse) => (
    requestApiResponse['hydra:member'].map((choice) => (
      {
        label: choice.name,
        value: choice['@id'],
      }
    ))
  );

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);

      const [
        audienceChoices,
        subjectChoices,
        topicChoices,
        deliveryMethodChoices,
      ] = await Promise.all([
        requestApi.getResponse({ url: 'web/training-course-audiences' }).then((responseData) => getChoices(responseData)),
        requestApi.getResponse({ url: 'web/training-course-subjects' }).then((responseData) => getChoices(responseData)),
        requestApi.getResponse({ url: 'web/training-course-topics' }).then((responseData) => getChoices(responseData)),
        requestApi.getResponse({ url: 'web/training-course-delivery-methods' }).then((responseData) => getChoices(responseData)),
      ]);

      setFields(buildFields(audienceChoices, subjectChoices, topicChoices, deliveryMethodChoices));

      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(async () => {
    await initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditWebinarCourse;
