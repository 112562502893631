import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Legend, BarChart, Bar, XAxis, YAxis, Tooltip,CartesianGrid, ResponsiveContainer } from 'recharts';

// WRM
import { ordinalSuffixOf } from 'utils/helpers';

class StackedBarAnalyticsChart extends React.Component {

    render() {
        const { chartData, height, chartDataSeries, onClickedBar } = this.props;

        const getAxisTick = (extend = {}) => ({ fontSize: 12, fontWeight: 500, ...extend })

        const yAxisFormatter = (valueInPence) => {
            const value = Math.round(valueInPence / 100);

            if (value >= 1000) {
                return `£${(value / 1000).toFixed(1)}k`.replace('.0', '');
            }

            return `£${value}`;
        }

        const tooltipLabelFormatter = (key) => {
            const day = moment(key);

            return `${ ordinalSuffixOf(day.format('D')) } ${ day.format('MMMM') }`;
        }

        const tooltipValueFormatter = (valueInPence, dataSeriesKey) => {
            const value = Math.round(valueInPence / 100);

            const formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
            });

            return [
                formatter.format(value),
                chartDataSeries.find((s) => s.key === dataSeriesKey).label
            ];
        }

        const legendFormatter = (dataSeriesKey) => chartDataSeries.find((s) => s.key === dataSeriesKey).label

        const chartClick = (e) => {
            onClickedBar(e.activeLabel);
        }

        return (
            <ResponsiveContainer width='100%' height={height}>
                <BarChart data={chartData} onClick={chartClick}>
                    <CartesianGrid stroke='#eeeeee' strokeDasharray='5 5' />
                    <XAxis tick={getAxisTick()} dataKey='createdAt' minTickGap={45} />
                    <YAxis tick={getAxisTick()} tickFormatter={yAxisFormatter} />
                    <Tooltip labelFormatter={tooltipLabelFormatter} formatter={tooltipValueFormatter} />
                    <Legend
                        iconType="circle"
                        iconSize={16}
                        formatter={legendFormatter}
                        height={70}
                        wrapperStyle={{paddingTop: '18px'}}
                    />
                    { chartDataSeries.map((dataSeries) => (
                            <Bar
                                dataKey={dataSeries.key}
                                fill={dataSeries.colour}
                                stackId="a"
                            />
                        )) }
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

StackedBarAnalyticsChart.propTypes = {
    /* eslint-disable */
    chartDataSeries: PropTypes.array.isRequired,
    chartData: PropTypes.object.isRequired,
    onClickedBar: PropTypes.func,
    /* eslint-enable */
    height: PropTypes.number
}

StackedBarAnalyticsChart.defaultProps = {
    height: 450,
    onClickedBar: () => {}
}

export default StackedBarAnalyticsChart;