// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Web components';
const apiEndpoint = 'web-components';
const resourceName = 'web component';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    field: 'slug',
    label: 'Slug',
    sortable: true,
  },
];

const filters = [
  {
    field: '_webComponentSearch',
    label: 'Search',
    type: 'text',
    apiAttribute: 'webComponentSearch',
  },
  {
    field: '_excludeArchived',
    label: 'Exclude archived',
    type: 'checkbox',
    apiAttribute: 'isArchived',
    apiValue: 'false',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const WebComponents = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default WebComponents;
