import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// MaterialUI
import {
  Card,
  CardContent,
  Grid,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { requestApi } from 'api/request-api';
import { useAppContext } from 'contexts/app-context';
import FormField from 'components/shared/FormField';

const RunDeepDiveReport = (props) => {
  const {
    deepDiveReport,
    getApiError,
  } = props;

  const [deepDiveReportRunning, setDeepDiveReportRunning] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();

  const initialValues = {};
  deepDiveReport.deepDiveSegments.forEach((deepDiveSegment) => {
    deepDiveSegment.deepDiveConditions.forEach((deepDiveCondition) => {
      initialValues[deepDiveCondition['@id']] = {};
      initialValues[deepDiveCondition['@id']].comparator = deepDiveCondition.comparator; // comparator is a string
      initialValues[deepDiveCondition['@id']].value = JSON.stringify(deepDiveCondition.value);
      initialValues[deepDiveCondition['@id']].dateRange = JSON.stringify(deepDiveCondition.dateRange);
    });
  });
  deepDiveReport.deepDiveOutputSteps.forEach((deepDiveOutputStep) => {
    initialValues[deepDiveOutputStep['@id']] = {};
    initialValues[deepDiveOutputStep['@id']].properties = JSON.stringify(deepDiveOutputStep.properties);
    initialValues[deepDiveOutputStep['@id']].dateRange = JSON.stringify(deepDiveOutputStep.dateRange);
    initialValues[deepDiveOutputStep['@id']].options = JSON.stringify(deepDiveOutputStep.options);
  });

  const validationShape = {};
  deepDiveReport.deepDiveSegments.forEach((deepDiveSegment) => {
    deepDiveSegment.deepDiveConditions.forEach((deepDiveCondition) => {
      validationShape[deepDiveCondition['@id']] = Yup.object().shape({
        value: Yup.string()
          .test(
            'is-json',
            'Not valid JSON',
            (value) => {
              try {
                JSON.parse(value);
              } catch (e) {
                return false;
              }
              return true;
            }
          ),
        dateRange: Yup.string()
          .test(
            'is-json',
            'Not valid JSON',
            (value) => {
              try {
                JSON.parse(value);
              } catch (e) {
                return false;
              }
              return true;
            }
          ),
      });
    });
  });
  deepDiveReport.deepDiveOutputSteps.forEach((deepDiveOutputStep) => {
    validationShape[deepDiveOutputStep['@id']] = Yup.object().shape({
      properties: Yup.string()
        .test(
          'is-json',
          'Not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          }
        ),
      dateRange: Yup.string()
        .test(
          'is-json',
          'Not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          }
        ),
      options: Yup.string()
        .test(
          'is-json',
          'Not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          }
        ),
    });
  });
  const validationSchema = Yup.object().shape(validationShape);

  // eslint-disable-next-line no-shadow
  const handleRunReportOnClick = async (formValues) => {
    const apiValues = {};
    Object.keys(formValues).forEach((iri) => {
      apiValues[iri] = {};
      Object.keys(formValues[iri]).forEach((attribute) => {
        const valueString = formValues[iri][attribute].trim();
        if (valueString !== '') {
          // The form value may be parseable JSON
          try {
            const valueObject = JSON.parse(valueString);
            apiValues[iri][attribute] = valueObject;
          } catch (e) {
            apiValues[iri][attribute] = valueString;
          }
        }
      });
    });

    setShowLoadingSpinner(true);
    setDeepDiveReportRunning(true);
    const generateEndpoint = `deep-dive-reports/${deepDiveReport.id}/generate`;
    const response = await requestApi.postResponse({
      url: generateEndpoint,
      data: apiValues,
      rawResponse: true,
    });

    // Special case for catching a JSON response when a raw response is requested (returns a Blob)
    const isResponseJsonBlob = response?.data instanceof Blob && response?.data?.type === 'application/json';
    if (isResponseJsonBlob) {
      const responseData = await (response?.data)?.text();
      const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
      if (responseJson?.success === false) {
        setDeepDiveReportRunning(false);
        setShowLoadingSpinner(false);
        alert(responseJson.error);
      }
    }

    const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
    if (navigator.msSaveBlob) return navigator.msSaveBlob(response.data, filename);
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;
    setDeepDiveReportRunning(false);
    setShowLoadingSpinner(false);
    return downloadLink.click();
  }

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Card>
            <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <MDTypography variant="body3">
                    Run Report: <b>{deepDiveReport.name}</b>
                </MDTypography>
                <MDTypography variant="body2">
                    Please note that this will run a snapshot of the report, 
                    fetching you the data within the requested time while not 
                    updating the &quot;last run at&quot; time.
                </MDTypography>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validateOnChange={false}
                  validationSchema={validationSchema}
                >
                  {(formik) => (
                    <Grid
                      container
                      spacing={2}
                    >
                      {/* Render runtime values for the DeepDiveSegments */}
                      {
                        deepDiveReport.deepDiveSegments.map((deepDiveSegment) => (
                          <>
                            <p>TODO: {deepDiveSegment.operation}</p>
                            {
                              deepDiveSegment.deepDiveConditions.map((deepDiveCondition) => (
                                <Grid
                                  item
                                  /* eslint-disable-next-line react/no-array-index-key */
                                  key={`grid-deep-dive-condition-${deepDiveCondition.id}`}
                                  xs={12}
                                >
                                <p>{deepDiveCondition.providerId}</p>
                                  <FormField
                                    type="text"
                                    name={`${deepDiveCondition['@id']}.comparator`}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                  <FormField
                                    type="textarea"
                                    name={`${deepDiveCondition['@id']}.value`}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                  <FormField
                                    type="textarea"
                                    name={`${deepDiveCondition['@id']}.dateRange`}
                                    formik={formik}
                                    getApiError={getApiError}
                                  />
                                </Grid>
                              ))
                            }
                          </>
                        ))
                      }

                      {/* Render runtime values for the DeepDiveOutputSteps */}
                      {
                        deepDiveReport.deepDiveOutputSteps.map((deepDiveOutputStep) => (
                          <Grid
                            item
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`grid-deep-dive-output-step-${deepDiveOutputStep.id}`}
                            xs={12}
                          >
                          <p>{deepDiveOutputStep.providerId}</p>
                          <FormField
                              type="textarea"
                              name={`${deepDiveOutputStep['@id']}.properties`}
                              formik={formik}
                              getApiError={getApiError}
                            />
                            <FormField
                              type="textarea"
                              name={`${deepDiveOutputStep['@id']}.dateRange`}
                              formik={formik}
                              getApiError={getApiError}
                            />
                            <FormField
                              type="textarea"
                              name={`${deepDiveOutputStep['@id']}.options`}
                              formik={formik}
                              getApiError={getApiError}
                            />
                          </Grid>
                        ))
                      }  

                      <Grid
                        item
                        xs={12}
                      >
                        <MDButton
                          color="info"
                          disabled={deepDiveReportRunning}
                          onClick={() => formik.isValid && handleRunReportOnClick(formik.values)}
                          variant="gradient"
                        >
                          Run report
                        </MDButton>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </Grid>
            </Grid>
            </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

RunDeepDiveReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deepDiveReport: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default RunDeepDiveReport;
