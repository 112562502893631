import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

const SaveCancelButtons = ({cancelLocation, formik, isModal, onModalClose}) => {
  const navigate = useNavigate();
  useEffect(() => {}, [formik.isSubmitting])
  const [isModalSubmitting, setIsModalSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (isModal) {
      setIsModalSubmitting(true);
    }
    formik.handleSubmit();
  }

  return (
    <>
      <MDButton
        color="info"
        disabled={formik.isSubmitting || isModalSubmitting}
        type="button"
        variant="gradient"
        onClick={async () => { await handleSubmit(); }}
      >
        {formik.isSubmitting || isModalSubmitting ? (
          <CircularProgress
            style={{ height: '20px', width: '20px', color: 'white' }} />
        ) : (
          <>
            Save
          </>
        )}
      </MDButton>

      <MDButton
        color="secondary"
        disabled={formik.isSubmitting || isModalSubmitting}
        onClick={() => isModal && onModalClose ? onModalClose() : navigate(cancelLocation)}
        sx={{ m: 1, mr: 'auto' }}
        variant="gradient"
      >
        Cancel
      </MDButton>
    </>
  );
};

SaveCancelButtons.propTypes = {
  cancelLocation: PropTypes.string,
  isModal: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  onModalClose: PropTypes.any,
}

SaveCancelButtons.defaultProps = {
  cancelLocation: '/',
  formik: null,
  isModal: false,
  onModalClose: null,
}

export default SaveCancelButtons;