import React, { useState } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {Navigate, useLocation} from 'react-router-dom';

// MUI
import { Card, CircularProgress } from '@mui/material';

// MDPR
import MDAlert from 'mdpr2/components/MDAlert';
import MDBox from 'mdpr2/components/MDBox';
import MDButton from 'mdpr2/components/MDButton';
import MDInput from 'mdpr2/components/MDInput';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import logoImage from 'assets/images/logo-wre-blue.svg';
import { adminSession, refreshToken } from 'constants';
import { useUser } from 'contexts/userContext';

function Login() {
  const { setUserProfile } = useUser();
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem(adminSession) && true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showTotp, setShowTotp] = useState(false);
  const [totp, setTotp] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [totpError, setTotpError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  if (isLoggedIn) {
    const navigateTo = location.state?.redirectAfterLogin ?? `/admin/dashboard`;

    return (
      <Navigate to={{pathname: navigateTo}} push />
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
        email,
        password,
        totp,
      })
      .then((res) => {
        if (res.data.login === true && res.data.totp === false) {
          if (showTotp) {
            setTotpError(true);
          } else {
            setShowTotp(true);
          }
          setIsSubmitting(false);
        }
        const decodedToken = jwtDecode(res.data.token);
        if (decodedToken) {
          const isAdmin = decodedToken.roles.includes('ROLE_ADMIN');
          if (!isAdmin) {
            setLoginError(true);
            setIsSubmitting(false);
          } else {
            setUserProfile(decodedToken);
            localStorage.setItem(adminSession, res.data.token);
            localStorage.setItem(refreshToken, res.data.refresh_token);
            setLoggedIn(true);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setLoginError(true);
          setIsSubmitting(false);
        }
      });
  };

  return (
    <Card>
      <MDBox
        variant="contained"
        bgColor="transparent"
        borderRadius="lg"
        coloredShadow="none"
        textAlign="center"
        pt={3}
        pl={3}
        pr={3}
      >
        <img src={logoImage} width="100%" height="auto" style={{maxWidth: 200,pointerEvents: 'none'}} alt="White Rose Education" />
      </MDBox>
      <MDBox pt={3} pb={3} px={3}>
        {
          loginError &&
          <MDAlert color="error">
            <MDTypography variant="body2" color="white">
              Invalid username or password
            </MDTypography>
          </MDAlert>
        }
        {
          totpError &&
          <MDAlert color="error">
            <MDTypography variant="body2" color="white">
              Invalid MFA code
            </MDTypography>
          </MDAlert>
        }
        <MDBox component="form" onSubmit={handleSubmit} role="form">
          <MDBox mb={2}>
            <MDInput type="email" label="Email address" fullWidth onChange={(e) => setEmail(e.target.value)} />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="password" label="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
          </MDBox>
         {
            showTotp &&
            <MDBox mb={2}>
              <MDInput type="text" label="MFA code" fullWidth onChange={(e) => setTotp(e.target.value)} />
            </MDBox>
          }
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress color='white' size='1.3rem' /> : <>log in</>}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Login;
