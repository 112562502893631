/* eslint-disable */
import PropTypes from "prop-types";
import {Autocomplete, FormControl, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

/**
 * typeahead filter
 * expects a filter with the following properties:
 *     type: 'typeahead'
 *     field: 'myFieldName'
 *     label: 'Field Label'
 *     search: (searchString, setOptions) => { // do search...; // setOptions([{id: 1, label: 'thing 1'}, ....]); }
 *     apiAttribute: 'apiFieldName'
 *
 * please consider debouncing search function where requests to the server are made
 */

const TypeaheadFilter = ({filter, filterValueOnChange, dirtyFilterValue}) => {

    const [options, setOptions] = useState([]);
    const [textFieldValue, setTextFieldValue] = useState('');
    const [optionValue, setOptionValue] = useState(null);

    const clearOptions = () => {
        setOptions([]);
    };

    useEffect(() => {
        // has been reset/cleared?
        if(dirtyFilterValue === null) {
            setTextFieldValue('');
            setOptionValue(null);
            clearOptions();
        }
    }, [dirtyFilterValue]);

    return (
        <FormControl sx={{ m: 1, width: 240 }}>
            <Autocomplete
                filterOptions={(x) => x}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={filter.label}
                        onChange={(e) => {
                            const {value} = e.target;
                            setTextFieldValue(value);
                            filter.search(value, setOptions);
                        }}
                        value={textFieldValue}
                    />
                }
                options={options}
                onChange={(event, newValue, ) => {
                    setOptionValue(newValue);

                    if(newValue === null) { // ie - when the clear button is clicked...
                        clearOptions();
                    }

                    filterValueOnChange(filter.field, newValue?.id);
                }}
                value={optionValue}
            />
        </FormControl>
    );
};

TypeaheadFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    filterValueOnChange: PropTypes.func.isRequired,
    dirtyFilterValue: PropTypes.any
};

TypeaheadFilter.defaultProps = {
    dirtyFilterValue: null
};

export default TypeaheadFilter;