// MUI
import { Tooltip } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Product categories';
const apiEndpoint = 'product-categories';
const resourceName = 'product category';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'slug',
    label: 'Slug',
    sortable: true,
    flex: 1,
  },
  {
    field: 'parentProductCategoryName',
    label: 'Parent category',
    flex: 1,
    formatter: (resource) => resource.parentProductCategory?.name,
  },
  {
    field: '_options',
    label: ' ',
    flex: 0.2,
    formatter: (resource) => {
      const {containsPhysicalProducts} = resource;
      const containsPhysicalProductsIcon = containsPhysicalProducts ? <Tooltip title="Contains physical products"><LocalShippingIcon color="disabled"/></Tooltip> : '';
      return (
        <div>
          {containsPhysicalProductsIcon}
        </div>
      );
    },
  }
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'slug',
    label: 'Slug',
    type: 'text',
  },
  {
    field: '_topLevelOnly',
    label: 'Top level only',
    type: 'checkbox',
    apiAttribute: 'exists[parentProductCategory]',
    apiValue: 'false',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const ProductCategories = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default ProductCategories;
