import { useEffect, useCallback, useState } from 'react';
import { getIn } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

// WRM
import { resourceApi } from 'api/resource-api';
import useMounted from 'hooks/use-mounted';
import LoadingSpinner from 'components/LoadingSpinner';
import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues, toFormValues } from 'components/shared/resource/api-form-mapper';
import AddEditDeepDiveReportForm from './AddEditDeepDiveReportForm';

const apiEndpoint = 'deep-dive-reports';
const resourceName = 'Deep Dive report';

const typeChoices = [
  { value: 'LearningGroup', label: 'Learning groups'},
  { value: 'Order', label: 'Orders'},
  { value: 'User', label: 'Users'},
];

Yup.addMethod(Yup.array, 'emailRecipientsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`validate-email-recipients-helper`, errorMessage, function (emailRecipientsHelperValues) {
    const { createError } = this;

    const errors = [];
    emailRecipientsHelperValues?.forEach((emailRecipientsHelperValue, index) => {
      const { emailRecipient } = emailRecipientsHelperValue;
      if (!emailRecipient) {
        errors.push(new ValidationError(
          `Email recipient is required`,
          '',
          `emailRecipientsHelper[${index}].emailRecipient`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  emailRecipients: Yup.string()
    .required('Email recipients is required')
    .test(
      'is-json',
      'Email recipients is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  emailRecipientsHelper: Yup.array()
    .emailRecipientsHelper('Invalid email recipients'),
  deepDiveSegments: Yup.array().of(
    Yup.object().shape({
      operation: Yup.string()
        .required('Operation is required'),
      deepDiveConditions: Yup.array().of(
        Yup.object().shape({
          providerId: Yup.string()
            .required('Provider is required'),
          value: Yup.string()
            .test(
              'is-json',
              'The value is not valid JSON',
              (value) => {
                try {
                  JSON.parse(value);
                } catch (e) {
                  return false;
                }
                return true;
              },
            ),
          dateRange: Yup.string()
            .test(
              'is-json',
              'The value is not valid JSON',
              (value) => {
                try {
                  JSON.parse(value);
                } catch (e) {
                  return false;
                }
                return true;
              },
            ),
        }),
      ), 
    }),
  ),
  deepDiveOutputSteps: Yup.array().of(
    Yup.object().shape({
      providerId: Yup.string()
        .required('Provider is required'),
      options: Yup.string()
        .test(
          'is-json',
          'The value is not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          },
        ),
      properties: Yup.string()
        .test(
          'is-json',
          'The value is not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          },
        ),
      dateRange: Yup.string()
        .test(
          'is-json',
          'The value is not valid JSON',
          (value) => {
            try {
              JSON.parse(value);
            } catch (e) {
              return false;
            }
            return true;
          },
        ),
    }),
  ),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // Map emailRecipients to emailRecipientsHelper
  formValues.emailRecipientsHelper = [];
  resource.emailRecipients?.forEach((emailRecipient, index) => {
    const emailRecipientWithUuid = {};
    emailRecipientWithUuid.emailRecipient = emailRecipient;
    emailRecipientWithUuid.uuid = index;
    formValues.emailRecipientsHelper.push(emailRecipientWithUuid);
  });

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // Map emailRecipientsHelper to emailRecipients
  apiValues.emailRecipients = [];
  formValues.emailRecipientsHelper.forEach((emailRecipientHelper) => {
    const emailRecipient = emailRecipientHelper.emailRecipient ?? null;
    apiValues.emailRecipients.push(emailRecipient);
  });

  return apiValues;
}

const AddEditDeepDiveReport = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const [initialised, setInitialised] = useState(false);
  const [deepDiveConditionProviders, setDeepDiveConditionProviders] = useState();
  const [deepDiveOutputProviders, setDeepDiveOutputProviders] = useState();

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (!isMounted()) {
      return;
    }

    const deepDiveConditionProvidersResponse = await resourceApi.getResources({
      apiEndpoint: 'deep-dive-condition-providers',
    });
    if (deepDiveConditionProvidersResponse.resources.length > 0) {
      setDeepDiveConditionProviders(deepDiveConditionProvidersResponse.resources);
    }

    const deepDiveOutputProvidersResponse = await resourceApi.getResources({
      apiEndpoint: 'deep-dive-output-providers',
    });
    if (deepDiveOutputProvidersResponse.resources.length > 0) {
      setDeepDiveOutputProviders(deepDiveOutputProvidersResponse.resources);
    }

    setInitialised(true);
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return <LoadingSpinner/>;

  const deepDiveSegmentGetHeading = ((values) => {
    if (values.operation) {
      return values.operation;
    }
    return 'New segment';
  });

  const deepDiveConditionGetHeading = ((values) => {
    const deepDiveConditionProvider = deepDiveConditionProviders.find((ddcp) => ddcp.id === values.providerId);
    if (deepDiveConditionProvider) {
      return deepDiveConditionProvider.name;
    }
    if (values.providerId) {
      return values.providerId;
    }
    return 'New condition';
  });

  const deepDiveConditionProviderIdGetType = ((values, field) => {
    const providerIdField = field;
    const providerId = getIn(values, providerIdField);
    const deepDiveConditionProvider = deepDiveConditionProviders.find((ddcp) => ddcp.id === providerId);
    if (deepDiveConditionProvider) {
      return 'static';
    }
    return 'select';
  });

  const deepDiveConditionProviderIdGetChoices = ((values) => {
    const deepDiveReportType = values.type;
    const choices = [];
    deepDiveConditionProviders.forEach((deepDiveConditionProvider) => {
      if (deepDiveConditionProvider.id.startsWith(`${deepDiveReportType}.`)) {
        choices.push({
          value: deepDiveConditionProvider.id,
          label: deepDiveConditionProvider.name,
        });
      }
    });
    return choices;
  });

  const deepDiveConditionComparatorGetType = ((values, field) => {
    const providerIdField = field.replace('.comparator', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveConditionProvider = deepDiveConditionProviders.find((ddcp) => ddcp.id === providerId);
    if (deepDiveConditionProvider && deepDiveConditionProvider.validComparators.length > 0) {
      return 'select';
    }
    return 'hidden';
  });

  const deepDiveConditionComparatorGetChoices = (values, field) => {
    const choices = [];
    const providerIdField = field.replace('.comparator', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveConditionProvider = deepDiveConditionProviders.find((ddcp) => ddcp.id === providerId);
    if (deepDiveConditionProvider) {
      deepDiveConditionProvider.validComparators.forEach((validComparator) => {
        choices.push({
          value: validComparator,
          label: validComparator,
        });
      });
    }
    return choices;
  };

  const deepDiveConditionDateRangeGetType = ((values, field) => {
    const providerIdField = field.replace('.dateRange', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveConditionProvider = deepDiveConditionProviders.find((ddcp) => ddcp.id === providerId);
    if (deepDiveConditionProvider && deepDiveConditionProvider.supportsDateRange) {
      return 'textarea';
    }
    return 'hidden';
  });

  const deepDiveOutputStepGetHeading = ((values) => {
    const deepDiveOutputProvider = deepDiveOutputProviders.find((ddop) => ddop.id === values.providerId);
    if (deepDiveOutputProvider) {
      return deepDiveOutputProvider.name;
    }
    if (values.providerId) {
      return values.providerId;
    }
    return 'New output step';
  });

  const deepDiveOutputProviderIdGetType = ((values, field) => {
    const providerIdField = field;
    const providerId = getIn(values, providerIdField);
    const deepDiveOutputProvider = deepDiveOutputProviders.find((ddcp) => ddcp.id === providerId);
    if (deepDiveOutputProvider) {
      return 'static';
    }
    return 'select';
  });

  const deepDiveOutputProviderIdGetChoices = ((values) => {
    const deepDiveReportType = values.type;
    const choices = [];
    deepDiveOutputProviders.forEach((deepDiveOutputProvider) => {
      if (deepDiveOutputProvider.id.startsWith(`${deepDiveReportType}.`)) {
        choices.push({
          value: deepDiveOutputProvider.id,
          label: deepDiveOutputProvider.name,
        });
      }
    });
    return choices;
  });

  const deepDiveOutputStepPropertiesGetType = ((values, field) => {
    const providerIdField = field.replace('.properties', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveOutputProvider = deepDiveOutputProviders.find((ddop) => ddop.id === providerId);
    if (deepDiveOutputProvider && Object.keys(deepDiveOutputProvider.validProperties)?.length > 0) {
      return 'textarea';
    }
    return 'hidden';
  });

  const deepDiveOutputStepDateRangeGetType = ((values, field) => {
    const providerIdField = field.replace('.dateRange', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveOutputProvider = deepDiveOutputProviders.find((ddop) => ddop.id === providerId);
    if (deepDiveOutputProvider && deepDiveOutputProvider.supportsDateRange) {
      return 'textarea';
    }
    return 'hidden';
  });

  const deepDiveOutputStepOptionsGetType = ((values, field) => {
    const providerIdField = field.replace('.options', '.providerId');
    const providerId = getIn(values, providerIdField);
    const deepDiveOutputProvider = deepDiveOutputProviders.find((ddop) => ddop.id === providerId);
    if (deepDiveOutputProvider && Object.keys(deepDiveOutputProvider.validOptions)?.length > 0) {
      return 'textarea';
    }
    return 'hidden';
  });

  const fields = [
    {
      name: 'type',
      label: 'Report type',
      type: 'select',
      choices: typeChoices,
    },
    {
      name: 'name',
      label: 'Report name',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
    },
    {
      name: 'filenameFormat',
      label: 'Filename for generated report',
    },
    {
      name: 'isActive',
      label: 'Run on a scheduled basis?',
      type: 'checkbox',
    },
    {
      name: 'schedule',
      label: 'Schedule',
    },
    {
      name: 'emailRecipients',
      label: 'Email recipients',
      type: 'textarea',
      json: true,
      defaultValue: [],
    },
    {
      name: 'emailSubject',
      label: 'Email subject',
    },
    {
      name: 'deepDiveSegments',
      label: 'Start with an empty list and...',
      type: 'fieldCollection',
      getHeading: deepDiveSegmentGetHeading,
      draggable: true,
      childFields: [
        {
          name: 'operation',
          label: 'Operation',
          type: 'select',
          choices: [
            { value: 'add', label: 'Add'},
            { value: 'remove', label: 'Remove'},
            { value: 'intersect', label: 'Intersect'},
          ],
        },
        {
          name: 'deepDiveConditions',
          label: 'Build the conditions...',
          type: 'fieldCollection',
          getHeading: deepDiveConditionGetHeading,
          draggable: true,
          childFields: [
            {
              name: 'providerId',
              label: 'Condition provider',
              getType: deepDiveConditionProviderIdGetType,
              getChoices: deepDiveConditionProviderIdGetChoices,
            },
            {
              name: 'comparator',
              label: 'Comparator',
              getType: deepDiveConditionComparatorGetType,
              getChoices: deepDiveConditionComparatorGetChoices,
            },
            {
              name: 'value',
              label: 'Value',
              type: 'textarea',
              json: true,
            },
            {
              name: 'dateRange',
              label: 'Date range',
              getType: deepDiveConditionDateRangeGetType,
              json: true,
              defaultValue: '[]',
            },
          ],
        },
      ],
    },
    {
      name: 'deepDiveOutputSteps',
      label: 'Output steps',
      type: 'fieldCollection',
      getHeading: deepDiveOutputStepGetHeading,
      draggable: true,
      childFields: [
        {
          name: 'providerId',
          label: 'Output provider',
          getType: deepDiveOutputProviderIdGetType,
          getChoices: deepDiveOutputProviderIdGetChoices,
        },
        {
          name: 'properties',
          label: 'Properties',
          getType: deepDiveOutputStepPropertiesGetType,
          json: true,
          defaultValue: '[]',
        },
        {
          name: 'dateRange',
          label: 'Date range',
          getType: deepDiveOutputStepDateRangeGetType,
          json: true,
          defaultValue: '[]',
        },
        {
          name: 'options',
          label: 'Options',
          getType: deepDiveOutputStepOptionsGetType,
          json: true,
          defaultValue: '[]',
        },
      ],
    },
  ];

  return (
    <AddEditResource
      addEditForm={AddEditDeepDiveReportForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditDeepDiveReport;
