// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Custom Groups';
const apiEndpoint = 'custom-learning-groups';
const resourceName = 'Custom Group';

const columns = [
    {
        field: 'name',
        label: 'Group Name',
        flex: 2,
        formatter: (resource) => resource.name,
    },
    {
        field: '_userCount',
        label: '# Active Users',
        flex: 1,
        formatter: (resource) => `${resource.learningGroupAccesses.filter(access => access.isActive).length}`,
    },
    {
        field: '_activeOwnedLicenceCount',
        label: '# Owned Licences',
        flex: 1,
        formatter: (resource) => `${resource.ownedLicences.length}`,
    },

];

const defaultFilterValues = {};

const fixedFilterValues = {};

const CustomLearningGroups = () => {
    const filters = [
        {
            field: 'name',
            label: 'Group Name',
            type: 'text',
        }
    ];

    return (
        <ListResources
            apiEndpoint={apiEndpoint}
            columns={columns}
            defaultFilterValues={defaultFilterValues}
            filters={filters}
            fixedFilterValues={fixedFilterValues}
            resourceName={resourceName}
            title={title}
        />
    );
};

export default CustomLearningGroups;
