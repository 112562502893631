// MUI
import { Card, CardContent, Grid, Icon } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';

// WRM
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';
import CardHeader from 'components/shared/CardHeader';
import FormField from "components/shared/FormField";
import convertPriceToPounds from 'utils/convert-price-to-pounds';

import moment from 'moment';
import MDButton from 'mdpr2/components/MDButton';
import * as Yup from 'yup';
import { Formik } from "formik";
import { useState } from 'react';
import { requestApi } from 'api/request-api';
import LoadingSpinner from "../../../components/LoadingSpinner";


const OrderRefunds = () => {

    const apiEndpoint = 'order-refunds';
    const resourceName = 'order-refunds';

    const reasonCategoryChoices = [
        { value: 'unhappy-with-product', label: 'Unhappy with product' },
        { value: 'infant-not-required-with-primary-membership', label: 'Infant not required with primary membership' },
        { value: 'selected-incorrect-package', label: 'Selected incorrect package' },
        { value: 'wrong-quantity-ordered', label: 'Wrong quantity ordered' },
        { value: 'delivery-issues', label: 'Delivery issues' },
        { value: 'other', label: 'Other' },
    ];

    const previewBtn = (resource) => {
        const { id } = resource.order;
        return (
            <>
                <MDButton
                    color="dark"
                    onClick={() => window.open(`/admin/orders/edit/${id}`, '_blank')}
                    variant="outlined"
                    iconOnly
                >
                    <OpenInNew />
                </MDButton>
                &nbsp;
            </>)
    }


    const columns = [
        {
            field: 'order.reference',
            label: 'Order #',
            sortable: true,
            flex: 1,
            formatter: (resource) => resource.order.reference
        },
        {
            field: 'preview',
            label: ' ',
            flex: 1,
            formatter: (resource) => (previewBtn(resource))
        },
        {
            field: 'reasonCategory',
            label: 'Reason Category',
            flex: 2,
            formatter: resource => {
                const category = reasonCategoryChoices.find(choice => choice.value === resource.reasonCategory);
                return category ? category.label : resource.reasonCategory;
            }
        },
        {
            field: 'reason',
            label: 'Reason',
            flex: 3,
        },
        {
            field: 'amount',
            label: 'Amount',
            formatter: (resource) => convertPriceToPounds(resource.amount),
            flex: 1,
        },
        {
            field: 'createdAt',
            label: 'Created At',
            flex: 2,
            formatter: (resource) => getDateLocaleString(resource.createdAt),
            sortable: true,
        },
    ];

    const filters = [
        {
            field: 'order.reference',
            label: 'Order #',
            type: 'text',
        },
    ];

    const defaultSortValues = [
        {
            field: 'order.reference',
            direction: 'desc',
        },

    ];

    const fixedFilterValues = {
        singleUse: false,
    };

    const [reportGenerating, setReportGenerating] = useState(false);

    const handleRunReportOnClick = (formValues) => {
        const startDate = formValues.startDate ? formValues.startDate : '';
        const endDate = formValues.endDate ? formValues.endDate : '';

        const downloadEndpoint = `download-order-refund-report?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`;

        setReportGenerating(true);

        requestApi.getResponse({
            url: downloadEndpoint,
            rawResponse: true
        }).then((response) => {
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setReportGenerating(false);
        });
    }

    return (
        <>
            <Grid
                container
                mt={-2}
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <CardHeader color="info">
                            <Icon>view_list</Icon> Run Order Refund Report
                        </CardHeader>
                        <CardContent>
                            <Formik
                                initialValues={{
                                    startDate: null,
                                    endDate: moment().startOf('day'),
                                }}
                                validationSchema={Yup.object().shape({
                                    startDate: Yup.date()
                                        .nullable(),
                                    endDate: Yup.date()
                                        .nullable()
                                        .min(Yup.ref('startDate'), 'End date must be later than start date'),
                                })}
                            >
                                {(formik) => (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <FormField
                                                type="date"
                                                name="startDate"
                                                label="Start date"
                                                formik={formik}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <FormField
                                                type="date"
                                                name="endDate"
                                                label="End date"
                                                formik={formik}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={2}>
                                            <MDButton
                                                color="primary"
                                                disabled={reportGenerating || (Object.keys(formik.errors).length > 0)}
                                                onClick={() => handleRunReportOnClick(formik.values)}>
                                                Run report
                                            </MDButton>
                                        </Grid>
                                    </Grid>

                                )}
                            </Formik>
                            {reportGenerating && <LoadingSpinner />}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ListResources
                apiEndpoint={apiEndpoint}
                columns={columns}
                defaultSortValues={defaultSortValues}
                filters={filters}
                fixedFilterValues={fixedFilterValues}
                resourceName={resourceName}
                title=''
                disableEditResource
                disableAddResource
                disableOpenInNewTab
            />
        </>
    )
};

export default OrderRefunds;
