import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';

const apiEndpoint = 'video-courses';
const resourceName = 'video course';

const subjectChoices = [
  {
    value: 'maths',
    label: 'Maths',
  },
  {
    value: 'science',
    label: 'Science',
  }
];

const audienceChoices = [
  {
    label: 'School',
    value: 'school',
  },
  {
    label: 'Teacher',
    value: 'teacher',
  },
];

const yearChoices = [
  {
    value: 'early-years',
    label: 'Early Years',
  },
  {
    value: 'primary',
    label: 'Primary (Y1-6)',
  },
  {
    value: 'secondary',
    label: 'Secondary (Y7-11)',
  },
  {
    value: 'year-1',
    label: 'Year 1',
  },
  {
    value: 'year-2',
    label: 'Year 2',
  },
  {
    value: 'year-3',
    label: 'Year 3',
  },
  {
    value: 'year-4',
    label: 'Year 4',
  },
  {
    value: 'year-5',
    label: 'Year 5',
  },
  {
    value: 'year-6',
    label: 'Year 6',
  },
  {
    value: 'year-7',
    label: 'Year 7',
  },
  {
    value: 'year-8',
    label: 'Year 8',
  },
  {
    value: 'year-9',
    label: 'Year 9',
  },
  {
    value: 'year-10',
    label: 'Year 10',
  },
  {
    value: 'year-11',
    label: 'Year 11',
  },
];

const videoCourseSectionGetHeading = (values) => values.name;
const videoCourseSectionMediaGetHeading = (values) => values.name || values.externalUri;

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'slug',
    label: 'Slug',
  },
  {
    name: 'subjects',
    label: 'Subjects',
    type: 'selectAsTable',
    choices: subjectChoices
  },
  {
    name: 'description',
    label: 'Description',
    type: 'richtext',
  },
  {
    name: 'imageUri',
    label: 'Image URL',
    type: 'filePicker',
  },
  {
    name: 'introduction',
    label: 'Introduction',
  },
  {
    name: 'priceFrom',
    label: 'Price from',
    type: 'currency',
  },
  {
    name: 'videoCourseSections',
    label: 'Lessons',
    type: 'fieldCollection',
    getHeading: videoCourseSectionGetHeading,
    childFields: [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'childVideoCourseSections',
        label: 'Topics',
        type: 'fieldCollection',
        getHeading: videoCourseSectionGetHeading,
        childFields: [
          {
            name: 'name',
            label: 'Name',
          },
          {
            name: 'description',
            label: 'Description',
          },
          {
            name: 'videoCourseSectionMedias',
            label: 'Media',
            type: 'fieldCollection',
            draggable: true,
            getHeading: videoCourseSectionMediaGetHeading,
            childFields: [
              {
                name: 'name',
                label: 'Name',
              },
              {
                name: 'type',
                label: 'Type (video or download)',
              },
              {
                name: 'description',
                label: 'Description',
              },
              {
                name: 'externalUri',
                label: 'URL',
              },
            ],
          },
        ],
      },
      {
        name: 'videoCourseSectionMedias',
        label: 'Media',
        type: 'fieldCollection',
        getHeading: videoCourseSectionMediaGetHeading,
        childFields: [
          {
            name: 'name',
            label: 'Name',
          },
          {
            name: 'type',
            label: 'Type (video or download)',
          },
          {
            name: 'description',
            label: 'Description',
          },
          {
            name: 'externalUri',
            label: 'URL',
          },
        ],
      },
    ],
  },
  {
    name: 'audiences',
    label: 'Audience',
    type: 'selectAsTable',
    choices: audienceChoices,
  },
  {
    name: 'years',
    label: 'Years',
    type: 'selectAsTable',
    choices: yearChoices,
  },
  {
    name: 'isSticky',
    label: 'Sticky within search results?',
    type: 'checkbox',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
  videoCourseSections: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Name is required'),
      childVideoCourseSections: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .required('Name is required'),
          videoCourseSectionMedias: Yup.array().of(
            Yup.object().shape({
              type: Yup.string()
                .required('Media type is required'),
              externalUri: Yup.string()
                .required('URL is required'),
            }),
          ),
        }),
      ),
      videoCourseSectionMedias: Yup.array().of(
        Yup.object().shape({
          type: Yup.string()
            .required('Media type is required'),
          externalUri: Yup.string()
            .required('URL is required'),
        }),
      ),
    }),
  ),
});

const AddEditVideoCourse = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditVideoCourse;
