// WRM
import ListResources from 'components/shared/ListResources';
import getDateUtcString from 'utils/get-date-utc-string';

const title = 'Teams';
const apiEndpoint = 'team-memberships';
const resourceName = 'team';

const columns = [
  {
    field: 'name',
    label: 'Team name',
    sortable: true,
    flex: 2,
  },
  {
    field: 'membershipPlanName',
    label: 'Membership plan',
    flex: 3,
    formatter: (resource) => resource.membershipPlan?.name,
  },
  {
    field: 'startsAt',
    label: 'Starts',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateUtcString(resource.startsAt, 'date'),
  },
  {
    field: 'expiresAt',
    label: 'Expires',
    sortable: true,
    flex: 1,
    formatter: (resource) => getDateUtcString(resource.expiresAt, 'date'),
  },
  {
    field: '_membershipCount',
    label: 'Members',
    flex: 1,
    formatter: (resource) => `${resource.memberships.length} of ${resource.maxMembers}`,
  },
  {
    field: '_managerNames',
    label: 'Managers',
    sortable: false,
    flex: 2,
    formatter: (resource) => {
      const teamManagerNames = [];
      resource.teamManagers?.forEach(teamManager => teamManagerNames.push(teamManager.name));
      return teamManagerNames.join(', ');
    },
  },
];

const filters = [
  {
    field: 'name',
    label: 'Team name',
    type: 'text',
  },
  {
    field: '_membershipSearch',
    label: 'Manager/postcode',
    type: 'text',
    apiAttribute: 'membershipSearch',
  },
];

const defaultFilterValues = {};

const defaultSortValues = [
  {
    field: 'startsAt',
    direction: 'desc',
  },
];

const fixedFilterValues = {};

const TeamMemberships = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultFilterValues={defaultFilterValues}
    defaultSortValues={defaultSortValues}
    filters={filters}
    fixedFilterValues={fixedFilterValues}
    resourceName={resourceName}
    title={title}
  />
);

export default TeamMemberships;
