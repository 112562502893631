import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import AddEditUserForm from './AddEditUserForm';

const apiEndpoint = 'users';
const resourceName = 'user';

// https://whiterosemaths.atlassian.net/browse/WFS-730
const rolesChoices = [
  {
    value: 'ROLE_WAREHOUSE',
    label: 'Warehouse',
  },
  {
    value: 'ROLE_TEAM_MEMBER',
    label: 'Team Member',
  },
  {
    value: 'ROLE_CONTENT_EDITOR',
    label: 'Content Editor',
  },
  {
    value: 'ROLE_ECOMMERCE_SUPPORT',
    label: 'E-commerce Support',
  },
  {
    value: 'ROLE_ECOMMERCE_SENIOR_SUPPORT',
    label: 'E-commerce Senior Support',
  },
  {
    value: 'ROLE_MARKETING',
    label: 'Marketing',
  },
  {
    value: 'ROLE_SLT',
    label: 'SLT',
  },
  {
    value: 'ROLE_INFINITY_QUIZ_ADMIN',
    label: 'Infinity Admin',
  },
  {
    value: 'ROLE_SUPER_ADMIN',
    label: 'Administrator',
  },
  {
    value: 'ROLE_DEVELOPER',
    label: 'Developer',
  },
];

const fields = [
  {
    name: 'firstName',
    label: 'First name',
  },
  {
    name: 'lastName',
    label: 'Last name',
  },
  {
    name: 'email',
    label: 'Email address',
  },
  {
    name: 'password',
    label: 'Change password',
  },
  {
    name: 'roles',
    label: 'Roles',
    type: 'select',
    choices: rolesChoices,
    defaultValue: ['ROLE_USER'],
  },
  {
    name: 'isEnabled',
    label: 'Allow user to log in',
    type: 'checkbox',
    defaultValue: true,
  },
  {
    name: 'sendWelcomeEmail',
    label: 'Send welcome email',
    type: 'checkbox',
    defaultValue: true,
  },
  {
    name: 'schools',
    label: 'Schools',
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  email: Yup.string()
    .required('Email address is required')
});

const AddEditUser = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={AddEditUserForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditUser;
