import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import useMounted from 'hooks/use-mounted';
import axios from 'axios';
import AddEditProductBundlePartForm from './AddEditProductBundlePartForm';
import { getRequestQuery } from '../../../api/apiRequest';
import { defaultOrderShipments } from '../../../constants';

const apiEndpoint = 'product-bundle-parts';
const resourceName = 'product in bundle';


const fields = [
  {
    name: 'partProductOrPartProductVariant',
    label: 'Product',
    type: 'hidden',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'number',
  },
  {
    name: 'shipment',
    label: 'Shipment (deprecated)',
    type: 'text',
  },
  {
    name: 'orderShipment',
    label: 'Order Shipment',
    type: 'select',
    choices: defaultOrderShipments,
    defaultValue: 1,
  },
];

const validationSchema = Yup.object().shape({
  partProductOrPartProductVariant: Yup.string()
    .required('Product is required'),
  quantity: Yup.number()
    .required('Quantity is required'),
  orderShipment: Yup.string()
    .required('Shipment is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  if (resource.partProduct) {
    formValues.partProductOrPartProductVariant = resource.partProduct['@id'];
  }
  if (resource.partProductVariant) {
    formValues.partProductOrPartProductVariant = resource.partProductVariant['@id'];
  }

  return formValues;
};

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  if (formValues.partProductOrPartProductVariant.startsWith('/products/')) {
    apiValues.partProduct = formValues.partProductOrPartProductVariant;
    apiValues.partProductVariant = null;
  }
  if (formValues.partProductOrPartProductVariant.startsWith('/product-variants/')) {
    apiValues.partProduct = null;
    apiValues.partProductVariant = formValues.partProductOrPartProductVariant;
  }
  delete apiValues.partProductOrPartProductVariant;

  if (apiValues.shipment === '') {
    apiValues.shipment = null;
  }

  return apiValues;
};

const AddEditProductBundlePart = props => {
  const {
    productBundlePart: productBundlePartToEdit,
    product,
    productVariant,
    onClose,
  } = props;

  const [initialised, setInitialised] = useState(false);

  const isMounted = useMounted();
  const id = productBundlePartToEdit ? Number(productBundlePartToEdit.id) : null;

  const initialise = useCallback(async () => {
    if (!isMounted()) {
      return;
    }

    const apiOrderShipmentChoices = await axios(getRequestQuery('/order-shipments')).then(
      (response) => ([response.data]),
    ).catch((error) => {
      console.error('Error occurred', error);
      return defaultOrderShipments;
    });

    const shipmentField = fields.find((el) => el.name === 'orderShipment');
    if (shipmentField) {
      shipmentField.choices = apiOrderShipmentChoices;
    }

    if (!productBundlePartToEdit && product) {
      fields.push(
        {
          name: 'product',
          label: 'Product',
          type: 'hidden',
          defaultValue: product['@id'],
        },
        {
          name: 'displayOrder',
          type: 'hidden',
          defaultValue: 999999,
        },
      );
    }
    if (!productBundlePartToEdit && productVariant) {
      fields.push(
        {
          name: 'productVariant',
          label: 'Product variant',
          type: 'hidden',
          defaultValue: productVariant['@id'],
        },
        {
          name: 'displayOrder',
          type: 'hidden',
          defaultValue: 999999,
        },
      );
    }
    setInitialised(true);
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditProductBundlePartForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      isModal
      onModalClose={onClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditProductBundlePart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productBundlePart: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  productVariant: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

AddEditProductBundlePart.defaultProps = {
  productBundlePart: null,
  product: null,
  productVariant: null,
};

export default AddEditProductBundlePart;
