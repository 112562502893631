/* eslint-disable */
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

// MUI
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { requestApi } from 'api/request-api';
import CardHeader from 'components/shared/CardHeader';
import FormField from 'components/shared/FormField';
import { useAppContext } from 'contexts/app-context';
import getPriceLocaleString from 'utils/get-price-locale-string';
import { getShallowArrayDiff, isEmptyObject } from 'utils/helpers';
import StatCard from "components/shared/StatCard/StatCard";
import componentStyles from './Analytics.module.scss';
import LoadingSpinner from "components/LoadingSpinner";
import MembershipRevenueWidget from 'components/shared/MembershipWidget/MembershipRevenueWidget';
import InfinityAnalytics from './InfinityAnalytics';

const productsColumns = [
  {
    field: 'productName',
    headerName: 'Product',
    flex: 2,
  },
  {
    field: 'sku',
    headerName: 'SKU',
    flex: 1,
  },
  {
    field: 'orderUnitCount',
    headerName: 'Items sold',
    flex: 1,
    valueFormatter: ({value}) => value.toLocaleString(),
  },
  {
    field: 'salesTotal',
    headerName: 'Sales (£)',
    flex: 1,
    valueFormatter: ({value}) => getPriceLocaleString(value),
  },
  {
    field: 'orderCount',
    headerName: 'Orders',
    flex: 1,
    valueFormatter: ({value}) => value.toLocaleString(),
  },
];

function ExportContainer() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const Analytics = () => {
  const [startDate, setStartDate] = useState(moment().utc());
  const [endDate, setEndDate] = useState(moment().utc());
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [productVariantAnalyticsLoading, setProductVariantAnalyticsLoading] = useState(false);
  const [analytics, setAnalytics] = useState(null);
  const [productAnalytics, setProductAnalytics] = useState(null);
  const [productVariantAnalytics, setProductVariantAnalytics] = useState(null);
  const [membershipRevenueAnalytics, setMembershipRevenueAnalytics] = useState(null);
  const [productsPageSize, setProductsPageSize] = useState(10);
  const [productVariantsPageSize, setProductVariantsPageSize] = useState(10);

  // current data set start/end dates
  const [dataStartDate, setDataStartDate] = useState(startDate);
  const [dataEndDate, setDataEndDate] = useState(endDate);

  // Preset dates
  const [presetDatesMenuAnchorEl, setPresetDatesMenuAnchorEl] = useState(null);

  // Modal
  const [productVariantAnalyticsModalOpen, setProductVariantAnalyticsModalOpen] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();

  // Preset dates
  const presetDatesMenuOpen = Boolean(presetDatesMenuAnchorEl);

  const handlePresetDatesOnClick = (event) => {
    setPresetDatesMenuAnchorEl(event.currentTarget);
  };

  const handlePresetDatesMenuOnClose = () => {
    setPresetDatesMenuAnchorEl(null);
  };

  const handlePresetDatesOnSelect = (label) => {
    if (label === 'Today') {
      setStartDate(moment().utc());
      setEndDate(moment().utc());
    } else if (label === 'Yesterday') {
      setStartDate(moment().utc().subtract(1, 'days'));
      setEndDate(moment().utc().subtract(1, 'days'));
    } else if (label === 'Last week') {
      setStartDate(moment().utc().subtract(1, 'weeks').startOf('isoWeek'));
      setEndDate(moment().utc().subtract(1, 'weeks').endOf('isoWeek'));
    } else if (label === 'Month to date') {
      setStartDate(moment().utc().startOf('month'));
      setEndDate(moment().utc());
    } else if (label === 'Last month') {
      setStartDate(moment().utc().subtract(1, 'months').startOf('month'));
      setEndDate(moment().utc().subtract(1, 'months').endOf('month'));
    } else if (label === 'Year to date') {
      setStartDate(moment().utc().startOf('year'));
      setEndDate(moment().utc());
    }
    setPresetDatesMenuAnchorEl(null);
  }

  const viewProductVariantAnalytics = async (event) => {
    const { row } = event;
    if (row.productType === 'simple') return;
    const productSku = row.sku;
    const productVariantEndpoint = `analytics/product-variant?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}&sku=${productSku}`;
    setProductVariantAnalyticsModalOpen(true);
    setProductVariantAnalyticsLoading(true);
    const productVariantResponse = await requestApi.getResponse({url: productVariantEndpoint});
    setProductVariantAnalytics(productVariantResponse);
    setProductVariantAnalyticsLoading(false);
  }

  const shouldPadGivenPeriod = (givenPeriod, daysInPeriod) => {
    const givenPeriodKeys = (givenPeriod) ? Object.keys(givenPeriod) : null;

    if(Array.isArray(givenPeriodKeys)) {
      if(givenPeriodKeys.indexOf('Individuals') >= 0) {
        return false;
      }

      // check if any of the memberships in the given period have
      // fewer objects than the number of days in the period
      if(givenPeriodKeys.findIndex(key => givenPeriod[key].length !== daysInPeriod) !== -1) {
        return true;
      }

      return true;
    }

    return false;
  }

  useEffect(() => {

    if(membershipRevenueAnalytics) {

      // stop repeated calls to setMembershipRevenueAnalytics() re-triggering useEffect
      if(isEmptyObject(membershipRevenueAnalytics.selectedPeriod) && isEmptyObject(membershipRevenueAnalytics.lastYearPeriod)) {
        return;
      }

      const { selectedPeriod = {}, lastYearPeriod = {} } = membershipRevenueAnalytics;

      const daysInPeriod = Math.abs(moment(endDate).diff(startDate, 'days')) + 1 || 0;

      const selectedPeriodKeys = Object.keys(selectedPeriod);
      const lastYearPeriodKeys = Object.keys(lastYearPeriod);

      const shouldPadSelectedPeriod = shouldPadGivenPeriod(selectedPeriod, daysInPeriod);
      const shouldPadLastYearPeriod = shouldPadGivenPeriod(lastYearPeriod, daysInPeriod);

      let paddedSelectedPeriod = {};
      let paddedLastYearPeriod = {};

      // if there is a difference in key length for either set of
      // data then diff the keys and zero out the missing keys
      if(selectedPeriodKeys.length !== lastYearPeriodKeys.length) {
        const keyDiff = getShallowArrayDiff(selectedPeriodKeys, lastYearPeriodKeys);

        if(keyDiff && keyDiff.length) {
          for(let index = 0; index < keyDiff.length; index++) {
            const key = keyDiff[index];

            if(!paddedSelectedPeriod.hasOwnProperty(key)) {
              paddedSelectedPeriod[key] = (new Array(daysInPeriod).fill()).map((_, index) => {
                const date = moment(startDate).add(index, 'day').format('YYYY-MM-DD');

                return {
                  date,
                  dateTotal: 0
                }
              })
            }

            if(!paddedLastYearPeriod.hasOwnProperty(key)) {
              paddedLastYearPeriod[key] = (new Array(daysInPeriod).fill()).map((_, index) => {
                const date = moment(startDate).subtract(1, 'year').add(index, 'day').format('YYYY-MM-DD');

                return {
                  date,
                  dateTotal: 0
                }
              })
            }
          }
        }
      }

      if(shouldPadSelectedPeriod) {
        selectedPeriodKeys.map((key) => {
          const group = selectedPeriod[key];

          paddedSelectedPeriod[key] = (new Array(daysInPeriod).fill()).map((_, index) => {
            const date = moment(startDate).add(index, 'day').format('YYYY-MM-DD');
            const exists = group.findIndex((row) => row.date === date);

            if(exists === -1) {
              return { date, dateTotal: 0 };
            }

            return group[exists];
          })
        });

        const paddedSelectedPeriodKeys = Object.keys(paddedSelectedPeriod);

        const paddedSelectedIndividualsData = paddedSelectedPeriodKeys.reduce(
          (accumulator, key) => {
            if(key.indexOf('Membership Resources – Year ') >= 0) {
              return [...accumulator, ...paddedSelectedPeriod[key]];
            }

            return accumulator;
          },
          []
        );

        paddedSelectedPeriod = paddedSelectedPeriodKeys.reduce((parent, key) => {
          if(key.indexOf('Membership Resources – Year ') >= 0) {
            const exists = paddedSelectedPeriod.Individuals;

            if (!exists) {
              return {
                ...parent,

                Individuals: paddedSelectedIndividualsData.reduce(
                  (acc, { date, dateTotal }) => {
                    const exists = acc.findIndex((row) => row.date === date);

                    if (exists === -1) {
                      return [...acc, { date, dateTotal }];
                    } else {
                      return acc.map((row, index) => {
                        if (exists === index) {
                          return {
                            date,
                            dateTotal:
                              parseInt(row.dateTotal, 10) + parseInt(dateTotal, 10)
                          };
                        }

                        return row;
                      });
                    }
                  },
                  []
                )
              };
            }
          }

          return { ...parent, [key]: paddedSelectedPeriod[key] };
        }, {});
      }

      if(shouldPadLastYearPeriod) {
        lastYearPeriodKeys.map((key) => {
          const group = lastYearPeriod[key];

          paddedLastYearPeriod[key] = (new Array(daysInPeriod).fill()).map((_, index) => {
            const date = moment(startDate).subtract(1, 'year').add(index, 'day').format('YYYY-MM-DD');
            const exists = group.findIndex((row) => row.date === date);

            if(exists === -1) {
              return { date, dateTotal: 0 };
            }

            return group[exists];
          })
        });

        const paddedLastYearPeriodKeys = Object.keys(paddedLastYearPeriod);

        const paddedLastYearIndividualsData = paddedLastYearPeriodKeys.reduce(
          (accumulator, key) => {
            if(key.indexOf('Membership Resources – Year ') >= 0) {
              return [...accumulator, ...paddedLastYearPeriod[key]];
            }

            return accumulator;
          },
          []
        );

        paddedLastYearPeriod = paddedLastYearPeriodKeys.reduce((parent, key) => {
          if(key.indexOf('Membership Resources – Year ') >= 0) {
            const exists = paddedLastYearPeriod.Individuals;

            if (!exists) {
              return {
                ...parent,

                Individuals: paddedLastYearIndividualsData.reduce((acc, { date, dateTotal }) => {
                  const exists = acc.findIndex((row) => row.date === date);

                  return exists === -1 ?  [...acc, { date, dateTotal }] : acc.map((row, index) => {
                    if (exists === index) {
                      return {
                        date,
                        dateTotal:
                          parseInt(row.dateTotal, 10) + parseInt(dateTotal, 10)
                      };
                    }

                    return row;
                  });
                }, [])
              };
            }
          }

          return { ...parent, [key]: paddedLastYearPeriod[key] };
        }, {});
      }

      const sortOrder = [
        'Membership Resources – Infant School (Team)',
        'Membership Resources – Primary School (Team)',
        'Membership Resources – Secondary School (Team)',
        'Individuals',
        'Course – All Courses (Team)'
      ];

      paddedSelectedPeriod = Object.keys(paddedSelectedPeriod)
        .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
        .reduce((acc, key) => { return { ...acc, [key]: paddedSelectedPeriod[key] } }, {})

      paddedLastYearPeriod = Object.keys(paddedLastYearPeriod)
        .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
        .reduce((acc, key) => { return { ...acc, [key]: paddedLastYearPeriod[key] } }, {})

      if(shouldPadSelectedPeriod || shouldPadLastYearPeriod) {
        setMembershipRevenueAnalytics({ selectedPeriod: paddedSelectedPeriod, lastYearPeriod: paddedLastYearPeriod });
      }
    }
  }, [membershipRevenueAnalytics]);

  const totalsByDateToChartWidgetFormat = (totalsByDate, yearOffset) => {

    const daysInPeriod = Math.abs(moment(dataEndDate).diff(dataStartDate, 'days')) + 1 || 0;
    const offsetStartDate = moment(dataStartDate).add(yearOffset, 'year').format('YYYY-MM-DD');

    return (new Array(daysInPeriod).fill()).map((_, index) => {
      const date = moment(offsetStartDate).add(index, 'day').format('YYYY-MM-DD');

      return {
        date,
        dateTotal: Math.round((totalsByDate[date] ?? 0) / 100, 2)
      }
    });
  }

  const totalNetRevenueByDayChart = () => {

    if(analyticsLoading
      || analytics === null
      || !analytics.orderNetTotalsByDate
      || analytics.orderNetTotalsByDate.length === 0)
    {
      return null;
    }

    const { current = [], previous = [] } = analytics.orderNetTotalsByDate;

    const selectedPeriod = totalsByDateToChartWidgetFormat(current);
    const lastYearPeriod = totalsByDateToChartWidgetFormat(previous, -1);

    return (
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <MembershipRevenueWidget
          label={'Total Sales'}
          startDate={ dataStartDate }
          endDate={ dataEndDate }
          data={ { selectedPeriod, lastYearPeriod } }
        />
      </Grid>
    )
  };

  return (
    <>
      <Grid
        container
        mt={-2}
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Card>
            <CardHeader color="info">
              Analytics
            </CardHeader>
            <CardContent>
              <Formik
                enableReinitialize
                initialValues={{ startDate: startDate, endDate: endDate }}
                validationSchema={Yup.object().shape({
                  startDate: Yup.date()
                    .typeError('Invalid date format')
                    .required('Start date is required'),
                  endDate: Yup.date()
                    .typeError('Invalid date format')
                    .required('End date is required')
                    .min(Yup.ref('startDate'), 'End date must be later than start date'),
                })}
                onSubmit={async (values) => {
                  setShowLoadingSpinner(true);
                  setAnalyticsLoading(true);
                  const startDate = values.startDate ? values.startDate : '';
                  const endDate = values.endDate ? values.endDate : '';
                  setStartDate(startDate);
                  setEndDate(endDate);

                  const revenueEndpoint = `analytics/revenue?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`;
                  const revenueResponse = await requestApi.getResponse({url: revenueEndpoint});
                  setAnalytics(revenueResponse);
                  const productEndpoint = `analytics/product?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`;
                  const productResponse = await requestApi.getResponse({url: productEndpoint});
                  setProductAnalytics(productResponse);
                  const membershipRevenueEndpoint = `analytics/memberships/revenue?startDate=${startDate ? startDate.format('YYYY-MM-DD') : ''}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`;
                  const membershipRevenueResponse = await requestApi.getResponse({url: membershipRevenueEndpoint});
                  setMembershipRevenueAnalytics(membershipRevenueResponse);

                  // keep track of the date range used when making the data request
                  setDataStartDate(startDate);
                  setDataEndDate(endDate);

                  setAnalyticsLoading(false);
                  setShowLoadingSpinner(false);
                }}
              >
                {(formik) => {
                  useEffect(() => {
                    formik.submitForm();
                  }, [])

                  return (
                    <form onSubmit={formik.handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={2}
                        >
                          <MDButton
                            color="info"
                            onClick={handlePresetDatesOnClick}
                            variant="gradient"
                          >
                            Preset dates
                          </MDButton>
                          <Menu
                            anchorEl={presetDatesMenuAnchorEl}
                            onClose={handlePresetDatesMenuOnClose}
                            open={presetDatesMenuOpen}
                          >
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Today')}>
                              Today
                            </MenuItem>
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Yesterday')}>
                              Yesterday
                            </MenuItem>
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Last week')}>
                              Last week
                            </MenuItem>
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Month to date')}>
                              Month to date
                            </MenuItem>
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Last month')}>
                              Last month
                            </MenuItem>
                            <MenuItem onClick={() => handlePresetDatesOnSelect('Year to date')}>
                              Year to date
                            </MenuItem>
                          </Menu>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                        >
                          <FormField
                            type="date"
                            name="startDate"
                            label="Start date"
                            formik={formik}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <FormField
                            type="date"
                            name="endDate"
                            label="End date"
                            formik={formik}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                          <MDButton
                            type="submit"
                            color="info"
                            variant="gradient"
                          >
                            View analytics
                          </MDButton>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={4}
          >
            {/* Sales */}
            <Grid
              item
              xs={6}
              md={3}
            >
              <StatCard
                  statName="Sales"
                  icon="currency_pound"
                  headline={<>
                    <small>£</small>
                    {getPriceLocaleString(analytics ? analytics.orderNetTotals?.current : 0)}
                  </>}
                  secondary={<>
                    Last year: £{getPriceLocaleString(analytics ? analytics.orderNetTotals?.previous : 0)}
                  </>}
              />
            </Grid>

            {/* Orders */}
            <Grid
              item
              xs={6}
              md={3}
            >
              <StatCard
                  statName="Orders"
                  icon="shopping_cart"
                  headline={analytics ? analytics.orderCounts?.current.toLocaleString() : 0}
                  secondary={ <p>Last year: {analytics ? analytics.orderCounts?.previous.toLocaleString() : 0}</p>}
              />
            </Grid>

            {/* Average order value */}
            <Grid
              item
              xs={6}
              md={3}
            >
              <StatCard
                  statName="Avg. order value"
                  icon="currency_pound"
                  headline={<>
                    <small>£</small>{getPriceLocaleString(analytics ? analytics.averageOrderValues?.current : 0)}
                  </>}
                  secondary={ <p>
                    Last year: <small>£</small>{getPriceLocaleString(analytics ? analytics.averageOrderValues?.previous : 0)}
                  </p>}
              />
            </Grid>

            {/* Average items per order value */}
            <Grid
              item
              xs={6}
              md={3}
            >
              <StatCard
                  statName="Avg. items per order"
                  icon="shopping_cart"
                  headline={analytics ? analytics.averageOrderUnitsPerOrder?.current.toLocaleString() : 0}
                  secondary={ <p>
                    Last year: {analytics ? analytics.averageOrderUnitsPerOrder?.previous.toLocaleString() : 0}
                  </p> }
              />
            </Grid>

            <InfinityAnalytics />

            {/* Products */}
            <Grid
              item
              xs={12}
            >
              <Card>
                <CardHeader color="info">Products {startDate.format('DD/MM/YYYY')}-{endDate.format('DD/MM/YYYY')}</CardHeader>
                <CardContent>
                  <DataGrid
                      autoHeight
                      columns={productsColumns}
                      components={{ Toolbar: ExportContainer }}
                      disableSelectionOnClick
                      getRowClassName={({row}) =>
                          row.productType === 'variable' && componentStyles['product-type-variable']
                      }
                      onPageSizeChange={(newPageSize) => setProductsPageSize(newPageSize)}
                      onRowClick={viewProductVariantAnalytics}
                      pageSize={productsPageSize}
                      rows={productAnalytics?.productCounts?.current || []}
                      rowsPerPageOptions={[10, 30, 100]}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        { totalNetRevenueByDayChart() }

        { !analyticsLoading && (
            <>
              { membershipRevenueAnalytics ?
                  Object.keys(membershipRevenueAnalytics.selectedPeriod).map((key) => {
                    const showOnly = [
                      'Membership Resources – Infant School (Team)',
                      'Membership Resources – Primary School (Team)',
                      'Membership Resources – Secondary School (Team)',
                      'Course – All Courses (Team)', 'Individuals'
                    ];

                    if(showOnly.indexOf(key) === -1) {
                      return null;
                    }

                    const selectedPeriod = membershipRevenueAnalytics.selectedPeriod[key];
                    const lastYearPeriod = membershipRevenueAnalytics.lastYearPeriod[key];

                    return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={key}>
                          <MembershipRevenueWidget
                              key={key}
                              label={key}
                              startDate={ startDate }
                              endDate={ endDate }
                              data={ { selectedPeriod, lastYearPeriod } }
                          />
                        </Grid>
                    )
                  })
                  : null }
            </>
        ) }

      </Grid>

      {
        productVariantAnalytics &&
        <Dialog
          open={productVariantAnalyticsModalOpen}
          fullWidth
          maxWidth={false}
          aria-labelledby="product-variant-analytics"
          aria-describedby="product-variant-analytics"
        >
          <DialogTitle id="scroll-dialog-title">Product variant analytics {startDate.format('DD/MM/YYYY')}-{endDate.format('DD/MM/YYYY')}</DialogTitle>
          <DialogContent>

            { productVariantAnalyticsLoading && <LoadingSpinner/> }
            { !productVariantAnalyticsLoading && (
                <DataGrid
                    autoHeight
                    columns={[
                      {field: 'productName', headerName: 'Product', flex: 2},
                      {field: 'sku', headerName: 'SKU', flex: 1},
                      {
                        field: 'orderUnitCount', headerName: 'Items sold', flex: 1,
                        valueFormatter: (params) => {
                          return params.value.toLocaleString()
                        }
                      },
                      {
                        field: 'salesTotal', headerName: 'Sales', flex: 1,
                        valueFormatter: (params) => {
                          return `£${getPriceLocaleString(params.value)}`
                        }
                      },
                      {
                        field: 'orderCount', headerName: 'Orders', flex: 1,
                        valueFormatter: (params) => {
                          return params.value.toLocaleString()
                        }
                      },
                    ]}
                    onPageSizeChange={(newPageSize) => setProductVariantsPageSize(newPageSize)}
                    pageSize={productVariantsPageSize}
                    rows={productVariantAnalytics?.productVariantCounts?.current}
                    rowsPerPageOptions={[10, 30, 100]}
                />
            )}
          </DialogContent>
          <DialogActions>
            <MDButton
              color="info"
              onClick={() => setProductVariantAnalyticsModalOpen(false)}
              variant="gradient"
            >
              Close
            </MDButton>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default Analytics;
