import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';

const apiEndpoint = 'membership-plans';
const resourceName = 'membership plan';

const lengthPeriodChoices = [
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'day',
    label: 'Day',
  },
];

const checkFieldRender = (parent, parentValue) => {
  // Reverse the logic for the 'access all' checkboxes because we want to render the child when parent field is unchecked
  if (parent === 'accessAllDigitalTools' || parent === 'accessAllResourcePages' || parent === 'accessAllVideoCourses') {
    return !parentValue;
  }
  return parentValue;
};

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'lengthPeriod',
    label: 'Length period',
    type: 'select',
    choices: lengthPeriodChoices,
    defaultValue: 'year'
  },
  {
    name: 'lengthAmount',
    label: 'Length amount',
    type: 'number',
  },
  {
    name: 'isTeamMembershipPlan',
    label: 'Team membership?',
    type: 'checkbox',
  },
  {
    name: 'maxTeamMembers',
    label: 'Maximum team members',
    type: 'number',
    parent: 'isTeamMembershipPlan',
    checkFieldRender,
  },
  {
    name: 'accessAllResourcePages',
    label: 'Gives access to all resource pages?',
    type: 'checkbox',
  },
  {
    name: 'accessAllVideoCourses',
    label: 'Gives access to all video courses?',
    type: 'checkbox',
  },
  {
    name: 'accessAllDigitalTools',
    label: 'Gives access to all digital tools?',
    type: 'checkbox',
  },
  {
    name: 'accessAllInfinityProducts',
    label: 'Gives access to all infinity products?',
    type: 'checkbox',
  },
  {
    name: 'resourcePages',
    label: 'Accessible resource pages',
    type: 'selectAsTable',
    choices: [],
    defaultValue: [],
    parent: 'accessAllResourcePages',
    checkFieldRender,
  },
  {
    name: 'videoCourses',
    label: 'Give access to video courses',
    type: 'selectAsTable',
    choices: [],
    defaultValue: [],
    parent: 'accessAllVideoCourses',
    checkFieldRender,
  },
  {
    name: 'digitalTools',
    label: 'Give access to digital tools',
    type: 'selectAsTable',
    choices: [],
    defaultValue: [],
    parent: 'accessAllDigitalTools',
    checkFieldRender,
  },
  {
    name: 'renewalMessage',
    label: 'Renewal message',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  lengthPeriod: Yup.string()
    .required('Length period is required'),
  lengthAmount: Yup.number()
    .required('Length amount is required'),
});

const AddEditMembershipPlan = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const resourcePageChoices = await requestApi.getResponse({ url: 'resource-pages/choices?exists[parentPage]=false' });
      const resourcePagesField = fields.find((field) => field.name === 'resourcePages');
      resourcePagesField.choices = resourcePageChoices;
      const videoCourseChoices = await requestApi.getResponse({ url: 'video-courses/choices' });
      const videoCoursesField = fields.find((field) => field.name === 'videoCourses');
      videoCoursesField.choices = videoCourseChoices;
      const digitalToolChoices = await requestApi.getResponse({ url: 'digital-tools/choices' });
      const digitalToolsField = fields.find((field) => field.name === 'digitalTools');
      digitalToolsField.choices = digitalToolChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditMembershipPlan;
