import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import useMounted from 'hooks/use-mounted';

// MUI
import { Box, TextField } from '@material-ui/core';
import { CircularProgress } from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';
import TimelineList from 'mdpr2/examples/Timeline/TimelineList';

// WRM
import { resourceApi } from 'api/resource-api';
import TimelineItemRich from 'components/TimelineItemRich';
import componentStyles from './LinkOrder.module.scss';
import ConfirmModal from '../../../components/shared/ConfirmModal';

const LinkOrder = ({ order }) => {
  const reFetchUserOrders = useRef(true);
  const manualOrderReference = useRef('');
  const manualOrderFoundOnReference = useRef({});
  const isMounted = useMounted();

  const [userOrders, setUserOrders] = useState([]);
  const [linkedOrders, setLinkedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const maximumNumberOfOrdersBeforeCollapsed = 3;

  const fetchChildAndParentOrders = async () => {
    setIsLoading(true);

    let parentOrder;
    if (order.parentOrder) {
      parentOrder = await resourceApi.getResource({
        apiEndpoint: 'orders',
        id: order.parentOrder.id,
      });
    }

    if (order.parentOrder && order.childOrders.length < 1) {
      setLinkedOrders([parentOrder]);
      return;
    }

    // this will trigger fetchLinkableOrders() which will resolve isLoading
    order.childOrders.forEach((childOrder) => {
      resourceApi.getResource({ apiEndpoint: 'orders', id: childOrder.id }).then(
        (response) => {
          if (parentOrder) {
            setLinkedOrders((prevChildOrders) => [parentOrder, ...prevChildOrders, response]);
          } else {
            setLinkedOrders((prevChildOrders) => [...prevChildOrders, response]);
          }
        },
      );
    });
  };

  const fetchLinkableOrders = async () => {
    setIsLoading(true);

    resourceApi.getResources({
      apiEndpoint: 'orders',
      filterValues: {
        userSearch: `${order.user.firstName} ${order.user.lastName}`,
      },
      pagination: {
        itemsPerPage: 9999,
      },
    }).then(
      (response) => {
        const unFilteredUserOrders = response.resources;
        if (unFilteredUserOrders.length < 1) {
          console.error('no user data');
          return;
        }
        const childOrderIds = linkedOrders.map(linkedOrder => linkedOrder.id);
        const filteredUserOrders = unFilteredUserOrders.filter((userOrder) =>
          userOrder.id !== order.id && !childOrderIds.includes(userOrder.id));
        setUserOrders(filteredUserOrders);
      },
    ).finally(() => setIsLoading(false));
  };

  const linkOrderViaReference = (orderReference) => {
    if (orderReference.length < 6) {
      alert('The order reference that has been given is not long enough.');
      return;
    }

    // Using getResources rather than getResource to call via reference rather than id.
    resourceApi.getResources({
      apiEndpoint: 'orders',
      filterValues: {
        reference: orderReference,
      },
      pagination: {
        itemsPerPage: 1,
      }}).then(
        (response) => {
          if (response.resources.length < 1) {
            alert('No order found for this reference');
            return;
          }
          const orderFound = response.resources[0];
          if (orderFound.parentOrder?.id === order.id) {
            alert('This order is already linked to the current order.');
            return;
          }
          manualOrderFoundOnReference.current = orderFound;
          setIsConfirmModalOpen(true);
        }
    );
  };

  const linkOrder = (newOrder) => {
    resourceApi.saveResource({ apiEndpoint: 'orders', id: newOrder.id, data: { 'parentOrder': order['@id'] } });

    reFetchUserOrders.current = false;
    if (isConfirmModalOpen) {
      setIsConfirmModalOpen(false);
    }
    setLinkedOrders((prevChildOrders) => [...prevChildOrders, newOrder]);
    setUserOrders((prevChildOrders) => prevChildOrders.filter(
      (childOrder) => childOrder.id !== newOrder.id,
    ));
    reFetchUserOrders.current = true;
    toast.success('Successfully linked order.');
  };

  const unlinkOrder = (orderToBeUnlinked) => {
    const unlinkingParentOrder = orderToBeUnlinked.id === order.parentOrder?.id;
    const newOrderData = unlinkingParentOrder ? {
      ...order,
      parentOrder: null,
    } : { ...orderToBeUnlinked, parentOrder: null };
    resourceApi.saveResource({
      apiEndpoint: 'orders',
      id: unlinkingParentOrder ? order.id : orderToBeUnlinked.id,
      data: newOrderData,
    })
      .then(() => {
        setLinkedOrders(linkedOrders.filter((item) => item !== orderToBeUnlinked));
        toast.success('Successfully unlinked order.');
      })
      .catch(() => {
        toast.error('Failed to unlink order. Please try again in a moment.');
      });
  };

  const findRecentOrderContainer = (event) => (event.target.closest('.recent-order-item-container'));
  const expandContainer = (element) => {
    findRecentOrderContainer(element).classList.remove(componentStyles['minimised-container']); // Remove so no need to worry about filtering on non-complete shipments
  };

  const confirmModalBody = () => (
    <span>
      You are about to link with order
      <a href={`/admin/warehouse/orders/edit/${manualOrderFoundOnReference.current.id}`} target="_blank"
         rel="noreferrer"> #{manualOrderFoundOnReference.current.reference}</a>.
      {manualOrderFoundOnReference.current.parentOrder && (
        <><br /> <p>By linking this order you will overwrite its current parent order
          <a href={`/admin/warehouse/orders/edit/${manualOrderFoundOnReference.current.parentOrder.id}`} target="_blank"
             rel="noreferrer"> #1{manualOrderFoundOnReference.current.parentOrder.id}</a>.</p></>
      )}
      <br />Please confirm that this is the correct order before proceeding.
    </span>
  );

  useEffect(async () => {
    if (isMounted) {
      await fetchChildAndParentOrders();
    }
  }, []);

  useEffect(() => {
    if (reFetchUserOrders.current) {
      fetchLinkableOrders();
    }
  }, [linkedOrders]);


  return (
    <Box>
      {isLoading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress style={{ height: '20px', width: '20px' }} />
        </Box>
      )}

      {!isLoading && (
        <Box sx={{ width: '100%', paddingX: '2rem', display: 'flex' }}>
          <TextField label="Link Order Via Reference"
                     variant="outlined"
                     placeholder="e.g. 1154139"
                     fullWidth
                     onChange={(e) => {
                       manualOrderReference.current = e.target.value;
                     }}
          />
          <MDButton onClick={() => linkOrderViaReference(manualOrderReference.current)} sx={{ width: '30%' }}>Link
            order</MDButton>
        </Box>
      )}

      <Box sx={(!isLoading && linkedOrders.length > 0) && { marginBottom: '-50px' }}>
        {(linkedOrders?.length > 0 && !isLoading) && (
          <TimelineList title="">
            <TimelineItemRich
              title="Linked orders"
              color="success"
              icon="bookmarks"
              description={linkedOrders?.map((linkedOrder) => (
                <Box key={`child-order-${linkedOrder.id}`}
                     sx={{ display: 'flex', borderBottom: '1px solid rgb(222 226 230)', alignItems: 'center' }}>
                  <a href={`/admin/warehouse/orders/edit/${linkedOrder.id}`}
                     style={{ width: '50%' }}>#{linkedOrder.reference}</a>
                  <MDButton onClick={() => unlinkOrder(linkedOrder)}>Un-Link order</MDButton>
                </Box>
              ))} />
          </TimelineList>
        )}
      </Box>

      <Box>
        {userOrders && userOrders.length > 0 && !isLoading && (
          <TimelineList title="">
            <TimelineItemRich
              title="Unlinked recent orders"
              color="warning"
              icon="bookmarks"
              description={
                <Box
                  className={userOrders.length > maximumNumberOfOrdersBeforeCollapsed ? `${componentStyles['minimised-container']} recent-order-item-container` : ``}
                  onClick={(e) => userOrders.length > maximumNumberOfOrdersBeforeCollapsed && expandContainer(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && userOrders.length > maximumNumberOfOrdersBeforeCollapsed) {
                      expandContainer(e);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {userOrders.map((el) => (
                    <Box sx={{ display: 'flex', borderBottom: '1px solid rgb(222 226 230)', alignItems: 'center' }}
                         key={`link-order-${el.id}`}>
                      <a href={`/admin/warehouse/orders/edit/${el.id}`} style={{ width: '50%' }}>#{el.reference}</a>
                      <MDButton onClick={() => linkOrder(el)}>Link order</MDButton>
                    </Box>
                  ))}
                </Box>

              } />
          </TimelineList>
        )}
      </Box>
      {
        userOrders.length < 1 && !isLoading && (
          <Box sx={{ marginTop: '3rem', marginLeft: '2rem' }}>
            <MDTypography variant="body2">This user has no other orders.</MDTypography>
          </Box>
        )
      }
      <ConfirmModal
        body={confirmModalBody()}
        open={isConfirmModalOpen}
        setOpen={(open) => setIsConfirmModalOpen(open)}
        title="Are you sure?"
        confirmCallback={() => linkOrder(manualOrderFoundOnReference.current)}
      />
    </Box>
  );
};

LinkOrder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
};

export default LinkOrder;