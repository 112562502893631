
import ListResources from 'components/shared/ListResources';
import PropTypes from 'prop-types';

const columns = [
  {
    field: 'name',
    label: 'Name',
    formatter: ({firstName, lastName}) => `${firstName?? ''} ${lastName?? ''}`,
    flex: 1
  },
  {
    field: 'email',
    label: 'email',
    flex: 2
  },
];



const filters = [
  {
    field: 'firstName',
    label: 'First name',
    type: 'text',
  },
  {
    field: 'lastName',
    label: 'Last name',
    type: 'text',
  },
  {
    field: 'email',
    label: 'Email',
    type: 'text',
  }
];


const MisTeachers = ({ learningGroupId}) => {
  const title = 'MIS teachers';
  const apiEndpoint = 'admin/infinity/mis-teachers';
  const resourceName = 'mis_teacher';
  return (
    <ListResources
    apiEndpoint={apiEndpoint}
    fixedFilterValues = {{learningGroup: learningGroupId}}
    columns={columns}
    customItemsPerPage={5}
    filters={filters}
    resourceName={resourceName}
    title={title}
    disableAddResource
    disableOpenInNewTab
  />
  );
};

MisTeachers.propTypes = {
  learningGroupId: PropTypes.number.isRequired,
};

export default MisTeachers;
