import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// MUI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';

const IssueRefundModal = (props) => {
  const { callbackData, confirmCallback, onClose } = props;

  const reasonCategoryChoices = [
    { value: 'unhappy-with-product', label: 'Unhappy with product' },
    { value: 'infant-not-required-with-primary-membership', label: 'Infant not required with primary membership' },
    { value: 'selected-incorrect-package', label: 'Selected incorrect package' },
    { value: 'wrong-quantity-ordered', label: 'Wrong quantity ordered' },
    { value: 'delivery-issues', label: 'Delivery issues' },
    { value: 'other', label: 'Other (please specify)' },
  ];

  const initialValues = {
    reasonCategory: '',
    reason: '',
  };

  const validationSchema = Yup.object().shape({
    reasonCategory: Yup.string().required('A reason is required')
      .oneOf(reasonCategoryChoices.map((reasonCategoryChoice) => reasonCategoryChoice.value)),
    reason: Yup.string().when('reasonCategory', {
        is: (reasonCategory) => reasonCategory === 'other',
        then: Yup.string().required('A note is required'),
      }),
  });

  const onFormSubmit = async (formValues) => {
    confirmCallback({
      reasonCategory: formValues.reasonCategory,
      reason: formValues.reason,
      callbackData,
    });
  }

  return (
    <Dialog
      open
      aria-labelledby="issue-refund-modal"
      aria-describedby="issue-refund-modal"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
              <DialogTitle>Issue refund?</DialogTitle>
              <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item>
                      <p>Are you sure you want to issue this refund?</p>
                    </Grid>

                    <FormField
                      type="select"
                      name="reasonCategory"
                      label="Reason"
                      choices={reasonCategoryChoices}
                      formik={formik}
                      getApiError={() => false}
                    />

                    <FormField
                      type="textarea"
                      name="reason"
                      label="Note (optional)"
                      formik={formik}
                      getApiError={() => false}
                    />
                  </Grid>
              </DialogContent>
              <DialogActions>
                <SaveCancelButtons
                  formik={formik}
                  isModal
                  onModalClose={onClose}
                />
              </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

IssueRefundModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callbackData: PropTypes.object.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IssueRefundModal;
