/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldArray, Formik, getIn } from 'formik';
import moment from 'moment';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import { resourceApi } from 'api/resource-api';
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import SaveCancelButtons from 'components/shared/SaveCancelButtons';
import useMounted from 'hooks/use-mounted';
import PageResources from './PageResources';
import FileBrowserDialog from "../FileBrowser/FileBrowserDialog";

const AddEditPageForm = (props) => {
  const {
    pageType,
    cancelLocation,
    fields,
    formValues,
    getApiError,
    onFormSubmit,
    resource,
    validationSchema,
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [pageBlockTypes, setPageBlockTypes] = useState([]);
  // 'Add page block'
  const [addPageBlockMenuAnchorEl, setAddPageBlockMenuAnchorEl] = useState(null);
  // 'Delete page block'
  const [deletePageBlockConfirmationModalOpen, setDeletePageBlockConfirmationModalOpen] = useState(false);
  const [deletePageBlockIndex, setDeletePageBlockIndex] = useState();
  const [deletePageBlockArrayHelpers, setDeletePageBlockArrayHelpers] = useState();
  // File browser
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [fileBrowserField, setFileBrowserField] = useState(null);
  const fileBrowserCallback = useRef(null);

  const isMounted = useMounted();

  const assignUuidsToContent = (content) => {
    Object.keys(content).forEach((key) => {
      if (Array.isArray(content[key])) {
        content[key].forEach((arrayElement) => {
          // eslint-disable-next-line no-param-reassign
          arrayElement.uuid = `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}-${moment().valueOf()}`;
          assignUuidsToContent(arrayElement);
        });
      }
    });
  };

  // eslint-disable-next-line no-shadow
  const initialiseForm = useCallback(async ({ resource }) => {
    try {
      resource.pageBlocks?.forEach((pageBlock) => {
        assignUuidsToContent(pageBlock.content);
      });

      const data = await resourceApi.getResources({
        apiEndpoint: 'page-block-types',
        pagination: { itemsPerPage: 999 },
        sortValues: [{ field: 'name', direction: 'asc' }],
      });
      setPageBlockTypes(data.resources);
      setInitialised(true);
    } catch (error) {
      // TODO
    }
  }, [isMounted]);

  useEffect(() => {
    initialiseForm({ fields, resource });
  }, [initialiseForm, fields, resource]);

  // 'Add page block'
  const addPageBlockMenuOpen = Boolean(addPageBlockMenuAnchorEl);

  const handleAddPageBlockOnClick = (event) => {
    setAddPageBlockMenuAnchorEl(event.currentTarget);
  };

  const handleAddPageBlockMenuClose = () => {
    setAddPageBlockMenuAnchorEl(null);
  };

  const addPageBlockForPageBlockTypeIri = (pageBlockTypeIri, arrayHelpers) => {
    setAddPageBlockMenuAnchorEl(null);

    // Find the PageBlockType from the incoming ID
    const pageBlockType = pageBlockTypes.find((pbt) => pbt['@id'] === pageBlockTypeIri);

    // Add the new PageBlock
    const prototype = {};
    prototype.pageBlockType = pageBlockType['@id'];
    prototype.content = {};
    pageBlockType.definition.forEach((definitionElement) => {
      if (definitionElement.data_type === 'repeater') {
        prototype.content[definitionElement.code] = [];
      } else {
        prototype.content[definitionElement.code] = '';
      }
    });
    prototype.uuid = `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}-${moment().valueOf()}`;
    arrayHelpers.push(prototype);
  };

  const handleDeletePageBlock = (index, arrayHelpers) => {
    setDeletePageBlockIndex(index);
    setDeletePageBlockArrayHelpers(arrayHelpers);
    setDeletePageBlockConfirmationModalOpen(true);
  };
  const confirmDeletePageBlock = () => {
    deletePageBlockArrayHelpers.remove(deletePageBlockIndex);
    setDeletePageBlockConfirmationModalOpen(false);
  };

  // File browser
  const showFileBrowser = ((fieldOrCallback) => {
    if (typeof fieldOrCallback === 'string') {
      setFileBrowserField(fieldOrCallback);
    }
    if (typeof fieldOrCallback === 'function') {
      fileBrowserCallback.current = fieldOrCallback;
    }
    setFileBrowserOpen(true);
  });
  const closeFileBrowser = () => {
    setFileBrowserOpen(false);
  };

  if (!initialised) return '';

  const handlePageBlockDragEnd = (formik, result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const { pageBlocks } = formik.values;
    const reorderedPageBlocks = [];
    const movedPageBlock = pageBlocks[result.source.index];
    pageBlocks.forEach((pageBlock, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedPageBlocks.push(movedPageBlock);
      }
      if (index !== result.source.index) {
        reorderedPageBlocks.push(pageBlock);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedPageBlocks.push(movedPageBlock);
      }
    });
    formik.setFieldValue('pageBlocks', reorderedPageBlocks);
  };

  const getFieldFromDefinitionElement = (definitionElement, pageBlockIndex = null) => {
    const field = {};
    if (pageBlockIndex !== null) {
      field.name = `pageBlocks[${[pageBlockIndex]}].content.${definitionElement.code}`;
    } else {
      field.name = definitionElement.code;
    }
    field.label = definitionElement.name;
    field.type = definitionElement.data_type;
    field.choices = definitionElement.choices;
    if (field.type === 'page_link') {
      field.type = 'text';
    }
    if (definitionElement.data_type === 'repeater') {
      field.type = 'fieldCollection';
      field.draggable = true;
      field.childFields = [];
      definitionElement.children.forEach((child) => {
        field.childFields.push(getFieldFromDefinitionElement(child));
      });
      field.repeaterLabelProperty = definitionElement.repeater_label_property;
      field.addButtonLabel = definitionElement.admin_button_label;
    }
    return field;
  };

  const renderPageBlock = (pageBlockValue, pageBlockIndex, formik) => {
    const pageBlockType = pageBlockTypes.find((pbt) => pbt['@id'] === pageBlockValue.pageBlockType);
    return (
      <Grid
        container
        spacing={2}
      >
        {
          pageBlockType.definition.map((definitionElement) => {
            const field = getFieldFromDefinitionElement(definitionElement, pageBlockIndex);
            return (
              <Grid
                item
                key={`grid-${pageBlockValue['@id'] || pageBlockValue.uuid}-${definitionElement.code}`}
                md={12}
                xs={12}
              >
                <FormField
                  addButtonLabel={field.addButtonLabel}
                  childFields={field.childFields}
                  choices={field.choices}
                  draggable={field.draggable}
                  formik={formik}
                  getApiError={getApiError}
                  getHeading={(childValue, childIndex) => (field.repeaterLabelProperty ? childValue[field.repeaterLabelProperty] : `${field.label} #${childIndex + 1}`)}
                  label={field.label}
                  name={field.name}
                  showFileBrowser={showFileBrowser}
                  type={field.type}
                />
              </Grid>
            );
          })
        }
        <FormField
            name={`pageBlocks[${pageBlockIndex}].identifier`}
            label="Block identifier (optional)"
            formik={formik}
            getApiError={getApiError}
            key={`pageBlocks[${pageBlockIndex}].identifier`}
        />

      </Grid>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onFormSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form formik={formik}>
            <Card>
              <CardContent>
                <Grid
                  container
                  mt={1}
                  spacing={2}
                >
                  <FormField
                    {...fields.find((field) => field.name === 'name')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'slug')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  {
                    pageType === 'home_learning_page' && (
                      <FormField
                        {...fields.find((field) => field.name === 'parentHomeLearningPage')}
                        formik={formik}
                        getApiError={getApiError}
                      />
                    )
                  }

                  {
                    pageType === 'resource_page' && (
                      <FormField
                        {...fields.find((field) => field.name === 'parentResourcePage')}
                        formik={formik}
                        getApiError={getApiError}
                      />
                    )
                  }

                  {
                    pageType === 'web_page' && (
                      <FormField
                        {...fields.find((field) => field.name === 'parentWebPage')}
                        formik={formik}
                        getApiError={getApiError}
                      />
                    )
                  }

                  {
                    pageType === 'resource_page' && formik.values.parentResourcePage === 'none' && (
                      <>
                        <FormField
                          {...fields.find((field) => field.name === 'section')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'myAccountSection')}
                          formik={formik}
                          getApiError={getApiError}
                        />

                        <FormField
                          {...fields.find((field) => field.name === 'tileImageUrl')}
                          formik={formik}
                          getApiError={getApiError}
                          showFileBrowser={showFileBrowser}
                        />
                      </>
                    )
                  }

                  {/* departments should show for all pages but not web components */}
                  {
                      ['home_learning_page', 'web_page', 'resource_page' ].includes(pageType) && (
                          <FormField
                              {...fields.find((field) => field.name === 'departments')}
                              formik={formik}
                              getApiError={getApiError}
                          />
                      )
                  }

                  <FormField
                    {...fields.find((field) => field.name === 'visibility')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'displayOrder')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <FormField
                    {...fields.find((field) => field.name === 'isArchived')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  {
                    (pageType === 'resource_page') && (
                        <FormField
                          {...fields.find((field) => field.name === 'zipIncludesPublicResources')}
                          formik={formik}
                          getApiError={getApiError}
                        />
                    )
                  }

                  <Grid
                    item
                    key="grid-pageBlocks"
                    md={12}
                    xs={12}
                  >
                    <FieldArray
                      name="pageBlocks"
                      render={(fieldArrayHelpers) => (
                        <>
                          <Grid
                            container
                            justifyContent="space-between"
                            paddingBottom={2}
                          >
                            <Grid item>
                              <MDTypography variant="h6">
                                Page blocks
                              </MDTypography>
                            </Grid>
                            <Grid item>
                              <MDButton
                                onClick={handleAddPageBlockOnClick}
                                startIcon={<AddIcon fontSize="small" />}
                                variant="gradient"
                                color="info"
                              >
                                Add page block
                              </MDButton>
                              <Menu
                                anchorEl={addPageBlockMenuAnchorEl}
                                onClose={handleAddPageBlockMenuClose}
                                open={addPageBlockMenuOpen}
                              >
                                {
                                  pageBlockTypes.map((pageBlockType) => (
                                    <MenuItem
                                      key={`menu-item-page-block-type-${pageBlockType.id}`}
                                      onClick={() => addPageBlockForPageBlockTypeIri(pageBlockType['@id'], fieldArrayHelpers)}
                                    >
                                      {pageBlockType.name}
                                    </MenuItem>
                                  ))
                                }
                              </Menu>
                            </Grid>
                          </Grid>
                          <DragDropContext onDragEnd={(result) => handlePageBlockDragEnd(formik, result)}>
                            <Droppable
                              direction="vertical"
                              droppableId="accordion-droppable"
                            >
                              {
                                (droppableProvided) => (
                                  <Box
                                    ref={droppableProvided.innerRef}
                                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                                    {...droppableProvided.droppableProps}
                                  >
                                    {
                                      getIn(formik.values, 'pageBlocks')?.map((pageBlockValue, pageBlockIndex) => {
                                        const pageBlockType = pageBlockTypes.find((pbt) => pbt['@id'] === pageBlockValue.pageBlockType);
                                        return (
                                          <Draggable
                                            draggableId={`accordion-${pageBlockValue['@id'] || pageBlockValue.uuid}`}
                                            index={pageBlockIndex}
                                            key={`accordion-${pageBlockValue['@id'] || pageBlockValue.uuid}`}
                                          >
                                            {
                                              (draggableProvided, snapshot) => (
                                                <Accordion
                                                  key={`accordion-pageBlocks-${pageBlockValue['@id'] || pageBlockValue.uuid}`}
                                                  TransitionProps={{ unmountOnExit: true }}
                                                  ref={draggableProvided.innerRef}
                                                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                                                  {...draggableProvided.draggableProps}
                                                  style={{
                                                    ...draggableProvided.draggableProps.style,
                                                    background: snapshot.isDragging && 'rgba(0, 0, 0, 0.10)',
                                                  }}
                                                >
                                                  <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    style={{
                                                      background: snapshot.isDragging ? 'none' : 'rgba(0, 0, 0, .025)',
                                                      backgroundColor: '#1BA0AE',
                                                    }}
                                                  >
                                                    <Grid
                                                      container
                                                      justifyContent="space-between"
                                                    >
                                                      <Grid item>
                                                        <Grid
                                                          container
                                                          spacing={2}
                                                        >
                                                          <Grid item>
                                                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                                            <div {...draggableProvided.dragHandleProps}>
                                                              <DragHandleIcon />
                                                            </div>
                                                          </Grid>
                                                          <Grid item>
                                                            <MDTypography variant="subtitle2">
                                                              {pageBlockType?.name}
                                                              {
                                                                pageBlockValue.content.title
                                                                && (
                                                                  <>
                                                                    &nbsp;-&nbsp;
                                                                    <strong>{pageBlockValue.content.title.replace(/<[^>]*>?/gm, '')}</strong>
                                                                  </>
                                                                )
                                                              }
                                                            </MDTypography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                      <Grid item>
                                                        <IconButton
                                                          color="error"
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDeletePageBlock(pageBlockIndex, fieldArrayHelpers);
                                                          }}
                                                          size="small"
                                                        >
                                                          <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                      </Grid>
                                                    </Grid>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    {renderPageBlock(pageBlockValue, pageBlockIndex, formik)}
                                                  </AccordionDetails>
                                                </Accordion>
                                              )
                                            }
                                          </Draggable>
                                        );
                                      })
                                    }
                                    {droppableProvided.placeholder}
                                  </Box>
                                )
                              }
                            </Droppable>
                          </DragDropContext>
                        </>
                      )}
                    />
                  </Grid>

                  {
                    (pageType === 'home_learning_page' || pageType === 'resource_page') && (
                      <PageResources formik={formik} getApiError={getApiError} showFileBrowser={showFileBrowser} />
                    )
                  }
                  {
                    (pageType === 'home_learning_page' || pageType === 'resource_page' || pageType === 'web_page') && (
                      <FormField
                        {...fields.find((field) => field.name === 'metaData')}
                        formik={formik}
                        getApiError={getApiError}
                      />
                    )
                  }
                </Grid>
              </CardContent>
              <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                <SaveCancelButtons
                  cancelLocation={cancelLocation}
                  formik={formik}
                />
              </CardActions>
            </Card>
            {
              fileBrowserOpen && (
                <FileBrowserDialog
                  fieldToUpdate={fileBrowserField}
                  setFieldValue={formik.setFieldValue}
                  callbackOnSelection={fileBrowserCallback.current || null}
                  closeFileBrowser={closeFileBrowser}
                />
              )
            }
          </Form>
        )}
      </Formik>
      {
        deletePageBlockConfirmationModalOpen && (
          <Dialog
            open
            aria-labelledby="delete-page-block"
            aria-describedby="delete-page-block"
          >
            <DialogTitle id="scroll-dialog-title">Delete page block</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this page block?
            </DialogContent>
            <DialogActions>
              <MDButton
                color="info"
                onClick={confirmDeletePageBlock}
                variant="gradient"
              >
                Yes
              </MDButton>
              <MDButton
                color="secondary"
                onClick={() => setDeletePageBlockConfirmationModalOpen(false)}
                variant="gradient"
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
};

AddEditPageForm.propTypes = {
};

AddEditPageForm.defaultProps = {
};

export default AddEditPageForm;
