/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from "lodash";
import AWS from 'aws-sdk';
import { ChonkyActions, ChonkyIconName, FullFileBrowser, setChonkyDefaults, defineFileAction } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material'; // https://github.com/TimboKZ/Chonky/issues/101
import { DropzoneDialog } from 'react-mui-dropzone'; // TEMP: https://www.npmjs.com/package/react-mui-dropzone
import { requestApi } from 'api/request-api';

// WRM
import { basename, join } from 'utils/path';
import { mediaFileS3IdToUrl } from "utils/helpers";

let s3 = null;
let bucket = null;

const initS3Config = () => {
  return new Promise((resolve, reject) => {

    // already initialised?
    if (s3 !== null) {
      resolve();
      return;
    }

    requestApi.getResponse({ url: 'aws/s3-assets-config' }).then((response) => {

      const { region, accessKeyId, secretAccessKey } = response;
      s3 = new AWS.S3({
        region,
        accessKeyId,
        secretAccessKey
      });
      bucket = response.bucket ?? null;

      resolve();

    }).catch((reason) => {
      reject(reason);
    })
  });
};

// Chonky config
setChonkyDefaults({ iconComponent: ChonkyIconFA, disableDragAndDrop: true });

const fetchS3Files = (prefix) => (
  s3
    .listObjectsV2({
      Bucket: bucket,
      Delimiter: '/',
      Prefix: prefix !== '/' ? prefix : '',
    })
    .promise()
    .then((response) => {
      let s3Files = [];
      const s3Objects = response.Contents;
      const s3Prefixes = response.CommonPrefixes;

      // Files
      if (s3Objects) {
        s3Objects.forEach((object) => {
          let thumbnailUrl = '';
          if (object.Key.substr(-4).toLowerCase() === '.jpg'
            || object.Key.substr(-5).toLowerCase() === '.jpeg'
            || object.Key.substr(-4).toLowerCase() === '.png') {
            thumbnailUrl = mediaFileS3IdToUrl(object.Key);
          }
          s3Files.push({
            id: object.Key,
            name: basename(object.Key),
            thumbnailUrl,
            modified: object.LastModified,
            size: object.Size,
          });
        });
      }
      // Remove the current folder, which is returned as a file
      s3Files = s3Files.filter((s3File) => (
        s3File.id.slice(-1) !== '/'
      ));

      // Folders
      if (s3Prefixes) {
        s3Files.push(
          ...s3Prefixes.map((s3Prefix) => ({
            id: s3Prefix.Prefix,
            name: basename(s3Prefix.Prefix),
            isDir: true,
          })),
        );
      }

      return s3Files;
    })
);

const createS3Folder = (key) => (
  s3
    .putObject({
      Bucket: bucket,
      Key: key,
    })
    .promise()
    .then((response) => (
      response.$response.httpResponse.statusCode === 200 ? { success: true } : { success: false }
    ))
);

const notifyServerOfMediaUpload = (key) => {
  requestApi.postResponse({ url: 'media-uploads', data: { key } }).catch(() => {
    console.warn('Unable to notify server of media upload');
  });
}

const uploadS3File = (key, data, contentType) => {
  return s3
    .putObject({
      Bucket: bucket,
      Key: key,
      Body: data,
      ContentType: contentType || 'application/octet-stream',
    })
    .promise()
    .then((response) => {
      notifyServerOfMediaUpload(key);
      return response.$response.httpResponse.statusCode === 200 ? { success: true } : { success: false };
    });
};

const deleteS3File = (key) => (
  s3
    .deleteObject({
      Bucket: bucket,
      Key: key,
    })
    .promise()
    .then((response) => (
      response.$response.httpResponse.statusCode === 204 ? { success: true } : { success: false }
    ))
);

const isS3FolderEmpty = async (key) => {
  const s3Files = await fetchS3Files(key);
  if (s3Files.length === 0) {
    return true;
  }
  return false;
};

// eslint-disable-next-line no-unused-vars
const emptyS3Folder = async (key) => {
  let deletedFileCount = 0;

  const s3Files = await fetchS3Files(key);
  if (s3Files.length > 0) {
    /* eslint-disable no-restricted-syntax */
    for (const file of s3Files) {
      if (file.isDir) {
        // eslint-disable-next-line no-await-in-loop
        const emptyS3FolderResponse = await emptyS3Folder(file.id);
        if (emptyS3FolderResponse.success) {
          // eslint-disable-next-line no-await-in-loop
          const deleteFileResponse = await deleteS3File(file.id);
          if (deleteFileResponse.success) deletedFileCount += 1;
        }
      } else {
        /* eslint-disable no-await-in-loop */
        const deleteFileResponse = await deleteS3File(file.id);
        if (deleteFileResponse.success) deletedFileCount += 1;
      }
    }
  }

  if (deletedFileCount !== s3Files.length) {
    return { success: false };
  }

  return { success: true };
};

const FileBrowser = (props) => {
  const {
    callbackOnSelection,
    closeFileBrowser,
    fieldToUpdate,
    setFieldValue,
  } = props;

  const [folderPrefix, setKeyPrefix] = useState(localStorage.getItem('fileBrowser_folderPrefix') || '/');
  const [files, setFiles] = useState();
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchFieldString, setSearchFieldString] = useState('');
  const [error, setError] = useState(null);
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  // Delete state
  const [filesToDelete, setFilesToDelete] = useState([]);
  // Modal state
  const [deleteModalBody, setDeleteModalBody] = useState('');
  const [deleteModalWarning, setDeleteModalWarning] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  // User feedback state
  const [, setUserFeedback] = useState();
  const [, setUserFeedbackOpen] = useState(false);

  const clearSearchString = () => setSearchFieldString('');

  const loadFiles = () => {
    fetchS3Files(folderPrefix)
      .then(setFiles)
      .catch((fetchS3FilesError) => setError(fetchS3FilesError.message));
  };

  // trigger initial load of S3 config, and populate files
  useEffect(() => {
    initS3Config().then(() => {
      loadFiles();
    }).catch(initS3ConfigError => setError(initS3ConfigError.message));
  }, [folderPrefix]);

  const filterFiles = () => {
    const filtered = (files ?? []).filter((file) => {
      return file.name.toLowerCase().includes(searchFieldString.toLowerCase())
    });

    setFilteredFiles(filtered);
  }

  const debouncedFilterFiles = useMemo(
    () => debounce(filterFiles, 200),
    [files, searchFieldString]
  );

  useEffect(debouncedFilterFiles, [files, searchFieldString]);

  const folderChain = useMemo(() => {
    // eslint-disable-next-line no-shadow
    let folderChain;
    if (folderPrefix === '/') {
      folderChain = [];
    } else {
      let currentPrefix = '';
      folderChain = folderPrefix
        .replace(/\/*$/, '')
        .split('/')
        .map((prefixPart) => {
          currentPrefix = currentPrefix
            ? join([currentPrefix, prefixPart])
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }
    folderChain.unshift({
      id: '/',
      name: '/',
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const confirmDeleteFiles = async () => {
    let hasErrors = false;
    for (const file of filesToDelete) {
      // Don't allow anything in fixed to be deleted
      if (file.id.startsWith('fixed/')) {
        // console.log('Cannot delete fixed file', file.id);
        hasErrors = true;
      } else if (file.isDir) {
        const isFolderEmpty = await isS3FolderEmpty(file.id);
        if (isFolderEmpty) {
          await deleteS3File(file.id);
        } else {
          // console.log('Will not delete folder', file.id);
          hasErrors = true;
        }
        /* TOO POWERFUL FOR PRODUCTION
        const deletedFiles = await emptyS3Folder(file.id);
        if (deletedFiles.success) {
          await deleteS3File(file.id);
        }
        */
      } else {
        await deleteS3File(file.id);
      }
    }
    // Refresh the files
    loadFiles();
    if (hasErrors) {
      setUserFeedback('Some files/folders could not be deleted');
    } else {
      setUserFeedback('Files/folders deleted successfully');
    }
    setUserFeedbackOpen(true);
    setDeleteModalOpen(false);
  };

  const copyMediaUrlToClipboardAction = defineFileAction({
    id: 'copy_media_url_to_clipboard',
    button: {
      name: 'Copy Media URL to Clipboard',
      contextMenu: true,
      icon: ChonkyIconName.copy,
    },
  });

  const handleFileAction = async (data) => {
    if (data.id === ChonkyActions.CreateFolder.id) {
      // 'Create folder' has been clicked
      const folderName = prompt('Provide the name for your new folder');
      if (folderName) {
        createS3Folder(`${folderPrefix !== '/' ? folderPrefix : ''}${folderName}/`)
          .then(loadFiles)
          .catch((createS3FolderError) => setError(createS3FolderError.message));
      }
    }

    if (data.id === copyMediaUrlToClipboardAction.id) {
      // 'Copy Media URL to Clipboard' has been clicked
      if (!data.state.contextMenuTriggerFile) return;
      navigator.clipboard.writeText(mediaFileS3IdToUrl(data.state.contextMenuTriggerFile.id));
    }

    if (data.id === ChonkyActions.DeleteFiles.id) {
      // 'Delete files' has been clicked
      const { selectedFiles } = data.state;
      setFilesToDelete(selectedFiles);

      if (selectedFiles.length > 1) {
        setDeleteModalBody(`Are you sure you want to delete ${selectedFiles.length} files/folders?`);
      } else {
        setDeleteModalBody('Are you sure you want to delete this file/folder?');
      }
      let renderDirWarning = false;
      for (const file of selectedFiles) {
        if (file.isDir) {
          renderDirWarning = true;
        }
      }
      if (renderDirWarning) {
        setDeleteModalWarning('For safety, only empty folders will be deleted');
      } else {
        setDeleteModalWarning('');
      }
      setDeleteModalOpen(true);
    }

    if (data.id === ChonkyActions.OpenFiles.id) {
      // A file/folder has been double clicked
      if (data.payload.files && data.payload.files.length !== 1) return;
      if (!data.payload.targetFile) return;

      if (data.payload.targetFile.isDir) {
        // A folder has been double clicked - open this folder (strip the trailing slash)
        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
        setKeyPrefix(newPrefix);
        clearSearchString();
        localStorage.setItem('fileBrowser_folderPrefix', newPrefix);
      } else {
        // A file has been double clicked - ie 'picked'
        const url = mediaFileS3IdToUrl(data.payload.targetFile.id);
        if (fieldToUpdate && setFieldValue) setFieldValue(fieldToUpdate, url);
        if (callbackOnSelection) callbackOnSelection(url);
        if (closeFileBrowser) closeFileBrowser();
      }
    }

    if (data.id === ChonkyActions.UploadFiles.id) {
      // 'Upload files' has been clicked
      setIsDropzoneOpen(true);
    }
  };

  const fileActions = useMemo(
    () => [
      ChonkyActions.CreateFolder,
      ChonkyActions.DeleteFiles,
      ChonkyActions.UploadFiles,
      ...(window.isSecureContext ? [copyMediaUrlToClipboardAction] : []) // clipboard only available on secure pages
    ],
    [],
  );

  const searchFieldProps = {
    name: "search",
    label: "Search/Filter Files",
    fullWidth: true,
    margin: "normal",
    size: "small",
    variant: "outlined",
    onChange: (e) => { setSearchFieldString(e.target.value); }
  };

  return (
    <>
      {error && <div>{error}</div>}
      <Grid container>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            size="xxs"
            value={searchFieldString}
            {...searchFieldProps}
            autoFocus
          />
        </Grid>
      </Grid>
      <div style={{ height: 600 }}>
        <DndProvider backend={HTML5Backend}>
          {/* https://github.com/TimboKZ/Chonky/issues/101 */}
          <ThemeProvider theme={createTheme({})}>
            <FullFileBrowser
              files={filteredFiles}
              folderChain={folderChain}
              fileActions={fileActions}
              onFileAction={handleFileAction}
            />
          </ThemeProvider>
        </DndProvider>
      </div>

      <DropzoneDialog
        acceptedFiles={[
          'image/*',
          '.doc',
          '.docx',
          '.notebook',
          '.pdf',
          '.ppt',
          '.pptx',
          '.xls',
          '.xlsx',
          '.zip',
        ]}
        filesLimit={40}
        maxFileSize={134217728} // 128MB
        open={isDropzoneOpen}
        dialogTitle="Upload files"
        submitButtonText="Upload"
        cancelButtonText="Close"
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewText="Selected files"
        showAlerts={['error']}
        onClose={() => setIsDropzoneOpen(false)}
        onSave={(dropzoneFiles) => {
          dropzoneFiles.forEach((file) => {
            uploadS3File(`${folderPrefix !== '/' ? folderPrefix : ''}${file.name}`, file, file.type)
              .then(loadFiles)
              .catch((uploadS3FileError) => setError(uploadS3FileError.message));
          });
          setIsDropzoneOpen(false);
        }}
      />

      <Dialog open={deleteModalOpen}>
        <DialogTitle>Delete files?</DialogTitle>
        <DialogContent>
          <p>{deleteModalWarning}</p>
          <p>{deleteModalBody}</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmDeleteFiles}
            sx={{ m: 1 }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={() => setDeleteModalOpen(false)}
            sx={{ m: 1 }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FileBrowser.propTypes = {
  callbackOnSelection: PropTypes.func,
  closeFileBrowser: PropTypes.func,
  fieldToUpdate: PropTypes.string,
  setFieldValue: PropTypes.func,
};

FileBrowser.defaultProps = {
  callbackOnSelection: null,
  closeFileBrowser: null,
  fieldToUpdate: null,
  setFieldValue: null,
};

export default FileBrowser;
