import { toFormValues } from 'components/shared/resource/api-form-mapper';

const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // formValues.billingAddress = resource.billingAddress;
  // formValues.deliveryAddress = resource.deliveryAddress;

  return formValues;
}

export default toFormValuesCustom;
