/* eslint-disable */
export const tagsChoices = [
  {
    value: 'damaged',
    label: 'Damaged stock',
  },
];

export const stockChangeTypes = [
  {value: 'StockChangeCorrection', label: 'Correction'},
  {value: 'StockChangeCount', label: 'Count'},
  {value: 'StockChangeDespatch', label: 'Despatch'},
  {value: 'StockChangeOrder', label: 'Supplier Order'},
  {value: 'StockChangeReceive', label: 'Receive'},
  {value: 'StockChangeReserve', label: 'Reserve'},
  {value: 'StockChangeReturn', label: 'Return'},
  {value: 'StockChangeTransfer', label: 'Transfer'},
  {value: 'StockChangeUnreserve', label: 'Un-reserve'},
  {value: 'StockChangeWriteoff', label: 'Write Off'},
];

export const writeOffReasons = [
  {value: 'Damaged - Elanders', label: 'Damaged - Elanders'},
  {value: 'Damaged - Other', label: 'Damaged - Other'},
  {value: 'Sample', label: 'Sample'},
  {value: 'Charity', label: 'Charity'},
  {value: 'Gifting', label: 'Gifting'},
  {value: 'Marketing', label: 'Marketing'},
  {value: 'Marketing - International', label: 'Marketing - International'},
  {value: 'Tempo Time Credits', label: 'Tempo Time Credits'},
  {value: 'Other', label: 'Other'}
];

export const correctionReasons = [
  {value: 'Stock Received Difference', label: 'Stock Received Difference'},
  {value: 'Other', label: 'Other'},
];
