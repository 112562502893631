import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// MUI
import { Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Icon from '@mui/material/Icon';
import OpenInNew from '@mui/icons-material/OpenInNew';

// MDPR
import MDBox from 'mdpr2/components/MDBox';
import MDPagination from 'mdpr2/components/MDPagination';
import MDTypography from 'mdpr2/components/MDTypography';
import MDButton from 'mdpr2/components/MDButton';

// WRM
import useMounted from 'hooks/use-mounted';
import AddEditMembership from 'pages/auth/Memberships/AddEditMembership';
import AddEditProductVariant from 'pages/auth/Products/AddEditProductVariant';
import AddEditLearningGroupAccess from 'pages/auth/LearningGroups/AddEditLearningGroupAccess';
import AddEditLicence from 'pages/auth/Licences/AddEditLicence';

const ListResourcesTable = (props) => {
  const {
    adminEndpoint,
    apiEndpoint,
    columns,
    isAddEditModal,
    onAddEditModalClose,
    pagination,
    parent,
    resourceCount,
    resources,
    setPagination,
    setSortValues,
    sortValues,
    disableEditResource,
    disableOpenInNewTab,
    onRowDoubleClick,
    customItemsPerPage,
  } = props;
  const [paginationData, setPaginationData] = useState({});
  const [resourceToAddOrEdit, setResourceToAddOrEdit] = useState(null);
  const [isAddEditResourceModalOpen, setIsAddEditResourceModalOpen] = useState(false);

  const isMounted = useMounted();

  const navigate = useNavigate();

  useEffect(() => {
    if (resourceCount === 0 && resources.length === 0) {
      setPaginationData({});
      return;
    }

    // eslint-disable-next-line no-shadow
    const paginationData = {};
    paginationData.pagesPerPageSet = 10;
    paginationData.page = pagination.page || 1;
    paginationData.resourceCount = resourceCount || resources.length;
    paginationData.resourcesPerPage = pagination.itemsPerPage || (customItemsPerPage ?? 30);
    paginationData.lastPage = Math.ceil(paginationData.resourceCount / paginationData.resourcesPerPage);
    paginationData.lastPageSet = Math.ceil(paginationData.lastPage / paginationData.pagesPerPageSet);
    paginationData.pageSet = Math.ceil(paginationData.page / paginationData.pagesPerPageSet);
    paginationData.firstPageInPageSet = (paginationData.pageSet - 1) * paginationData.pagesPerPageSet + 1;
    paginationData.lastPageInPageSet = paginationData.pageSet * paginationData.pagesPerPageSet;
    if (paginationData.lastPageInPageSet > paginationData.lastPage) {
      paginationData.lastPageInPageSet = paginationData.lastPage;
    }
    paginationData.firstResource = (paginationData.page - 1) * paginationData.resourcesPerPage + 1;
    paginationData.lastResource = paginationData.page * paginationData.resourcesPerPage;
    if (paginationData.lastResource > paginationData.resourceCount) {
      paginationData.lastResource = paginationData.resourceCount;
    }
    paginationData.pageSetRange = Array(paginationData.lastPageInPageSet - paginationData.firstPageInPageSet + 1)
      .fill()
      .map((_, idx) => paginationData.firstPageInPageSet + idx);
    if (isMounted()) {
      setPaginationData(paginationData);
    }
  }, [isMounted, pagination, resourceCount]);

  const handleColumnOnClick = (field) => {
    const currentSortValue = sortValues.find((sortValue) => sortValue.field === field) || null;
    // eslint-disable-next-line no-nested-ternary
    const direction = currentSortValue ? (currentSortValue.direction === 'asc' ? 'desc' : 'asc') : 'asc';
    const newSortValues = [{ field, direction }];
    setSortValues(newSortValues);
  };

  const goToPage = (newPage) => {
    const newPagination = { ...pagination };
    newPagination.page = newPage;
    setPagination(newPagination);
  };

  const editResource = (resource) => {
    if (disableEditResource === true) {
      return;
    }

    if (isAddEditModal) {
      setResourceToAddOrEdit(resource);
      setIsAddEditResourceModalOpen(true);
    } else {
      navigate(`/admin/${adminEndpoint || apiEndpoint}/edit/${resource.id}`);
    }
  };

  const closeAddEditResourceModal = () => {
    setIsAddEditResourceModalOpen(false);
    if (onAddEditModalClose) onAddEditModalClose();
  };

  const openInNewTabButton = (resource) => {
    if (disableOpenInNewTab || isAddEditModal) {
      return null;
    }
    return (
      <div className="resourceOpenInNew">
        <MDButton
          size="small"
          color="dark"
          onClick={() => window.open(`/admin/${adminEndpoint || apiEndpoint}/edit/${resource.id}`, '_blank')}
          variant="outlined"
          title="Open in new window"
          iconOnly
        >
          <OpenInNew fontSize="small" />
        </MDButton>
      </div>
    );
  };

  let flexTotal = 0;
  columns.forEach((column) => {
    flexTotal += column.flex ? column.flex : 1;
  });

  return (
    <>
      <TableContainer>
        <Table>
          <MDBox component="thead">
            <TableRow>
              {columns.map((column) => {
                const isColumnSortable = column.sortable || false;
                const columnSortValue = sortValues.find((sortValue) => sortValue.field === column.field) || null;
                const columnWidth = `${((column.flex || 1) / flexTotal) * 100}%`;
                return (
                  <MDBox
                    component="th"
                    key={`th-column-${column.field}`}
                    onClick={() => isColumnSortable && handleColumnOnClick(column.field)}
                    px={2}
                    py={1.5}
                    sx={({ borders: { borderWidth }, palette: { light } }) => ({
                      borderBottom: `${borderWidth[1]} solid ${light.main}`,
                    })}
                    width={columnWidth}
                  >
                    <MDBox
                      textAlign="left"
                      color="secondary"
                      position="relative"
                      sx={({ typography: { size, fontWeightBold } }) => ({
                        cursor: column.sortable && 'pointer',
                        fontSize: size.xxs,
                        fontWeight: fontWeightBold,
                        textTransform: 'uppercase',
                        userSelect: column.sortable && 'none',
                      })}
                    >
                      {column.label}
                      {isColumnSortable && (
                        <MDBox
                          left="unset"
                          position="absolute"
                          right="16px"
                          sx={({ typography: { size } }) => ({
                            fontSize: size.lg,
                          })}
                          top={0}
                        >
                          <MDBox
                            color={columnSortValue && columnSortValue.direction === 'asc' ? 'text' : 'secondary'}
                            opacity={columnSortValue && columnSortValue.direction === 'asc' ? 1 : 0.5}
                            position="absolute"
                            top={-6}
                          >
                            <Icon>arrow_drop_up</Icon>
                          </MDBox>
                          <MDBox
                            color={columnSortValue && columnSortValue.direction === 'desc' ? 'text' : 'secondary'}
                            opacity={columnSortValue && columnSortValue.direction === 'desc' ? 1 : 0.5}
                            position="absolute"
                            top={0}
                          >
                            <Icon>arrow_drop_down</Icon>
                          </MDBox>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                );
              })}
            </TableRow>
          </MDBox>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                key={`table-row-resource-${resource.id}`}
                onDoubleClick={() => (onRowDoubleClick ? onRowDoubleClick(resource) : editResource(resource))}
              >
                {columns.map((column, index) => (
                  <TableCell key={`table-cell-resource-${resource.id}-column-${column.field}`}>
                    <MDTypography variant="caption" fontWeight="regular">
                      {index === 0 && openInNewTabButton(resource)}
                      {column.formatter ? column.formatter(resource) : resource[column.field] || ''}
                    </MDTypography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {resources.length === 0 && (
              <TableRow key="table-row-no-data">
                <TableCell colSpan={columns.length} align="center">
                  <MDTypography variant="caption" fontWeight="bold">
                    No records to display
                  </MDTypography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {paginationData.resourceCount > 0 && (
          <MDBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {paginationData.firstResource} to {paginationData.lastResource} of{' '}
                {paginationData.resourceCount}
              </MDTypography>
            </MDBox>
            {paginationData.lastPage > 1 && (
              <MDPagination variant="gradient" color="info">
                {paginationData.pageSet > 1 && (
                  <MDPagination
                    item
                    onClick={() => goToPage((paginationData.pageSet - 2) * paginationData.pagesPerPageSet + 1)}
                  >
                    <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
                  </MDPagination>
                )}
                {paginationData.pageSetRange.map((pageInPageSet) => (
                  <MDPagination
                    item
                    key={`md-pagination-page-${pageInPageSet}`}
                    onClick={() => goToPage(pageInPageSet)}
                    active={paginationData.page === pageInPageSet}
                  >
                    {pageInPageSet}
                  </MDPagination>
                ))}
                {paginationData.pageSet < paginationData.lastPageSet && (
                  <MDPagination
                    item
                    onClick={() => goToPage(paginationData.pageSet * paginationData.pagesPerPageSet + 1)}
                  >
                    <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
                  </MDPagination>
                )}
              </MDPagination>
            )}
          </MDBox>
        )}
      </TableContainer>

      <Dialog
        open={isAddEditResourceModalOpen}
        onClose={closeAddEditResourceModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-resource"
        aria-describedby="add-edit-resource"
      >
        <DialogContent>
          {apiEndpoint === 'memberships' && (
            <AddEditMembership
              isModal
              membershipId={resourceToAddOrEdit && resourceToAddOrEdit.id}
              onClose={closeAddEditResourceModal}
              onModalClose={closeAddEditResourceModal}
              parent={parent}
            />
          )}
          {apiEndpoint === 'product-variants' && (
            <AddEditProductVariant
              isModal
              productVariantId={resourceToAddOrEdit && resourceToAddOrEdit.id}
              onClose={closeAddEditResourceModal}
              onModalClose={closeAddEditResourceModal}
              parent={parent}
            />
          )}
          {apiEndpoint === 'learning-group-accesses' && (
            <AddEditLearningGroupAccess
              isModal
              learningGroupAccessId={resourceToAddOrEdit && resourceToAddOrEdit.id}
              onClose={closeAddEditResourceModal}
              onModalClose={closeAddEditResourceModal}
              parent={parent}
            />
          )}
          {apiEndpoint === 'licences' && (
            <AddEditLicence
              isModal
              licenceId={resourceToAddOrEdit && resourceToAddOrEdit.id}
              onClose={closeAddEditResourceModal}
              onModalClose={closeAddEditResourceModal}
              parent={parent}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ListResourcesTable.propTypes = {
  adminEndpoint: PropTypes.string,
  apiEndpoint: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  isAddEditModal: PropTypes.bool,
  onAddEditModalClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pagination: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  parent: PropTypes.object,
  resourceCount: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  resources: PropTypes.array.isRequired,
  setPagination: PropTypes.func,
  setSortValues: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  sortValues: PropTypes.array,
  disableEditResource: PropTypes.bool,
  disableOpenInNewTab: PropTypes.bool,
  onRowDoubleClick: PropTypes.func,
  customItemsPerPage: PropTypes.number,
};

ListResourcesTable.defaultProps = {
  adminEndpoint: null,
  apiEndpoint: null,
  isAddEditModal: false,
  onAddEditModalClose: null,
  pagination: {},
  parent: null,
  resourceCount: null,
  setPagination: null,
  setSortValues: null,
  sortValues: [],
  disableEditResource: false,
  disableOpenInNewTab: false,
  onRowDoubleClick: null,
  customItemsPerPage: null,
};

export default ListResourcesTable;
