import React from 'react';
import { FieldArray } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MaterialUI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import MDButton from 'mdpr2/components/MDButton';

// WRM
import FormField from 'components/shared/FormField';
import componentStyles from './CustomConditionsHelper.module.scss';

const CustomConditionsHelper = (props) => {
  const {
    formik,
    getApiError,
  } = props;

  const typeChoices = [
    {
      value: 'text',
      label: 'Text',
    },
    {
      value: 'select',
      label: 'Select',
    },
    {
      value: 'checkbox',
      label: 'Checkbox',
    },
  ]  

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedCustomConditions = [];
    const movedCustomCondition = formik.values.customConditionsHelper[result.source.index];
    formik.values.customConditionsHelper.forEach((customCondition, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedCustomConditions.push(movedCustomCondition);
      }
      if (index !== result.source.index) {
        reorderedCustomConditions.push(customCondition);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedCustomConditions.push(movedCustomCondition);
      }
    });
    formik.setFieldValue('customConditionsHelper', reorderedCustomConditions);
  };

  return (
    <FieldArray name="customConditionsHelper" render={fieldArrayHelpers => (
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <h4>Parameters for &apos;Run report&apos;</h4>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <MDButton
              className={componentStyles['add-custom-condition-button']}
              color="info"
              onClick={() => {
                const prototype = {};
                prototype.type = 'text';
                prototype.choices = '[]';
                prototype.property = '';
                prototype.label = '';
                prototype.description = '';
                prototype.uuid = moment().valueOf();
                fieldArrayHelpers.push(prototype);
              }}
              size="small"
              startIcon={<AddIcon/>}
              variant="gradient"
            >
              Add &apos;Run report&apos; parameter
            </MDButton>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Table>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  direction="vertical"
                  droppableId="custom-conditions-droppable"
                >
                  {
                    (droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {
                          formik.values.customConditionsHelper?.map((customCondition, index) => {
                            const typeFieldName = `customConditionsHelper[${index}].type`;
                            const choicesFieldName = `customConditionsHelper[${index}].choices`;
                            const propertyFieldName = `customConditionsHelper[${index}].property`;
                            const labelFieldName = `customConditionsHelper[${index}].label`;
                            const descriptionFieldName = `customConditionsHelper[${index}].description`;
                            return (
                              <Draggable
                                draggableId={`custom-condition-${customCondition.uuid}`}
                                index={index}
                                key={`custom-condition-${customCondition.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <TableRow
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                                      }}
                                    >
                                      <TableCell style={{ width: '1px' }}>
                                        <div {...draggableProvided.dragHandleProps}><DragHandleIcon /></div>
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          type="select"
                                          name={typeFieldName}
                                          label="Type"
                                          choices={typeChoices}
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={choicesFieldName}
                                          label="Options"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={propertyFieldName}
                                          label="Property"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={labelFieldName}
                                          label="Label"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <FormField
                                          name={descriptionFieldName}
                                          label="Description"
                                          formik={formik}
                                          getApiError={getApiError}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: '1px' }}>
                                        <MDButton
                                          color="error"
                                          iconOnly
                                          onClick={() => {
                                            fieldArrayHelpers.remove(index);
                                          }}
                                          variant="gradient"
                                        >
                                          <DeleteIcon/>
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </TableBody>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    )} />
  );
};

CustomConditionsHelper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

export default CustomConditionsHelper;
