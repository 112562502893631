import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import { resourceApi } from 'api/resource-api';
import ListResourcesTable from 'components/shared/ListResources/ListResourcesTable';
import useMounted from 'hooks/use-mounted';
import AddEditProductVariant from './AddEditProductVariant';

const columns = [
  {
    field: 'sku',
    label: 'SKU',
    flex: 1,
  },
  {
    field: 'productVariantOptionValues',
    label: 'Options',
    flex: 2,
    formatter: (resource) => {
      const { productVariantOptionValues } = resource;
      const optionValues = [];
      productVariantOptionValues.forEach(productVariantOptionValue => {
        optionValues.push(productVariantOptionValue.value);
      })
      return optionValues.join(' / ');
    },
  },
  {
    field: 'price',
    label: 'Variant-specific price',
    flex: 1,
    formatter: (resource) => resource.price !== null ? `£${(resource.price / 100).toFixed(2)}` : '',
  },
  {
    field: 'packingUnits',
    label: 'Variant-specific packing units',
    flex: 1,
  },
  {
    field: 'warehouseValue',
    label: 'Variant-specific warehouse value',
    flex: 1,
  },
];

const ProductVariants = (props) => {
  const {
    product
  } = props;

  const [initialised, setInitialised] = useState(false);
  const [productVariants, setProductVariants] = useState([]);
  const [productVariantToEdit, setProductVariantToEdit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const isMounted = useMounted();

  const loadProductVariants = async () => {
    const data = await resourceApi.getResources({
      apiEndpoint: 'product-variants',
      filterValues: {
        product: product['@id'],
      },
      sortValues: [
        {
          field: 'sku',
          direction: 'asc',
        },
      ],
      pagination: {
        itemsPerPage: 999999,
      },
    });
    if (isMounted()) {
      setProductVariants(data.resources);
    }
  }

  const initialise = useCallback(async () => {
    await loadProductVariants();
    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  const addProductVariant = () => {
    setProductVariantToEdit(null);
    setModalOpen(true);
  };

  const closeModal = async () => {
    setModalOpen(false);
    await loadProductVariants();
  }

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Product variants</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Box display="flex" justifyContent="flex-end">
                <MDButton
                  color="info"
                  onClick={addProductVariant}
                  variant="gradient"
                >
                  Add product variant
                </MDButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ListResourcesTable
                apiEndpoint="product-variants"
                columns={columns}
                isAddEditModal
                onAddEditModalClose={() => loadProductVariants()}
                parent={product}
                resources={productVariants}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={modalOpen}
        onClose={closeModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
        aria-labelledby="add-edit-product-variant"
        aria-describedby="add-edit-product-variant"
      >
        <DialogTitle id="scroll-dialog-title">{productVariantToEdit ? 'Edit product variant' : 'Add product variant'}</DialogTitle>
        <DialogContent >
          <AddEditProductVariant
            isModal
            onModalClose={closeModal}
            parent={product}
            productVariant={productVariantToEdit}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

ProductVariants.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired
};

export default ProductVariants;
