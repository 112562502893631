import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import countriesArr from 'utils/countriesList';

// WRM
import { requestApi } from 'api/request-api';
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import AddEditShippingServiceForm from './AddEditShippingServiceForm';

const apiEndpoint = 'shipping-services';
const resourceName = 'shipping service';

const countryChoices  = countriesArr.map(choice => ({ value: choice[0], label: choice[1] }));

const statusChoices = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];

const fields = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    label: 'Code',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    choices: statusChoices,
    defaultValue: 'inactive',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'price',
    label: 'Price',
    type: 'currency',
  },
  {
    name: 'shippingServiceZone',
    label: 'Shipping service zone',
    type: 'select',
  },
  {
    name: 'conditions',
    label: 'Conditions',
    json: true,
    defaultValue: []
  },
  {
    name: 'displayMessage',
    label: 'Display Message'
  },
  {
    name: 'countries',
    label: 'Countries',
    type: 'select',
    choices: countryChoices,
    defaultValue: []
  },
];


Yup.addMethod(Yup.array, 'conditionsHelper', function (errorMessage) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test(`conditions-helper`, errorMessage, function (conditionsHelperValues) {
    const { createError } = this;

    const errors = [];
    conditionsHelperValues?.forEach((conditionsHelperValue, index) => {
      const { attribute, comparator, value } = conditionsHelperValue;
      if (!attribute) {
        errors.push(new ValidationError(
          `Attribute is required`,
          '',
          `conditionsHelper[${index}].attribute`
        ));
      }
      if (!comparator) {
        errors.push(new ValidationError(
          `Comparator is required`,
          '',
          `conditionsHelper[${index}].comparator`
        ));
      }
      if (typeof value === 'undefined') {
        errors.push(new ValidationError(
          `Value is required`,
          '',
          `conditionsHelper[${index}].value`
        ));
      }
    });

    return createError({
      message: () => errors
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  code: Yup.string()
    .required('Code required'),
  price: Yup.number()
    .typeError('Invalid price')
    .min(0, 'Price cannot be negative')
    .required('Price is required'),
  conditions: Yup.string()
    .required('Conditions are required')
    .test(
      'is-json',
      'Shipping service conditions is not valid JSON',
      (value) => {
        try {
          JSON.parse(value);
        } catch (e) {
          return false;
        }
        return true;
      }
    ),
  conditionsHelper: Yup.array()
    .conditionsHelper('Invalid conditions'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // Map conditions to conditionsHelper
  formValues.conditionsHelper = [];
  resource.conditions?.forEach((condition, index) => {
    const conditionWithUuid = {};
    conditionWithUuid.attribute = condition.attribute;
    conditionWithUuid.comparator = condition.comparator;
    conditionWithUuid.value = condition.value;
    conditionWithUuid.uuid = index;
    formValues.conditionsHelper.push(conditionWithUuid);
  });

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // Map conditionsHelper to conditions
  apiValues.conditions = [];
  formValues.conditionsHelper.forEach((conditionHelper) => {
    const condition = {
      attribute: conditionHelper.attribute,
      comparator: conditionHelper.comparator,
      value: conditionHelper.value,
    };
    apiValues.conditions.push(condition);
  });

  if(formValues.displayMessage !== null && formValues.displayMessage.trim() === '') {
    apiValues.displayMessage = null;
  }

  return apiValues;
}

const AddEditShippingService = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const shippingServiceZoneChoices = await requestApi.getResponse({ url: 'shipping-service-zones/choices' });
      const shippingServiceZoneField = fields.find((field) => field.name === 'shippingServiceZone');
      shippingServiceZoneField.choices = shippingServiceZoneChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <AddEditResource
      addEditForm={AddEditShippingServiceForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditShippingService;
