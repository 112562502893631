/* eslint-disable */
import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response?.status === 401 && error.response?.data?.message === 'Expired JWT Token' && !originalConfig.retry) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        originalConfig.retry = true;
        try {
          const data = { refresh_token: refreshToken };
          const headers = { 'Content-Type': 'application/ld+json; charset=utf-8' };
          const refreshTokenResponse = await axios.post(`${process.env.NEXT_PUBLIC_API_BASE_URL}/token/refresh`, data, headers);
          const newAccessToken = refreshTokenResponse.data?.token;
          const newRefreshToken = refreshTokenResponse.data?.refresh_token;

          if (newAccessToken && newRefreshToken) {
            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalConfig);
          }
        } catch (_error) {
          if (_error.response?.status === 401 && _error.response?.data?.message === 'An authentication exception occurred.') {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.replace('/authentication/login');
          }
          return Promise.reject(_error);
        }
      } else {
        localStorage.removeItem('accessToken');
        window.location.replace('/authentication/login');
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
