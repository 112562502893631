import { useState } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Field } from "formik";
import { putRequestQuery } from 'api/apiRequest';
import capitalise from 'utils/capitalise';
import MDBadge from "mdpr2/components/MDBadge";
import MDTypography from "mdpr2/components/MDTypography";
import MDButton from "mdpr2/components/MDButton";
import convertPriceToPounds from 'utils/convert-price-to-pounds';
import FormField from "../FormField";

const OrderItems = (props) => {
    const {
        formik,
        getApiError,
        Autocomplete,
        basket,
        setBasket,
        toApiValuesCustom,
        fields,
        entityName,
        apiErrors,
        setSearchTerm,
        productOrProductVariantChoices,
        setProductGroupPartsModalOpen,
        userFeedbackSuccess,
        productOrProductVariantIri,
        setProductOrProductVariantIri,
        searchGroupProducts,
        setSearchGroupProducts,
        resource
    } = props

    const [productQuantity, setProductQuantity] = useState(1);

    const handleViewProductsInGroupOnClick = () => {
        setProductGroupPartsModalOpen(true);
    }

    const removeProductFromBasket = async (productIri) => {
        let data;
        if (productIri.match(/^\/product-variants/)) {
            data = {
                basketLines: [
                    { productVariant: productIri, quantity: 0 }
                ]
            }
        } else {
            data = {
                basketLines: [
                    { product: productIri, quantity: 0 }
                ]
            }
        }
        const response = await axios(putRequestQuery(`baskets/${basket.id}`, data));
        if (response && response.data) {
            setBasket(response.data);
            userFeedbackSuccess(`${capitalise(entityName)} has been updated`);
        }
    };

    const getUnboundApiErrorMessages = () => {
        const unboundApiErrorMessages = [];
        const unboundApiErrors = apiErrors?.filter(apiError => apiError.propertyPath === '');
        unboundApiErrors.forEach(unboundApiError => {
            unboundApiErrorMessages.push(unboundApiError.message);
        });
        return unboundApiErrorMessages;
    }

    const addProductsToBasket = async (productOrProductVariantIrisAndQuantities, formValues) => {
        if (!basket.id) {
            return;
        }

        const data = Object.keys(formValues).length > 0 ? toApiValuesCustom(formValues, fields) : {};
        data.basketLines = [];
        productOrProductVariantIrisAndQuantities.forEach((productOrProductVariantIriAndQuantity) => {
            if (productOrProductVariantIriAndQuantity.productOrProductVariantIri.match(/^\/product-variants/)) {
                data.basketLines.push({
                    productVariant: productOrProductVariantIriAndQuantity.productOrProductVariantIri,
                    quantity: productOrProductVariantIriAndQuantity.quantity || 1
                })
            } else {
                data.basketLines.push({
                    product: productOrProductVariantIriAndQuantity.productOrProductVariantIri,
                    quantity: productOrProductVariantIriAndQuantity.quantity || 1
                });
            }
        });
        const response = await axios(putRequestQuery(`baskets/${basket.id}/add`, data));
        if (response && response.data) {
            setBasket(response.data);
            userFeedbackSuccess(`${capitalise(entityName)} has been updated`);
        }
    };

    const handleAddToOrderOnClick = (formValues) => {
        if (productOrProductVariantIri) {
            addProductsToBasket(
                [{ productOrProductVariantIri, quantity: productQuantity }],
                formValues
            );
        }
    };

    return (
        <Card>
            <CardHeader title="Order items" />
            <CardContent>
                <Grid container spacing={2}>
                    {!basket?.id &&
                        <Grid item xs={12}>
                            <MDTypography variant="subtitle2">Please assign a user before adding order items</MDTypography>
                        </Grid>
                    }
                    {basket?.id &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {!resource.isQuote && (
                                    <Grid item xs={8}>
                                        <Field
                                            component={Autocomplete}
                                            options={productOrProductVariantChoices}
                                            disabled={resource.isQuote}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(fieldParams) => (
                                                <TextField
                                                    {...fieldParams}
                                                    label="Search for product"
                                                    name="searchTerm"
                                                    onChange={(event) => setSearchTerm(event.target.value)}
                                                    variant="outlined"
                                                />
                                            )}
                                            /* eslint-disable-next-line no-shadow */
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    {
                                                        option.type && (
                                                            <>
                                                                <MDBadge color="info" badgeContent={option.type} />
                                                                &nbsp;
                                                            </>
                                                        )
                                                    }
                                                    {option.label}
                                                </li>
                                            )}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    setProductOrProductVariantIri(value.value);
                                                }
                                            }}
                                        />
                                        <FormField
                                            type="checkbox"
                                            name="searchGroupProducts"
                                            label="Search group products"
                                            onChange={(event, newValue) => {
                                                setSearchGroupProducts(newValue);
                                                formik.setFieldValue('searchGroupProducts', newValue);
                                            }}
                                            formik={formik}
                                            getApiError={getApiError}
                                        />
                                    </Grid>
                                )}


                                {
                                    // If a non-group Product has been selected, render the quantity and 'Add to order' button
                                    !searchGroupProducts && productOrProductVariantIri && productOrProductVariantChoices.some((productOrProductVariantChoice) => productOrProductVariantChoice.value === productOrProductVariantIri)
                                    && (
                                        <>
                                            <Grid
                                                item
                                                xs={2}
                                            >
                                                <TextField
                                                    name="productQuantity"
                                                    label="Quantity"
                                                    variant="outlined"
                                                    onChange={event => {
                                                        // eslint-disable-next-line no-shadow
                                                        let productQuantity = parseInt(event.target.value, 10);
                                                        if (Number.isNaN(productQuantity)) productQuantity = '';
                                                        setProductQuantity(productQuantity);
                                                    }}
                                                    value={productQuantity}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                            >
                                                <MDButton
                                                    color="info"
                                                    fullWidth
                                                    onClick={() => handleAddToOrderOnClick(formik.values)}
                                                >
                                                    Add to {entityName}
                                                </MDButton>
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    // If a group Product has been selected, render the 'View products in group' button
                                    searchGroupProducts && productOrProductVariantIri && productOrProductVariantChoices.some((productOrProductVariantChoice) => productOrProductVariantChoice.value === productOrProductVariantIri)
                                    && (
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <MDButton
                                                color="info"
                                                fullWidth
                                                onClick={handleViewProductsInGroupOnClick}
                                            >
                                                View products in group
                                            </MDButton>
                                        </Grid>
                                    )
                                }

                                {
                                    getUnboundApiErrorMessages().length > 0 &&
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Alert severity="error">
                                            <ul>
                                                {getUnboundApiErrorMessages().map(unboundApiErrorMessage =>
                                                    <li>{unboundApiErrorMessage}</li>)}
                                            </ul>
                                        </Alert>
                                    </Grid>
                                }

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TableContainer sx={{ boxShadow: "none" }}>
                                        <Table>
                                            <TableHead style={{ display: 'table-row-group' }}>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Line Total</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    basket.basketLines?.map((basketLine, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <TableRow key={`basket-line-${index}`}>
                                                            <TableCell>
                                                                <h4>{basketLine.basketItem.name}</h4>
                                                                {basketLine.basketItem.sku}
                                                                {
                                                                    basketLine.basketItem.attributes.map(attribute => (
                                                                        <>
                                                                            <br />
                                                                            {attribute.name}: {attribute.value}
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    Array.isArray(basketLine.orderData) &&
                                                                    basketLine.orderData.map(orderDataElement => (
                                                                        <>
                                                                            <br />
                                                                            {orderDataElement.label}: {orderDataElement.value}
                                                                        </>
                                                                    ))
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {basketLine.quantity}
                                                            </TableCell>
                                                            <TableCell>
                                                                £{convertPriceToPounds(basketLine.linePriceBeforeDiscount.excTax)}
                                                            </TableCell>
                                                            {!resource.isQuote && (
                                                                <TableCell>
                                                                    <MDButton
                                                                        color="error"
                                                                        iconOnly
                                                                        onClick={() => removeProductFromBasket(basketLine.product || basketLine.productVariant)}
                                                                        variant="gradient"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </MDButton>
                                                                </TableCell>
                                                            )}

                                                        </TableRow>
                                                    ))
                                                }

                                                <TableRow key="discounts_applied">
                                                    <TableCell>
                                                        <h4>Discounts applied</h4>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                </TableRow>

                                                {
                                                    basket.promotions?.length ? basket.promotions?.map((basketUnitDiscount) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {basketUnitDiscount.promotion.name === 'Retrospective Subscription Credit' ?
                                                                    'Credit from unused subscriptions ' :
                                                                    `${basketUnitDiscount.promotion.name} `}
                                                            </TableCell>
                                                            <TableCell />
                                                            <TableCell>
                                                                -£{convertPriceToPounds(basketUnitDiscount.discountPrice.excTax)}
                                                            </TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )) : null
                                                }

                                                <TableRow key="summary">
                                                    <TableCell>
                                                        <h4 style={{ visibility: 'hidden' }}>Summary</h4>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>Subtotal</TableCell>
                                                    <TableCell>£{convertPriceToPounds(basket.subtotalPrice?.excTax || 0)}</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                                {
                                                    basket.manualDiscount === null && basket.discountPrice?.incTax > 0 && (
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell>Discount</TableCell>
                                                            <TableCell>-£{convertPriceToPounds(basket.discountPrice.excTax)}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )
                                                }
                                                {
                                                    basket.manualDiscount !== null && (
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell>Discount</TableCell>
                                                            <TableCell>-£{convertPriceToPounds(basket.discountPrice.excTax)}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )
                                                }
                                                {
                                                    basket.manualShippingServiceAmount === null && (
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell>
                                                                Shipping<br />
                                                                {basket.basketShipping?.shippingService?.name || <strong>No shipping option is available for this order</strong>}
                                                            </TableCell>
                                                            <TableCell>£{convertPriceToPounds(basket.basketShipping?.price?.incTax || 0)}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )
                                                }
                                                {
                                                    basket.manualShippingServiceAmount !== null && (
                                                        <TableRow>
                                                            <TableCell>
                                                                Manual shipping:&nbsp;
                                                                {basket.manualShippingServiceName || <strong>No manual shipping service is set for this order</strong>}
                                                            </TableCell>
                                                            <TableCell />
                                                            <TableCell>£{convertPriceToPounds(basket.manualShippingServiceAmount || 0)}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )
                                                }
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>
                                                        <Tooltip title="Subtotal - Discount + Shipping" disableInteractive>
                                                            <strong>Total Excl. Tax</strong>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>£{convertPriceToPounds((basket.totalPrice?.excTax || 0))}</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>VAT</TableCell>
                                                    <TableCell>£{convertPriceToPounds((basket.totalPrice?.incTax || 0) - (basket.totalPrice?.excTax || 0))}</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>
                                                        <Tooltip title="(Subtotal - Discount + Shipping) + Tax" disableInteractive>
                                                            <strong>Total Incl. Tax</strong>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell><h4><strong>£{convertPriceToPounds(basket.totalPrice?.incTax || 0)}</strong></h4></TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

OrderItems.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    formik: PropTypes.any.isRequired,
    getApiError: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    Autocomplete: PropTypes.object.isRequired,
    setBasket: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    basket: PropTypes.object.isRequired,
    toApiValuesCustom: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    entityName: PropTypes.string.isRequired,
    apiErrors: PropTypes.arrayOf(PropTypes.string),
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func.isRequired,
    productOrProductVariantChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    setProductGroupPartsModalOpen: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    userFeedbackSuccess: PropTypes.func.isRequired,
    productOrProductVariantIri: PropTypes.string,
    setProductOrProductVariantIri: PropTypes.func.isRequired,
    searchGroupProducts: PropTypes.bool.isRequired,
    setSearchGroupProducts: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
};

OrderItems.defaultProps = {
    apiErrors: [],
    searchTerm: '',
    productOrProductVariantIri: '',
};

export default OrderItems