/* eslint-disable */
import React from 'react';
import axios from 'axios';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DataGrid } from "@mui/x-data-grid";
import { Box, Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardContent, Grid } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EyeIcon from '@mui/icons-material/Visibility';
import RefreshRounded from "@mui/icons-material/RefreshRounded";
import GetApp from "@mui/icons-material/GetApp";

// MDPR
import MDButton from 'mdpr2/components/MDButton';

// WRM
import CardHeader from 'components/shared/CardHeader';
import LoadingSpinner from "components/LoadingSpinner";
import { getRequestQuery } from 'api/apiRequest';
import DateRangeForm from "components/shared/DateRangeForm/DateRangeForm";
import getPriceLocaleString from 'utils/get-price-locale-string';
import StackedBarAnalyticsChart from "components/shared/StackedBarAnalyticsChart/StackedBarAnalyticsChart";
import getDateLocaleString from 'utils/get-date-locale-string';
import ListResources from "components/shared/ListResources";
import StatCard from "components/shared/StatCard/StatCard";

// http://vrl.cs.brown.edu/color
const seriesColours = [
    "#256676",
    "#1e965f",
    "#d93669",
    "#ed820a",
    "#2ee52d",
    "#d45fea",
    "#f24219",
    "#eac328",
    "#7c8a4f",
    "#fcc2fb"
];

const localStyles = {
    infoText: {
        color: "#256676"
    },
    rotateIcon: {
        animation: "$spin 1s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    }
};

class DiscountAnalytics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // init component with WTD date-range
            startDate: moment().utc().subtract(6, 'days'),
            endDate: moment.utc(),
            loading: false,
            discountsData: null,
            headlineNumbers: null,
            breakdownNumbers: null,
            chartData: null,
            chartDataSeries: null,
            promotionTypeDrillDialogShow: false,
            promotionTypeDrillCriteria: null,
            downloadingXls: false
        };
    }

    openPromotionTypeDrillDialog({startDate, endDate, promotionCode, promotionName}) {
        this.setState({
            promotionTypeDrillDialogShow: true,
            promotionTypeDrillCriteria: {startDate, endDate, promotionCode, promotionName}
        });
    }

    closePromotionTypeDrillDialog() {
        this.setState({
            promotionTypeDrillDialogShow: false,
            promotionTypeDrillCriteria: null
        });
    }

    componentDidMount() {
        this.refreshData(this.state);
    }

    refreshData({ startDate, endDate }) {
        this.setState({
            loading: true,
            startDate,
            endDate
        });

        this.closePromotionTypeDrillDialog();

        let discountsQueryString = 'analytics/discounts';
        discountsQueryString += ('?startDate=' + (startDate ? startDate.format('YYYY-MM-DD') : ''));
        discountsQueryString += ('&endDate=' + (endDate ? endDate.format('YYYY-MM-DD') : ''));
        axios(getRequestQuery(discountsQueryString)).then((response) => {

            if (response.status === 200) {
                this.setState({
                    discountsData: response.data
                });

                this.rebuildNumbers();
                this.rebuildChartData();
            }
            else {
                this.setState({
                    discountsData: null,
                    headlineNumbers: null,
                    breakdownNumbers: null,
                    chartData: null,
                    chartDataSeries: null
                })
            }

            this.setState({loading: false})
        });
    }

    rebuildNumbers() {
        const { discountsData } = this.state;
        const breakdownNumbers = {};
        const headlineNumbers = {
            discountExcTax: 0,
            discountedOrderUnits: 0,
            discountedOrders: 0,
        };

        let dataPoint = null;

        for (let i = 0; i < discountsData.length; i++)
        {
            dataPoint = discountsData[i];

            if (!(dataPoint.promotionCode in breakdownNumbers)) {
                breakdownNumbers[dataPoint.promotionCode] = {
                    id: dataPoint.promotionCode,
                    discountExcTax: dataPoint.discountExcTax,
                    discountedOrderUnits: dataPoint.discountedOrderUnits,
                    discountedOrders: dataPoint.discountedOrders,
                    promotionCode: dataPoint.promotionCode,
                    promotionName: dataPoint.promotionName,
                }
            } else {
                breakdownNumbers[dataPoint.promotionCode].discountExcTax += dataPoint.discountExcTax;
                breakdownNumbers[dataPoint.promotionCode].discountedOrderUnits += dataPoint.discountedOrderUnits;
                breakdownNumbers[dataPoint.promotionCode].discountedOrders += dataPoint.discountedOrders;
            }

            headlineNumbers.discountExcTax += dataPoint.discountExcTax;
            headlineNumbers.discountedOrderUnits += dataPoint.discountedOrderUnits;
            headlineNumbers.discountedOrders += dataPoint.discountedOrders;
        }

        this.setState({
            breakdownNumbers: Object.keys(breakdownNumbers)
                .map((key) => breakdownNumbers[key])
                .sort((a,b) => a.discountExcTax > b.discountExcTax ? -1 : 1),
            headlineNumbers
        });
    }

    orderedDatesInPeriod() {
        const { startDate, endDate } = this.state;

        if (!startDate || !endDate || endDate.isBefore(startDate)) {
            return [];
        }

        const orderedDatesInPeriod = [];
        let tmpDate = moment(startDate);

        while (tmpDate.isSameOrBefore(endDate)) {
            orderedDatesInPeriod.push(tmpDate.format('YYYY-MM-DD'));
            tmpDate = moment(tmpDate).add(1, 'days');
        }

        return orderedDatesInPeriod;
    }

    rebuildChartData() {

        const { discountsData } = this.state;
        const orderedDatesInPeriod = this.orderedDatesInPeriod();
        const chartDataSeries = {};
        const tmpChartData = {};
        let colIdx = 0, promoKey = null, dataPoint = null;

        for (let i = 0; i < discountsData.length; i++) {
            dataPoint = discountsData[i];
            promoKey = dataPoint.promotionCode;

            if (!(promoKey in chartDataSeries)) {
                chartDataSeries[promoKey] = {
                    key: promoKey,
                    label: dataPoint.promotionName,
                    colour: seriesColours[colIdx++ % seriesColours.length]
                }
                tmpChartData[promoKey] = [];
            }

            if (!(dataPoint.createdAt in tmpChartData)) {
                tmpChartData[dataPoint.createdAt] = {};
            }

            tmpChartData[dataPoint.createdAt][promoKey] = dataPoint.discountExcTax;
        }

        const chartData = orderedDatesInPeriod.map((createdAt) => {

            const item = {
                createdAt
            };

            for (promoKey in chartDataSeries) {
                item[promoKey] = 0;

                if ((createdAt in tmpChartData) && (promoKey in tmpChartData[createdAt])) {
                    item[promoKey] = tmpChartData[createdAt][promoKey];
                }
            }

            return item;
        });

        this.setState({
            chartData,
            chartDataSeries: Object.keys(chartDataSeries).map((key) => chartDataSeries[key])
        });
    }

    downloadXls() {
        const { startDate, endDate } = this.state;

        this.setState({ downloadingXls: true });

        let requestUrl = 'order-promotions/xls-export';
        requestUrl += ('?startDate=' + (startDate ? startDate.format('YYYY-MM-DD') : ''));
        requestUrl += ('&endDate=' + (endDate ? endDate.format('YYYY-MM-DD') : ''));

        axios(getRequestQuery(requestUrl, 'blob'))
            .then((response) => {
                const filename = response.headers['content-disposition'].split(';')[1].split('=')[1];
                const blob = new Blob(
                    [response.data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                );
                const objectUrl = window.URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');

                downloadLink.href = objectUrl;
                downloadLink.download = filename;
                downloadLink.click();

                window.URL.revokeObjectURL(objectUrl);

            })
            .finally(() => {
                this.setState({ downloadingXls: false });
            });
    }

    render() {
        const { classes } = this.props;

        const onDateRangeFormUpdated = ({ startDate, endDate }) => {
            this.refreshData({ startDate, endDate });
        }

        return (
            <>
                {/* header section */}
                <Grid container mt={-2} spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="info">
                                Discounts
                            </CardHeader>
                            <CardContent>
                                <DateRangeForm
                                    initStartDate={this.state.startDate}
                                    initEndDate={this.state.endDate}
                                    onUpdate={onDateRangeFormUpdated}
                                >
                                </DateRangeForm>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* data section */}
                { this.state.loading ?
                    <LoadingSpinner title={''}/> :
                    [
                        this.renderHeadlineNumbers(),
                        this.renderBreakdownNumbers(),
                        this.renderChart()
                    ]
                }

                {/* promotion drill dialog */}
                { this.state.promotionTypeDrillDialogShow ? this.renderPromotionTypeDrillDialog() : null }
            </>
        );
    }

    renderHeadlineNumbers() {

        if (!this.state.headlineNumbers) {
            return;
        }

        const { classes } = this.props;

        return (
            <Grid container spacing={4} my={2}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <StatCard
                        statName="Total Discounts (Exc. Tax)"
                        fontSize="large"
                        icon="currency_pound"
                        headline={<>
                            <small>£</small>
                            {getPriceLocaleString(this.state.headlineNumbers.discountExcTax)}
                        </>}
                        secondary={<></>}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <StatCard
                        statName="Total Discounted Orders"
                        fontSize="large"
                        icon="shopping_cart"
                        headline={<>
                            {this.state.headlineNumbers.discountedOrders.toLocaleString()}
                        </>}
                        secondary={<></>}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <StatCard
                        statName="Total Discounted Order Items"
                        fontSize="medium"
                        icon="circle"
                        headline={<>
                            {this.state.headlineNumbers.discountedOrderUnits.toLocaleString()}
                        </>}
                        secondary={<></>}
                    />
                </Grid>
            </Grid>
        );
    }

    renderBreakdownNumbers() {

        if (!this.state.breakdownNumbers) {
            return;
        }

        const { classes } = this.props;
        const { downloadingXls } = this.state;

        return (
            <Grid spacing={4} my={2} container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" text>
                            <h4>
                                Breakdown by Promotion Type {this.state.startDate.format('DD/MM/YYYY')} - {this.state.endDate.format('DD/MM/YYYY')}
                            </h4>
                        </CardHeader>
                        <CardContent>
                            <Box
                                sx={{ mt: -6, mb: 1 }}
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                <MDButton
                                    disabled={downloadingXls}
                                    onClick={ () => { this.downloadXls(); }}
                                    color="success"
                                >
                                    { downloadingXls ?
                                        (<RefreshRounded className={classes.rotateIcon}/>) :
                                        (<GetApp/>)
                                    }
                                    Download XLS
                                </MDButton>
                            </Box>

                            <DataGrid
                                columns={[
                                    {field: 'promotionName', headerName: 'Promotion Type', flex: 2},
                                    {
                                        field: 'discountExcTax', headerName: 'Total Discounts', flex: 1,
                                        valueFormatter: (params) => { return '£' + getPriceLocaleString(params.value) }
                                    },
                                    {
                                        field: 'discountedOrders', headerName: 'No. Orders', flex: 1,
                                        valueFormatter: (params) => { return params.value.toLocaleString() }
                                    },
                                    {
                                        field: 'discountedOrderUnits', headerName: 'No. Order Units', flex: 1,
                                        valueFormatter: (params) => { return params.value.toLocaleString() }
                                    },
                                    {
                                        field: 'promo-type-drill', headerName: ' ', sortable: false, flex: '0 0',
                                        renderCell: (params) => {
                                            const { promotionCode, promotionName } = params.row;
                                            const { startDate, endDate } = this.state;
                                            return (
                                                <Tooltip title="View Detail">
                                                    <MDButton simple
                                                            justIcon
                                                            color="info"
                                                            onClick={() => this.openPromotionTypeDrillDialog({
                                                                startDate,
                                                                endDate,
                                                                promotionCode,
                                                                promotionName
                                                            })}>
                                                        <EyeIcon/>
                                                    </MDButton>
                                                </Tooltip>
                                            );
                                        }
                                    }
                                ]}
                                rows={this.state.breakdownNumbers || []}
                                pageSize={10}
                                autoHeight
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    renderChart() {
        const barClicked = (dateStr) => {
            this.openPromotionTypeDrillDialog({
                startDate: moment(dateStr),
                endDate: moment(dateStr),
                promotionCode: null,
                promotionName: null
            });
        }

        return this.state.discountsData ? (
            <Grid spacing={4} my={2} container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" text>
                            <h4>
                                Breakdown by Date / Promotion Type {this.state.startDate.format('DD/MM/YYYY')} - {this.state.endDate.format('DD/MM/YYYY')}
                            </h4>
                        </CardHeader>
                        <CardContent>
                            <Box mb={ 4 } ml={ 4 }>
                                <p>Click a bar/date in the chart to see a detailed view of promotion/discounts for that date...</p>
                            </Box>
                            <StackedBarAnalyticsChart
                                onClickedBar={barClicked}
                                chartData={this.state.chartData}
                                chartDataSeries={this.state.chartDataSeries}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        ) : null;
    }

    renderPromotionTypeDrillDialog() {
        const { startDate, endDate, promotionCode, promotionName } = this.state.promotionTypeDrillCriteria;
        const { classes } = this.props;

        const title = "Discounted Orders";
        const apiEndpoint = 'order-promotions';
        const resourceName = 'Order Promotion';
        const fixedFilterValues = {
            'createdFrom': (startDate ? startDate.format('YYYY-MM-DD') : ''),
            'createdTo': (endDate ? endDate.format('YYYY-MM-DD') : '')
        };

        if (promotionCode !== null) {
            fixedFilterValues['promotionCode'] = promotionCode;
        };

        const columns = [
            {
                field: 'createdAt',
                label: 'Order Created',
                sortable: false,
                flex: 1,
                formatter: ({createdAt}) => getDateLocaleString(createdAt)
            },
            {
                field: 'customer', label: 'Customer', sortable: false, flex: 1,
            },
            {
                field: 'promotionCreatedByEtc', label: 'Promo Code / Created By / (hover for notes)', sortable: false, flex: 2,
                formatter: ({ promotionCreatedBy, promotionCode, promotionDescription }) => {
                    const displayPromoDescription = typeof (promotionDescription) === 'string' && promotionDescription.trim() !== '';

                    return (
                        <Tooltip title={ displayPromoDescription ? promotionDescription : '- No Notes -'}>
                            <Box sx={{"py": 2}}>
                                {`${promotionCode} / ${promotionCreatedBy}`}
                            </Box>
                        </Tooltip>
                    );
                }
            },
            {
                field: 'openPromoInNewTab', label: 'Promo', flex: '0', sortable: false,
                formatter: ({ promotionId, promotionCode }) => {
                    const resourceType = promotionCode.match('^single_use_discount') ? 'single-use-discounts' : 'promotions';

                    const url = `/admin/${resourceType}/edit/${promotionId}`;
                    return (
                        <MDButton
                            disabled={promotionCode.match('^manual_discount')}
                            justIcon
                            color="info"
                            className="open-in-new-tab"
                            onClick={() => window.open(url)}>
                            <OpenInNewIcon />
                        </MDButton>
                    );
                }
            },
            {
                field: 'discountExcTax',
                label: 'Discount Amount',
                sortable: false,
                flex: 1,
                formatter: ({discountExcTax}) => { return '£' + getPriceLocaleString(discountExcTax) }
            },
            {
                field: 'openOrderInNewTab', label: 'Order', flex: '0 0', sortable: false,
                formatter: ({ orderId }) => {
                    const url = `/admin/orders/edit/${orderId}`;
                    return (
                        <MDButton justIcon color="info" className="open-in-new-tab" onClick={() => window.open(url)}><OpenInNewIcon /></MDButton>
                    );
                }
            },
        ];

        const config = {
            title,
            apiEndpoint,
            resourceName,
            columns,
            defaultSortValues: [],
            filters: [],
            fixedFilterValues,
            defaultSorting: [],
            disableAddResource: true,
        }

        const closeModal = () => {
            this.closePromotionTypeDrillDialog();
        }

        // ensure our page number is not cached
        // resetResourcesState(apiEndpoint);
        // @todo - may have to revisit this if people want our resource lookup modal to reset to page 1 every time

        return  (
            <Dialog
                open={this.state.promotionTypeDrillDialogShow}
                onClose={closeModal}
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">
                    Promotion Type: <span className={classes.infoText}>{promotionName ?? 'All'} - </span>
                    { startDate.format('DD/MM/YYYY')} { endDate.isAfter(startDate) ? ' - ' + endDate.format('DD/MM/YYYY') : ''}
                </DialogTitle>
                <DialogContent>
                    <ListResources {...config}/>
                </DialogContent>
                <DialogActions>
                    <MDButton color="default" autoFocus onClick={closeModal}>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles({ ...localStyles })(DiscountAnalytics);
