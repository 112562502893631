import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { requestApi } from 'api/request-api';
import useMounted from 'hooks/use-mounted';
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import { useAppContext } from 'contexts/app-context';

const apiEndpoint = 'menu-items';
const resourceName = 'menu item';

const checkFieldRender = (parentName, parentValue, name) => {
  if (parentName === 'type') {
    if (parentValue === name) return true;
    return false;
  }
  return true;
}

const fields = [
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    choices: [
      {
        value: 'webPage',
        label: 'Web page',
      },
      {
        value: 'resourcePage',
        label: 'Resource page',
      },
      {
        value: 'directUri',
        label: 'Direct URL',
      },
      {
        value: 'imageUrl',
        label: 'Image URL',
      },
    ],
    defaultValue: 'webPage'
  },
  {
    name: 'webPage',
    label: 'Web page',
    type: 'select',
    parent: 'type',
    checkFieldRender,
  },
  {
    name: 'resourcePage',
    label: 'Resource page',
    type: 'select',
    parent: 'type',
    checkFieldRender,
  },
  {
    name: 'directUri',
    label: "Direct URL (# for no link)",
    type: 'text',
    parent: 'type',
    checkFieldRender
  },
  {
    name: 'imageUrl',
    label: "Image URL",
    // don't use filePicker here because we would be nesting drag and drop contexts, as this component already exists inside the tree
    // see issue https://github.com/react-dnd/react-dnd/issues/186 on ideas for how to resolve
    // for now just use text field for the image URL
    type: 'text',
    parent: 'type',
    checkFieldRender
  },
  {
    name: 'label',
    label: 'Label',
    type: 'text',
  },
  {
    name: 'colspan',
    label: 'Colspan',
    type: 'number'
  },
];

const validationSchema = Yup.object().shape({
  type: Yup.string(),
  label: Yup.string().required('Label is required'),
  colspan: Yup.string().nullable().test(
      'custom-test',
      'colspan must be an integer or blank',
      value => value === '' || (value ?? null) === null || Number.isInteger(Number(value))
  ),
  webPage: Yup.string().when('type', {
    is: 'webPage',
    then: Yup.string()
        .required('Web page is required')
        .notOneOf(['', 'none'], 'Web page is required'),
    otherwise: Yup.string(),
  }),
  resourcePage: Yup.string().when('type', {
    is: 'resourcePage',
    then: Yup.string()
        .required('Resource page is required')
        .notOneOf(['', 'none'], 'Resource page is required'),
    otherwise: Yup.string(),
  }),
  directUri: Yup.string().when('type', {
    is: 'directUri',
    then: Yup.string().required('Direct URL is required'),
    otherwise: Yup.string().nullable(),
  }),
  imageUrl: Yup.string().when('type', {
    is: 'imageUrl',
    then: Yup.string().required('Image URL is required'),
    otherwise: Yup.string().nullable(),
  }),
});

const AddEditMenuItem = (props) => {
  const {
    menuItemId,
    menuId,
    onModalClose,
    parentMenuItemId,
  } = props;

  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const resourcePageChoices = await requestApi.getResponse({url: 'resource-pages/choices'});
      const resourcePageField = fields.find((field) => field.name === 'resourcePage');
      resourcePageField.choices = resourcePageChoices;
      const webPageChoices = await requestApi.getResponse({url: 'web-pages/choices'});
      const webPageField = fields.find((field) => field.name === 'webPage');
      webPageField.choices = webPageChoices;
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    // Perform standard mapping
    const formValues = toFormValues(resource, fields);

    return formValues;
  }

  // eslint-disable-next-line no-shadow
  const toApiValuesCustom = (formValues, fields) => {
    // Perform standard mapping
    const apiValues = toApiValues(formValues, fields);

    // Nullify the invalid directUri/resourcePage/webPage data, depending on type
    if (apiValues.type === 'directUri') {
      apiValues.resourcePage = null;
      apiValues.webPage = null;
      apiValues.imageUrl = null;
    }
    if (apiValues.type === 'resourcePage') {
      apiValues.directUri = null;
      apiValues.webPage = null;
      apiValues.imageUrl = null;
    }
    if (apiValues.type === 'webPage') {
      apiValues.directUri = null;
      apiValues.resourcePage = null;
      apiValues.imageUrl = null;
    }
    if (apiValues.type === 'imageUrl') {
      apiValues.directUri = null;
      apiValues.resourcePage = null;
      apiValues.webPage = null;
    }

    if (parentMenuItemId) {
      apiValues.parentMenuItem = `/menu-items/${parentMenuItemId}`;
    }
    // it doesn't hang off a parent menu item, so if new, parent is the menu itself!
    else if (!menuItemId) {
      apiValues.menu = `/menus/${menuId}`;
    }

    return apiValues;
  }

  return (
    <AddEditResource
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={menuItemId}
      isModal
      onModalClose={onModalClose}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
}

AddEditMenuItem.propTypes = {
  menuId: PropTypes.number,
  menuItemId: PropTypes.number,
  onModalClose: PropTypes.func,
  parentMenuItemId: PropTypes.number,
};

AddEditMenuItem.defaultProps = {
  menuId: null,
  menuItemId: null,
  onModalClose: () => {},
  parentMenuItemId: null,
};

export default AddEditMenuItem;
