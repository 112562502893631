import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import { ConvertLearningGroupForm } from './ConvertLearningGroupForm';

export const ConvertLearningGroupModal = ({
  formRef,
  convertRequestErrored,
  setconvertLearningGroupModalOpen,
  onSubmitForm,
  schoolAleadyLearningGroup,
}) => (
  <Dialog
    open
    aria-labelledby="convert-learning-group"
    aria-describedby="convert-learning-group"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle id="convert-learning-group-title">Convert custom group to school group</DialogTitle>
    <DialogContent>
      <ConvertLearningGroupForm
        formRef={formRef}
        convertRequestErrored={convertRequestErrored}
        schoolAleadyLearningGroup={schoolAleadyLearningGroup}
      />
    </DialogContent>
    <DialogActions>
      <MDButton color="info" onClick={onSubmitForm} variant="gradient" data-testid="convert-group-modal-submit">
        Convert to school group
      </MDButton>
      <MDButton
        color="secondary"
        onClick={() => setconvertLearningGroupModalOpen(false)}
        variant="gradient"
        data-testid="convert-group-modal-cancel"
      >
        Cancel
      </MDButton>
    </DialogActions>
  </Dialog>
);

ConvertLearningGroupModal.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  convertRequestErrored: PropTypes.bool.isRequired,
  setconvertLearningGroupModalOpen: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  schoolAleadyLearningGroup: PropTypes.bool.isRequired,
};

export default ConvertLearningGroupModal;
