import PropTypes from 'prop-types';

// MUI
import { Grid } from '@mui/material';

// MDPR
import image from 'mdpr2/assets/images/login-bg.png';
import MDBox from 'mdpr2/components/MDBox';
import PageLayout from 'mdpr2/examples/LayoutContainers/PageLayout';

const AuthLayout = ({ children }) => (
  <PageLayout>
    <MDBox
      position="absolute"
      width="100%"
      minHeight="100vh"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          image &&
          `${linearGradient(
            rgba(gradients.dark.main, 0.6),
            rgba(gradients.dark.state, 0.6)
          )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
    <MDBox px={1} width="100%" height="100vh" mx="auto">
      <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          {children}
        </Grid>
      </Grid>
    </MDBox>
  </PageLayout>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
