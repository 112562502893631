// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Sage product codes';
const apiEndpoint = 'sage-product-codes';
const resourceName = 'Sage product code';

const columns = [
  {
    field: 'product.sku',
    label: 'Product SKU',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.product.sku,
  },
  {
    field: 'product.name',
    label: 'Product name',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.product.name,
  },
  {
    field: 'nominalCodeUk',
    label: 'Nominal code (UK)',
    sortable: true,
    flex: 1,
  },
  {
    field: 'nominalCodeInternational',
    label: 'Nominal code (International)',
    sortable: true,
    flex: 1,
  },
  {
    field: 'departmentCodeUk',
    label: 'Department code (UK)',
    sortable: true,
    flex: 1,
  },
  {
    field: 'departmentCodeInternational',
    label: 'Department code (International)',
    sortable: true,
    flex: 1,
  },
  {
    field: 'sageSalesOrderOverride',
    label: 'Sage sales order override',
    sortable: true,
    flex: 1,
    formatter: (resource) => resource.sageSalesOrderOverride?.toUpperCase(),
  },
];

const filters = [
  {
    field: 'search',
    label: 'Search',
    type: 'text',
  },
  {
    field: 'hasMissingCodes',
    label: 'Has missing code?',
    type: 'checkbox',
  },
];

const defaultSortValues = [];

const SageProductCodes = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    disableAddResource
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default SageProductCodes;
