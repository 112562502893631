/**
 * Provide our own versions of react router useBlocker() and usePrompt() functions
 *
 * @todo - usePrompt/useBlocker functions will likely be added back into a later minor version of react router 6 (see
 * comment below) - add back in when we can, until then, using this approach seems to be the de-facto workaround,
 * recommended in several places including here:
 * https://denislistiadi.medium.com/react-router-v6-preventing-transitions-2389806e8556
 *
 * [ref https://github.com/remix-run/react-router/blob/main/docs/upgrading/v5.md]
 * <Prompt> from v5 (along with usePrompt and useBlocker from the v6 betas) are not included in the current released
 * version of v6. We decided we'd rather ship with what we have than take even more time to nail down a feature that
 * isn't fully baked. We will absolutely be working on adding this back in to v6 at some point in the near future,
 * but not for our first stable release of 6.x.
 */
import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export function useBlocker(blocker, when = true) {
    const { navigator } = React.useContext(NavigationContext);
    React.useEffect(() => {
        if (!when) return;
        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                }
            };
            blocker(autoUnblockingTx);
        });
        // eslint-disable-next-line consistent-return
        return unblock;
    }, [navigator, blocker, when]);
}
export default function usePrompt(message, when = true) {
    const blocker = React.useCallback(
        (tx) => {
            if (window.confirm(message)) tx.retry();
        },
        [message]
    );
    useBlocker(blocker, when);
}