/* eslint-disable */
import { getIn } from 'formik';

// MUI
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';

const Checkbox = (props) => {
  const {
    formik,
    getApiError = () => false,
    label,
    name,
    onChange,
  } = props;

  const errorMessage = getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name));

  return (
    <Box sx={{ ml: 1 }}>
      <FormControlLabel
        control={(
          <Switch
            checked={getIn(formik.values, name)}
            onChange={(event, newValue) => onChange ? onChange(event, newValue) : formik.setFieldValue(name, newValue)}
          />
        )}
        label={label}
      />
      {
        errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>
      }
    </Box>
  );
};

export default Checkbox;
