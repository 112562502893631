import { useEffect, useState } from 'react';
import ListResources from 'components/shared/ListResources';
import getDateLocaleString from 'utils/get-date-locale-string';
import humanize from 'utils/humanize';
import MDButton from 'mdpr2/components/MDButton';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { Icon } from '@mui/material';
import ConfirmModal from "components/shared/ConfirmModal";
import { toast } from 'react-hot-toast';
import { EditStockChangeModal } from './components/EditStockChangeModal';
import { stockChangeVerbPhrase } from './helpers';
import { stockChangeTypes, writeOffReasons } from './constants';

const StockChanges = () => {
  const title = 'Stock audit';
  const apiEndpoint = 'stock-changes';
  const resourceName = 'stock change entry';
  const [editStockChangeModalOpen, setEditStockChangeModalOpen] = useState(false);
  const [deleteStockChangeModalOpen, setDeleteStockChangeModalOpen] = useState(false);
  const [currentStockChange, setCurrentStockChange] = useState(false);
  const [rerender, setRerender] = useState(false);

  const handleOpenEditStockChangeModal = (stockChangeToBeEdited) => {
    setCurrentStockChange(stockChangeToBeEdited);
    setEditStockChangeModalOpen(true);
    setRerender((prev) => !prev);
  };

  const handleOpenDeleteStockChangeModal = (stockChangeToBeDeleted) => {
    setCurrentStockChange(stockChangeToBeDeleted);
    setDeleteStockChangeModalOpen(true);
    setRerender((prev) => !prev);
  };

  const nonEditableStockTypes = ['reserved', 'despatched'];

  const renderEditDeleteBtns = (resource) => {
    if (nonEditableStockTypes.includes(resource.typeDisplay.toLowerCase())) {
      return null;
    }

    return (
      <>
        <MDButton
          color="info"
          type="submit"
          variant="gradient"
          sx={{ mr: 1 }}
          size="small"
          onClick={() => handleOpenEditStockChangeModal(resource)}
        >
          Edit
        </MDButton>
        <MDButton
          color="error"
          type="submit"
          variant="gradient"
          size="small"
          onClick={() => handleOpenDeleteStockChangeModal(resource)}
        >
          <Icon>delete</Icon>
        </MDButton>
      </>
    );
  }

  const columns = [
    {
      field: '_type',
      label: 'Type',
      flex: 1,
      formatter: (resource) => {
        const { typeDisplay } = resource;
        return humanize(typeDisplay ?? '');
      },
    },
    {
      field: '_productSku',
      label: 'SKU',
      flex: 1,
      formatter: (resource) => {
        const { actualProduct } = resource;
        return actualProduct.sku;
      },
    },
    {
      field: '_productName',
      label: 'Product',
      flex: 1.5,
      formatter: (resource) => {
        const { actualProduct } = resource;
        return actualProduct.name;
      },
    },
    {
      field: '_order',
      label: 'Order #',
      flex: 1,
      formatter: (resource) => resource.order?.reference,
    },
    {
      field: '_quantity',
      label: 'Quantity',
      flex: 2,
      formatter: (resource) => {
        // eslint-disable-next-line no-shadow
        const { location, quantity, type, warehouseDelta } = resource;
        const verbPhrase = stockChangeVerbPhrase(type, warehouseDelta);

        return `${typeof quantity === 'object' ? Object.values(quantity)[0] : quantity} ${verbPhrase} ${location ? `${location.name} ` : ''}stock`;
      },
    },
    {
      field: 'createdAt',
      label: 'Created',
      sortable: true,
      flex: 1,
      formatter: (resource) => getDateLocaleString(resource.createdAt),
    },
    {
      field: 'supplier',
      label: 'Supplier',
      sortable: false,
      flex: 1,
      formatter: (resource) => (resource?.supplier ? resource.supplier.name : ''),
    },
    {
      field: '_notes',
      label: 'Notes',
      flex: 2,
      formatter: (resource) => resource.notes?.join(' / '),
    },
    {
      field: '_edit',
      label: '',
      flex: 3,
      formatter: (resource) => renderEditDeleteBtns(resource),
    },
  ];

  const filters = [
    {
      field: '_productSkuSearch',
      label: 'SKU',
      type: 'text',
      apiAttribute: 'productSkuSearch',
    },
    {
      field: '_orderSearch',
      label: 'Order',
      type: 'text',
      apiAttribute: 'orderSearch',
    },
    {
      field: '_stockChangeType',
      label: 'Stock Change Type',
      type: 'select',
      choices: stockChangeTypes,
      apiAttribute: 'type',
    },
    {
      field: '_writeOffReason',
      label: 'Write Off Reason',
      type: 'select',
      choices: writeOffReasons,
      apiAttribute: 'writeOffReason',
    },
    {
      field: '_startDateSearch',
      label: 'Start Date',
      type: 'datetime',
      apiAttribute: 'createdAt[after]',
    },
    {
      field: '_endDateSearch',
      label: 'End Date',
      type: 'datetime',
      apiAttribute: 'createdAt[before]',
    },
  ];

  const defaultFilterValues = {
    _stockChangeType: [],
    _writeOffReason: [],
  };

  const defaultSortValues = [
    {
      field: 'createdAt',
      direction: 'desc',
    },
  ];

  const submitForm = async (stockChange, data) => {
    await requestApi.putResponse({
      url: `stock-change/${stockChange.id}/quantity`,
      data,
    }).then(() => {
      setEditStockChangeModalOpen(false);
      toast.success('Successfully updated stock change.');
      setRerender((prev) => !prev);
    }
    );
  };

  const deleteStockChange = async (stockChange) => {
    await resourceApi.deleteResource({ apiEndpoint: 'stock-change', id: stockChange.id }).then(() => {
      setDeleteStockChangeModalOpen(false);
      toast.success('Successfully deleted stock change.');
      window.location.reload();
    });
  };

  useEffect(() => { }, [rerender]);

  return (
    <>
      <ListResources
        apiEndpoint={apiEndpoint}
        columns={columns}
        defaultSortValues={defaultSortValues}
        filters={filters}
        resourceName={resourceName}
        title={title}
        disableAddResource
        disableEditResource
        defaultFilterValues={defaultFilterValues}
        disableOpenInNewTab
        isDownloadable
      />
      {editStockChangeModalOpen && (
        <EditStockChangeModal
          onClose={() => setEditStockChangeModalOpen(false)}
          onSubmit={submitForm}
          stockChange={currentStockChange}
        />
      )}
      <ConfirmModal
        open={deleteStockChangeModalOpen}
        body='Once you delete this stock change? It cannot be recovered'
        title='Are you sure?'
        setOpen={(open) => setDeleteStockChangeModalOpen(open)}
        isDelete
        confirmCallback={() => deleteStockChange(currentStockChange)}
      />
    </>
  );
};

export default StockChanges;
