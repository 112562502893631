/* eslint-disable */
import axios from './axios';

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  if (localStorage.getItem('accessToken')) {
    headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return headers;
};

class ResourcesApi {
  // eslint-disable-next-line class-methods-use-this
  async getResources({
                       apiEndpoint,
                       filterValues = {},
                       sortValues = [],
                       pagination = {}
    }) {

    return new Promise((resolve, reject) => {
      try {
        const parameters = [];
        Object.keys(filterValues).forEach((filterKey) => {
          // Handle multiple values (eg from a multi select)
          if (Array.isArray(filterValues[filterKey])) {
            const parameterArray = [];
            filterValues[filterKey].forEach((filterValue) => parameterArray.push(`${filterKey}[]=${encodeURIComponent(filterValue)}`));
            parameters.push(parameterArray.join('&'));
          } else {
            parameters.push(`${filterKey}=${encodeURIComponent(filterValues[filterKey])}`);
          }
        });
        sortValues.forEach((sortValue) => {
          parameters.push(`_order[${sortValue.field}]=${sortValue.direction}`);
        });
        if (pagination.page) {
          parameters.push(`_page=${pagination.page}`);
        }
        if (pagination.itemsPerPage) {
          parameters.push(`_itemsPerPage=${pagination.itemsPerPage}`);
        }

        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}?${parameters.join('&')}`,
          headers: getHeaders(),
        };

        axios(axiosConfig)
          .then((response) => {
            const resources = response.data['hydra:member'];
            const resourceCount = response.data['hydra:totalItems'];
            resolve({ resources, resourceCount });
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async getResource({ apiEndpoint, id }) {

    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}/${id}`,
          headers: getHeaders(),
        };

        axios(axiosConfig)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async saveResource(props) {
    const {
      apiEndpoint,
      data,
      id,
      extension,
    } = props;

    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: id ? 'PUT' : 'POST',
          url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}${id ? `/${id}` : ''}${extension ? `/${extension}` : ''}`,
          headers: getHeaders(),
          data: JSON.stringify(data),
        };

        axios(axiosConfig)
          .then((response) => {
            resolve({ resource: response.data });
          })
          .catch((error) => {
            if (error.response?.status === 422) {
              resolve({ resource: null, violations: error.response.data?.violations || [] });
            }
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteResource(props) {
    const {
      apiEndpoint,
      id,
    } = props;

    return new Promise((resolve, reject) => {
      try {
        const axiosConfig = {
          method: 'DELETE',
          url: `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}/${id}`,
          headers: getHeaders(),
        };

        axios(axiosConfig)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.message || 'There was a problem';
            reject(new Error(errorMessage));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const resourceApi = new ResourcesApi();
